import { UserDevicePermission } from "../constants/index.js";

export class UserDevicePermissionsManager {
	constructor({ logger, mobileApp }) {
		this.logger = logger.nested({ name: "UserDevicePermissionsManager" });
		this.mobileApp = mobileApp;
		this.userDevicePermissionsDialogRef = null;
	}

	setDialogRef(dialogRef) {
		this.userDevicePermissionsDialogRef = dialogRef;
	}

	async isTrackingAllowed({ fallbackToOptions = false } = {}) {
		let isAllowed = !this.#isTrackingDenied();
		if (!isAllowed) {
			if (this.userDevicePermissionsDialogRef?.value) {
				await this.userDevicePermissionsDialogRef.value.open({ permission: UserDevicePermission.TRACKING });
			} else {
				await this.mobileApp?.requestAppTrackingTransparencyPermission(fallbackToOptions);
			}
			isAllowed = !this.#isTrackingDenied();
		}
		return isAllowed;
	}

	#isTrackingDenied() {
		return this.mobileApp?.appleAppTrackingTransparency.isDenied ?? false;
	}
}
