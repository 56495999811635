<template>
	<div :class="{ 'breadcrumb': true, 'is-compact': props.isCompact, 'highlight-last': props.highlightLast }">
		<slot />
	</div>
</template>

<script setup>
const props = defineProps({
	isCompact: {
		type: Boolean,
		default: true,
	},
	highlightLast: {
		type: Boolean,
		default: false,
	},
});
</script>

<style lang="scss" scoped>
@import "../assets/styles/variables_new.scss";

.breadcrumb {
	display: flex;
	font-size: $text-size-tertiary;
	font-weight: $text-bold-secondary;
	color: $text-color-primary;

	> :deep(span) {
		min-width: fit-content;
	}

	> :deep(*) {
		padding: calc($spacing/2) $spacing;
		max-width: 50%;
		@include text-ellipsis;
	}

	> :deep(a) {
		text-decoration: underline;
	}

	&.is-compact {
		> :deep(*) {
			padding: 0 0 0 0;
		}
	}

	&.highlight-last {
		> :deep(*) {
			&:last-child {
				background-color: $background-color-tertiary;
				border-radius: $border-radius-quinary;
				color: $text-color-onezone-1;
			}
		}
	}
}

@media (min-width: $bp-medium) {
	.breadcrumb {
		font-size: $text-size-secondary;
	}
}
</style>
