export default {
	startingWatching: {
		level: "info",
		message: () => `Starting watching for user's location`,
	},
	cannotStartWatchingWithoutPermission: {
		level: "warn",
		message: ({ permissions }) => `Cannot start watching for user's location without permission: ${permissions}`,
	},
	watchingForLocationSuccess: {
		level: "info",
		message: ({ location }) => `Watching for location - success: ${location}`,
	},
	stoppingWatching: {
		level: "info",
		message: () => `Stopping watching for user's location`,
	},
	locationReceived: {
		level: "info",
		message: ({ location }) => `Watched location received: ${location}`,
	},
	watchedLocationReceivedError: {
		level: "error",
		message: ({ errorJson }) => `Watched location received - error: ${errorJson}`,
	},
	gettingLocation: {
		level: "info",
		message: () => `Getting user's location`,
	},
	getttingLocationSuccess: {
		level: "info",
		message: ({ location }) => `Getting location - success: ${location}`,
	},
	watchingForLocationError: {
		level: "error",
		message: ({ errorJson }) => `Watching for location - error: ${errorJson}`,
	},
	gettingLocationError: {
		level: "error",
		message: ({ errorJson }) => `Getting location - error: ${errorJson}`,
	},
};
