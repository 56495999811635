import { EntityTypeNames } from "../../constants/index.js";

export default class ListProcessor {
	static doesSupportType(typeName) {
		return typeName === EntityTypeNames.User;
	}

	constructor({ entityStore }) {
		this.entityStore = entityStore;
	}

	process({ entity, entityProcessor }, args, summary) {
		const processedEntity = entityProcessor.processObject(entity, args, summary);

		const storedEntity = this.entityStore.store(
			{
				id: entity.id,
				typeName: EntityTypeNames.User,
				entity: {
					...processedEntity,
				},
				computedFields: {
					followingMap: (stored) => (stored.following ? Object.fromEntries(stored.following.map(({ id }) => [id, true])) : []),
				},
			},
			summary,
		);

		return storedEntity;
	}
}
