<template>
	<div ref="scrollableContainerRef" class="scrollable-container" :style="{ 'padding-bottom': `${keyboardCompensationForMobile}px` }">
		<slot />
	</div>
</template>

<script setup>
import { useElementBounding, useWindowSize } from "@vueuse/core";
import { Keyboard } from "@capacitor/keyboard";
import { computed, inject, onMounted, onUnmounted, ref } from "vue";

const platform = inject("platform");

const scrollableContainerRef = ref();
const mobileKeyboardHeight = ref(0);

const { bottom: scrollableContainerBottom } = useElementBounding(scrollableContainerRef);
const { height: windowHeight } = useWindowSize();

const keyboardCompensationForMobile = computed(() => (mobileKeyboardHeight.value > 0 ? scrollableContainerBottom.value - (windowHeight.value - mobileKeyboardHeight.value) + 14 : 0));

let keyboardWillShowListener,
	keyboardDidShowListener,
	keyboardWillHideListener,
	isKeyboardShowing = false;
onMounted(async () => {
	if (platform.isNative) {
		keyboardWillShowListener = await Keyboard.addListener("keyboardWillShow", (info) => {
			mobileKeyboardHeight.value = info.keyboardHeight;
		});
		keyboardDidShowListener = await Keyboard.addListener("keyboardDidShow", () => {
			if (!isKeyboardShowing) {
				isKeyboardShowing = true;
				const focusedElement = document.activeElement;
				if (focusedElement) {
					focusedElement.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
				}
			}
		});
		keyboardWillHideListener = await Keyboard.addListener("keyboardWillHide", () => {
			isKeyboardShowing = false;
			mobileKeyboardHeight.value = 0;
		});
	}
});
onUnmounted(() => {
	keyboardWillShowListener?.remove();
	keyboardDidShowListener?.remove();
	keyboardWillHideListener?.remove();
});
</script>

<style scoped lang="scss">
@import "../assets/styles/variables_new.scss";

.scrollable-container {
	overflow: auto;
	margin: 0 calc($spacing / 2);
	padding: 0 calc($spacing / 2);
}
</style>
