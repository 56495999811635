<template>
	<dl>
		<dt>Referrer Details</dt>
		<dd>
			<h3>Install</h3>
			<ul>
				<li>
					<label>utm_source:</label><span>{{ tracking.referrerDetails.install.utm_source ?? "none" }}</span>
				</li>
				<li>
					<label>utm_medium:</label><span>{{ tracking.referrerDetails.install.utm_medium ?? "none" }}</span>
				</li>
				<li>
					<label>utm_campaign:</label><span>{{ tracking.referrerDetails.install.utm_campaign ?? "none" }}</span>
				</li>
			</ul>
			<h3>Open</h3>
			<ul>
				<li>
					<label>utm_source:</label><span>{{ tracking.referrerDetails.open.utm_source ?? "none" }}</span>
				</li>
				<li>
					<label>utm_medium:</label><span>{{ tracking.referrerDetails.open.utm_medium ?? "none" }}</span>
				</li>
				<li>
					<label>utm_campaign:</label><span>{{ tracking.referrerDetails.open.utm_campaign ?? "none" }}</span>
				</li>
			</ul>
		</dd>
	</dl>
</template>

<script setup>
import { inject } from "vue";

const tracking = inject("tracking");
</script>

<style scoped lang="scss">
@import "../assets/styles/variables_new.scss";

dl {
	display: flex;
	flex-flow: row wrap;
	gap: $spacing;
	margin: 0;
}
dt {
	flex-basis: calc(35% - ($spacing * 2));
	border: 1px solid $border-color-primary;
	background: $background-color-tertiary;
	color: $text-color-secondary;
	text-align: right;
	text-align: right;
	padding: $spacing $spacing $spacing 0;
	box-sizing: border-box;
}
dd {
	flex-basis: calc(65% - ($spacing * 2));
	flex-grow: 1;
	border: 1px solid $border-color-primary;
	margin-left: 0;
	padding: 0 $spacing;
	display: flex;
	flex-direction: column;
	justify-content: center;
	box-sizing: border-box;

	h3,
	ul {
		margin: 0;

		span {
			color: $text-color-onezone-1;
		}
	}

	select {
		max-width: 200px;
	}
}
</style>
