<template>
	<div ref="target" :style="{ visibility: isResetting ? 'hidden' : 'visible' }" :class="props.class">
		<slot />
	</div>
</template>

<script setup>
import { useScrollMemory } from "./useScrollMemory.js";

const props = defineProps({
	name: {
		type: String,
		required: true,
	},
	class: {
		type: String,
		default: null,
	},
});

const { target, isResetting } = useScrollMemory({ name: props.name, targetResolver: (unresolvedTarget) => unresolvedTarget.value });
</script>
