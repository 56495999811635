import { EntityTypeNames } from "../EntityTypeNames.js";
import { GlobalChangeSets } from "../../constants/index.js";

export default class {
	constructor({ changeManager, entityStore }) {
		this.changeManager = changeManager;
		this.entityStore = entityStore;
	}

	add({ followUser, user }) {
		const data = { followUserId: followUser.id, userId: user.id };
		if (!this.changeManager.removeChangeFromQueue({ typeName: "UnfollowUser", data }, { activeOnly: true })) {
			this.changeManager.addChangeToQueue({ typeName: this.name, data, args: { followUser, user } }, { changeSet: GlobalChangeSets.Followers });
		}
	}

	getChangeEffects(change) {
		return [
			{
				typeName: EntityTypeNames.User,
				id: change.data.followUserId,
				getEffects: () => {
					return [
						{
							if: ({ followers }) => !!followers,
							type: "insertAtStart",
							key: "followers",
							value: { id: change.data.userId, entity: change.args.user },
						},
					];
				},
			},
			{
				typeName: EntityTypeNames.User,
				id: change.data.userId,
				getEffects: () => {
					return [
						{
							type: "insertAtStart",
							key: "following",
							value: { id: change.data.followUserId, entity: change.args.followUser },
						},
					];
				},
			},
		];
	}
}
