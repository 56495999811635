<template>
	<div class="dialog-fullscreen-page-layout">
		<SafeArea />
		<div :class="{ 'header-row': true, 'show-header-on-desktop': props.showHeaderOnDesktop }">
			<div class="header-tray-left">
				<BackButton v-if="props.hasBackButton" class="back-button" :enable-back-action="props.autoWireBackButton" @click="backOrCloseButtonClicked" />
			</div>

			<div class="header">
				<slot name="header">
					<h2>{{ props.header }}</h2>
				</slot>
			</div>

			<div class="header-tray-right">
				<slot name="header-tray-right" />
				<CloseButton v-if="props.hasBackButton" class="close-button" :enable-back-action="props.autoWireBackButton" @click="backOrCloseButtonClicked" />
			</div>
		</div>

		<div class="content">
			<slot name="default" />
		</div>
	</div>
</template>

<script setup>
import BackButton from "./BackButton.vue";
import CloseButton from "./CloseButton.vue";

const props = defineProps({
	header: {
		type: String,
		default: null,
	},
	showHeaderOnDesktop: {
		type: Boolean,
		default: false,
	},
	hasBackButton: {
		type: Boolean,
		default: true,
	},
	autoWireBackButton: {
		type: Boolean,
		default: true,
	},
});

const emit = defineEmits(["close"]);

function backOrCloseButtonClicked() {
	if (!props.autoWireBackButton) {
		emit("close");
	}
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/variables_new.scss";

.dialog-fullscreen-page-layout {
	position: relative;
	display: flex;
	flex-direction: column;
	overflow: auto;
	flex-grow: 1;

	.header-row {
		position: relative;
		display: flex;
		align-items: center;
		padding-bottom: calc($spacing / 2);
		box-sizing: border-box;
		border-bottom: $border-size-primary solid $border-color-primary;

		.header {
			display: flex;
			flex-direction: column;
			justify-content: center;
			text-align: center;
			padding: 0 calc($spacing);
			box-sizing: border-box;
			overflow: auto;
			min-height: 30px;

			:deep(h2),
			:deep(h3) {
				display: block;
				margin: 0;
				@include text-ellipsis;
			}

			:deep(h2) {
				font-size: $text-size-quaternary;
			}

			:deep(h3) {
				color: $text-color-primary;
				font-weight: normal;
				font-size: $text-size-tertiary;
			}
		}

		.header-tray-left,
		.header-tray-right {
			position: relative;
			flex: 1;
			height: 100%;
			display: flex;
			align-items: flex-end;
			padding: 0 calc($spacing / 1.5);

			/* add padding to all buttons so they are more clickable */
			:deep(> *) {
				position: absolute;
				padding: calc($spacing) calc($spacing / 1.5);
				z-index: 100;
			}
		}

		.header-tray-left {
			margin-right: calc($spacing);

			:deep(> *) {
				transform: translate(calc(0px - ($spacing / 1.5)), calc($spacing / 1.5));
			}
		}

		.header-tray-right {
			display: flex;
			justify-content: flex-end;
			margin-left: calc($spacing);

			.close-button {
				display: none;
			}

			:deep(> *) {
				transform: translate(calc($spacing / 1.5), calc($spacing / 1.5));
			}
		}
	}

	.content {
		position: relative;
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		box-sizing: border-box;
		padding: calc($spacing) 0;
		overflow: auto;
		overscroll-behavior: none;
	}
}

@media (min-width: $bp-medium) {
	.dialog-fullscreen-page-layout {
		.header-row {
			overflow: visible;

			&.show-header-on-desktop {
				padding: calc($spacing/1.5) 0;

				.header-tray-right {
					align-items: center;
				}
			}

			&:not(.show-header-on-desktop) {
				position: absolute;
				width: 100%;
				// height: 0;
				// padding: 0;
				border-bottom: none;
				z-index: 1;

				.header {
					display: none;
				}

				.header-tray-left {
					display: none;
				}

				.header-tray-right {
					margin-top: calc($spacing / 1.5);
					align-items: flex-start;
				}
			}

			.header-tray-left,
			.header-tray-right {
				// margin: 0 calc($spacing / 1.5);
			}

			.header-tray-left {
				.back-button {
					display: none;
				}
			}

			.header-tray-right {
				justify-content: flex-end;

				:deep(> *) {
					padding: 0;
					transform: none;
				}

				:deep(.share-button) {
					display: none !important;
				}

				:deep(.close-button) {
					display: flex;
				}
			}
		}

		.content {
			flex-grow: 1;
			margin: calc($spacing / 2) calc($spacing / 2) calc($spacing) calc($spacing);
			padding: calc($spacing / 2) 0 0 0;
			// @include box-shadow-secondary;
		}
	}
}
</style>
