<template>
	<div class="explore-tags-container">
		<loading-message-with-error
			v-if="(tagsQuery.isLoading && (tagsQuery.state?.loading ?? LOADING_STATE.LOAD_INITIAL) === LOADING_STATE.LOAD_INITIAL) || tagsQuery.error"
			:error="tagsQuery.error"
			:retry="tagsQuery.retry"
			class="loading-message"
		>
			Loading, please wait...
		</loading-message-with-error>
		<div v-else class="explore-tags">
			<PageHeader>All Tags</PageHeader>
			<!-- <SuperList v-slot="{ item: tag, style }" :name="`${cityName}-tags`" :items="tags" :item-size="76" :is-virtual="true" class="explore-tags-list">
				<TagCard :key="tag.id" :tag="tag" :show-mini="true" :show-tag-category="true" :style="style" />
			</SuperList> -->
			<NewList
				v-slot="{ item: tag, isItemVisible }"
				class="explore-tags-list"
				:name="`${cityName}-tags`"
				:items="tags"
				:direction="ListDirections.VERTICAL"
				:item-height="76"
				:gap-size="0"
				:item-buffer-count="4"
			>
				<TagCard v-if="isItemVisible" :key="tag.id" :tag="tag" :show-mini="true" :show-tag-category="true" />
			</NewList>
		</div>
	</div>
</template>

<script setup>
import { inject, computed, ref, onMounted } from "vue";

import { LOADING_STATE, ListDirections } from "../constants/index.js";
import { useRouteParams } from "../helpers/index.js";
// import { breakpoints } from "../domain/index.js";

import TagCard from "./TagCard.vue";
// import SuperList from "./SuperList.vue";
import NewList from "./NewList.vue";
import PageHeader from "./PageHeader.vue";

const routeParams = useRouteParams();

const isAppHydrated = inject("isAppHydrated");
const model = inject("model");

const isMounted = ref(false);
onMounted(() => {
	isMounted.value = true;
});

// const isSmallerThanMedium = breakpoints.smaller("medium");

const cityName = computed(() => routeParams.cityName);
const tagsQuery = model.queries.GetTags({
	cityName,
});

const tags = computed(() => tagsQuery.model?.tags.filter(({ category }) => category !== "MEAL_TYPE") ?? []);
// const tags = computed(() => tagsQuery.model?.tag ?? []);

if (!isAppHydrated.value) {
	await tagsQuery.promise;
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/variables_new.scss";

.explore-tags-container {
	display: flex;
	flex-direction: column;
	height: 100%;
	overflow: auto;
	margin-top: $spacing;
	@include centre-loading-message;

	.explore-tags {
		display: flex;
		flex-direction: column;
		gap: calc($spacing * 0.5);
		overflow: auto;
		overscroll-behavior: none;

		.explore-tags-list {
			height: 100%;
			width: 100%;
			// overflow: auto;
			display: flex;
			flex-direction: column;
			gap: $spacing;
			align-content: start;
		}
	}
}
</style>
