export default {
	initialisingDatadogLogs: {
		level: "info",
		message: ({ env, version }) => `🐶 Initialising Datadog logs for platform env ${env} and version ${version}...`,
	},
	initialisedDatadogLogs: {
		level: "info",
		message: ({ sessionId }) => `🐶 ...successfully initialised Datadog logs with session id ${sessionId}`,
	},
	identifyingUser: {
		level: "info",
		message: ({ userId }) => `🪪 ⇨ 🐶 Identifying user ${userId} to Datadog`,
	},
	settingApiEnvironment: {
		level: "info",
		message: ({ name, version }) => `🐶 Setting API environment to ${name} and version ${version}`,
	},
	ensuringLoggedOutFromDatadog: {
		level: "info",
		message: () => `🐶 Ensuring logged out from Datadog`,
	},
	waitingForDatadogLogsScriptToLoad: {
		level: "info",
		message: () => `🐶 DD_LOGS not found, waiting for Datadog logs script to load...`,
	},
	dataDogScriptLoaded: {
		level: "info",
		message: () => `🐶 ...datadog logs script loaded`,
	},
	timedOutWaitingForDatadogLogsScriptToLoad: {
		level: "info",
		message: () => `🐶 ...timed out waiting for Datadog logs script to load`,
	},
};
