<template>
	<div class="input-errors-container">
		<div class="input-errors">
			<!-- <ValidationErrorMessage v-for="error of validation?.$errors" :key="error.$uid">{{ error.$message }}</ValidationErrorMessage> -->
			<ValidationErrorMessage v-if="firstError">{{ firstError.$message }}</ValidationErrorMessage>
		</div>
	</div>
</template>

<script setup>
import { computed } from "vue";

import ValidationErrorMessage from "./ValidationErrorMessage.vue";

const props = defineProps({
	validation: {
		type: Object,
		required: true,
	},
});

const validation = computed(() => props.validation);
const firstError = computed(() => validation.value?.$errors?.[0]);
</script>

<style scoped lang="scss">
@import "../../assets/styles/variables_new.scss";

.input-errors-container {
	position: relative;

	.input-errors {
		// position: absolute;
		display: flex;
		flex-direction: column;
		gap: calc($spacing / 2);
		// min-height: calc($spacing * 1.6);
		margin: 0 calc($spacing / 2) 0 calc($spacing / 2);
		box-sizing: border-box;
	}
}
</style>
