<template>
	<div ref="mapRef" class="google-map"></div>
</template>

<script setup>
import { computed, inject, markRaw, onActivated, onDeactivated, onMounted, onUnmounted, reactive, ref, toRef, watch } from "vue";
import { debounce } from "lodash-es";

import { useUserLists, deepDiff, toRawDeep } from "../helpers/index.js";
import { UserListNames, VenueMarkerTypes, VenueMarkerTypeOrder } from "../constants/index.js";
import loggingMessages from "./GoogleMap.logging-messages.js";

import MarkerStandard_Unselected from "../assets/icons/Map_clear_unselected.svg";
import MarkerStandard_Selected from "../assets/icons/Map_clear_selected.svg";
import MarkerPerk_Unselected from "../assets/icons/Map_perk_unselected.svg";
import MarkerPerk_Selected from "../assets/icons/Map_perk_selected.svg";
import MarkerBeen_Unselected from "../assets/icons/Map_been_unselected.svg";
import MarkerBeen_Selected from "../assets/icons/Map_been_selected.svg";
import MarkerFavourite_Unselected from "../assets/icons/Map_favourite_unselected.svg";
import MarkerFavourite_Selected from "../assets/icons/Map_favourite_selected.svg";
import MarkerGoTry_Unselected from "../assets/icons/Map_go_try_unselected.svg";
import MarkerGoTry_Selected from "../assets/icons/Map_go_try_selected.svg";
import UserLocationMarker from "../assets/icons/user-location-marker.svg";
import UserLocationMarkerWithHeading from "../assets/icons/user-location-marker-with-heading.svg";
import SearchLocationMarker from "../assets/icons/search-location-marker.svg";

const props = defineProps({
	selectedVenueId: {
		type: String,
		default: null,
	},
	center: {
		type: Object,
		default: null,
	},
	zoomLevel: {
		type: Number,
		default: 15,
	},
	searchLocation: {
		type: Object,
		required: true,
	},
	venues: {
		type: Array,
		required: true,
	},
	userLists: {
		type: Object,
		required: true,
	},
	panToSelectedVenue: {
		type: Boolean,
		default: true,
	},
	fitToBounds: {
		type: Boolean,
		default: false,
	},
	showZoomControls: {
		type: Boolean,
		default: true,
	},
});

defineExpose({
	moveToUserLocation,
});

const emit = defineEmits(["venueChange", "centerChange", "zoomChange", "mapsApiLoaded"]);

const logger = inject("logger").nested({ name: "GoogleMap" });
const geoLocation = inject("geoLocation");

const isMounted = ref(false),
	isUserDraggingMap = ref(false),
	mapRef = ref(null),
	google = reactive({
		isLoaded: false,
		map: null,
		maps: null,
		services: {},
	});

let MARKER_STYLES, MARKER_STYLE_MARKER_TYPE_LOOKUP;

const venueMarkers = [];
const mapMarkerEntities = computed(() => {
	if (!google.maps || !google.isLoaded || !google.map) {
		return [];
	}
	return reactive([
		...(geoLocation.isLoading
			? []
			: [
					{
						id: "userLocation",
						position: geoLocation,
						rotation: computed(() => geoLocation.heading),
						icon: computed(() => (geoLocation.heading ? MARKER_STYLES.USER_LOCATION_WITH_HEADING : MARKER_STYLES.USER_LOCATION)),
					},
					// {
					// 	id: "searchLocation",
					// 	position: computed(() => props.searchLocation),
					// 	icon: computed(() => MARKER_STYLES.SEARCH_LOCATION),
					// 	zIndex: 100,
					// },
			  ]),
		...(props.venues ?? []).map((venue) => {
			const isInUserLists = useUserLists(reactive({ userLists: toRef(props, "userLists"), venue }));
			const venueMarkerType = computed(() => {
				if (venue.perks().length > 0) {
					return VenueMarkerTypes.PERK;
				} else if (isInUserLists[UserListNames.GO_TRY]) {
					return VenueMarkerTypes.GO_TRY;
				} else if (isInUserLists[UserListNames.FAVOURITES]) {
					return VenueMarkerTypes.FAVOURITE;
				} else if (isInUserLists[UserListNames.BEEN]) {
					return VenueMarkerTypes.BEEN;
				} else {
					return VenueMarkerTypes.STANDARD;
				}
			});

			return {
				id: venue.id,
				position: { lat: venue.latitude, lng: venue.longitude },
				title: venue.name,
				icon: computed(() => MARKER_STYLE_MARKER_TYPE_LOOKUP[venueMarkerType.value][props.selectedVenueId === venue.id ? "selected" : "standard"]),
				click: () => {
					changeVenue(venue);
				},
				zIndex: computed(() => (props.selectedVenueId === venue.id ? 100 : VenueMarkerTypeOrder.indexOf(venueMarkerType.value))),
				animation: window.google.maps.Animation.DROP,
			};
		}),
	]);
});

const createdMarkers = [];
watch(
	mapMarkerEntities,
	() => {
		mapMarkerEntities.value.forEach((mapMarkerEntity) => {
			const existingMarker = createdMarkers.find(({ id }) => id === mapMarkerEntity.id);
			const rawMapMarkerEntity = toRawDeep(mapMarkerEntity);

			const diff = deepDiff(existingMarker?.details ?? {}, rawMapMarkerEntity);
			if (diff.length > 0) {
				if (existingMarker) {
					logger.log(loggingMessages.mapMarkerUpdated, { id: mapMarkerEntity.id, diff });
					existingMarker.marker.setTitle(rawMapMarkerEntity.title);
					existingMarker.marker.setPosition(rawMapMarkerEntity.position);
					existingMarker.marker.setIcon({ ...rawMapMarkerEntity.icon, rotation: rawMapMarkerEntity.rotation });
					existingMarker.marker.setZIndex(rawMapMarkerEntity.zIndex);
					existingMarker.marker.setAnimation(rawMapMarkerEntity.animation);
					existingMarker.details = rawMapMarkerEntity;
				} else {
					logger.log(loggingMessages.mapMarkerCreated, { id: rawMapMarkerEntity.id });
					const newMarker = new google.maps.Marker({
						title: rawMapMarkerEntity.title,
						position: rawMapMarkerEntity.position,
						map: google.map,
						icon: { ...rawMapMarkerEntity.icon, rotation: rawMapMarkerEntity.rotation },
						clickable: !!mapMarkerEntity.click,
						draggable: false,
						zIndex: rawMapMarkerEntity.zIndex,
						// meta: rawMapMarkerEntity.meta,
						animation: rawMapMarkerEntity.animation,
					});
					const handlers = {};
					if (mapMarkerEntity.click) {
						handlers.click = mapMarkerEntity.click;
						newMarker.addListener("click", handlers.click);
					}
					createdMarkers.push({
						id: mapMarkerEntity.id,
						details: rawMapMarkerEntity,
						marker: markRaw(newMarker),
						handlers,
					});
				}
			}
		});

		const markersToRemove = createdMarkers.filter(({ id }) => !mapMarkerEntities.value.find((mapMarkerEntity) => mapMarkerEntity.id === id));
		removeMapMarkers(markersToRemove);
	},
	{ deep: true },
);

const currentUserPositionMarker = ref(null);

const panMapTo = (location) => {
	if (google.map) {
		google.map.panTo(location);
	}
};
const zoomMapTo = (zoomLevel) => {
	if (google.map) {
		google.map.setZoom(zoomLevel);
	}
};

onMounted(async () => {
	geoLocation.startWatching();
	await loadGoogleMaps();
	const map = await setupGoogleMaps();
	loadPlacesService(map);

	isMounted.value = true;
	emit("mapsApiLoaded", google);
});

onUnmounted(() => {
	geoLocation.stopWatching();
	isMounted.value = false;
});

onActivated(() => {
	geoLocation.startWatching();
});

onDeactivated(() => {
	geoLocation.stopWatching();
});

watch([geoLocation, toRef(google, "map")], () => {
	if (geoLocation.hasLocation && google.map) {
		updateUserLocationMarker();
	}
});

// let circle;
watch(
	[toRef(props, "center"), toRef(google, "map")],
	() => {
		if (props.center && google.map) {
			logger.log(loggingMessages.centerChangedPanningMap, { location: props.center });
			panMapTo(props.center);
			// if (circle) {
			// 	circle.setMap(null);
			// }
			// circle = new google.maps.Circle({
			// 	strokeColor: "#000000",
			// 	strokeOpacity: 1,
			// 	strokeWeight: 1,
			// 	fillColor: "#000000",
			// 	fillOpacity: 0.1,
			// 	map: google.map,
			// 	center: props.center,
			// 	radius: 20000,
			// });
		}
	},
	{ immediate: true },
);

watch(
	[toRef(props, "zoomLevel"), toRef(google, "map")],
	() => {
		if (props.zoomLevel && google.map) {
			const zoomLevel = google.map.getZoom();
			if (zoomLevel !== props.zoomLevel) {
				logger.log(loggingMessages.zoomLevelChanged, { zoomLevel: props.zoomLevel });
				zoomMapTo(props.zoomLevel);
			}
		}
	},
	{ immediate: true },
);

watch(
	[toRef(props, "showZoomControls"), toRef(google, "map")],
	() => {
		if (google.map) {
			google.map.setOptions({ zoomControl: props.showZoomControls });
		}
	},
	{ immediate: true },
);

watch(
	toRef(props, "selectedVenueId"),
	(newVenueId) => {
		highlightSelectedVenue(newVenueId);
	},
	{ immediate: false },
);

watch(
	[isMounted, toRef(props, "venues")],
	() => {
		if (isMounted.value && props.venues) {
			// updateVenueMarkers(props.venues);

			if (google.map && props.fitToBounds) {
				let bounds = null;
				props.venues?.forEach((venue) => {
					const pos = new google.maps.LatLng(venue.latitude, venue.longitude);
					if (!bounds) {
						bounds = new google.maps.LatLngBounds(pos);
					} else {
						bounds.extend(pos);
					}
				});
				if (bounds) {
					logger.log(loggingMessages.fittingToBounds, { bounds });
					google.map.fitBounds(bounds);
					if (google.map.getZoom() > 15) {
						google.map.setZoom(15);
					}
				}

				const selectedVenue = props.venues.find((venue) => venue.id === props.selectedVenueId);
				if (selectedVenue) {
					const shouldPanTo = !props.center;
					highlightSelectedVenue(selectedVenue.id, shouldPanTo);
				} else if (props.venues.length > 0) {
					changeVenue(props.venues[0]);
				} else if (!props.center) {
					logger.log(loggingMessages.mountedOrVenuesChangedPanningMapToSearchLocation, { location: props.searchLocation });
					panMapTo(props.searchLocation);
					/* if no venues, we still need to move the map somewhere */
				}
			}
		}
	},
	{ immediate: true },
);

const onDragStart = () => {
	isUserDraggingMap.value = true;
};

const onDragEnd = () => {
	const newCenter = { lat: parseFloat(google.map.getCenter().lat().toFixed(4)), lng: parseFloat(google.map.getCenter().lng().toFixed(4)) };
	emit("centerChange", newCenter);
};

const debouncedEmitZoom = debounce(() => {
	emit("zoomChange", google.map.getZoom());
}, 1000);

const onZoomChanged = () => {
	debouncedEmitZoom();
};

function removeMapMarkers(markersToRemove) {
	markersToRemove.forEach((markerToRemove) => {
		google.maps.event.clearInstanceListeners(markerToRemove.marker);
		markerToRemove.marker.setMap(null);
		createdMarkers.splice(createdMarkers.indexOf(markerToRemove), 1);
		logger.log(loggingMessages.mapMarkerRemoved, { id: markerToRemove.id });
	});
}

function highlightSelectedVenue(newVenueId, panTo = true) {
	if (newVenueId && createdMarkers.length > 0) {
		const marker = createdMarkers.find(({ id }) => id === newVenueId);
		if (marker) {
			if (props.panToSelectedVenue && panTo) {
				logger.log(loggingMessages.highlightingSelectedVenue, { id: newVenueId, location: marker.details.position });
				panMapTo(marker.details.position);
			}
		} else {
			/* REF seems to be legimate case when venue is removed from the list, for now don't throw error, app behaves well */
			// throw new Error(`No marker found for venue id: ${newVenueId}`);
		}
	}
}

async function loadGoogleMaps() {
	if (!google.maps) {
		await window.google.maps.importLibrary("maps");
		await window.google.maps.importLibrary("geometry");
		google.maps = markRaw(window.google.maps);
	}
	if (!google.services.autocomplete) {
		await window.google.maps.importLibrary("places");
		google.services.autocomplete = markRaw(new window.google.maps.places.AutocompleteService());
	}
	if (!MARKER_STYLES) {
		MARKER_STYLES = {
			SEARCH_LOCATION: {
				url: SearchLocationMarker,
				anchor: new window.google.maps.Point(20, 20),
				scaledSize: new window.google.maps.Size(40, 40),
			},
			USER_LOCATION: {
				url: UserLocationMarker,
				anchor: new window.google.maps.Point(15, 15),
				scaledSize: new window.google.maps.Size(30, 30),
			},
			USER_LOCATION_WITH_HEADING: {
				url: UserLocationMarkerWithHeading,
				anchor: new window.google.maps.Point(15, 15),
				scaledSize: new window.google.maps.Size(30, 30),
			},
			STANDARD: {
				url: MarkerStandard_Unselected,
				anchor: new window.google.maps.Point(15, 15),
				scaledSize: new window.google.maps.Size(30, 30),
			},
			STANDARD_SELECTED: {
				url: MarkerStandard_Selected,
				anchor: new window.google.maps.Point(20, 20),
				scaledSize: new window.google.maps.Size(40, 40),
			},
			PERK_STANDARD: {
				url: MarkerPerk_Unselected,
				anchor: new window.google.maps.Point(20, 20),
				scaledSize: new window.google.maps.Size(40, 40),
			},
			PERK_SELECTED: {
				url: MarkerPerk_Selected,
				anchor: new window.google.maps.Point(20, 20),
				scaledSize: new window.google.maps.Size(40, 40),
			},
			BEEN_STANDARD: {
				url: MarkerBeen_Unselected,
				anchor: new window.google.maps.Point(15, 15),
				scaledSize: new window.google.maps.Size(30, 30),
			},
			BEEN_SELECTED: {
				url: MarkerBeen_Selected,
				anchor: new window.google.maps.Point(20, 20),
				scaledSize: new window.google.maps.Size(40, 40),
			},
			FAVOURITE_STANDARD: {
				url: MarkerFavourite_Unselected,
				anchor: new window.google.maps.Point(15, 15),
				scaledSize: new window.google.maps.Size(30, 30),
			},
			FAVOURITE_SELECTED: {
				url: MarkerFavourite_Selected,
				anchor: new window.google.maps.Point(20, 20),
				scaledSize: new window.google.maps.Size(40, 40),
			},
			GO_TRY_STANDARD: {
				url: MarkerGoTry_Unselected,
				anchor: new window.google.maps.Point(15, 15),
				scaledSize: new window.google.maps.Size(30, 30),
			},
			GO_TRY_SELECTED: {
				url: MarkerGoTry_Selected,
				anchor: new window.google.maps.Point(20, 20),
				scaledSize: new window.google.maps.Size(40, 40),
			},
		};

		MARKER_STYLE_MARKER_TYPE_LOOKUP = {
			[VenueMarkerTypes.PERK]: {
				standard: MARKER_STYLES.PERK_STANDARD,
				selected: MARKER_STYLES.PERK_SELECTED,
			},
			[VenueMarkerTypes.GO_TRY]: {
				standard: MARKER_STYLES.GO_TRY_STANDARD,
				selected: MARKER_STYLES.GO_TRY_SELECTED,
			},
			[VenueMarkerTypes.FAVOURITE]: {
				standard: MARKER_STYLES.FAVOURITE_STANDARD,
				selected: MARKER_STYLES.FAVOURITE_SELECTED,
			},
			[VenueMarkerTypes.BEEN]: {
				standard: MARKER_STYLES.BEEN_STANDARD,
				selected: MARKER_STYLES.BEEN_SELECTED,
			},
			[VenueMarkerTypes.STANDARD]: {
				standard: MARKER_STYLES.STANDARD,
				selected: MARKER_STYLES.STANDARD_SELECTED,
			},
		};
	}
	google.isLoaded = true;
}

function loadPlacesService(map) {
	if (map) {
		google.services.places = markRaw(new window.google.maps.places.PlacesService(map));
	}
}

async function setupGoogleMaps() {
	tearDownGoogleMaps();
	if (mapRef.value) {
		logger.log(loggingMessages.settingUpGoogleMaps);
		google.map = markRaw(
			new window.google.maps.Map(mapRef.value, {
				// mapId: "onezone-city-map",
				isFractionalZoomEnabled: true,
				gestureHandling: "greedy",
				zoom: 15,
				disableDefaultUI: true,
				styles: [
					{
						featureType: "poi.business",
						elementType: "all",
						stylers: [
							{
								visibility: "off",
							},
						],
					},
					{
						featureType: "transit",
						elementType: "labels.icon",
						stylers: [
							{
								visibility: "on",
							},
						],
					},
				],
			}),
		);

		google.map.addListener("dragstart", onDragStart);
		google.map.addListener("dragend", onDragEnd);
		google.map.addListener("zoom_changed", onZoomChanged);
	}

	return google.map;
}

function tearDownGoogleMaps() {
	removeVenueMarkers();
	removeUserLocationMarker();
	if (google.map) {
		logger.log(loggingMessages.tearingDownGoogleMaps);
		google.map.removeListener("dragstart", onDragStart);
		google.map.removeListener("dragend", onDragEnd);
		google.map.removeListener("zoom_changed", onZoomChanged);
		google.map.setMap(null);
		google.map = null;
	}
}

function removeVenueMarkers() {
	venueMarkers.forEach((marker) => {
		marker.setMap(null);
	});
	venueMarkers.splice(0, venueMarkers.length);
}

function changeVenue(venue) {
	emit("venueChange", venue.id);
}

function updateUserLocationMarker() {
	// setMapMarker({
	// 	id: "userLocation",
	// 	position: geoLocation,
	// 	rotation: computed(() => geoLocation.heading),
	// 	icon: computed(() => (geoLocation.heading ? MARKER_STYLES.USER_LOCATION_WITH_HEADING : MARKER_STYLES.USER_LOCATION)),
	// });
	// removeUserLocationMarker();
	// currentUserPositionMarker.value = new window.google.maps.Marker({
	// 	position: { lat: geoLocation.lat, lng: geoLocation.lng },
	// 	map: google.map,
	// 	title: "Your location",
	// 	icon: {
	// 		...(geoLocation.heading ? MARKER_STYLES.USER_LOCATION_WITH_HEADING : MARKER_STYLES.USER_LOCATION),
	// 		rotation: geoLocation.heading,
	// 	},
	// 	clickable: false,
	// 	zIndex: 0,
	// });
}

function removeUserLocationMarker() {
	if (currentUserPositionMarker.value) {
		currentUserPositionMarker.value.setMap(null);
		currentUserPositionMarker.value = null;
	}
}

function moveToUserLocation() {
	if (geoLocation.hasLocation && google.map) {
		panMapTo(geoLocation);
		emit("centerChange", geoLocation);
	}
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/variables_new.scss";

.google-map {
	height: 100%;
}

:deep(.fa-circle) {
	width: 100px;
}

/* REF override google maps logo and terms of service positioning, move upwards to avoid accidental clicks when using the navigation on mobile */
:deep(div:has(> a > div > img[alt="Google"])) {
	top: 3px;
	bottom: auto !important;
}
:deep(div:has(> .gmnoprint)) {
	&.gm-bundled-control {
		bottom: 240px !important;
		right: 36px !important;
		// top: 0px;

		div:has(> button) {
			display: flex;
			flex-direction: column;
			gap: 1px;
			background-color: transparent !important;
			box-shadow: none !important;

			> div {
				background-color: transparent !important;
			}
			> button {
				background-color: $background-color-primary !important;
				border-radius: 50% !important;
				width: 32px !important;
				height: 32px !important;
				@include drop-shadow-primary;

				> img {
					width: 12px !important;
					height: 12px !important;
				}
			}
		}
	}

	&:not(.gm-bundled-control) {
		bottom: auto !important;
		// right: 0px !important;
		// top: 0px;

		button,
		span,
		a {
			color: $text-color-secondary !important;
		}
	}
}
</style>
