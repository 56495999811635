<template>
	<loading-message-with-error v-if="queries.isLoading || queries.error" :error="queries.error" :retry="queries.retry">Loading cities, please wait...</loading-message-with-error>
	<div v-else class="city-list">
		<section>
			<h3>Current City</h3>
			<CityCard v-if="currentCity" :city="currentCity" :show-mini="true" :router-link-enabled="true" />
		</section>
		<!-- <PremiumUpgradeMessage :current-user="currentUser" :feature="PremiumFeature.CITY" :user-action="SuggestedUserAction.UPGRADE_PREMIUM">Access more cities with onezone premium</PremiumUpgradeMessage> -->
		<section v-for="{ groupName, cities } in cityGroups" :key="groupName">
			<h3>{{ groupName }}</h3>
			<ul v-if="cities?.length > 0" class="city-group">
				<li v-for="city in cities" :key="city.id" class="city">
					<CityCard :city="city" :show-mini="true" :router-link-enabled="city.status !== CityStatus.COMING_SOON" :show-name-only="city.status === CityStatus.COMING_SOON" />
				</li>
			</ul>
		</section>
	</div>
</template>

<script setup>
import { inject, computed } from "vue";
import { useRoute } from "vue-router";

import { combineQueries } from "../helpers/index.js";
import { CityStatus, CityGroupNames } from "../constants/index.js";

import CityCard from "./CityCard.vue";

const CITY_GROUP_ORDER = [CityGroupNames.UK, CityGroupNames.EUROPE];

const model = inject("model");
const isAppHydrated = inject("isAppHydrated");

const currentRoute = useRoute();

const queries = combineQueries({
	citiesQuery: model.queries.GetCities(),
	currentUser: model.queries.GetCurrentUser(),
});

const currentCity = computed(() => {
	return queries.model?.cities?.find(({ name }) => name.toLowerCase() === currentRoute.params.city.toLowerCase());
});
const cityGroups = computed(() =>
	Object.entries(
		queries.model?.cities.reduce((acc, city) => {
			if (currentCity.value?.id !== city.id) {
				const groupName = city.group.name;
				if (!acc[groupName]) {
					acc[groupName] = [];
				}
				acc[groupName].push(city);
			}
			return acc;
		}, {}),
	)
		.map(([groupName, cities]) => ({ groupName, cities }))
		.sort((a, b) => CITY_GROUP_ORDER.indexOf(a.groupName) - CITY_GROUP_ORDER.indexOf(b.groupName)),
);

if (!isAppHydrated.value) {
	await queries.promise;
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/variables_new.scss";

.city-list {
	display: flex;
	flex-direction: column;
	gap: calc($spacing * 2);
	color: $text-color-primary;
	margin: 0 calc($spacing/2);

	ul {
		list-style: none;
		padding: 0;
		margin: 0;
	}

	h3 {
		margin: 0 0 calc($spacing / 2) calc($spacing / 2);
	}

	.premium-upgrade {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: calc($spacing / 2);

		.redeem-button {
			width: 13rem;
		}
	}

	.city-group {
		display: flex;
		flex-direction: column;
	}

	.city {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: $spacing;
	}
}

@media (min-width: $bp-medium) {
	.city-list {
		margin: 0 calc($spacing/2) 0 0;
	}
}
</style>
