<template>
	<VenueDetailSection title="Opening Times" class="venue-opening-hours">
		<ul>
			<li v-for="{ day, openingTimes, isToday } in openingHours" :key="`opening-hours-${day}`" :class="{ 'is-today': isToday }">
				<span class="day">{{ capitalize(day) }}</span>
				<span class="opening-times">{{ openingTimes }}</span>
			</li>
		</ul>
	</VenueDetailSection>
</template>

<script setup>
import { computed, capitalize } from "vue";

import VenueDetailSection from "./VenueDetailSection.vue";

const props = defineProps({
	venue: {
		type: Object,
		required: true,
	},
});

const openingHours = computed(() => {
	const daysOfWeekForIndex = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
	const daysOfWeekOrdered = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
	const dayOfWeekIndex = new Date().getDay();
	return props.venue.openingHours
		? daysOfWeekOrdered.map((day) => {
				return {
					day,
					openingTimes: props.venue.openingHours[day].replaceAll("-", " - ").replaceAll(".", ":"),
					isToday: daysOfWeekForIndex.indexOf(day) === dayOfWeekIndex,
				};
		  })
		: null;
});
</script>

<style scoped lang="scss">
@import "../../assets/styles/variables_new.scss";

.venue-opening-hours {
	display: flex;
	align-items: top;

	:deep(ul) {
		display: flex;
		flex-direction: column;
		padding: 0;
		margin: 0;
		flex-grow: 1;
		color: $text-color-primary;
		font-size: $text-size-tertiary;

		li {
			display: flex;
			justify-content: space-between;
			padding: calc($spacing / 1.5) 0;

			&:not(:last-child) {
				border-bottom: $border-size-primary solid $border-color-quinary;
			}
			&.is-today {
				font-weight: 500;
			}
			.day {
				width: 100px;
			}
		}
	}
}
</style>
