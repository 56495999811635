export const PremiumFeature = {
	PERK: "PERK",
	CITY: "CITY",
	LAUNCH: "LAUNCH",
	BEEN: "BEEN",
	FAVOURITES: "FAVOURITES",
	GO_TRY: "GOTRY",
	PROFILE: "PROFILE",
	ACTIVITY_FEED: "ACTIVITYFEED",
};
