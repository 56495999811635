import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

import loggingMessages from "./MakeVenueBookingReservation.logging-messages.js";

export default class {
	constructor({ logger, modelBuilder, api, authentication }) {
		this.logger = logger.nested({ name: "Model" });
		this.modelBuilder = modelBuilder;
		this.api = api;
		this.authentication = authentication;
	}

	async execute({ venueId, reservationToken, seatingPosition, reservationDetails, challengeDetails }) {
		const response = await this.api.mutations.makeVenueBookingReservation(
			{
				venueId,
				reservationToken,
				seatingPosition,
				reservationDetails: {
					...reservationDetails,
					creditCard: reservationDetails.creditCard
						? {
								...reservationDetails.creditCard,
								expiryMonth: reservationDetails.creditCard.expiryMonth ? parseInt(reservationDetails.creditCard.expiryMonth) : undefined,
								expiryYear: reservationDetails.creditCard.expiryYear ? parseInt(reservationDetails.creditCard.expiryYear) : undefined,
						  }
						: undefined,
				},
				challengeDetails,
			},
			{ accessToken: this.authentication.isAuthenticated.value ? this.authentication.getAccessToken : undefined },
		);

		const errors = response.errors;

		if (errors?.length > 0) {
			this.logger.log(loggingMessages.criticalErrorWhileMakingReservation, { venueId, errors: errors.map(({ message }) => message) });
			return { success: false, error: "Error making reservation with venue" };
		} else {
			const makeReservation = response.data?.venue.book.makeReservation;
			if (!makeReservation.success) {
				if (makeReservation.error) {
					this.logger.log(loggingMessages.errorWhileMakingReservation, { venueId, error: makeReservation.error });
					return { success: false, error: makeReservation.error };
				} else if (makeReservation.challenge) {
					this.logger.log(loggingMessages.challengeWhileMakingReservation, { venueId, typeName: makeReservation.challenge.type });
					return { success: false, challenge: makeReservation.challenge };
				} else {
					this.logger.log(loggingMessages.unexpectedErrorWhileMakingReservation, { venueId });
					return { success: false, error: "Error making reservation with venue" };
				}
			} else {
				this.logger.log(loggingMessages.reservationConfirmed, { venueId });
				return makeReservation;
			}
		}
	}
}
