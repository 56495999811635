<template>
	<div class="explore-tag-container">
		<PageHeader :show-share-button="!showLoadingOrErrorMessage" :share-title="tag?.name">
			<template #breadcrumb>
				<custom-router-link :to="{ name: 'City/Explore/Tags', params: { city: routeParams.cityName } }">All Tags</custom-router-link>
			</template>
			<template v-if="!showLoadingOrErrorMessage">{{ tag?.name }}</template>
			<template v-if="!showLoadingOrErrorMessage" #controls>
				<VenueFilterClientSide ref="venueFilterClientSideRef" :tags="tags" :venues="sortedVenues" :exclude-tags="[tagId]" />
			</template>
		</PageHeader>
		<StandardSearchContext :align-center="false" :tags="tags" :filter-state-manager="venueFilterClientSideRef?.filterStateManager" :show-search-text="false" />
		<loading-message-with-error v-if="showLoadingOrErrorMessage" :error="query.error" :retry="query.retry" class="loading-message"> Loading, please wait... </loading-message-with-error>
		<div v-else class="explore-tag">
			<SuperList v-slot="{ item: venue, style }" :name="`tag-${tag?.id}`" :items="venueFilterClientSideRef?.filteredVenues ?? []" :item-size="76" :is-virtual="true" class="explore-tag-venues-list">
				<VenueCard :key="venue.id" :venue="venue" :show-mini="true" :user-lists="userLists" :style="style" />
			</SuperList>
		</div>
	</div>
</template>

<script setup>
import { inject, computed, ref, onMounted } from "vue";

import { SortOrder } from "../constants/index.js";
import { combineQueries, useRouteParams, sorters } from "../helpers/index.js";

import PageHeader from "./PageHeader.vue";
import VenueCard from "./VenueCard.vue";
import SuperList from "./SuperList.vue";
import VenueFilterClientSide from "./VenueFilterClientSide.vue";
import StandardSearchContext from "./StandardSearchContext.vue";

const routeParams = useRouteParams();

const isAppHydrated = inject("isAppHydrated");
const model = inject("model");
const currentEnvironment = inject("currentEnvironment");
// const { venueResults, hasResults, allQueries: allSearchQueries } = inject("searchResults");

const venueFilterClientSideRef = ref(null);
const isMounted = ref(false);
onMounted(() => {
	isMounted.value = true;
});

const isSignedIn = computed(() => currentEnvironment.value?.isSignedIn.value);

const query = combineQueries({
	tagQuery: model.queries.GetTagWithVenues({
		cityName: computed(() => routeParams.cityName),
		tagId: computed(() => routeParams.tagId),
	}),
	tagsQuery: model.queries.GetTags({
		cityName: computed(() => routeParams.cityName),
	}),
	userListsQuery: model.queries.GetCurrentUserLists({ isSignedIn }),
});
// const venues = computed(() => (hasResults.value ? venueResults.value : tagVenues.value));
// const showLoadinngOrErrorMessage = computed(() => query.isLoading || query.error || allSearchQueries.isLoading);
const showLoadingOrErrorMessage = computed(() => query.isLoading || query.error);
const tagId = computed(() => routeParams.tagId);
const tags = computed(() => query.model?.tags ?? []);
const venues = computed(() => tagVenues.value);
const venueSorter = computed(() => sorters.sortVenues[SortOrder.NAME]);
const sortedVenues = computed(() => [...(venues.value ?? [])]?.sort(venueSorter.value));
const tagVenues = computed(() => query.model?.tag?.venues?.filter((venue) => venue.published && venue.tags.find(({ id }) => routeParams.tagId === id)) ?? []);
const tag = computed(() => query.model?.tag ?? null);
const userLists = computed(() => query.model?.currentUserLists ?? []);

if (!isAppHydrated.value) {
	await query.promise;
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/variables_new.scss";

.explore-tag-container {
	height: 100%;
	display: flex;
	flex-direction: column;
	gap: calc($spacing / 2);

	// padding: 0 calc($spacing * 2);
	margin-top: $spacing;
	overflow: auto;
	@include centre-loading-message;
	.explore-tag {
		display: flex;
		flex-direction: column;
		gap: calc($spacing * 0.5);
		overflow: auto;
		overscroll-behavior: none;

		.explore-tag-venues-list {
			// overflow: auto;
			display: flex;
			flex-direction: column;
			gap: $spacing;
			align-content: start;

			:deep(.super-list) {
				padding-right: calc($spacing / 2);
			}

			.image {
				width: 160px;
				height: 160px;
			}
		}
	}
}

@media (min-width: $bp-medium) {
	.explore-tag-container {
		padding: 0;
	}
}
</style>
