export default {
	scrollPositionStored: {
		level: "verbose",
		message: ({ name, position }) => `Scroll position stored for ${name} ${position}`,
	},
	scrollPositionRemoved: {
		level: "silly",
		message: ({ name }) => `Scroll position removed for ${name}`,
	},
	successfullyResetScrollPosition: {
		level: "verbose",
		message: ({ name, position, attempt }) => `Successfully restored scroll position for ${name}: ${position} on attempt #${attempt}`,
	},
	unableToResetScrollPosition: {
		level: "warn",
		message: ({ name, position, attempt, position2 }) => `Unable to set scroll position for ${name} to '${position}' after ${attempt} attempts. Only managed to get to '${position2}'`,
	},
};
