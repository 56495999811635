export default {
	loadingApiCacheDataFromDisk: {
		level: "verbose",
		message: ({ environmentName, count }) => `💾📦 Loading persistent cache from disk for ${environmentName}. Found ${count} files`,
	},
	loadedApiCacheDataFromDisk: {
		level: "verbose",
		message: ({ environmentName, count, durationMS }) => `🏢 ➯ 💾📦 Loaded ${count} files from disk for ${environmentName} in ${durationMS}`,
	},
	removedExpiredApiCacheDataFromDisk: {
		level: "verbose",
		message: ({ environmentName, count, durationMS }) => `💾📦 ➯ 🗑️ Removed ${count} expired cache files from disk for ${environmentName} in ${durationMS}`,
	},
};
