<template>
	<div class="dialog-layout-container">
		<div class="dialog">
			<safe-area />
			<div v-if="hasHeader" class="header">
				<slot name="header" />
				<!-- <a v-if="props.hasCloseButton" href="javascript:;" @click="props.dialog.close()">
				<span v-if="pageSize.isSmallerThanMedium">&lt;</span>
				<span v-if="!pageSize.isSmallerThanMedium">X</span>
			</a> -->
			</div>
			<main ref="contentRef" class="content">
				<section class="content-section">
					<slot name="content"></slot>
				</section>
			</main>
		</div>
	</div>
</template>

<script setup>
// import "animate.css";
import { provide, ref, useSlots, computed, onUnmounted } from "vue";
import { onClickOutside } from "@vueuse/core";

const props = defineProps({
	clickOutsideCloses: {
		type: Boolean,
		default: false,
	},
});

const contentRef = ref(null);
provide("contentRef", contentRef);

const removeOnClickOutside = onClickOutside(contentRef, () => {
	if (props.clickOutsideCloses) {
		const isContentVisible = window.getComputedStyle(contentRef.value)?.visibility === "visible";
		if (isContentVisible) {
			history.back();
		}
	}
});

onUnmounted(() => {
	removeOnClickOutside();
});

const slots = useSlots();
const hasHeader = computed(() => slots.header);
</script>

<style lang="scss" scoped>
@import "../../assets/styles/variables.scss";

.dialog-layout-container {
	z-index: 100;
	position: fixed;
	top: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100vw;
	height: 100vh;
	background-color: $color-background-modal;

	> .dialog {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100vw;
		height: 100vh;
		background-color: $color-background-primary;

		> .header {
			width: 100%;
			display: flex;
			border-bottom: $size-border-primary solid $color-border-primary;
			padding: calc($spacing / 2) 0;
			min-height: 22px;

			a {
				position: absolute;
				font-size: 1.5rem;
				font-weight: 500;
				line-height: 1.4rem;
				padding: 0 $spacing;
			}

			:deep(h2) {
				width: 100vw;
				margin: 0;
				text-align: center;
			}
		}

		> .content {
			width: 100%;
			flex-basis: auto;
			overflow: auto;

			> .content-section {
				box-sizing: border-box;
				width: 100%;
				max-width: $bp-large;
				padding-bottom: $margin-bottom-bp-small;
			}
		}
	}
}

@media (min-width: $bp-medium) {
	.dialog-layout-container {
		> .dialog {
			width: 80vw;
			height: 90vh;
			border-radius: $border-radius-primary;
			> .content {
				> .content-section {
					padding-bottom: $margin-bottom-bp-medium;
				}
			}
		}
	}
}
</style>
