export class ExternallyResolvablePromise extends Promise {
	constructor(executor = () => {}) {
		let resolver;
		let rejecter;

		super((res, rej) => {
			resolver = res;
			rejecter = rej;
			return executor(res, rej);
		});

		this.resolver = resolver;
		this.rejecter = rejecter;
		this.isFullfilled = false;
	}

	resolve(result) {
		// console.log("resolve", this.resolver, !this.isFullfilled);
		if (this.resolver && !this.isFullfilled) {
			this.isFullfilled = true;
			this.resolver(result);
		} else {
			throw new Error(`Resolver already called: ${result}`);
		}
	}

	reject(error) {
		// console.log("reject", error);
		if (this.rejecter && !this.isFullfilled) {
			this.isFullfilled = true;
			this.rejecter(error);
		} else {
			throw new Error(`Rejecter already called: ${error}`);
		}
	}
}
