import { onMounted, onUnmounted, reactive, watch, computed, nextTick, toRefs, unref } from "vue";

import { ListDirections } from "./ListDirections.js";

export const useIntersectionObserverList = (containerRef, options) => {
	const {
		items,
		direction = ListDirections.VERTICAL,
		itemWidth,
		itemHeight,
		gapSize = 0,
		itemBufferCount = 2,
		enableSnap = false,
	} = toRefs(options, ["items", "direction", "itemWidth", "itemHeight", "gapSize", "itemBufferCount", "enableSnap"]);

	const itemRefs = reactive([]);
	const itemVisibility = reactive({});
	let intersectionObserver;

	const containerStyle = computed(() => ({
		"display": "flex",
		"flexDirection": unref(direction) === ListDirections.VERTICAL ? "column" : "row",
		"gap": `${unref(gapSize)}px`,
		"overflow": "auto",
		"box-sizing": "content-box",
		"scroll-snap-type": unref(enableSnap) ? `${unref(direction) === ListDirections.VERTICAL ? "y" : "x"} mandatory` : undefined,
	}));
	const itemStyle = computed(() => ({
		"width": unref(itemWidth) ? `${unref(itemWidth)}px` : undefined,
		"height": unref(itemHeight) ? `${unref(itemHeight)}px` : undefined,
		"flex-shrink": 0,
		"box-sizing": "content-box",
		"scroll-snap-align": unref(enableSnap) ? "start" : undefined,
		"scroll-snap-stop": unref(enableSnap) ? "always" : undefined,
	}));

	let itemRefsWatchStopHandle, itemsWatchStopHandle, previousItemRefs;

	onMounted(() => {
		setup();
		startItemsWatcher();
	});

	onUnmounted(() => {
		tearDown();
		stopItemsWatcher();
	});

	/* 
		WHY: wrap itemRefs in computed otherwise this doesn't work on production build
		(behaviour is different than development, where this would still work without)
	*/
	return {
		itemRefs: computed(() => itemRefs),
		itemVisibility,
		itemStyle,
		containerStyle,
	};

	function setup() {
		intersectionObserver = createIntersectionObserver();
		startItemsRefsWatcher();
	}

	function createIntersectionObserver() {
		const size = unref(itemHeight) * unref(itemBufferCount) + unref(gapSize) * Math.min(unref(itemBufferCount), 0);
		const rootMargin = unref(direction) === ListDirections.VERTICAL ? `${size}px 0px` : `0px ${size}px`;

		return new IntersectionObserver(
			(entries) => {
				const visibleEntries = entries.filter((entry) => entry.isIntersecting);
				const invisibleEntries = entries.filter((entry) => !entry.isIntersecting);

				visibleEntries.forEach((entry) => {
					const { itemId } = entry.target.dataset;
					itemVisibility[itemId] = true;
				});
				invisibleEntries.forEach((entry) => {
					const { itemId } = entry.target.dataset;
					itemVisibility[itemId] = false;
				});
			},
			{
				root: unref(containerRef),
				rootMargin,
				threshold: 0,
			},
		);
	}

	function startItemsRefsWatcher() {
		itemRefsWatchStopHandle = watch(
			itemRefs,
			() => {
				const changedItemRefs = itemRefs.filter((venueRef) => !previousItemRefs?.includes(venueRef));
				const removedItemRefs = previousItemRefs?.filter((venueRef) => !itemRefs.includes(venueRef)) ?? [];

				changedItemRefs.forEach((itemRef) => {
					intersectionObserver.observe(itemRef);
				});
				removedItemRefs.forEach((itemRef) => {
					intersectionObserver.unobserve(itemRef);
				});
				previousItemRefs = [...itemRefs];
			},
			{ immediate: true },
		);
	}

	function tearDown() {
		if (intersectionObserver) {
			intersectionObserver.disconnect();
			intersectionObserver = null;
		}
		if (itemRefsWatchStopHandle) {
			itemRefsWatchStopHandle();
			itemRefsWatchStopHandle = null;
		}
		previousItemRefs = null;
	}

	function startItemsWatcher() {
		itemsWatchStopHandle = watch(items, () => {
			nextTick(() => {
				tearDown();
				setup();
			});
		});
	}

	function stopItemsWatcher() {
		if (itemsWatchStopHandle) {
			itemsWatchStopHandle();
			itemsWatchStopHandle = null;
		}
	}
};
