import { Authentication } from "./Authentication.js";

export class AuthenticationManager {
	constructor({ logger, authenticationCache, auth0ClientFactory } = {}) {
		this.logger = logger.nested({ name: "AuthenticationManager" });
		this.authenticationCache = authenticationCache;
		this.auth0ClientFactory = auth0ClientFactory;
	}

	create({ environmentName, defaultEnvironmentName, auth0Environment }) {
		const authentication = new Authentication({
			logger: this.logger,
			authenticationCache: this.authenticationCache,
			auth0ClientFactory: this.auth0ClientFactory,
			environmentName,
			defaultEnvironmentName,
			auth0Environment,
		});
		return authentication;
	}
}
