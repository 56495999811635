<template>
	<div class="venue-info">
		<span class="venue-name">{{ venueName }}</span>
		<span class="venue-address">{{ props.venue?.addressLine2 ?? "" }}</span>
	</div>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
	venue: {
		type: Object,
		default: null,
	},
});

const venueName = computed(() => props.venue?.name ?? "");
</script>

<style scoped lang="scss">
@import "../../assets/styles/variables_new.scss";

.venue-info {
	display: flex;
	flex-direction: column;
	align-items: center;

	.venue-name {
		display: inline;
		font-size: $text-size-quaternary;
		font-weight: $text-bold-primary;
		line-height: calc($text-size-header-tertiary + 0.1rem);
		// white-space: nowrap;
	}
	.venue-address {
		display: inline;
		font-size: $text-size-tertiary;
		font-weight: normal;
		white-space: nowrap;
	}
}
</style>
