<template>
	<Transition v-bind="animation" :mode="props.mode" :on-before-enter="onBeforeEnter" :on-before-leave="onBeforeLeave" appear>
		<slot />
	</Transition>
</template>

<script setup>
import { computed } from "vue";

import { formatObjectAsAttribute } from "../helpers/index.js";
import { AnimationNames } from "../constants/index.js";

const ANIMATIONS = {
	[AnimationNames.FADE]: { "enter-active-class": { animate__fadeIn: true }, "leave-active-class": { animate__fadeOut: true } },
	[AnimationNames.FADE_IN]: { "enter-active-class": { animate__fadeIn: true }, "leave-active-class": { animate__instant: true } },
	[AnimationNames.SLIDE_IN_LEFT]: { "enter-active-class": { animate__slideInLeft: true }, "leave-active-class": { animate__slideOutRight: true } },
	[AnimationNames.SLIDE_IN_OUT_BOTTOM]: { "enter-active-class": { animate__fadeInUp: true }, "leave-active-class": { animate__fadeOutDown: true } },
};

const props = defineProps({
	name: {
		type: String,
		default: AnimationNames.FADE,
	},
	duration: {
		type: String,
		default: null,
	},
	enterDelay: {
		type: String,
		default: null,
	},
	leaveDelay: {
		type: String,
		default: null,
	},
	mode: {
		type: String,
		default: "default",
	},
});

const animation = computed(() => {
	const animationTemplate = ANIMATIONS[props.name];
	const bind = Object.fromEntries(
		Object.entries({ ...animationTemplate }).map(([key, value]) => {
			return [key, formatObjectAsAttribute({ animate__animated: true, ...value })];
		}),
	);

	return { ...bind, style: { "animation-duration": props.duration } };
});

const onBeforeEnter = (el) => {
	el.style.animationDelay = props.enterDelay;
};

const onBeforeLeave = (el) => {
	el.style.animationDelay = props.leaveDelay;
};
</script>

<style scoped lang="scss">
@import "../assets/styles/variables_new.scss";

.animate__instant {
	animation-duration: 0s;
}
</style>
