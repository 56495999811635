<template>
	<div :class="{ 'add-to-user-lists': true, [props.orientation]: true, 'show-mini': props.showMini }" @click.prevent.stop>
		<div v-for="{ id, name, count, classes, onClick } in USER_LIST_TYPES" :key="id" :class="{ 'add-to-user-list-button': true }">
			<button :id="id" draggable="false" :class="classes" @click.stop.prevent="onClick"></button>
			<label v-if="props.showLabels" draggable="false" :for="id" :class="classes" @click.stop.prevent="onClick">
				<span>{{ name }}</span>
				<span v-if="shouldShowVenueCount" class="count">{{ count }}</span>
			</label>
		</div>
	</div>
</template>

<script setup>
import { computed, inject, reactive, toRef, unref } from "vue";

import { useUserLists, safeHapticsVibrate } from "../helpers/index.js";
import { UserListNames, UserListDatabaseNamesLookup, SuggestedUserAction } from "../constants/index.js";

const MIN_TIMES_ADDED_TO_USER_LISTS_TO_SHOW = 350;

const USER_LIST_TYPES = reactive([
	{
		id: computed(() => `been-${props.venue.id}`),
		name: computed(() => "Been"),
		count: computed(() => props.venue.beenCount),
		classes: computed(() => ({ "been": true, "is-in-users-list": isInUserLists[UserListNames.BEEN] })),
		onClick: () => toggleVenueInList(UserListNames.BEEN),
	},
	{
		id: computed(() => `favourite-${props.venue.id}`),
		name: computed(() => "Favourite"),
		count: computed(() => props.venue.favouriteCount),
		classes: computed(() => ({ "favourite": true, "is-in-users-list": isInUserLists[UserListNames.FAVOURITES] })),
		onClick: () => toggleVenueInList(UserListNames.FAVOURITES),
	},
	{
		id: computed(() => `goTry-${props.venue.id}`),
		name: computed(() => "Go Try"),
		count: computed(() => props.venue.gotryCount),
		classes: computed(() => ({ "gotry": true, "is-in-users-list": isInUserLists[UserListNames.GO_TRY] })),
		onClick: () => toggleVenueInList(UserListNames.GO_TRY),
	},
]);

const props = defineProps({
	venue: {
		type: Object,
		required: true,
	},
	cityId: {
		type: String,
		required: true,
	},
	userLists: {
		type: Object,
		default: null,
	},
	orientation: {
		type: String,
		required: false,
		default: "vertical",
	},
	showMini: {
		type: Boolean,
		default: false,
	},
	showLabels: {
		type: Boolean,
		default: true,
	},
	showVenueCount: {
		type: Boolean,
		default: false,
	},
});

const addChange = inject("addChange");
const currentEnvironment = inject("currentEnvironment");
const userActionDialogRef = inject("userActionDialogRef");

const totalTimesAddedToUserLists = computed(() => USER_LIST_TYPES.reduce((acc, { count }) => acc + count, 0));
const shouldShowVenueCount = computed(() => props.showVenueCount && totalTimesAddedToUserLists.value >= MIN_TIMES_ADDED_TO_USER_LISTS_TO_SHOW);
const isAuthenticated = computed(() => currentEnvironment.value.isAuthenticated.value);
const userLists = computed(() => props.userLists ?? []);
const isInUserLists = useUserLists(reactive({ userLists, venue: toRef(props, "venue") }));

function getListbyName(listName) {
	return unref(userLists)?.find(({ name }) => UserListDatabaseNamesLookup[name] === listName);
}

function toggleVenueInList(listName) {
	if (isAuthenticated.value) {
		safeHapticsVibrate({ duration: 20 });

		const list = getListbyName(listName);
		if (!list) {
			throw new Error("List does not exist: " + listName + " - UserList count = " + unref(userLists).length);
		}
		const isInList = !!list?.items?.find((item) => item?.venue?.id === props.venue.id);

		if (isInList) {
			// addChange.RemoveVenueFromUserNamedList({ venueId: props.venue.id, venueName: props.venue.name, cityId: props.cityId, listId: list.id, listName });
			addChange.RemoveVenueFromUserNamedList({ venue: props.venue, cityId: props.cityId, list: list });
		} else {
			// addChange.AddVenueToUserNamedList({ venueId: props.venue.id, venueName: props.venue.name, cityId: props.cityId, listId: list.id, listName });
			addChange.AddVenueToUserNamedList({ venue: props.venue, cityId: props.cityId, list });
		}
	} else {
		userActionDialogRef.value.open({ feature: listName, userAction: SuggestedUserAction.SIGN_IN });
	}
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/variables_new.scss";

.add-to-user-lists {
	display: flex;
	flex-direction: row;
	gap: calc($spacing * 1.5);

	&.vertical {
		.add-to-user-list-button {
			flex-direction: column;
			gap: calc($spacing/6);

			label {
				display: flex;
				flex-direction: column;
				align-items: center;
				font-size: $text-size-tertiary;

				.count {
					font-size: $text-size-quinary;
					font-weight: $text-bold-secondary;
				}
			}
		}
	}

	&.horizontal {
		flex-wrap: wrap;

		label {
			font-size: $text-size-secondary;
		}

		.add-to-user-list-button {
			flex-direction: row;
		}
	}

	&.show-mini {
		gap: calc($spacing);
		.add-to-user-list-button {
			button {
				width: 18px;
			}

			label {
				font-size: $text-size-quinary;
				color: $text-color-secondary;
			}
		}
	}

	.add-to-user-list-button {
		display: flex;
		align-items: center;
		gap: calc($spacing/2);

		button {
			width: 28px;
			aspect-ratio: 1;
			cursor: pointer;

			&.been {
				background-image: url("../assets/icons/been.svg");
				&.is-in-users-list {
					background-image: url("../assets/icons/been-highlighted.svg");
				}
			}
			&.favourite {
				background-image: url("../assets/icons/favourite.svg");
				&.is-in-users-list {
					background-image: url("../assets/icons/favourite-highlighted.svg");
				}
			}
			&.gotry {
				background-image: url("../assets/icons/go-try.svg");
				&.is-in-users-list {
					background-image: url("../assets/icons/go-try-highlighted.svg");
				}
			}
		}

		label {
			white-space: nowrap;
			cursor: pointer;
		}
	}
}
</style>
