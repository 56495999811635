import { computed } from "vue";

export default class {
	constructor({ logger, modelBuilder, api, authentication }) {
		this.logger = logger.nested({ name: "Model" });
		this.modelBuilder = modelBuilder;
		this.api = api;
		this.authentication = authentication;
	}

	execute({ cityName, tagId } = {}) {
		return this.modelBuilder.build({
			name: "GetTagWithVenues",
			args: { isSignedIn: computed(() => this.authentication.isAuthenticated.value), cityName, tagId },
			execute: async ({ args }) => {
				const cities = await this.api.queries.getCitiesAnonymous({}, { useClientCache: true });
				const foundCity = cities.cities.find(({ name }) => name.toLowerCase() === args.cityName);
				if (!foundCity) {
					throw new Error(`City '${args.cityName}' not found`);
				}

				const data = args.tagId
					? await this.api.queries.getTagWithVenues(
							{ cityId: foundCity.id, tagId: args.tagId },
							{ useClientCache: false, accessToken: args.isSignedIn ? this.authentication.getAccessToken : undefined },
					  )
					: null;

				const tag = computed(() => data.city.tag ?? []);

				return { tag };
			},
		});
	}
}
