<template>
	<div class="buttons"><slot /></div>
</template>

<script setup></script>

<style scoped lang="scss">
@import "../assets/styles/variables_new.scss";

.buttons {
	display: flex;
	gap: $spacing;
	justify-content: center;
	margin-top: calc($spacing/ 2);
}
</style>
