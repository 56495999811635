import { unref } from "vue";
import { Browser } from "@capacitor/browser";

import { usePlatform } from "../domain/platform.js";
import { useLogger } from "../functions/logger/useLogger.js";
import loggingMessages from "./externalLink.logging-messages.js";

export const externalLink = {
	mounted(el, binding) {
		const { clickHandler, openWindow = true, replaceUrl = false } = binding.value ?? {};
		const logger = useLogger({ name: "externalLink" });
		const platform = usePlatform();

		el.addEventListener("click", async (event) => {
			const url = el.href;
			try {
				event.preventDefault();
				if (clickHandler) {
					await clickHandler();
				}

				if (url) {
					const isCurrentHost = new URL(url).host === window.location.host;
					const isExternalLink = url.startsWith("http") && !isCurrentHost;
					const shouldOpenInNewWindow = unref(openWindow) && isExternalLink;

					if (shouldOpenInNewWindow) {
						if (platform.isNative) {
							await Browser.open({ url });
						} else {
							window.open(url, "_blank");
						}
					} else {
						if (unref(replaceUrl)) {
							window.location.replace(url);
						} else {
							window.location.href = url;
						}
					}
				}
			} catch (error) {
				logger.log(loggingMessages.errorOpeningExternalLink, { url, error });
			}
		});
	},
};
