<template>
	<UserList v-if="userList" :user="props.user" :current-user-lists="props.currentUserLists" :user-list="userList" />
</template>

<script setup>
import { computed, ref, onMounted } from "vue";

import { UserListDatabaseNames } from "../constants/index.js";

import UserList from "../components/UserList.vue";

const props = defineProps({
	user: {
		type: Object,
		required: true,
	},
	userLists: {
		type: Object,
		default: null,
	},
	currentUserLists: {
		type: Array,
		required: true,
	},
});

const isMounted = ref(false);

const userLists = computed(() => props.userLists);
const userList = computed(() => userLists.value?.find(({ name }) => name.toLowerCase() === UserListDatabaseNames.FAVOURITES.toLowerCase()));

onMounted(() => {
	isMounted.value = true;
});
</script>

<style lang="scss" scoped>
@import "../assets/styles/variables.scss";
</style>
