import { ref, watchEffect, unref, toRaw } from "vue";
import { deepDiff } from "./deepDiff.js";

/* same as standard 'computed' but only causes reactive effect if value has actually changed (seems like setting ref to same value doesn't trigger reactive effect) */
/* TODO: server side this seems to fire multiple times, but only once on client side */
export const computedLazy = (calculateFn) => {
	const computedValue = ref();

	watchEffect(() => {
		const value = calculateFn();
		const rawOldValue = toRaw(unref(computedValue));
		const rawNewValue = toRaw(unref(value));

		let isSameValue = rawOldValue == rawNewValue;
		if (Array.isArray(rawOldValue) || Array.isArray(rawNewValue)) {
			isSameValue = deepDiff(rawOldValue ?? [], rawNewValue ?? []).length === 0;
		}

		if (!isSameValue) {
			computedValue.value = rawNewValue;
		}
	});

	return computedValue;
};
