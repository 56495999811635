import { computed } from "vue";

export default class {
	constructor({ logger, modelBuilder, api, authentication }) {
		this.logger = logger.nested({ name: "Model" });
		this.modelBuilder = modelBuilder;
		this.api = api;
		this.authentication = authentication;
	}

	execute({ cityName } = {}) {
		return this.modelBuilder.build({
			name: "GetTags",
			args: { cityName },
			execute: async ({ args }) => {
				const cities = await this.api.queries.getCitiesAnonymous({}, { useClientCache: true });
				const foundCity = cities.cities.find(({ name }) => name.toLowerCase() === args.cityName);
				if (!foundCity) {
					throw new Error(`City '${args.cityName}' not found`);
				}

				const data = await this.api.queries.getTags({ cityId: foundCity.id }, { useClientCache: true });

				const tags = computed(() => data.city.tags);

				return { tags };
			},
		});
	}
}
