import { computed, ref } from "vue";
import { useRoute } from "vue-router";
import { useQueryString } from "../../functions/query-string/index.js";

import { usePlatform } from "../../domain/platform";
import DialogComponent from "./Dialog.vue";

export const useDialog = ({ paramName, isOpen = false } = {}) => {
	const route = useRoute();
	const platform = usePlatform();

	const params = paramName
		? platform.isServer
			? route.query
			: useQueryString({
					[paramName]: null,
			  })
		: null;

	const nonParamOpenState = ref(false);

	const dialog = {
		isOpen: computed(() => (paramName ? params[paramName] == 1 : nonParamOpenState.value)),
		open: () => {
			if (paramName) {
				if (platform.isServer) {
					params[paramName] = 1;
				} else {
					params.set(paramName, 1);
				}
			} else {
				nonParamOpenState.value = true;
			}
		},
		close: () => {
			if (paramName) {
				if (platform.isServer) {
					params[paramName] = null;
				} else {
					params.set(paramName, null);
				}
			} else {
				nonParamOpenState.value = false;
			}
		},
		toggle: () => {
			if (dialog.isOpen.value) {
				dialog.close();
			} else {
				dialog.open();
			}
		},
	};

	if (isOpen) {
		dialog.open();
	}

	return dialog;
};

export const Dialog = DialogComponent;
