import { EntityTypeNames } from "../EntityTypeNames.js";

export default class CityProcessor {
	static doesSupportType(typeName) {
		return typeName === EntityTypeNames.City;
	}

	constructor({ entityStore }) {
		this.entityStore = entityStore;
	}

	process({ entity, existingEntity, entityProcessor }, { skip = 0, replace = false } = {}, summary) {
		const manualProps = {};
		if (entity.feedLists) {
			if (replace) {
				manualProps.feedLists = entityProcessor.processEntities(entity.feedLists, {}, summary);
			} else {
				manualProps.feedLists = entityProcessor.mergeArray(entity.feedLists, existingEntity?.feedLists, skip, summary);
			}
		}

		const storedEntity = this.entityStore.store(
			{
				id: entity.id,
				typeName: EntityTypeNames.City,
				entity: {
					...entityProcessor.processObject(entity, { skip, replace }, summary, ["feedLists"]),
					...manualProps,
				},
			},
			summary,
		);

		return storedEntity;
	}
}
