<template>
	<div :class="{ 'search-box': true, 'is-expanded': isExpanded, 'drop-shadow': props.dropShadow }">
		<div v-show="!showFilters" class="search-box-input-wrapper">
			<div class="search-icon-container">
				<img v-if="isMounted" src="../assets/icons/search-icon.svg" draggable="false" class="search-icon" @click="searchIconOnClick" />
			</div>
			<input
				ref="inputRef"
				v-model="searchTextModel"
				type="text"
				:placeholder="props.placeholder"
				class="search-box-input"
				@keyup="inputKeyUp"
				@keypress="inputKeyPress"
				@change="inputLeave"
				@click="focusSearchBox"
			/>
			<TransitionAnimation mode="out-in" duration="0.1s">
				<img v-show="shouldShowClearTextButton" src="../assets/icons/font-awesome/circle-xmark-solid.svg" draggable="false" class="clear-text-icon" @click="clearLocalModel" />
			</TransitionAnimation>

			<slot name="filterButton" v-bind="{ show: shouldShowFilterButton }">
				<TransitionAnimation mode="out-in" duration="0.1s">
					<SearchFiltersButton
						v-show="shouldShowFilterButton"
						:is-highlighted="showFilters"
						:applied-filter-count="appliedFilterCountDisplay"
						class="search-filters-button"
						@click.stop="toggleFilters"
					/>
				</TransitionAnimation>
			</slot>
		</div>
		<div v-if="props.hasFilters" v-show="isExpanded && showFilters" class="search-box-slot-filters">
			<slot name="filters">
				<SearchFilters ref="searchFiltersRef" :search-text="props.searchText" :tags="tags" @close="closeFilters" />
			</slot>
		</div>
		<div v-show="isExpanded && (!props.hasFilters || !showFilters)" class="search-box-slot-results">
			<slot name="results" />
		</div>
		<div v-show="isExpanded" class="filter-footer">
			<div class="button-group">
				<button :disabled="!appliedFilterCount" class="push-button-primary" @click="clearAllFilters">Clear All{{ appliedFilterCount ? ` (${appliedFilterCount})` : "" }}</button>
				<button :disabled="!pendingFilterCount" class="push-button-primary" @click="persistFilters">
					Apply {{ pluralize(pendingFilterCount, "Filter") }}{{ pendingFilterCountDisplay ? ` (${pendingFilterCountDisplay})` : "" }}
				</button>
			</div>
			<slot name="footer" />
		</div>
	</div>
</template>

<script setup>
import { onMounted, ref, toRef, watch, useSlots, computed, inject } from "vue";
import { useFocus } from "@vueuse/core";
import { debounce } from "lodash-es";

import { formatFilterCountForDisplay, pluralize } from "../helpers/index.js";

import SearchFiltersButton from "./SearchFiltersButton.vue";
import SearchFilters from "./SearchFilters.vue";

const EMIT_CHANGE_TYPES = {
	leave: "leave",
	keyup: "keyup",
};

const props = defineProps({
	placeholder: {
		type: String,
		default: "Search for a place, area, list or tag",
	},
	searchText: {
		type: String,
		default: "",
	},
	emitChangeType: {
		type: String,
		default: "keyup",
		validator: (value) => ["leave", "keyup"].includes(value),
	},
	debounceKeyUp: {
		type: Number,
		default: 1000,
	},
	showResults: {
		type: Boolean,
		default: false,
	},
	showFilters: {
		type: Boolean,
		default: false,
	},
	tags: {
		type: Array,
		default: () => [],
	},
	dropShadow: {
		type: Boolean,
		default: false,
	},
	hasFilters: {
		type: Boolean,
		default: false,
	},
	showDetailsWhenCollapsed: {
		type: Boolean,
		default: true,
	},
});

const emit = defineEmits(["change", "focus", "filtersToggle", "filtersApply", "filtersClear", "resultsToggle"]);

const filterStateManager = inject("filterStateManager", {});

const isMounted = ref(false);
const inputRef = ref(null);
const searchFiltersRef = ref(null);
const localModel = ref("");
const lastEmmittedValue = ref(null);
const slots = useSlots();
const { focused: isInputFocused } = useFocus(inputRef);

const showResults = computed(() => props.showResults && !!slots.results);
const showFilters = computed(() => props.showFilters);
const isExpanded = computed(() => showResults.value || showFilters.value);
const appliedFilterCount = computed(() => filterStateManager.appliedFilterCount);
const pendingFilterCount = computed(() => filterStateManager.pendingFilterCount);
const appliedFilterCountDisplay = computed(() => (appliedFilterCount.value ? formatFilterCountForDisplay(appliedFilterCount.value) : null));
const pendingFilterCountDisplay = computed(() => (pendingFilterCount.value ? formatFilterCountForDisplay(pendingFilterCount.value) : null));
const shouldShowSearchDetails = computed(() => props.showDetailsWhenCollapsed || isExpanded.value || isInputFocused.value);
const shouldShowClearTextButton = computed(() => isMounted.value && localModel.value && shouldShowSearchDetails.value);
const shouldShowFilterButton = computed(() => props.hasFilters && (props.showDetailsWhenCollapsed || isExpanded.value));
const searchTextModel = computed({
	get: () => (shouldShowSearchDetails.value ? localModel.value : null),
	set: (value) => {
		localModel.value = value;
	},
});

watch(
	toRef(props, "searchText"),
	() => {
		localModel.value = props.searchText;
	},
	{ immediate: true },
);

watch(localModel, () => {
	if (props.emitChangeType === EMIT_CHANGE_TYPES.keyup) {
		changeValue(localModel.value, true);
	}
});

// watch(toRef(props, "showFilters"), () => {
// 	if (!props.showFilters) {
// 		resetFilters();
// 	}
// });

function clearAllFilters() {
	filterStateManager.clearFilterState();
	emit("filtersClear");
}

function persistFilters() {
	filterStateManager.applyPendingFilterState();
	emit("filtersApply");
}

const instantEmit = (...args) => {
	if (lastEmmittedValue.value != localModel.value) {
		emit(...args);
		lastEmmittedValue.value = localModel.value;
	}
};
const debouncedEmit = debounce(instantEmit, props.debounceKeyUp);

const changeValue = (value, shouldDebounce = false) => {
	const finalEmit = shouldDebounce ? debouncedEmit : instantEmit;
	finalEmit("change", value);
};

const clearLocalModel = () => {
	localModel.value = "";
	changeValue(localModel.value);
};

const inputLeave = () => {
	if (props.emitChangeType === EMIT_CHANGE_TYPES.leave) {
		changeValue(localModel.value);
	}
};

const inputKeyUp = (event) => {
	if (event.key === "Escape") {
		clearLocalModel();
	}
};

const inputKeyPress = (event) => {
	if (event.key === "Enter") {
		// event.preventDefault();
		changeValue(localModel.value);
		inputRef.value.blur();
	}
};

const focusSearchBox = () => {
	if (!props.showResults) {
		isInputFocused.value = true;
	}
	emit("focus");
};

const searchIconOnClick = () => {
	if (!showResults.value) {
		toggleResults();
		// focusSearchBox();
	} else {
		toggleResults();
	}
};

// const resetFilters = () => {
// 	searchFiltersRef.value.resetFilters();
// };

const toggleFilters = () => {
	emit("filtersToggle", { value: !showFilters.value });
};

const toggleResults = () => {
	emit("resultsToggle", { value: !props.showResults });
};

const closeFilters = () => {
	emit("filtersToggle", { value: false });
};

onMounted(() => {
	isMounted.value = true;
});
</script>

<style lang="scss" scoped>
@import "../assets/styles/variables_new.scss";

.search-box {
	display: flex;
	flex-direction: column;
	position: relative;
	border: $border-size-primary $border-color-primary solid;
	border-radius: $border-radius-quaternary;
	background-color: $background-color-primary;
	box-sizing: border-box;
	padding: calc($spacing / 1) calc($spacing / 1);
	transition: height 0.3s ease-out;
	height: 55px; /* changed from 52px to 50px to stop the search box from jumping when it's collapsed */

	&.drop-shadow {
		margin: calc($spacing / 2);
		@include drop-shadow-primary;
	}

	&.is-expanded {
		height: 100dvh;
		overflow: hidden;
	}

	.search-box-input-wrapper {
		display: flex;
		align-items: center;
		gap: $spacing;
		height: 25px;

		.search-filters-button {
			border-left: $border-size-primary $border-color-primary solid;
			padding: 0 $spacing 0 calc($spacing * 1.5);
			width: 32px;
			height: 28px;
		}

		.search-icon-container {
			position: relative;
			width: 22px;
			aspect-ratio: 1;
			.search-icon {
				color: $text-color-secondary;
				position: absolute;
				height: 22px;
			}
		}

		.clear-text-icon {
			color: $text-color-primary;
			cursor: pointer;
			font-size: 1.05rem;
		}
		.search-box-input {
			display: block;
			flex: 1;
			min-width: 0;
			height: 25px;
			border: none;
			&:focus {
				outline: none;
			}
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			color: $text-color-primary;

			&::placeholder {
				color: $text-color-tertiary;
			}

			&.hidden {
				color: $text-color-tertiary;
				font-size: $text-size-primary;
				font-weight: $text-bold-tertiary;
			}
		}
	}

	.filter-footer {
		display: flex;
		justify-content: space-between;
		gap: calc($spacing/4);
		border-top: $border-size-primary solid $border-color-primary;
		padding-top: calc($spacing / 1.2);
		box-sizing: border-box;
		// margin-bottom: calc($spacing / 2);

		.button-group {
			display: flex;
			gap: calc($spacing / 4);
			overflow: auto;
		}

		// > * {
		// 	flex: 1;
		// 	white-space: nowrap;
		// 	max-width: 120px;
		// }
	}

	.search-box-slot-filters,
	.search-box-slot-results {
		height: 100%;
		min-height: 40px;
		flex: 1;
		overflow: auto;
	}

	.search-box-slot-results {
		margin-top: calc($spacing/1.2);
	}
}
</style>
