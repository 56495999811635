import { computed } from "vue";

export default class {
	constructor({ logger, modelBuilder, api, authentication }) {
		this.logger = logger.nested({ name: "Model" });
		this.modelBuilder = modelBuilder;
		this.api = api;
		this.authentication = authentication;
	}

	execute({ listId } = {}) {
		return this.modelBuilder.build({
			name: "GetList",
			args: { isSignedIn: computed(() => this.authentication.isAuthenticated.value), listId },
			execute: async ({ args }) => {
				const data = args.listId
					? await this.api.queries.getList({ listId: args.listId }, { useClientCache: false, accessToken: args.isSignedIn ? this.authentication.getAccessToken : undefined })
					: null;

				const list = computed(() => data?.list);

				return { list };
			},
		});
	}
}
