<template>
	<div>
		<button v-if="!isCurrentUser && !isCurrentlyFollowing" class="push-button-primary" @click.prevent="followUser">Follow</button>
		<button v-else-if="!isCurrentUser && isCurrentlyFollowing" class="push-button-primary" @click.prevent="unfollowUser">Unfollow</button>
		<button v-else class="push-button-primary" disabled>Follow</button>
	</div>
</template>

<script setup>
import { inject, computed } from "vue";

import { PremiumFeature, SuggestedUserAction } from "../constants/index.js";
import { safeHapticsVibrate } from "../helpers/index.js";

const props = defineProps({
	user: {
		type: Object,
		required: true,
	},
});

const currentEnvironment = inject("currentEnvironment");
const addChange = inject("addChange");
const userActionDialogRef = inject("userActionDialogRef");

const isAuthenticated = computed(() => currentEnvironment.value.isAuthenticated.value);
const activeUser = computed(() => currentEnvironment.value.activeUser.value);
const userId = computed(() => props.user.id);
const isCurrentUser = computed(() => userId.value === activeUser.value?.id);
// const isCurrentlyFollowing = computed(() => activeUser.value?.following.some(({ id }) => id === userId.value));
const isCurrentlyFollowing = computed(() => activeUser.value?.followingMap[userId.value]);

function followUser() {
	if (isAuthenticated.value) {
		safeHapticsVibrate({ duration: 20 });

		if (!isCurrentlyFollowing.value) {
			addChange.FollowUser({ user: activeUser.value, followUser: props.user });
		} else {
			throw new Error("You are already following this user.");
		}
	} else {
		userActionDialogRef.value.open({ feature: PremiumFeature.ACTIVITY_FEED, userAction: SuggestedUserAction.SIGN_IN });
	}
}

function unfollowUser() {
	if (isAuthenticated.value) {
		safeHapticsVibrate({ duration: 20 });

		if (isCurrentlyFollowing.value) {
			addChange.UnfollowUser({ user: activeUser.value, followUser: props.user });
		} else {
			throw new Error("You are not following this user.");
		}
	} else {
		userActionDialogRef.value.open({ feature: PremiumFeature.ACTIVITY_FEED, userAction: SuggestedUserAction.SIGN_IN });
	}
}
</script>

<style scoped lang="scss">
@import "../assets/styles/variables_new.scss";
</style>
