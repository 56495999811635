<template>
	<div class="error-message"><slot /></div>
</template>

<style scoped lang="scss">
@import "../../assets/styles/variables_new.scss";

.error-message {
	font-size: $text-size-tertiary;
	font-weight: $text-bold-tertiary;
	color: $text-color-error;
}
</style>
