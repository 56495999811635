export default {
	settingUpGoogleMaps: {
		level: "info",
		message: () => `Setting up Google Maps`,
	},
	tearingDownGoogleMaps: {
		level: "info",
		message: () => `Tearing down Google Maps`,
	},
	mapMarkerUpdated: {
		level: "silly",
		message: ({ id, diff }) => `Map marker updated: ${id}: ${diff}`,
	},
	mapMarkerCreated: {
		level: "silly",
		message: ({ id }) => `Map marker created: ${id}`,
	},
	mapMarkerRemoved: {
		level: "silly",
		message: ({ id }) => `Map marker removed: ${id}`,
	},
	centerChangedPanningMap: {
		level: "verbose",
		message: ({ location }) => `Center changed, panning map to ${location}`,
	},
	zoomLevelChanged: {
		level: "verbose",
		message: ({ zoomLevel }) => `Zoom level changed to ${zoomLevel}`,
	},
	highlightingSelectedVenue: {
		level: "verbose",
		message: ({ id, location }) => `Highlighting selected venue ${id}, panning map to ${location}`,
	},
	mountedOrVenuesChangedPanningMapToSearchLocation: {
		level: "verbose",
		message: ({ location }) => `Mounted or venues changed, panning map to search location ${location}`,
	},
	fittingToBounds: {
		level: "verbose",
		message: ({ bounds }) => `Fitting to bounds ${bounds}`,
	},
};
