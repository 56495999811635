import { ErrorNested } from "./ErrorNested.js";

export class EnvironmentDetailsLoadingError extends ErrorNested {
	constructor(error, extensions) {
		super("EnvironmentDetailsLoadingError", `Critical error whilst attempting to get environment details`, {
			exception: error,
			code: "ENVIRONMENT_DETAILS_LOADING_ERROR",
			...extensions,
		});
	}
}
