import { decompress } from "compress-json";

import { createApp } from "./createApp.js";
import { useLogger } from "./functions/logger/index.js";
import loggingMessages from "./entry-client.logging-messages.js";

const logger = useLogger({ name: "entry-client" });

const startTime = performance.now();
const decompressed = window.INITIAL_STATE ? decompress(window.INITIAL_STATE) : null;
const duration = performance.now() - startTime;

logger.log(loggingMessages.initialStateDecompression, { duration });

createApp(decompressed || {})
	.then(({ app, router }) => {
		// wait until router is ready before mounting to ensure hydration match
		router.isReady().then(() => {
			app.mount("#app");
		});
	})
	.catch((error) => {
		console.error(error);
	});
