<template>
	<div class="carousel-container">
		<NavigationArrow direction="left" class="navigation-button-prev" />
		<NavigationArrow direction="right" class="navigation-button-next" />
		<swiper
			class="carousel"
			:breakpoints="swiperBreakpoints"
			:modules="swiperModules"
			:navigation="{ enabled: true, prevEl: '.navigation-button-prev', nextEl: '.navigation-button-next' }"
			:pagination="{ clickable: true, dynamicBullets: true, dynamicMainBullets }"
			:edge-swipe-detection="true"
			:zoom="{ enabled: false }"
			:keyboard="{ enabled: true }"
		>
			<swiper-slide v-for="(image, index) in venue.images" :key="`image-${index}`" :virtual-index="index" class="slide">
				<!-- <ImgIxWithPlaceholder :src="image.url" :preview="image.preview" :sizes="sizes" :widths="widths" :aspect-ratio="0.72" class="image" /> -->
				<VenueImage :image="image" />
			</swiper-slide>
		</swiper>
	</div>
</template>

<script setup>
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/zoom";
import { computed } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation, Pagination, Virtual, Zoom, Keyboard } from "swiper/modules";
// import { useWindowSize } from "@vueuse/core";

import NavigationArrow from "../NavigationArrow.vue";
// import ImgIxWithPlaceholder from "../imgix/ImgIxWithPlaceholder.vue";
import VenueImage from "./VenueImage.vue";

const props = defineProps({
	venue: {
		type: Object,
		required: true,
	},
});

const swiperModules = [Navigation, Pagination, Virtual, Zoom, Keyboard];
const swiperBreakpoints = {
	0: {
		slidesPerView: 1,
		spaceBetween: 0,
		centredSlides: true,
	},
	400: {
		slidesPerView: 1,
		spaceBetween: 0,
		centredSlides: true,
	},
	768: {
		slidesPerView: 1,
		spaceBetween: 0,
		centredSlides: true,
	},
};

// const windowSize = useWindowSize();

// const sizes = "(max-width: 767px) 100vw, 440px";
// const widths = computed(() => [...new Array(3).fill(null).map((v, index) => windowSize.width.value * (index + 1)), ...new Array(3).fill(null).map((v, index) => 440 * (index + 1))]);
const dynamicMainBullets = computed(() => Math.min(props.venue.images.length, 6));
</script>

<style scoped lang="scss">
@import "../../assets/styles/variables_new.scss";

$max-width-venue-carousel: 460px;
$max-height-venue-carousel-mobile: max(50dvh, 520px);
$max-height-venue-details-desktop: 700px;

.carousel-container {
	.navigation-button-prev,
	.navigation-button-next {
		display: none;
	}

	:deep(.carousel) {
		.slide {
			display: flex;
			align-items: center;
			overflow: hidden;
			max-height: $max-height-venue-carousel-mobile;

			.image {
				width: 100%;
				// object-fit: scale-down !important;
			}
		}

		.swiper-button-next,
		.swiper-button-prev {
			color: rgba($text-color-primary, 0.7);
		}

		.swiper-pagination {
			--swiper-pagination-bullet-size: 6px;
			--swiper-pagination-bullet-horizontal-gap: 3px;
			transform: none;
			left: auto;
			right: 0;
			bottom: $spacing;
			// right: 0;
			// left: auto;
			// text-align: right;
			// margin-right: calc($spacing * 2);
			// margin-bottom: calc($spacing / 2);
			// display: flex;
			// flex-direction: row;
			// align-items: center;
			// justify-content: flex-end;
		}
		.swiper-pagination-bullet {
			background-color: $background-color-primary;
			opacity: 0.8;
		}

		.swiper-pagination-bullet-active {
			background-color: $text-color-primary;
		}

		.swiper-button-disabled {
			opacity: 0;
		}

		.swiper-slide-active {
			z-index: 1000;
		}
	}
}

@media (min-width: $bp-medium) {
	.carousel-container {
		position: relative;
		width: 50%;
		max-width: $max-width-venue-carousel;

		.navigation-button-prev,
		.navigation-button-next {
			z-index: 2;
			position: absolute;
			display: block;
			top: 50%;
			transform: translateY(-50%);
		}
		.navigation-button-prev {
			left: calc($spacing / 2);
			right: auto;
		}
		.navigation-button-next {
			right: calc($spacing / 2);
			left: auto;
		}

		.swiper-button-disabled {
			opacity: 0;
		}

		:deep(.carousel) {
			height: 100%;

			.slide {
				justify-content: center;
				max-height: none;

				.image {
					/* 1px is to fix a bug where the image is not full height on safari. when resizing window, and text wraps on right, sometimes a pixel gap appears at bottom of image */
					height: calc(100% + 1px);
					object-fit: cover;
				}
			}

			.swiper-slide {
				// height: 100%;
			}
		}
	}
}
</style>
