<template>
	<div class="filters-container">
		<div class="filter-title">
			<CloseButton class="close-button" @click="closeButtonOnClick" />
			<h2>{{ heading }}</h2>
		</div>
		<StandardSearchContext :search-text="searchText" :tags="tags" :show-search-text="false" />
		<loading-message v-if="props.tags.length === 0">Loading, please wait...</loading-message>
		<template v-else>
			<div ref="filterTypesRef" class="filter-types">
				<SearchFilterType ref="pricesFilterTypeRef" class="filter-type" name="prices" title="Prices" :options="priceOptions" :is-collapsible="false" />
				<SearchFilterType ref="mealTypesFilterTypeRef" class="filter-type" name="mealTypeIds" title="Meal Types" :options="mealTypeTags" />
				<SearchFilterType ref="cuisineFilterTypeRefs" class="filter-type" name="cuisineIds" title="Cuisines / Foods" :options="cuisineTags" />
				<SearchFilterType ref="occasionsFilterTypeRefs" class="filter-type" name="occasionIds" title="Occasions" :options="occasionTags" />
				<SearchFilterType ref="vibeFilterTypeRefs" class="filter-type" name="vibeIds" title="Vibes" :options="vibeTags" />
			</div>
		</template>
	</div>
</template>

<script setup>
import { computed, ref } from "vue";

import { TagCategory } from "../constants/index.js";
import { sortArrayBasedOnOrderArray } from "../helpers/index.js";
import TAG_SORT_ORDER_BY_NAME from "../tag-order-meal-type-hardcoded.json";

import CloseButton from "./CloseButton.vue";
import SearchFilterType from "./SearchFilterType.vue";
import StandardSearchContext from "./StandardSearchContext.vue";

const props = defineProps({
	searchText: {
		type: String,
		required: true,
	},
	tags: {
		type: Array,
		default: () => [],
	},
	isSortEnabled: {
		type: Boolean,
		default: false,
	},
});

const emit = defineEmits(["close"]);

const filterTypesRef = ref(null);
const pricesFilterTypeRef = ref(null),
	mealTypesFilterTypeRef = ref(null),
	cuisineFilterTypeRefs = ref(null),
	occasionsFilterTypeRefs = ref(null),
	vibeFilterTypeRefs = ref(null);

const heading = computed(() => (props.isSortEnabled ? "Sort & Filter" : "Filter"));
const priceOptions = [
	{ name: "£", value: "1" },
	{ name: "££", value: "2" },
	{ name: "£££", value: "3" },
	{ name: "££££", value: "4" },
	{ name: "£££££", value: "5" },
];
const mealTypeTags = computed(() =>
	props.tags
		.filter(({ category }) => category === TagCategory.MEAL_TYPE)
		.sort(sortArrayBasedOnOrderArray(TAG_SORT_ORDER_BY_NAME, (v) => v.name))
		.map(({ name, id }) => ({ name: name, value: id })),
);
const cuisineTags = computed(() => props.tags.filter(({ category }) => category === TagCategory.CUISINE).map(({ name, id }) => ({ name: name, value: id })));
const occasionTags = computed(() => props.tags.filter(({ category }) => category === TagCategory.OCCASION).map(({ name, id }) => ({ name: name, value: id })));
const vibeTags = computed(() => props.tags.filter(({ category }) => category === TagCategory.VIBE).map(({ name, id }) => ({ name: name, value: id })));
const tags = computed(() => props.tags);

function closeButtonOnClick() {
	// resetFilters();
	emit("close");
}
</script>

<style scoped lang="scss">
@import "../assets/styles/variables_new.scss";

.filters-container {
	box-sizing: border-box;
	height: 100%;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	@include scroller-wrapper;
	padding: 0;

	.filter-title {
		display: flex;
		flex-direction: column;
		position: relative;
		margin-bottom: $spacing;

		h2 {
			font-size: $text-size-header;
			text-align: center;
		}

		.close-button {
			position: absolute;
			top: 0;
			right: 0;
		}
	}

	.filter-types {
		display: flex;
		flex-direction: column;
		flex-grow: 1;

		@include scroller;

		.filter-type {
			padding-left: calc($spacing / 2);
		}
	}
}
</style>
