<template>
	<div>
		Test Root
		<RouterView v-slot="{ Component }" class="router-view">
			<KeepAlive>
				<Component :is="Component" />
			</KeepAlive>
		</RouterView>
	</div>
</template>

<script setup></script>

<style lang="scss" scoped></style>
