import { computed } from "vue";

export default class {
	constructor({ logger, modelBuilder, api, authentication }) {
		this.logger = logger.nested({ name: "Model" });
		this.modelBuilder = modelBuilder;
		this.api = api;
		this.authentication = authentication;
	}

	execute({ zoneId } = {}) {
		return this.modelBuilder.build({
			name: "GetZone",
			args: { isSignedIn: computed(() => this.authentication.isAuthenticated.value), zoneId },
			execute: async ({ args }) => {
				const zone = args.zoneId
					? await this.api.queries.getZone({ zoneId: args.zoneId }, { useClientCache: true, accessToken: args.isSignedIn ? this.authentication.getAccessToken : undefined })
					: null;

				return { zone };
			},
		});
	}
}
