export class StripePaymentError extends Error {
	constructor(message, extensions) {
		super(`Critical error whilst processing stripe payment: ${message}`);
		this.name = "StripePaymentError";
		this.extensions = {
			code: "STRIPE_PAYMENT_ERROR",
			...extensions,
		};
	}
}
