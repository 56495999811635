import { capitalize, computed, inject, reactive, ref, toRefs } from "vue";
import { useRoute, useRouter } from "vue-router";

import { useQueryString } from "../functions/query-string/index.js";
import { useFilterState } from "./useFilterState.js";
import { combineQueries } from "./combineQueries.js";
import { getFilterCount } from "./getFilterCount.js";
import { waitForDataSSR } from "./waitForDataSSR.js";
import { useIsRouteActive } from "./useIsRouteActive.js";
import { formatFilterCountForDisplay } from "./formatFilterCountForDisplay.js";

export const useSearchResults = (options) => {
	const {
		cityName,
		searchContext,
		searchLocation,
		clientVenueFilter,
		pageSize,
		confirmDialogRef,
		shouldShowResultsWhenShowingFilters = ref(false),
		alwaysExpandResults = ref(false),
	} = toRefs(options);
	const { routeName } = options;

	const logger = inject("logger").nested({ name: "useSearchResults" });
	const currentEnvironment = inject("currentEnvironment");
	const model = inject("model");

	const router = useRouter();
	const route = useRoute();
	const isRouteActive = useIsRouteActive(routeName ?? route.name, { debugLabel: "useSearchResults" });
	const queryString = useQueryString({
		showSearchResults: "false",
		showSearchFilters: "false",
		search: "",
	});

	const filterStateManager = useFilterState({
		router,
		route,
		confirmPendingFilterState,
	});

	const isConfirmingPendingFilterState = ref(false);

	const isSignedIn = computed(() => currentEnvironment.value?.isSignedIn.value);
	const searchText = computed(() => queryString.search);
	const shouldShowLoadingMessageOrError = computed(() => queries.cityQuery.isLoading || queries.error);
	const shouldShowSearchResults = computed(() => (queryString.showSearchResults == "true" && (alwaysExpandResults.value || searchText.value.length > 0 || filterCount.value > 0) ? true : false));
	const shouldShowSearchFilters = computed(() => queryString.showSearchFilters == "true");
	const formattedCityName = computed(() => capitalize(cityName.value));
	const filterCount = computed(() => getFilterCount(filterStateManager.filterState));
	const isAllowedToViewCity = computed(() => queries.model?.currentCity?.canView?.canView);
	const suggestedUserAction = computed(() => queries.model?.currentCity?.canView?.action);
	const searchResultsClientFilters = computed(() => (clientVenueFilter?.value ? { venues: clientVenueFilter.value } : null));
	const searchResults = computed(() =>
		Object.fromEntries(Object.entries(queries.model?.search ?? {}).map(([k, v]) => [k, searchResultsClientFilters.value?.[k] ? v.filter(clientVenueFilter?.value ?? (() => true)) : v])),
	);
	const hasResults = computed(() => searchResults.value?.wasSearchExecuted ?? false);
	const venueResults = computed(() => searchResults.value?.venues ?? []);
	const tags = computed(() => queries.model?.tags ?? []);
	const userLists = computed(() => queries.model?.currentUserLists ?? []);
	const searchQuery = computed(() => queries.searchQuery);
	const cityQuery = computed(() => queries.cityQuery);
	const hasSearchContext = computed(() => !!searchContext?.value);
	const isSearching = computed(() => !!(searchText.value?.length > 0 || searchLocation?.value || getFilterCount(filterStateManager.filterState) > 0));

	const appliedFilterCountDisplay = computed(() => (filterStateManager.appliedFilterCount ? formatFilterCountForDisplay(filterStateManager.appliedFilterCount) : null));

	const queries = combineQueries(
		{
			cityQuery: model.queries.GetCurrentCity(),
			searchQuery: model.queries.SearchExplore(
				{
					searchText: computed(() => searchText.value),
					searchLocation,
					filterState: computed(() => filterStateManager.filterState),
					cityName,
					searchContext,
					size: pageSize,
				},
				{ routeName },
			),
			tags: model.queries.GetTags({
				cityName,
			}),
			userLists: model.queries.GetCurrentUserLists({ isSignedIn }),
		},
		{ isLoading: { include: ["cityQuery", "searchQuery", "tags"] } },
	);

	const promise = waitForDataSSR(queries, logger);

	const returnObject = {
		isSearching,
		hasSearchContext,
		pageSize,
		isSignedIn,
		isAllowedToViewCity,
		searchText,
		clearSearchText,
		shouldShowLoadingMessageOrError,
		hasResults,
		searchResults,
		venueResults,
		shouldShowSearchResults,
		showResults,
		hideResults,
		toggleResults,
		filterCount,
		pendingFilterCount: computed(() => filterStateManager.pendingFilterCount),
		appliedFilterCount: computed(() => filterStateManager.appliedFilterCount),
		appliedFilterCountDisplay,
		shouldShowSearchFilters,
		applyFilters,
		clearFilters,
		toggleFilters,
		suggestedUserAction,
		tags,
		userLists,
		formattedCityName,
		allQueries: queries,
		searchQuery,
		cityQuery,
		searchBoxBind: reactive({
			searchText,
			showResults: shouldShowSearchResults,
			hasFilters: true,
			showFilters: shouldShowSearchFilters,
			tags,
		}),
		searchBoxOn: {
			change: (value) => updateSearchText(value),
			focus: () => showResults(),
			resultsToggle: () => toggleResults(),
			filtersToggle: () => toggleFilters(),
			filtersApply: () => applyFilters(),
			filtersClear: () => clearFilters(),
		},
		searchResultsBind: reactive({
			searchText,
			searchResults,
			tags,
			userLists,
			query: searchQuery,
			pageSize,
		}),
		loadingMessageBind: reactive({
			error: computed(() => queries.error),
			retry: computed(() => queries.retry),
		}),
		confirmPendingFilterState,
		isConfirmingPendingFilterState,
		promise,
	};
	return returnObject;

	async function updateSearchText(value, shouldConfirmPendingFilterState = true) {
		if (shouldConfirmPendingFilterState) {
			await confirmPendingFilterState();
		}
		queryString.set("search", value === "" ? null : value);
	}

	function applyFilters() {
		hideFilters();
		if (!shouldShowSearchFilters.value) {
			clearSearchText();
		}
	}

	function clearFilters() {
		hideFilters();
	}

	function showFilters() {
		queryString.set("showSearchFilters", "true");
	}

	function hideFilters() {
		queryString.set("showSearchFilters", "false");
	}

	async function toggleFilters() {
		const isClosing = queryString["showSearchFilters"] === "true";
		// if (isClosing && !shouldShowSearchResults.value) {
		if (isClosing) {
			await confirmPendingFilterState();
		}
		if (isClosing) {
			hideFilters();
		} else {
			showFilters();
			if (shouldShowResultsWhenShowingFilters.value) {
				showResults();
			}
		}
	}

	function showResults() {
		queryString.set("showSearchResults", "true");
	}

	async function hideResults(autoApplyFilters = false) {
		if (filterStateManager.pendingFilterCount > 0 && !shouldShowSearchFilters.value) {
			clearSearchText();
		}
		if (autoApplyFilters) {
			filterStateManager.applyPendingFilterState();
		} else {
			await confirmPendingFilterState();
		}
		queryString.set("showSearchResults", "false");
		hideFilters();
	}

	async function toggleResults() {
		const isClosing = queryString["showSearchResults"] === "true";
		if (isClosing && !shouldShowSearchFilters.value) {
			await confirmPendingFilterState();
		}
		if (isClosing) {
			await hideResults();
		} else {
			showResults();
		}
	}

	async function confirmPendingFilterState() {
		if (filterStateManager.pendingFilterCount > 0 && isRouteActive.value) {
			// logger.log(loggingMessages.confirmPendingFilterState, { count: pendingFilterCount.value });
			isConfirmingPendingFilterState.value = true;
			const result = await confirmDialogRef.value.open();
			isConfirmingPendingFilterState.value = false;
			// logger.log(loggingMessages.confirmPendingFilterStateHandlingResult, { result: result.name });
			handleConfirmResult(result);
			return result;
		}
		return null;
	}

	function handleConfirmResult(result) {
		if (result?.name === "apply") {
			filterStateManager.applyPendingFilterState();
		} else if (result) {
			filterStateManager.clearPendingFilterState();
		}
	}

	function clearSearchText() {
		updateSearchText("", false);
	}
};
