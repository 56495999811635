<template>
	<RouterView v-slot="{ Component, route }" v-bind="{ ...props }">
		<slot v-bind="{ Component: getComponent(Component, route), route }" />
	</RouterView>
</template>

<script setup>
import { inject, provide, defineProps, getCurrentInstance, watchEffect, reactive, toRaw } from "vue";
import { RouterView, useRouter } from "vue-router";

// import { getComponentTypeName } from "../helpers/index.js";

const props = defineProps({
	...RouterView.props,
	isEnabled: {
		type: Boolean,
		default: true,
	},
});

const router = useRouter();
const parentRouterView = inject("parentRouterView", null);

const currentRouterViewDetails = reactive({});
watchEffect(() => {
	currentRouterViewDetails.depth = (parentRouterView?.depth ?? -1) + 1;
	// currentRouterViewDetails.componentTypeName = getComponentTypeName(getCurrentInstance().parent);
	currentRouterViewDetails.componentType = getComponentType(getCurrentInstance().parent);
	currentRouterViewDetails.routeConfigs = router.getRoutes().filter(({ components }) => components?.default === toRaw(currentRouterViewDetails.componentType));
});

provide("parentRouterView", currentRouterViewDetails);

function getComponent(Component, route) {
	if (!props.isEnabled || !Component) {
		return Component;
	}
	const matchedRoutedAtDepth = route.matched[currentRouterViewDetails.depth];
	return currentRouterViewDetails.routeConfigs?.find(({ name }) => matchedRoutedAtDepth.name === name) ? Component : null;
}

function getComponentType(Component) {
	return Component.type;
}
</script>

<style lang="scss" scoped></style>
