export default {
	startingInitialisation: {
		level: "verbose",
		message: ({ environmentName }) => `Initialising authentication for environment ${environmentName}...`,
	},
	userRememberedFromPreviousSession: {
		level: "info",
		message: ({ environmentName, user }) => `User remembered from previous session for environment ${environmentName}: ${user}`,
	},
	noUserRememberedFromPreviousSession: {
		level: "info",
		message: ({ environmentName }) => `No user remembered from previous session for environment ${environmentName}`,
	},
	errorLoggingInAlreadySignedInUser: {
		level: "warn",
		message: ({ environmentName, error }) => `Error logging in already signed in user into environment ${environmentName}\n${error}`,
	},
	attemptingToLoginUserWithAuth0: {
		level: "info",
		message: ({ user }) => `🔑 Attempting to login user ${user} with auth0...`,
	},
	gettingAccessToken: {
		level: "debug",
		message: () => `...getting access token...`,
	},
	successfullyRetrievedAccessToken: {
		level: "debug",
		message: () => `...successfully retrieved access token...`,
	},
	successfullyLoggedInUserWithAuth0: {
		level: "info",
		message: ({ user }) => `🔑 ...successfully logged in user ${user} with auth0`,
	},
	unexpectedErrorRetrievingAccessToken: {
		level: "error",
		message: ({ user }) => `...unexpected error retrieving access token, user ${user} has not been logged in (they will need to manually log in)`,
	},
	errorAttemptingToLoginUserWithAuth0: {
		level: "error",
		message: ({ user }) => `...error attempting to login user ${user} with auth0, the user has not been logged in (they will need to manually log in)`,
	},
};
