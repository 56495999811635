<template>
	<div class="venue-page" :style="paddingBottomStyle">
		<VenueImage v-if="firstVenueImage" :image="firstVenueImage" class="preload-first-image" />
		<loading-message-with-error v-if="queries.isLoading || queries.error" class="top-loading-message" :error="queries.error" :retry="queries.retry" :full-screen="true">
			Loading place, please wait...
		</loading-message-with-error>
		<error-message v-else-if="!venue" :full-screen="true">No place found</error-message>
		<error-message v-else-if="!userLists" :full-screen="true">No user lists found for user</error-message>
		<VenueDetail v-else :venue="venue" :user-lists="userLists" />
	</div>
</template>

<script setup>
import { computed, inject } from "vue";

import { combineQueries, useRouteParams } from "../helpers/index.js";

import VenueDetail from "../components/venue/VenueDetail.vue";
import VenueImage from "../components/venue/VenueImage.vue";
import { EntityTypeNames } from "../constants/EntityTypeNames.js";

const model = inject("model");
const currentEnvironment = inject("currentEnvironment");
const safeArea = inject("safeArea");
// const isAppHydrated = inject("isAppHydrated");

const routeParams = useRouteParams();

const venueId = computed(() => routeParams.venueId);
const isSignedIn = computed(() => currentEnvironment.value?.isSignedIn.value);

const queries = combineQueries({
	venueDetail: model.queries.GetVenueDetail({ venueId, isSignedIn }),
	userLists: model.queries.GetCurrentUserLists({ isSignedIn }),
});

const venue = computed(() => queries.model?.venue?.venue);
const userLists = computed(() => queries.model?.currentUserLists);

const existingVenueDataFromEntityStore = computed(() => (venueId.value ? model.getStoredEntity({ typeName: EntityTypeNames.Venue, id: venueId.value }) : null));
const firstVenueImage = computed(() => existingVenueDataFromEntityStore.value?.images?.[0]);

const paddingBottomStyle = { "padding-bottom": `${safeArea.insets?.bottom}px` };

// if (!isAppHydrated.value) {
// await queries.promise;
// }
</script>

<style lang="scss" scoped>
@import "../assets/styles/variables_new.scss";

.venue-page {
	overflow: auto;
	display: flex;
	flex-direction: column;
	// justify-content: center;
	height: 100%;
	// width: 100%;
	// z-index: 100;
	// background-color: $background-color-primary;
	// position: fixed !important;
	// top: 0;

	.preload-first-image {
		overflow: hidden;
		height: 0;
	}
}

@media (min-width: $bp-medium) {
	.venue-page {
		// position: inherit !important;
		// top: auto;

		:deep(.page) {
			flex-grow: 0;
		}
	}
}
</style>
