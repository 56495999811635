<template>
	<div class="entity-form">
		<slot v-bind="{ bind, on }">
			<FormLayoutSimple v-bind="bind" :show-labels="true" v-on="on" />
		</slot>
		<Dialog :dialog="entitySaveDialog">
			<LoadingMessage class="loading-message">Saving, please wait...</LoadingMessage>
		</Dialog>
	</div>
</template>

<script setup>
import { computed, inject, reactive, ref, toRef } from "vue";

import { useDialog, Dialog } from "../dialog/index.js";

import FormLayoutSimple from "./FormLayoutSimple.vue";

const props = defineProps({
	entity: {
		type: Object,
		default: null,
	},
	typeName: {
		type: String,
		default: null,
	},
	fieldsConfig: {
		type: Object,
		default: () => ({}),
	},
	syncState: {
		type: Boolean,
		default: false,
	},
});

const emit = defineEmits(["save"]);

const entityManager = inject("entityManager");

const serverErrorMessage = ref();

const entitySaveDialog = useDialog({ isOpen: false });

const entityState = entityManager.createEntityState(toRef(props, "entity"), toRef(props, "fieldsConfig"), toRef(props, "typeName"), {
	syncState: toRef(props, "syncState"),
});

const bind = computed(() => reactive({ entityState, serverErrorMessage }));
const on = computed(() => reactive({ save: onSave }));

async function onSave() {
	entitySaveDialog.open();
	const { success, serverError, state } = await entityState.saveEntity();
	entitySaveDialog.close();
	if (success) {
		emit("save", state);
	}
	if (serverError) {
		serverErrorMessage.value = serverError;
	}
}

defineExpose(entityState);
</script>

<style scoped lang="scss">
@import "../../assets/styles/variables_new.scss";

.entity-form {
	display: flex;
	flex-direction: column;
	width: 100%;

	.loading-message {
		height: 150px !important;
		width: min(500px, 80vw) !important;
		background-color: $background-color-primary;
		border-radius: $border-radius-primary;
		@include drop-shadow-primary;
	}
}
</style>
