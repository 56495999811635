import hash from "object-hash";

export const createSHA256Hash = async (data) => {
	return await hash(data);
	// const msgUint8 = new TextEncoder().encode(data); // encode as (utf-8) Uint8Array
	// console.log("HERE", crypto.subtle);
	// const hashBuffer = await window.crypto.subtle.digest("SHA-256", msgUint8); // hash the message
	// const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
	// const hashHex = hashArray.map((b) => b.toString(16).padStart(2, "0")).join(""); // convert bytes to hex string
	// return hashHex;
};
