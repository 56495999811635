<template>
	<ConditionalRouterLink :to="props.routerLink" :enabled="props.routerLinkEnabled" class="wide-card" @click.stop>
		<span class="top-text">{{ props.topText }}</span>
		<span :to="props.routerLink" class="top-sub-text">{{ props.topSubText }}</span>
		<span :to="props.routerLink" class="image">
			<span v-if="props.decalText" class="decal-text" v-html="props.decalText"></span>
			<ImgIxWithPlaceholder
				:src="props.imageSrc"
				:preview="props.imagePreview"
				sizes="358px"
				:widths="widths"
				:aspect-ratio="2"
				:class="{ disable: props.disableImage }"
				:is-lazy="props.isImageLazy"
			/>
		</span>
		<div class="bottom">
			<span class="bottom-text">{{ props.bottomText }}</span>
			<span class="controls"><slot /></span>
		</div>
	</ConditionalRouterLink>
</template>

<script setup>
import { computed } from "vue";
import ConditionalRouterLink from "./ConditionalRouterLink.vue";
import ImgIxWithPlaceholder from "./imgix/ImgIxWithPlaceholder.vue";

const props = defineProps({
	topText: {
		type: String,
		required: true,
	},
	topSubText: {
		type: String,
		required: true,
	},
	bottomText: {
		type: String,
		required: true,
	},
	decalText: {
		type: String,
		default: null,
	},
	imageSrc: {
		type: String,
		default: null,
	},
	imagePreview: {
		type: String,
		default: null,
	},
	isImageLazy: {
		type: Boolean,
		default: true,
	},
	routerLink: {
		type: [String, Object],
		default: null,
	},
	routerLinkEnabled: {
		type: Boolean,
		default: true,
	},
	showMini: {
		type: Boolean,
		default: false,
	},
	disableImage: {
		type: Boolean,
		default: true,
	},
});

const widths = computed(() => new Array(3).fill(null).map((v, index) => 358 * (index + 1)));
</script>

<style lang="scss" scoped>
@import "../assets/styles/variables_new.scss";

.wide-card {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 354px;
	font-size: $text-size-tertiary;

	.top-text {
		display: block;
		font-weight: $text-bold-secondary;
		color: $text-color-primary;
		width: 100%;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	.top-sub-text {
		display: block;
		color: $text-color-secondary;
		width: 100%;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		margin-bottom: calc($spacing / 6);
	}

	.image {
		width: 100%;
		// width: 330px;
		height: 170px;
		border-radius: $border-radius-primary;
		overflow: hidden;
		position: relative;

		.disable {
			opacity: 0.5;
		}

		.decal-text {
			display: flex;
			flex-direction: column;
			gap: calc($spacing / 4);
			text-align: right;
			position: absolute;
			top: calc($spacing);
			right: calc($spacing * 1.5);
			z-index: 1;
			color: $background-color-primary;
			font-size: $text-size-primary;
			line-height: $text-size-primary;
			font-weight: $text-bold-primary;

			:deep(.secondary) {
				font-size: $text-size-tertiary;
			}
		}
	}

	.bottom {
		display: flex;
		width: 100%;
		align-items: center;
		margin-top: calc($spacing / 4);

		.bottom-text {
			color: $text-color-secondary;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			font-weight: 600;
		}

		.controls {
			margin-left: calc($spacing/2);
		}
	}
}
</style>
