import { computed, getCurrentInstance, markRaw, reactive, unref, watch } from "vue";
import useVuelidate from "@vuelidate/core";

import { EntityTypes } from "./model/index.js";

export class EntityManager {
	constructor({ logger, addChange }) {
		this.logger = logger.nested({ name: "EntityManager" });
		this.addChange = addChange;
	}

	/* REF: entityTypeName is supplied for new entities, as they don't have __typename yet. */
	createEntityState(entity, fieldsConfig = {}, entityTypeName = null) {
		const entityType = computed(() => {
			const entityTypeNameUnref = unref(entityTypeName) ?? unref(entity)?.__typename;

			const EntityType = EntityTypes.find(({ Class: { typeName } }) => typeName === entityTypeNameUnref)?.Class;
			if (!EntityType) {
				throw new Error(`Cannot create entity state. Unknown entity type: ${entityTypeNameUnref}, possible options are: ${EntityTypes.map(({ Class: { typeName } }) => typeName).join(", ")}`);
			}
			const instance = new EntityType({ addChange: this.addChange });
			return instance;
		});

		const fieldDefinitions = computed(() => {
			return unref(entityType)
				.fields.map((field) => ({ ...field, ...(unref(fieldsConfig)[field.name] ?? {}) }))
				.filter(({ isHidden }) => !isHidden);
		});

		const state = reactive({});
		watch(
			entity,
			() => {
				if (entity) {
					Object.entries(unref(entity)).forEach(([key, value]) => {
						state[key] = value;
					});
				}
			},
			{ immediate: true },
		);

		const validationRules = computed(() => fieldDefinitions.value.reduce((acc, { name, validation }) => ({ ...acc, [name]: { ...(validation ?? {}), $autoDirty: true } }), {}));
		const validation = useVuelidate(validationRules, state, { currentVueInstance: getCurrentInstance()?.proxy });

		const entityFieldState = computed(() =>
			fieldDefinitions.value.map((fieldDefinition) => ({
				field: markRaw(fieldDefinition),
				validation: validation.value[fieldDefinition.name] ?? {},
				state: state[fieldDefinition.name],
				updateState: markRaw(({ value }) => {
					state[fieldDefinition.name] = value;
				}),
			})),
		);

		const entityState = reactive({
			entityType,
			validation,
			state,
			fields: entityFieldState,
			saveEntity: markRaw(async () => {
				const response = { isValid: false, success: false, serverError: null };
				unref(validation).$touch();

				if (!unref(validation).$invalid) {
					response.isValid = true;
					const { changeResult, error } = await unref(entityType).onSave(state);
					if (changeResult.success) {
						response.success = true;
					} else {
						if (changeResult.userErrors?.length > 0) {
							response.serverError = changeResult.userErrors.map(({ message }) => message).join(", ");
						} else if (error) {
							response.serverError = "An error occurred. Please try again later.";
						}
					}
				}

				return response;
			}),
		});

		return entityState;
	}
}
