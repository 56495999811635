<template>
	<div class="tabs">
		<slot />
	</div>
</template>

<script setup></script>

<style lang="scss" scoped>
@import "../assets/styles/variables_new.scss";
.tabs {
	display: flex;
	flex-direction: row;
	justify-content: space-around;

	&:has(.tab.secondary) {
		gap: $spacing;
	}
}
</style>
