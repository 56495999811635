export default {
	mounted: {
		level: "info",
		message: () => `Mounted App component`,
	},
	hydrated: {
		level: "info",
		message: () => `Hydrated App component`,
	},
	handlingLoginCallback: {
		level: "info",
		message: ({ url }) => `Handling auth0 login callback for url ${url}`,
	},
	userAuthenticatedAndHasProfileRedirectingToPreviousPage: {
		level: "info",
		message: ({ url }) => `User authenticated and has profile, redirecting to previous page ${url}`,
	},
	userAuthenticatedAndHasProfileRedirectingToHomePage: {
		level: "info",
		message: () => `User authenticated and has profile, redirecting to Home Page`,
	},
	userNotAuthenticatedRedirectingToLogin: {
		level: "info",
		message: () => `User not authenticated, redirecting to login page`,
	},
	userAuthenticatedButDoesNotHaveProfileRedirectingToCreateProfile: {
		level: "info",
		message: () => `User authenticated but does not have profile, redirecting to Create Profile page...`,
	},
	appFullyInitialised: {
		level: "info",
		message: ({ environmentName }) => `🏁 App fully initialised, using environment ${environmentName}`,
	},
};
