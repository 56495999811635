export const asyncDebounceWithArgs = (fn, wait, options = {}) => {
	if (!Number.isFinite(wait)) {
		throw new TypeError("Expected `wait` to be a finite number");
	}

	let executionPromiseMap = new Map();
	let timeoutMap = new Map();
	let resolveMap = new Map();

	return function (...args) {
		return new Promise((resolve) => {
			const argumentKey = JSON.stringify(args);
			let timeout = timeoutMap.get(argumentKey);
			const hasExistingTimeout = !!timeout;

			clearTimeout(timeout);

			timeout = setTimeout(() => {
				timeoutMap.delete(argumentKey);

				const result = options.before ? executionPromiseMap.get(argumentKey) : fn.apply(this, args);
				executionPromiseMap.delete(argumentKey);

				resolveMap.get(argumentKey).forEach((resolveFunction) => resolveFunction(result));
				resolveMap.delete(argumentKey);
			}, wait);
			timeoutMap.set(argumentKey, timeout);

			if (!resolveMap.has(argumentKey)) {
				resolveMap.set(argumentKey, []);
			}

			if (options.before) {
				if (hasExistingTimeout) {
					const executionPromise = executionPromiseMap.get(argumentKey);
					resolve(executionPromise);
				} else {
					const executionPromise = fn.apply(this, args);
					executionPromiseMap.set(argumentKey, executionPromise);
					resolve(executionPromise);
				}
			} else {
				resolveMap.get(argumentKey).push(resolve);
			}
		});
	};
};
