import { computed } from "vue";

export default class {
	constructor({ logger, router, modelBuilder, api, authentication }) {
		this.logger = logger.nested({ name: "Model" });
		this.router = router;
		this.modelBuilder = modelBuilder;
		this.api = api;
		this.authentication = authentication;
	}

	execute() {
		return this.modelBuilder.build({
			name: "GetCurrentCity",
			args: { isSignedIn: computed(() => this.authentication.isAuthenticated.value), cityName: computed(() => this.router.currentRoute.value.params.city?.toLowerCase()) },
			execute: async ({ args }) => {
				let currentCity = null;
				const cities = await this.api.queries.getCities({}, { useClientCache: true, accessToken: args.isSignedIn ? this.authentication.getAccessToken : undefined });
				currentCity = computed(() => cities.cities.find(({ name }) => name.toLowerCase() === args.cityName));

				return { currentCity };
			},
		});
	}
}
