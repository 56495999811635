<template>
	<div class="delete-user-button">
		<button class="link-button-primary" @click="deleteUserOnClick">Delete Account</button>

		<Dialog ref="confirmDialogRef" enter-name="no" escape-name="no" class="confirm-dialog">
			<DialogSimpleLayout :has-close-button="false">
				<DialogMessage>Are you sure you want to delete your account? This action is NOT reversible.</DialogMessage>
				<DialogButtons>
					<DialogButton name="yes">Yes</DialogButton>
					<DialogButton name="no">No</DialogButton>
				</DialogButtons>
			</DialogSimpleLayout>
		</Dialog>
	</div>
</template>

<script setup>
import { computed, inject, ref } from "vue";

import Dialog from "./Dialog.vue";
import DialogSimpleLayout from "./DialogSimpleLayout.vue";
import DialogMessage from "./DialogMessage.vue";
import DialogButtons from "./DialogButtons.vue";
import DialogButton from "./DialogButton.vue";

const currentEnvironment = inject("currentEnvironment");
const addChange = inject("addChange");

const confirmDialogRef = ref();

const activeUser = computed(() => currentEnvironment.value.activeUser.value);

async function deleteUserOnClick() {
	const dialog = confirmDialogRef.value;
	if (dialog && !dialog.isOpen) {
		const result = await confirmDialogRef.value.open();
		if (result.name === "yes") {
			await addChange.SoftDeleteUser({ userId: activeUser.value?.id });
			await currentEnvironment.value.logout();
		}
	}
}
</script>

<style scoped lang="scss">
@import "../assets/styles/variables_new.scss";

.delete-user-button {
	display: flex;
}

.confirm-dialog {
	max-width: 350px;
}
</style>
