import { computed, inject, markRaw, provide, reactive, ref, triggerRef, watch } from "vue";

import { FilterNames, ResultTypes, TagCategoryFilterLookup } from "../constants/index.js";
import { fromArray, toArray, useQueryString } from "../functions/query-string/index.js";
import { ensureArray } from "./ensureArray.js";
import { getPendingFilterCount } from "./getPendingFilterCount.js";
import { getFilterCount } from "./getFilterCount.js";
import loggingMessages from "./useFilterState.logging-messages.js";
import { getFilterValue } from "./getFilterValue.js";

export const useFilterState = ({ router, route, confirmPendingFilterState } = {}) => {
	const FILTER_KEY_RESULT_TYPE_LOOKUP = {
		[ResultTypes.LISTS]: () => FilterNames.lists,
		[ResultTypes.ZONES]: () => FilterNames.zones,
		[ResultTypes.PRICES]: () => FilterNames.prices,
		[ResultTypes.TAGS]: (tag) => TagCategoryFilterLookup[tag.category],
	};

	const FILTER_KEY_VALUE_RESOLVER = {
		[FilterNames.lists]: ({ id, name }) => ({ id, name }),
		[FilterNames.zones]: ({ id, name }) => ({ id, name }),
		[FilterNames.prices]: ({ id }) => id,
		[FilterNames.mealTypes]: ({ id }) => id,
		[FilterNames.cuisines]: ({ id }) => id,
		[FilterNames.occasions]: ({ id }) => id,
		[FilterNames.vibes]: ({ id }) => id,
	};

	const logger = inject("logger").nested({ name: "useFilterState" });

	const queryString = useQueryString(
		{
			[FilterNames.prices]: [],
			[FilterNames.mealTypes]: [],
			[FilterNames.cuisines]: [],
			[FilterNames.occasions]: [],
			[FilterNames.vibes]: [],
			[FilterNames.lists]: [],
			[FilterNames.zones]: [],
		},
		{ router, route, persist: !!router },
	);

	const pendingFilterState = ref({});

	const filterState = computed(() => getFilterState());
	const appliedFilterCount = computed(() => getFilterCount(filterState.value));
	const pendingFilterCount = computed(() => (pendingFilterState.value ? getPendingFilterCount(filterState.value, pendingFilterState.value) : 0));

	const filterStateManager = reactive({
		filterState,
		pendingFilterState,
		appliedFilterCount,
		pendingFilterCount,
		updateFilterState: markRaw(updateFilterState),
		addPendingFilterState: markRaw(addPendingFilterState),
		applyPendingFilterState: markRaw(applyPendingFilterState),
		clearFilterState: markRaw(clearFilterState),
		clearPendingFilterState: markRaw(clearPendingFilterState),
		addPendingFilter: markRaw(addPendingFilter),
		removePendingFilter: markRaw(removePendingFilter),
		setPendingFilter: markRaw(setPendingFilter),
		confirmPendingFilterState: confirmPendingFilterState ? markRaw(confirmPendingFilterState) : null,
		getFilterKeyFromEntity: markRaw(getFilterKeyFromEntity),
		checkAlreadyAPendingFilter: markRaw(checkAlreadyAPendingFilter),
	});

	provide("filterStateManager", filterStateManager);

	watch(
		filterState,
		() => {
			logger.log(loggingMessages.syncingPendingFilterState, { filterState: pendingFilterState.value });
			clearPendingFilterState();
		},
		{ immediate: true },
	);

	return filterStateManager;

	function getFilterKeyFromEntity(resultType, entity) {
		return FILTER_KEY_RESULT_TYPE_LOOKUP[resultType](entity);
	}

	function checkAlreadyAPendingFilter(resultType, entity) {
		const filterKey = getFilterKeyFromEntity(resultType, entity);
		return pendingFilterState.value[filterKey]?.some((v) => getFilterValue(v) === getFilterValue(entity)) ?? false;
	}

	function getFilterState() {
		const _filterState = {};
		_filterState.prices = queryString.prices?.length > 0 ? ensureArray(queryString.prices) : undefined;
		_filterState.mealTypeIds = queryString.mealTypeIds?.length > 0 ? ensureArray(queryString.mealTypeIds) : undefined;
		_filterState.cuisineIds = queryString.cuisineIds?.length > 0 ? ensureArray(queryString.cuisineIds) : undefined;
		_filterState.occasionIds = queryString.occasionIds?.length > 0 ? ensureArray(queryString.occasionIds) : undefined;
		_filterState.vibeIds = queryString.vibeIds?.length > 0 ? ensureArray(queryString.vibeIds) : undefined;
		_filterState.listIds = queryString.listIds?.length > 0 ? ensureArray(queryString.listIds).map((entry) => fromArray(entry, [["id"], ["name"]])) : undefined;
		_filterState.zoneIds = queryString.zoneIds?.length > 0 ? ensureArray(queryString.zoneIds).map((entry) => fromArray(entry, [["id"], ["name"]])) : undefined;
		return _filterState;
	}

	function updateFilterState(_filterState) {
		if (_filterState.prices) {
			queryString.set(FilterNames.prices, _filterState.prices);
		} else {
			queryString.remove(FilterNames.prices);
		}
		if (_filterState.mealTypeIds) {
			queryString.set(FilterNames.mealTypes, _filterState.mealTypeIds);
		} else {
			queryString.remove(FilterNames.mealTypes);
		}
		if (_filterState.cuisineIds) {
			queryString.set(FilterNames.cuisines, _filterState.cuisineIds);
		} else {
			queryString.remove(FilterNames.cuisines);
		}
		if (_filterState.occasionIds) {
			queryString.set(FilterNames.occasions, _filterState.occasionIds);
		} else {
			queryString.remove(FilterNames.occasions);
		}
		if (_filterState.vibeIds) {
			queryString.set(FilterNames.vibes, _filterState.vibeIds);
		} else {
			queryString.remove(FilterNames.vibes);
		}
		if (_filterState.listIds) {
			queryString.set(
				FilterNames.lists,
				_filterState.listIds.map((entry) => toArray(entry, ["id", "name"])),
			);
		} else {
			queryString.remove(FilterNames.lists);
		}
		if (_filterState.zoneIds) {
			queryString.set(
				FilterNames.zones,
				_filterState.zoneIds.map((entry) => toArray(entry, ["id", "name"])),
			);
		} else {
			queryString.remove(FilterNames.zones);
		}
	}

	function setPendingFilter(key, value) {
		pendingFilterState.value[key] = [...value];
		triggerRef(pendingFilterState);
	}

	function addPendingFilter(key, entity) {
		const value = FILTER_KEY_VALUE_RESOLVER[key](entity);
		if (!pendingFilterState.value[key]) {
			setPendingFilter(key, []);
		}
		pendingFilterState.value[key].push(value);
	}

	function removePendingFilter(key, value) {
		if (pendingFilterState.value[key]) {
			pendingFilterState.value[key] = pendingFilterState.value[key].filter((v) => getFilterValue(v) !== getFilterValue(value));
			if (pendingFilterState.value[key].length === 0) {
				delete pendingFilterState.value[key];
			}
		}
	}

	function addPendingFilterState(updatedFilterState) {
		pendingFilterState.value = { ...pendingFilterState.value, ...updatedFilterState };
		pendingFilterState.value = Object.fromEntries(Object.entries(pendingFilterState.value).filter(([, value]) => (Array.isArray(value) ? value.length > 0 : value ?? null !== null)));
	}

	function applyPendingFilterState() {
		// console.log("applyPendingFilterState", pendingFilterState.value);
		updateFilterState(pendingFilterState.value);
		/* do not clear pending filter state, as this results in issue when applyPendingFilterState is called multiple times in a row. If we clear pending filter state, then the next time applyPendingFilterState is called it will clear any previously set values */
		// clearPendingFilterState();
	}

	function clearFilterState() {
		updateFilterState({});
	}

	function clearPendingFilterState() {
		pendingFilterState.value = JSON.parse(JSON.stringify(filterState.value));
	}
};
