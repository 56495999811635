<template>
	<div :class="{ 'form-field-container': true, 'error': validation?.$errors?.length > 0 }" :style="{ width, 'aspect-ratio': props.aspectRatio }" @click="onImageClicked">
		<div class="form-field">
			<input ref="inputRef" type="file" accept="image/*" @change="onStateChanged" />
			<Spinner v-if="isUploading" class="uploading-spinner" />
			<FontAwesomeIcon v-if="!isUploading" icon="camera" class="upload-icon" />
			<div v-if="previewUrl" class="image-preview" :style="style">
				<ImgIxWithPlaceholder :src="previewUrl" :sizes="sizes" :widths="widths" :aspect-ratio="1" />
			</div>
			<ImgIxWithPlaceholder v-else :src="imageUrl" :preview="imagePreview" :sizes="sizes" :widths="widths" :aspect-ratio="1" class="image" :style="style" />
		</div>
		<FormFieldErrors :validation="validation" />
	</div>
</template>

<script setup>
import { computed, inject, ref, toRef, watch } from "vue";

import { ApiRequestError } from "../../errors/index.js";
import loggingMessages from "./FormFieldImage.logging-messages.js";

import ImgIxWithPlaceholder from "../imgix/ImgIxWithPlaceholder.vue";
import FormFieldErrors from "./FormFieldErrors.vue";
import Spinner from "../Spinner.vue";

const props = defineProps({
	validation: {
		type: Object,
		required: true,
	},
	state: {
		type: Object,
		default: () => ({}),
	},
	placeholder: {
		type: String,
		default: "",
	},
	style: {
		type: Object,
		default: () => ({}),
	},
	imageSize: {
		type: Number,
		default: 100,
	},
	aspectRatio: {
		type: Number,
		default: 1,
	},
	apiPath: {
		type: String,
		required: true,
	},
	imageFieldName: {
		type: String,
		required: true,
	},
	maxImageFileSize: {
		type: Number,
		default: 1024 * 1024 * 5, // 5MB
	},
});

const emits = defineEmits(["change"]);

const logger = inject("logger").nested({ name: "FormFieldImage" });
const currentEnvironment = inject("currentEnvironment");
const globalMessageDialog = inject("globalMessageDialog");

const inputRef = ref();
const previewUrl = ref(null);
const fieldState = ref();
const isUploading = ref(false);
watch(
	toRef(props, "state"),
	() => {
		fieldState.value = props.state?.url;
	},
	{ immediate: true },
);

const validation = computed(() => props.validation);
const imageUrl = computed(() => props.state?.url);
const imagePreview = computed(() => props.state?.preview);
const style = computed(() => props.style);
const sizes = computed(() => `${props.imageSize}px`);
const width = computed(() => `${props.imageSize}px`);
const widths = computed(() => [props.imageSize, props.imageSize * 2, props.imageSize * 3]);

const onImageClicked = () => {
	inputRef.value.click();
};

const onStateChanged = async (event) => {
	const file = event.target.files[0];
	if (file) {
		if (file.size >= props.maxImageFileSize) {
			logger.log(loggingMessages.imageUploadNotAllowedSizeExceeded, { data: { name: file.name, size: file.size, maxSize: props.maxImageFileSize } });
			globalMessageDialog.show(`The image you are trying to upload is too large. Please upload an image that is smaller than ${Math.floor(props.maxImageFileSize / (1024 * 1024))}MB.`);
		} else {
			logger.log(loggingMessages.uploadingImage, { data: { name: file.name, size: file.size } });

			const formData = new FormData();
			formData.append(props.imageFieldName, file);

			let fetchResponse;

			const fullApiPath = `${currentEnvironment.value.config?.api.url}${props.apiPath}`;
			const accessToken = await currentEnvironment.value.authentication.getAccessToken();
			try {
				isUploading.value = true;
				fetchResponse = await fetch(fullApiPath, {
					method: "POST",
					headers: {
						Authorization: `Bearer ${accessToken}`,
					},
					body: formData,
				});
			} catch (error) {
				throw new ApiRequestError(`Executing POST request to: ${fullApiPath}`, error);
			} finally {
				isUploading.value = false;
			}
			// const filePreviewUrl = await getUploadedImagePreviewUrl(file);
			// previewUrl.value = filePreviewUrl;

			const data = await fetchResponse.json();
			logger.log(loggingMessages.uploadedImage, { data });
			if (fetchResponse.ok) {
				previewUrl.value = data.success;
				emits("change", { value: data });
			} else {
				throw new ApiRequestError(`Uploading image to: ${fullApiPath}`, new Error(data.error));
			}
		}
	}
};
</script>

<style scoped lang="scss">
@import "../../assets/styles/variables_new.scss";

.form-field-container {
	display: flex;
	flex-direction: column;
	// align-items: start;
	justify-content: center;

	.form-field {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		> input[type="file"] {
			display: none;
		}

		.upload-icon,
		.uploading-spinner {
			position: absolute;
			height: 55px;
			aspect-ratio: 1;
			opacity: 0.75;
			background-color: $background-color-primary;
			border-radius: 50%;
			padding: calc($spacing/1.5);
			box-sizing: border-box;
			@include drop-shadow;

			z-index: 1;
		}
		.upload-icon {
			cursor: pointer;
			width: 38px;
			height: 38px;
		}

		.uploading-spinner {
			display: flex;
			align-items: center;
			justify-content: center;
			animation: pulse 4s infinite;
			animation-timing-function: ease-in-out;

			@keyframes pulse {
				0% {
				}
				50% {
					height: 70px;
				}
				100% {
				}
			}
		}

		.image,
		.image-preview {
			display: flex;
			align-items: center;
			overflow: hidden;
			aspect-ratio: 1;
		}

		.image {
			:deep(img) {
				// width: 100% !important;
			}
		}

		.image-preview {
			:deep(img) {
				// width: 100% !important;
			}
		}
	}
}
</style>
