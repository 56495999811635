import { EntityTypeNames } from "../EntityTypeNames.js";

export default class VenueProcessor {
	static doesSupportType(typeName) {
		return typeName === EntityTypeNames.Area;
	}

	constructor({ entityStore }) {
		this.entityStore = entityStore;
	}

	process({ entity, entityProcessor }, args, summary) {
		const processedEntity = entityProcessor.processObject(entity, args, summary);

		const storedEntity = this.entityStore.store(
			{
				id: entity.id,
				typeName: EntityTypeNames.Area,
				entity: {
					...processedEntity,
				},
				methods: {
					city: (stored) => stored.city,
				},
			},
			summary,
		);

		return storedEntity;
	}
}
