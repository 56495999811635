import { Capacitor } from "@capacitor/core";

const platformName = Capacitor.getPlatform();
const isWeb = platformName === "web";
const isServer = import.meta.env ? import.meta.env.SSR : isWeb;
const isAndroid = platformName === "android";
const isIos = platformName === "ios";
const isNative = isAndroid || isIos;
const isWebAndroid = isWeb && !isServer && navigator.userAgent.match(/Android/i) !== null;
const isWebIos = isWeb && !isServer && navigator.userAgent.match(/(iPhone|iPad)/i) !== null;
const isAnyAndroid = isAndroid || isWebAndroid;
const isAnyIos = isIos || isWebIos;
const name = platformName + (isWeb ? (isServer ? "-server" : "-client") : "");
const isLocalHost = isWeb && !isServer && window.location.hostname === "localhost";
const isTouchEnabled = isServer ? false : "ontouchstart" in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
const isSafari = isWeb && !isServer && /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
const isSafariOrIos = isSafari || isIos;
const isChrome = isWeb && !isServer && /chrome/i.test(navigator.userAgent);

export const usePlatform = () => {
	return {
		name,
		isServer,
		isWeb,
		isAndroid,
		isIos,
		isNative,
		isWebAndroid,
		isWebIos,
		isAnyAndroid,
		isAnyIos,
		isLocalHost,
		isTouchEnabled,
		isSafari,
		isSafariOrIos,
		isChrome,
	};
};
