import { ErrorNested } from "./ErrorNested.js";

export class EnvironmentManagerInitialisationError extends ErrorNested {
	constructor(error, extensions) {
		super("EnvironmentManagerInitialisationError", `Critical error whilst initialising environment manager`, {
			exception: error,
			code: "ENVIRONMENT_MANAGER_INITIALISATION_ERROR",
			...extensions,
		});
	}
}
