export default {
	invokingModelMethod: {
		level: "debug",
		message: ({ modelMethodName, args }) => `⬇️ ${modelMethodName} Invoking model method with args ${args}`,
	},
	invokingQueuedModelMethod: {
		level: "debug",
		message: ({ modelMethodName, args }) => `⤵️ ${modelMethodName} Invoking queued model method with args ${args}`,
	},
	modelMethodAlreadyInvoking: {
		level: "info",
		message: ({ modelMethodName }) => `✋ ${modelMethodName} Model method already invoking. Queueing up next invocation`,
	},
	initialDataFetch: {
		level: "info",
		message: ({ modelMethodName }) => `1️⃣ ${modelMethodName} Initial data fetch for model method`,
	},
	watchArgumentChanged: {
		level: "info",
		message: ({ modelMethodName, diff }) => `👀 ${modelMethodName} One or more watched arguments changed for model method:\n${diff}`,
	},
	executingAction: {
		level: "info",
		message: ({ modelMethodName, actionName }) => `🎬 ${modelMethodName} Executing action ${actionName} for model method`,
	},
	startingWatcher: {
		level: "verbose",
		message: ({ modelMethodName }) => `⏺️ ${modelMethodName} Starting watcher for model method`,
	},
	pausingWatcher: {
		level: "verbose",
		message: ({ modelMethodName }) => `⏸️ ${modelMethodName} Pausing watcher for model method`,
	},
	resumingWatcher: {
		level: "verbose",
		message: ({ modelMethodName }) => `⏯️ ${modelMethodName} Resuming watcher for model method`,
	},
	destroyingWatcher: {
		level: "verbose",
		message: ({ modelMethodName }) => `⏹️ ${modelMethodName} Destroying watcher for model method`,
	},
	errorInvokingWatchArgs: {
		level: "error",
		message: ({ modelMethodName, error }) => `Error invoking watchArgs for model method ${modelMethodName}:\n${error}`,
	},
	errorInvokingModelMethod: {
		level: "error",
		message: ({ modelMethodName, error }) => `Error invoking model method ${modelMethodName}:\n${error}`,
	},
};
