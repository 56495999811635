import { EntityTypeNames } from "../../constants/index.js";

export default class ActivityFeedProcessor {
	static doesSupportType(typeName) {
		return typeName === EntityTypeNames.ActivityFeed;
	}

	constructor({ entityStore }) {
		this.entityStore = entityStore;
	}

	process({ entity, entityProcessor }, { skip = 0, replace = false } = {}, summary) {
		const manualProps = {};
		if (entity.events) {
			// if (replace) {
			manualProps.events = entityProcessor.processEntities(entity.events, {}, summary);
			// } else {
			// 	manualProps.events = entityProcessor.mergeArray(entity.events, existingEntity?.events, skip, summary);
			// }
		}

		return {
			...entityProcessor.processObject(entity, { skip, replace }, summary, ["events"]),
			...manualProps,
		};
	}
}
