export default {
	initialisingEnvironment: {
		level: "info",
		message: ({ environmentName }) => `🌎 Initialising environment ${environmentName}...`,
	},
	userIsAdmin: {
		level: "info",
		message: ({ user }) => `🔒 Logged in user ${user} is an Administrator`,
	},
	impersonatingUser: {
		level: "info",
		message: ({ user }) => `👤 Impersonating user ${user}`,
	},
	successfullyInitialisedEnvironment: {
		level: "info",
		message: ({ environmentName }) => `🌎 Successfully initialised environment ${environmentName}`,
	},
	unableToCompleteEnvironmentInitialisationUserProfileDoesNotExist: {
		level: "error",
		message: ({ environmentName }) => `🌎 Unable to complete environment initialisation, user profile does not exist for environment ${environmentName}`,
	},
	errorGettingEnvironmentDetails: {
		level: "error",
		message: ({ environmentName, error }) => `🌎 Error getting environment details for environment ${environmentName}: ${error}`,
	},
	skippingTrackingInitialisationAsDenied: {
		level: "info",
		message: `⏭️ Skipping tracking initialisation as permission is denied`,
	},
};
