export default {
	challengeWhileMakingReservation: {
		level: "info",
		message: ({ venueId, typeName }) => `Challenge while making reservation with venue ${venueId}: ${typeName}`,
	},
	reservationConfirmed: {
		level: "info",
		message: ({ venueId }) => `Reservation confirmed with venue ${venueId}`,
	},
	criticalErrorWhileMakingReservation: {
		level: "error",
		message: ({ venueId, errors }) => `Critical error making reservation with venue ${venueId}:\n${errors}`,
	},
	unexpectedErrorWhileMakingReservation: {
		level: "error",
		message: ({ venueId }) => `Unexpected error making reservation with venue ${venueId}`,
	},
	errorWhileMakingReservation: {
		level: "error",
		message: ({ venueId, error }) => `Error making reservation with venue ${venueId}:\n${error}`,
	},
};
