import { inject, getCurrentInstance, onMounted } from "vue";

import { useLogger } from "../functions/logger/index.js";
import loggingMessages from "./waitForDataSSR.logging-messages.js";

export const waitForDataSSR = async (queries, logger = useLogger({ name: "waitForDataSSR" })) => {
	const isAppHydrated = inject("isAppHydrated");
	const platform = inject("platform");

	const isSSRRender = platform.isServer || window.IS_SSR_RENDER;

	const instance = getCurrentInstance();
	const componentName = instance.type.__name;

	onMounted(() => {
		logger.log(loggingMessages.componentMounted, { name: componentName });
	});

	if (isSSRRender && !isAppHydrated.value) {
		await queries.promise;
		logger.log(loggingMessages.waitedForDataForComponent, { name: componentName });
	}
};
