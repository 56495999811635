export default {
	initialisingTrackingForEnvironment: {
		level: "info",
		message: ({ environmentName }) => `👣 Initialising tracking for environment ${environmentName}`,
	},
	resettingTrackingForEnvironment: {
		level: "info",
		message: ({ environmentName }) => `👣 Resetting tracking for environment ${environmentName}`,
	},
	initialisingClarity: {
		level: "info",
		message: () => `👣 Initialising Clarity`,
	},
	identifyingUserToClarity: {
		level: "info",
		message: ({ userId }) => `🪪 ⇨ 👣 Identifying user ${userId} to Clarity`,
	},
	initialisingFirebaseAnalytics: {
		level: "info",
		message: () => `👣 Initialising Firebase Analytics`,
	},
	initialisingGoogleAnalytics: {
		level: "info",
		message: () => `👣 Initialising Google Analytics`,
	},
	identifyingUserToFirebaseAnalytics: {
		level: "info",
		message: ({ userId }) => `🪪 ⇨ 👣 Identifying user ${userId} to Firebase Analytics`,
	},
	identifyingUserToGoogleAnalytics: {
		level: "info",
		message: ({ userId }) => `🪪 ⇨ 👣 Identifying user ${userId} to Google Analytics`,
	},
	initialisingOneSignal: {
		level: "info",
		message: ({ token }) => `👣 Initialising OneSignal with app id ${token}`,
	},
	oneSignalNotificationPermissionStatus: {
		level: "info",
		message: ({ hasPermission, canRequestPermission }) => `🔔 OneSignal notification permission status: hasPermission=${hasPermission}, canRequestPermission=${canRequestPermission}`,
	},
	identifyingUserToOneSignal: {
		level: "info",
		message: ({ userId }) => `🪪 ⇨ 👣 Identifying user ${userId} to OneSignal`,
	},
	oneSignalAlreadyInitialisedSkipping: {
		level: "info",
		message: () => `👣 OneSignal already initialised, skipping ⏭️`,
	},
	requestedAdvertisingIdPermissionResult: {
		level: "info",
		message: ({ data }) => `👣 Requested advertising ID permission, user responded with: ${data}`,
	},
	advertisingIdAvailable: {
		level: "info",
		message: ({ data }) => `👣 Advertising ID available: ${data}`,
	},
	initialisingMixpanel: {
		level: "info",
		message: ({ token }) => `👣 Initialising Mixpanel with project token ${token}`,
	},
	identifyingUserToMixpanel: {
		level: "info",
		message: ({ userId }) => `🪪 ⇨ 👣 Identifying user ${userId} to Mixpanel`,
	},
	errorInitialisingOneSignal: {
		level: "error",
		message: ({ error }) => `👣 Error initialising OneSignal: ${error}`,
	},
	trackingPageViewWithMixPanel: {
		level: "verbose",
		message: ({ pageName, query, params }) => `👣 Tracking page view with mixpanel for page ${pageName} - ${params} - ${query} `,
	},
	trackingPageViewWithFirebaseAnalytics: {
		level: "verbose",
		message: ({ pageName }) => `👣 Tracking page view with firebase analytics for page ${pageName}`,
	},
	trackingEventWithMixPanel: {
		level: "verbose",
		message: ({ eventName, properties }) => `👣 Tracking event with mixpanel for event ${eventName}: ${properties}`,
	},
	trackingEventWithFirebaseAnalytics: {
		level: "verbose",
		message: ({ eventName, properties }) => `👣 Tracking event with firebase analytics for event ${eventName}: ${properties}`,
	},
	requestingOneSignalNotificationPermission: {
		level: "info",
		message: () => `🔔 Requesting OneSignal notification permission, falling back to settings page if required...`,
	},
	oneSignalNotificationPermissionRequestResult: {
		level: "info",
		message: ({ wasGranted }) => `🔔 ...OneSignal notification permission request result: wasGranted=${wasGranted}`,
	},
	ensureLoggedOutFromOneSignal: {
		level: "info",
		message: () => `🔔 Ensuring logged out from OneSignal`,
	},
	oneSignalPushNotificationsNotSupported: {
		level: "info",
		message: () => `🔔 Push notifications not supported, skipping initialisation`,
	},
	googlePlayStoreInstallReferrerAvailable: {
		level: "info",
		message: ({ data }) => `🔗 Google Play Store referrer available: ${data}`,
	},
	googlePlayStoreInstallReferrerNotAvailable: {
		level: "info",
		message: () => `🔗 Google Play Store referrer not available`,
	},
	loggingOutFromMixpanel: {
		level: "info",
		message: () => `👣 Logging out from Mixpanel`,
	},
	loggingOutFromOneSignal: {
		level: "info",
		message: () => `👣 Logging out from OneSignal`,
	},
};
