<template>
	<div :class="{ 'page': true, 'is-page-full-height': props.isPageFullHeight }">
		<slot />
	</div>
</template>

<script setup>
const props = defineProps({
	isPageFullHeight: {
		type: Boolean,
		default: false,
	},
});
</script>

<style scoped lang="scss">
@import "../assets/styles/variables_new.scss";

.page {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	overflow: auto;
}

@media (min-width: $bp-medium) {
	.page {
		border: $border-size-primary solid $border-color-primary;
		border-radius: $border-radius-primary;
		margin-bottom: $spacing;

		&:not(.is-page-full-height) {
			flex-grow: 0;
		}
	}
}
</style>
