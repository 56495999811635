<template>
	<div class="anonymous-preview">
		<UserAction :feature="props.feature" :user-action="props.userAction" class="user-action" />
		<div class="page-preview">
			<slot><div :style="style"></div></slot>
		</div>
	</div>
</template>

<script setup>
import { computed } from "vue";

import UserAction from "./UserAction.vue";

const props = defineProps({
	feature: {
		type: String,
		required: true,
	},
	userAction: {
		type: String,
		required: true,
	},
	pageImageUrl: {
		type: String,
		default: null,
	},
});

const style = computed(() => (props.pageImageUrl ? { "background-image": `url(${props.pageImageUrl})` } : {}));
</script>

<style scoped lang="scss">
@import "../assets/styles/variables_new.scss";

.anonymous-preview {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	.page-preview {
		position: absolute;
		height: 100%;
		width: 100%;
		@include page-preview("");
	}

	.user-action {
		padding: calc($spacing * 2);
		box-sizing: border-box;
		z-index: 1;
		border-radius: $border-radius-primary;
		@include drop-shadow-primary;
	}
}
</style>
