<template>
	<div class="debug-type-link">
		<a class="push-button-primary" :href="props.item.value.link" target="_blank">{{ props.item.value.text }}</a>
		<QRCode :data="props.item.value.link" @click="copyLinkToClipboard" />
	</div>
</template>

<script setup>
import QRCode from "../QRCode.vue";

const props = defineProps({
	item: {
		type: Object,
		required: true,
	},
});

function copyLinkToClipboard() {
	navigator.clipboard.writeText(props.item.value.link);
	window.alert("Copied to clipboard!");
}
</script>

<style scoped lang="scss">
@import "../../assets/styles/variables_new.scss";
.debug-type-link {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: calc($spacing / 2);

	.push-button-primary {
		font-size: $text-size-tertiary;
	}
}
</style>
