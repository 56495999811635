<template>
	<div class="venue-list">
		<NavigationArrow direction="left" class="custom-swiper-button-prev" />
		<swiper
			:modules="swiperModules"
			:navigation="{ nextEl: '.custom-swiper-button-next', prevEl: '.custom-swiper-button-prev' }"
			:mousewheel="{ enabled: true, sensitivity: 0.5 }"
			:keyboard="{ enabled: true }"
			class="carousel"
			:centered-slides="true"
			slides-per-view="auto"
			direction="horizontal"
			edge-swipe-detection="prevent"
			@swiper="initSwiper"
			@slide-change="slideChange"
		>
			<swiper-slide v-for="(venue, index) in venues" :key="venue.id" :virtual-index="index">
				<VenueCard :venue="venue" :user-lists="userLists" :show-user-lists="true" class="venue" :show-micro="true" />
			</swiper-slide>
		</swiper>
		<NavigationArrow direction="right" class="custom-swiper-button-next" />
		<!-- -->
	</div>
</template>

<script setup>
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/keyboard";
import { computed, ref, toRef, watch } from "vue";
import { Navigation, Virtual, Mousewheel, Keyboard } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/vue";

import VenueCard from "./VenueCard.vue";
import NavigationArrow from "./NavigationArrow.vue";

const props = defineProps({
	venues: {
		type: Array,
		required: true,
	},
	selectedVenueId: {
		type: String,
		default: null,
	},
	userLists: {
		type: Object,
		default: null,
	},
});

const swiperRef = ref(null);
const swiperModules = [Navigation, Virtual, Mousewheel, Keyboard];

const initSwiper = (swiper) => {
	swiperRef.value = swiper;
};

const userLists = computed(() => props.userLists);
const venues = computed(() => props.venues);

const slideChange = (swiper) => {
	const selectedVenue = props.venues[swiper.activeIndex];
	if (selectedVenue && selectedVenue.id !== props.selectedVenueId) {
		emit("venueChange", selectedVenue.id);
	}
};

const emit = defineEmits(["venueChange"]);

watch(
	[toRef(props, "selectedVenueId"), toRef(props, "venues"), swiperRef],
	() => {
		if (props.selectedVenueId && swiperRef.value) {
			const venueIndexInList = props.venues.findIndex((venue) => venue.id === props.selectedVenueId);
			swiperRef.value.update();
			swiperRef.value.slideTo(venueIndexInList, 400);
		}
	},
	{ immediate: true },
);
</script>

<style lang="scss" scoped>
@import "../assets/styles/variables_new.scss";

.venue-list {
	position: relative;
	width: 100%;
	// height: 100%;
	display: flex;
	gap: $spacing;

	.custom-swiper-button-prev,
	.custom-swiper-button-next {
		position: absolute;
		top: 50%;
		z-index: 2;
		transform: translateY(-50%);
		display: none;
	}

	.carousel {
		width: 100%;
		height: 120px;

		// &.vertical {
		// 	width: 270px;
		// 	height: 100%;
		// }

		:deep(.swiper-wrapper) {
			// gap: $spacing;
			// align-content: center;
		}
		:deep(.swiper-slide) {
			display: flex;
			flex-direction: row;
			align-items: center;
			width: 250px;
			max-width: 250px;
			transition: transform 0.3s ease-in-out;
			overflow: hidden;
			margin: 0 calc($spacing / 2);
			padding: 10px;
			box-sizing: border-box;
		}
		:deep(.swiper-slide-active) {
			transform: scale(1.2);
			z-index: 3;
		}

		.venue {
			width: 100%;
			box-sizing: border-box;
			background-color: $background-color-primary;
			border-radius: $border-radius-secondary;
			@include drop-shadow-primary;
			padding: calc($spacing/2);
		}
	}
}

@media (min-width: $bp-medium) {
	.venue-list {
		padding: 0 calc($spacing * 3);
		box-sizing: border-box;

		.custom-swiper-button-prev,
		.custom-swiper-button-next {
			display: block;
			margin: 0 calc($spacing/2);
		}

		.custom-swiper-button-prev {
			left: 0;
		}

		.custom-swiper-button-next {
			right: 0;
		}

		.swiper-button-disabled {
			visibility: hidden;
		}
	}
}
</style>
