<template>
	<div class="dialog-simple-layout">
		<CloseButton v-if="props.hasCloseButton" class="close-button" :enable-back-action="props.autoWireCloseButton" :show-light="props.showLightCloseButton" @click="closeButtonClicked" />
		<div class="content">
			<slot name="default" />
		</div>
	</div>
</template>

<script setup>
import CloseButton from "./CloseButton.vue";

const props = defineProps({
	header: {
		type: String,
		default: null,
	},
	showHeaderOnDesktop: {
		type: Boolean,
		default: false,
	},
	hasCloseButton: {
		type: Boolean,
		default: true,
	},
	showLightCloseButton: {
		type: Boolean,
		default: false,
	},
});

const emit = defineEmits(["close"]);

function closeButtonClicked() {
	emit("close");
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/variables_new.scss";

.dialog-simple-layout {
	position: relative;
	display: flex;
	flex-direction: column;
	// width: 100%;
	overflow: auto;
	flex-grow: 1;

	.close-button {
		position: absolute;
		z-index: 1;
		top: 0;
		right: 0;
		margin: calc($spacing / 2);
	}

	.content {
		position: relative;
		flex-grow: 1;
		display: flex;
		gap: calc($spacing / 2);
		flex-direction: column;
		box-sizing: border-box;
		margin: $spacing calc($spacing * 2) $spacing calc($spacing * 2);
		padding: calc($spacing) calc($spacing * 0.5) calc($spacing) calc($spacing * 0.5);
		// padding: calc($spacing);
		overflow: auto;

		// > :deep(*) {
		// 	margin: 0 calc($spacing * 2);
		// }
	}
}

@media (min-width: $bp-medium) {
	.dialog-simple-layout {
		.content {
			// flex-grow: 1;
		}
	}
}
</style>
