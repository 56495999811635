import { computed, reactive } from "vue";
import { UserListNames, UserListDatabaseNamesLookup } from "../constants/index.js";

export const useUserLists = (reactiveOptions) => {
	const getListbyName = (listName) => {
		return reactiveOptions.userLists?.find(({ name }) => UserListDatabaseNamesLookup[name] === listName);
	};

	// const isInUserLists = reactive({
	// 	[UserListNames.BEEN]: computed(() => {
	// 		return !!getListbyName(UserListNames.BEEN)?.items?.some((item) => item?.venue?.id === reactiveOptions.venue.id);
	// 	}),
	// 	[UserListNames.FAVOURITES]: computed(() => {
	// 		return !!getListbyName(UserListNames.FAVOURITES)?.items?.some((item) => item?.venue?.id === reactiveOptions.venue.id);
	// 	}),
	// 	[UserListNames.GO_TRY]: computed(() => {
	// 		return !!getListbyName(UserListNames.GO_TRY)?.items?.some((item) => item?.venue?.id === reactiveOptions.venue.id);
	// 	}),
	// });

	const isInUserLists = reactive({
		[UserListNames.BEEN]: computed(() => !!getListbyName(UserListNames.BEEN)?.venueMap[reactiveOptions.venue.id]),
		[UserListNames.FAVOURITES]: computed(() => !!getListbyName(UserListNames.FAVOURITES)?.venueMap[reactiveOptions.venue.id]),
		[UserListNames.GO_TRY]: computed(() => !!getListbyName(UserListNames.GO_TRY)?.venueMap[reactiveOptions.venue.id]),
	});

	return isInUserLists;
};
