import { onActivated, onBeforeUnmount, onDeactivated, onMounted, reactive } from "vue";

export const useComponentState = () => {
	const state = reactive({
		isMounted: false,
		isActive: false,
		hasJustLoaded: true,
	});

	onMounted(() => {
		state.isMounted = true;
		state.isActive = true;
		setTimeout(() => {
			state.hasJustLoaded = false;
		}, 20);
	});

	onActivated(() => {
		state.isActive = true;
		setTimeout(() => {
			state.hasJustLoaded = false;
		}, 20);
	});

	onBeforeUnmount(() => {
		state.isMounted = false;
		state.isActive = false;
		state.hasJustLoaded = true;
	});

	onDeactivated(() => {
		state.isActive = false;
		state.hasJustLoaded = true;
	});

	return state;
};
