/* GitHub Copilt Generated Function */
/* function that compares two objects and returns an array of all the differences across both objects, including the path and values from both objects */
export function deepDiff(obj1, obj2) {
	const diffs = [];

	function compare(_obj1, _obj2, path) {
		Object.keys(_obj1).forEach((key) => {
			const value1 = _obj1[key];
			const value2 = _obj2[key];

			if (typeof value1 === "object" && typeof value2 === "object" && value1 !== null && value2 !== null) {
				compare(value1, value2, path.concat(key));
			} else if (value1 !== value2) {
				diffs.push({ path: path.concat(key).join("."), value1, value2 });
			}
		});

		Object.keys(_obj2).forEach((key) => {
			if (typeof _obj1[key] === "undefined" && typeof _obj2[key] !== "undefined") {
				diffs.push({ path: path.concat(key).join("."), value1: _obj1[key], value2: _obj2[key] });
			}
		});
	}

	compare(obj1, obj2, []);

	return diffs;
}
