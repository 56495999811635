<template>
	<VenueDetailSection title="Contact" class="venue-contact">
		<a v-if="contactNumber" class="call-button" :href="`tel:${contactNumber}`">{{ contactNumber }}</a>
		<span v-else>No contact number available</span>
	</VenueDetailSection>
</template>

<script setup>
import { computed } from "vue";

import VenueDetailSection from "./VenueDetailSection.vue";

const props = defineProps({
	venue: {
		type: Object,
		required: true,
	},
});

const contactNumber = computed(() => props.venue.contactNumber);
</script>

<style scoped lang="scss">
@import "../../assets/styles/variables_new.scss";

.venue-contact {
	// :deep(.call-button) {
	// 	font-size: $text-size-header-secondary;
	// 	// background-image: url("../../assets/icons/contact-number.svg");
	// 	background-position: calc($spacing) center;
	// 	background-size: 20px;
	// 	background-repeat: no-repeat;
	// 	padding: calc($spacing/2) calc($spacing * 1.5) calc($spacing/2) calc($spacing * 3);
	// }
}
</style>
