<template>
	<div class="venue-detail-section">
		<h3 v-if="props.title">{{ props.title }}</h3>
		<div class="detail">
			<slot />
		</div>
	</div>
</template>

<script setup>
const props = defineProps({
	title: {
		type: String,
		default: null,
	},
});
</script>

<style scoped lang="scss">
@import "../../assets/styles/variables_new.scss";

.venue-detail-section {
	display: flex;
	flex-direction: column;

	> h3 {
		color: $text-color-primary;
		font-size: $text-size-quaternary;
		margin-bottom: calc($spacing/1);
	}

	> .detail {
		display: flex;
		align-items: flex-start;
		gap: calc($spacing);
		font-size: $text-size-secondary;
	}
}
</style>
