import { createSHA256Hash, asyncDebounceWithArgs } from "../helpers/index.js";
import loggingMessages from "./onezoneApi.logging-messages.js";

export class OnezoneApi {
	constructor({ logger, environmentName, dataService, apiDataCache, entityProcessorService, queryProcessorService, mutationProcessorService }) {
		this.logger = logger.nested({ name: "OnezoneApi" });
		this.environmentName = environmentName;
		this.dataService = dataService;
		this.apiDataCache = apiDataCache;
		this.entityProcessorService = entityProcessorService;
		this.queryProcessorService = queryProcessorService;
		this.mutationProcessorService = mutationProcessorService;

		this.queryCache = {};

		this.queries = Object.fromEntries(
			this.queryProcessorService.queries
				.filter(({ environmentName: _environmentName }) => !_environmentName || _environmentName === this.environmentName)
				.map(({ name, query, queryProcessor }) => {
					const queryExecutor = asyncDebounceWithArgs(
						async (variables, options = {}) => {
							options.usePersistentCache = false; //TODO: override this for now to disable the feature until we come back to finish it
							const { useClientCache = false, usePersistentCache = false } = options ?? {};

							let results;
							const cacheKey = this.#getCacheKey({ query, variables, options });
							if (this.queryCache[cacheKey] && useClientCache) {
								this.logger.log(loggingMessages.usingClientCachedDataForOperation, { operationName: name, variables, timeRemaining: "TODO" });
								results = this.queryCache[cacheKey];
							} else {
								const hashedCacheKey = await createSHA256Hash(cacheKey);
								const summary = {};
								let data;
								if (usePersistentCache) {
									const exists = await this.apiDataCache.exists({ key: hashedCacheKey });
									if (exists) {
										this.logger.log(loggingMessages.usingPersistentCachedDataForOperation, { operationName: name, variables });
										data = await this.apiDataCache.get({ key: hashedCacheKey });
									}
								}
								if (!data) {
									data = await this.dataService.get({ name, query, variables, options });
									if (usePersistentCache) {
										await this.apiDataCache.set({ key: hashedCacheKey, value: data });
									}
								}
								results = await queryProcessor({ data, variables, summary });
								this.queryCache[cacheKey] = results;
							}
							return results;
						},
						0,
						{ before: true },
					);

					return [name, queryExecutor];
				}),
		);

		this.mutations = Object.fromEntries(
			this.mutationProcessorService.mutations.map(({ name, mutation, mutationProcessor }) => {
				const mutationExecutor = asyncDebounceWithArgs(
					async (variables, options) => {
						const { data, errors } = await this.dataService.update({ name, mutation, variables, options });
						const mutationProcessorResults = data ? await mutationProcessor({ data, variables }) : null;

						return { data: mutationProcessorResults, errors };
					},
					0,
					{ before: true },
				);

				return [name, mutationExecutor];
			}),
		);
	}

	clearCache() {
		this.queryCache = {};
	}

	#getCacheKey({ query, variables, options }) {
		const json = JSON.stringify({
			query: query.loc.source.body,
			variables,
			options: options ? Object.fromEntries(Object.entries(options).filter(([key]) => !["useClientCache"].includes(key))) : undefined,
		});
		return json;
	}
}
