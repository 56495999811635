<template>
	<div class="base-container">
		<div class="base-wrapper">
			<Header :class="{ 'hide-header': !showHeader }" :show-buttons="false" />
			<div class="main">
				<div class="navigation">
					<Navigation />
				</div>
				<div class="spacer-left"></div>
				<div class="content">
					<ActivatedRouterView v-slot="{ Component }" class="router-view">
						<KeepAlive>
							<Component :is="Component" />
						</KeepAlive>
					</ActivatedRouterView>
				</div>
				<div class="spacer-right"></div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { computed } from "vue";
import { useRoute } from "vue-router";

import Header from "../components/Header.vue";
import Navigation from "../components/Navigation.vue";

const route = useRoute();

const showHeader = computed(() => route.meta?.showHeader ?? true);
</script>

<style lang="scss" scoped>
@import "../assets/styles/variables_new.scss";

.base-container {
	> .base-wrapper {
		display: flex;
		flex-direction: column;
		height: 100dvh;

		.hide-header {
			display: none;
		}

		> .main {
			flex-grow: 1;
			display: flex;
			flex-direction: column;
			overflow: auto;

			> .navigation {
				order: 2;
			}

			> .content {
				display: flex;
				flex-direction: column;
				position: relative;
				order: 1;
				flex-grow: 1;
				overflow: hidden;
				// margin-top: $spacing;

				> .router-view {
					overflow: auto;
					animation-duration: 0.3s;

					&.animate__animated {
						position: absolute;
						width: 100vw;
						height: 100%;
					}
				}
			}

			> .spacer-left,
			> .spacer-right {
				display: none;
			}
		}
	}
}

@media (min-width: $bp-medium) {
	.base-container {
		display: flex;
		justify-content: center;

		> .base-wrapper {
			flex-grow: 1;
			min-width: 0; /* REF: https://stackoverflow.com/questions/36247140/why-does-flex-grow-1-shrink-the-parent-element */
			max-width: 1260px;
			margin: 0 calc($spacing * 2);

			.hide-header {
				display: block;
			}

			> .main {
				flex-direction: row;
				> .navigation {
					display: flex;
					flex-direction: column;
					order: 1;
					// min-width: 100px;
					margin-right: calc($spacing * 4.1);
					margin-left: calc($spacing / 4); /* this is to account for the onezone logo image itself having a margin */

					:deep(.nav-wrapper) {
						overflow-y: auto;
					}
				}

				> .content {
					order: 2;
				}
			}
		}
	}
}
</style>
