<template>
	<div class="explore-list-container">
		<PageHeader v-if="isHeaderReady" :show-share-button="!showLoadingOrErrorMessage" :share-title="list?.name">
			<template #breadcrumb>
				<custom-router-link :to="{ name: 'City/Explore/Lists/Category', params: { city: routeParams.cityName, categoryName } }">
					{{ listBreadcrumb?.text }}
				</custom-router-link>
			</template>
			<template v-if="!showLoadingOrErrorMessage">{{ list?.name }}</template>
			<template v-if="!showLoadingOrErrorMessage" #controls>
				<VenueFilterClientSide ref="venueFilterClientSideRef" :tags="tags" :venues="sortedVenues" />
			</template>
		</PageHeader>
		<StandardSearchContext :align-center="false" :tags="tags" :filter-state-manager="venueFilterClientSideRef?.filterStateManager" :show-search-text="false" />
		<loading-message-with-error v-if="showLoadingOrErrorMessage" :error="query.error" :retry="query.retry" class="loading-message"> Loading, please wait... </loading-message-with-error>
		<div v-else class="explore-list">
			<!-- <SuperList v-slot="{ item: venue, style }" :name="`list-${list?.id}`" :items="venueFilterClientSideRef?.filteredVenues ?? []" :item-size="76" :is-virtual="true" class="explore-list-venues-list">
				<VenueCard :key="venue.id" :venue="venue" :show-mini="true" :user-lists="userLists" :style="style" />
			</SuperList> -->
			<NewList
				v-slot="{ item: venue, isItemVisible }"
				class="explore-list-venues-list"
				:name="`list-${list?.id}`"
				:items="venueFilterClientSideRef?.filteredVenues ?? []"
				:direction="ListDirections.VERTICAL"
				:item-height="76"
				:gap-size="0"
				:item-buffer-count="4"
			>
				<VenueCard v-if="isItemVisible" :venue="venue" :show-mini="true" :user-lists="userLists" />
			</NewList>
		</div>
	</div>
</template>

<script setup>
import { inject, computed, ref, onMounted, toRef } from "vue";

import { combineQueries, useRouteParams, sorters, ensureCorrectCity } from "../helpers/index.js";
import { ListCategory, ListCategoryUrlParamLookupByName, SortOrder, ListDirections } from "../constants/index.js";

import VenueCard from "./VenueCard.vue";
// import SuperList from "./SuperList.vue";
import NewList from "./NewList.vue";
import PageHeader from "./PageHeader.vue";
import StandardSearchContext from "./StandardSearchContext.vue";
import VenueFilterClientSide from "./VenueFilterClientSide.vue";

const LIST_CATEGORY_BREADCRUMB = {
	[ListCategory.VIBES]: { text: "All Vibe Lists", categoryName: ListCategoryUrlParamLookupByName[ListCategory.VIBES] },
	[ListCategory.CUISINES]: { text: "All Cuisine Lists", categoryName: ListCategoryUrlParamLookupByName[ListCategory.CUISINES] },
	[ListCategory.OCCASIONS]: { text: "All Occasion Lists", categoryName: ListCategoryUrlParamLookupByName[ListCategory.OCCASIONS] },
	[ListCategory.MOST_POPULAR]: { text: "All Top Lists", categoryName: ListCategoryUrlParamLookupByName[ListCategory.MOST_POPULAR] },
};

const LIST_CATEGORY_PREFERENCE_ORDER = [ListCategory.MOST_POPULAR, ListCategory.CUISINES, ListCategory.OCCASIONS, ListCategory.VIBES];

const routeParams = useRouteParams();

const isAppHydrated = inject("isAppHydrated");
const model = inject("model");
const currentEnvironment = inject("currentEnvironment");
const logger = inject("logger");
// const { venueResults, hasResults, allQueries: allSearchQueries } = inject("searchResults");

const isMounted = ref(false);
const venueFilterClientSideRef = ref(null);

onMounted(() => {
	isMounted.value = true;
});

const isSignedIn = computed(() => currentEnvironment.value?.isSignedIn.value);

const query = combineQueries({
	listQuery: model.queries.GetList({
		listId: computed(() => routeParams.listId),
	}),
	userListsQuery: model.queries.GetCurrentUserLists({ isSignedIn }),
	tags: model.queries.GetTags({
		cityName: toRef(routeParams, "cityName"),
	}),
});

const list = computed(() => query.model?.list);
const listVenues = computed(
	() =>
		query.model?.list?.items
			?.filter(({ venue }) => venue.published)
			.map(({ venue }) => {
				return venue;
			}) ?? [],
);
// const venues = computed(() => (hasResults.value ? venueResults.value : listVenues.value));
// const showLoadinngOrErrorMessage = computed(() => query.isLoading || query.error || allSearchQueries.isLoading);
const showLoadingOrErrorMessage = computed(() => query.isLoading || query.error);
const venues = computed(() => listVenues.value);
const tags = computed(() => query.model?.tags ?? []);
const venueSorter = computed(() => sorters.sortVenues[SortOrder.NAME]);
const sortedVenues = computed(() => [...(venues.value ?? [])]?.sort(venueSorter.value));
const userLists = computed(() => query.model?.currentUserLists ?? []);
const listCategories = computed(() => (list.value?.category?.length > 0 ? list.value.category : null));
const sortedListCategories = computed(() =>
	listCategories.value ? [...listCategories.value].sort((a, b) => LIST_CATEGORY_PREFERENCE_ORDER.indexOf(a) - LIST_CATEGORY_PREFERENCE_ORDER.indexOf(b)) : null,
);
const listCategory = computed(() => (sortedListCategories.value ? sortedListCategories.value[0] : null));
const listBreadcrumb = computed(() => (listCategory.value ? LIST_CATEGORY_BREADCRUMB[listCategory.value.name] : null));
const categoryName = computed(() => listBreadcrumb.value?.categoryName ?? null);
const isHeaderReady = computed(() => !!categoryName.value);
const listCityName = computed(() => list.value?.city?.()?.name ?? null);
ensureCorrectCity(logger, listCityName);

if (!isAppHydrated.value) {
	await query.promise;
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/variables_new.scss";

.explore-list-container {
	height: 100%;
	display: flex;
	flex-direction: column;
	gap: calc($spacing / 2);
	// padding: 0 calc($spacing * 2);
	margin-top: $spacing;
	overflow: auto;
	@include centre-loading-message;

	.explore-list {
		display: flex;
		flex-direction: column;
		gap: calc($spacing * 0.5);
		overflow: auto;
		overscroll-behavior: none;

		:deep(.super-list) {
			padding-right: calc($spacing / 2);
		}

		.category-tabs {
			margin-bottom: calc($spacing * 1.5);
		}

		// .explore-list-venues-list {
		// 	overflow: auto;
		// 	display: flex;
		// 	flex-direction: column;
		// 	gap: $spacing;
		// 	align-content: start;

		// 	.image {
		// 		width: 160px;
		// 		height: 160px;
		// 	}
		// }
	}
}

@media (min-width: $bp-medium) {
	.explore-list-container {
		padding: 0;
	}
}
</style>
