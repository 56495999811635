<template>
	<div v-if="showMessage" class="network-status">Network problems, waiting until better signal detected.</div>
</template>

<script setup>
import { computed, inject, onMounted, onUnmounted, reactive, ref } from "vue";
import { Network } from "@capacitor/network";

import loggingMessages from "./NetworkStatus.logging-messages.js";

const logger = inject("logger").nested({ name: "Header" });

const isMounted = ref(false);
const NETWORK_STATUS = {
	wifi: "wifi",
	cellular: "cellular",
	none: "none",
	unknown: "unknown",
};
const networkStatus = reactive({
	isConnected: true,
	status: NETWORK_STATUS.unknown,
});
const updateNetworkStatus = (updatedNetworkStatus) => {
	networkStatus.isConnected = updatedNetworkStatus.connected;
	networkStatus.status = updatedNetworkStatus.connectionType;
	logger.log(loggingMessages.networkStatusChange, { networkStatus });
};

const showMessage = computed(() => {
	return !networkStatus.isConnected;
});

onMounted(async () => {
	isMounted.value = true;
	Network.addListener("networkStatusChange", (updatedNetworkStatus) => {
		updateNetworkStatus(updatedNetworkStatus);
	});

	try {
		const updatedNetworkStatus = await Network.getStatus();
		updateNetworkStatus(updatedNetworkStatus);
	} catch (error) {
		logger.log(loggingMessages.networkStatusError, { error });
	}
});

onUnmounted(() => {
	Network.removeAllListeners();
});
</script>

<style lang="scss" scoped>
@import "../assets/styles/variables_new.scss";

.network-status {
	width: 100%;
	box-sizing: border-box;
	padding: calc($spacing / 4);
	background-color: $background-color-onezone-1;
	font-size: $text-size-tertiary;
	font-weight: $text-bold-primary;
	text-align: center;
	// box-shadow: 0px 0px 10px $text-color-primary;
}
</style>
