export default {
	criticalErrorLockingVenueBookingAvailability: {
		level: "error",
		message: ({ venueId, time, partySize, seatingPosition, errors }) =>
			`Critical error locking venue booking availability for venue ${venueId} at time ${time} for party size ${partySize} and seating position ${seatingPosition}:\n${errors}`,
	},
	errorLockingVenueBookingAvailability: {
		level: "error",
		message: ({ venueId, time, partySize, seatingPosition, error }) =>
			`Error locking venue booking availability for venue ${venueId} at time ${time} for party size ${partySize} and seating position ${seatingPosition}:\n${error}`,
	},
};
