import { ErrorNested } from "./ErrorNested.js";

export class ApiRequestError extends ErrorNested {
	constructor(message, error, extensions) {
		super("ApiRequestError", `An error occurred calling the API: ${message}`, {
			exception: error,
			code: "API_REQUEST_ERROR",
			...extensions,
		});
	}
}
