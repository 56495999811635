<template>
	<router-link v-bind="{ ...props, to: toLink }" :class="{ 'router-link-active-partial-path': isRouteInCurrentPath }"><slot /></router-link>
</template>

<script setup>
import { computed } from "vue";
import { RouterLink, useRoute, useRouter } from "vue-router";

const props = defineProps({
	...RouterLink.props,
	enablePreviousRoutes: {
		type: Boolean,
		default: false,
	},
});

const router = useRouter();
const route = useRoute();

const allRoutes = router.getRoutes();

const routeConfig = computed(() => allRoutes.find(({ name }) => name.toLowerCase() === props.to?.name?.toLowerCase()));

const routePath = computed(() => routeConfig.value?.path.replace(/:\w+/g, "\\w+"));

const isRouteInCurrentPath = computed(() => {
	const isPathMatchEnabledOnRoute = route.meta.pathMatch ?? true;
	const isPathMatch = new RegExp(`^${routePath.value}`).test(route.path);
	const isParamMatch = props.to?.params ? Object.entries(props.to?.params).every(([key, value]) => route.params[key] === value) : true;

	return isPathMatchEnabledOnRoute && isPathMatch && isParamMatch;
});

const previousRoute = computed(() => (props.enablePreviousRoutes ? router.previousRoutes?.[props.to?.name] : null) ?? null);

const toLink = computed(() => previousRoute?.value ?? props.to);
</script>

<style lang="scss" scoped></style>
