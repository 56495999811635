import { getFileDetails } from "../../helpers/index.js";

const entityFiles = import.meta.glob("./entities/*.js", { eager: true });
const mutationFiles = import.meta.glob("./mutations/*.js", { eager: true });
const queryFiles = import.meta.glob("./queries/*.js", { eager: true });

const EntityTypes = Object.entries(entityFiles).map(([name, file]) => ({ name: getFileDetails(name).name, Class: file.default }));
const queries = Object.entries(queryFiles).map(([name, file]) => ({ name: getFileDetails(name).name, Class: file.default }));
const mutations = Object.entries(mutationFiles).map(([name, file]) => ({ name: getFileDetails(name).name, Class: file.default }));

export { EntityTypes, mutations, queries };
