import graphqlQueries from "./queries/index.js";
import loggingMessages from "./QueryProcessorService.logging-messages.js";

export class QueryProcessorService {
	constructor({ logger, queryStore, entityProcessorService }) {
		this.logger = logger.nested({ name: "QueryProcessorService" });
		this.queryStore = queryStore;
		this.entityProcessorService = entityProcessorService;

		this.queries = this.generateQueries();
	}

	generateQueries() {
		return graphqlQueries.map(({ name, query, config }) => {
			const queryProcessor = async ({ data, variables, summary }) => {
				const processedQuery = config?.process
					? await config.process({ queryStore: this.queryStore, entityProcessorService: this.entityProcessorService, data, variables, summary })
					: this.entityProcessorService.processRawData(data, summary);

				this.logger.log(loggingMessages.processedQuery, { operationName: name }, summary);

				return processedQuery;
			};

			return {
				name,
				query,
				queryProcessor,
			};
		});
	}
}
