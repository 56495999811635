export default {
	syncingPendingFilterState: {
		level: "verbose",
		message: ({ filterState }) => `Original filter state set/changed. Syncing pending filter state: ${filterState}`,
	},
	confirmPendingFilterState: {
		level: "verbose",
		message: ({ count }) => `User has ${count} pending map filters, showing confirmation dialog...`,
	},
	confirmPendingFilterStateHandlingResult: {
		level: "verbose",
		message: ({ result }) => `...user pressed ${result} on pending map filters confirmation dialog, handling result`,
	},
};
