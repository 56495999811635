export default {
	invalidBlurhash: {
		level: "error",
		message: ({ url, blurhash, reason }) => `Invalid blurhash for ${url}: ${blurhash}\nReason: ${reason}`,
	},
	invalidPreviewJson: {
		level: "warn",
		message: ({ url, preview, error }) => `Invalid preview JSON for ${url}: ${preview}\nError: ${error}`,
	},
	attemptingToReloadImage: {
		level: "verbose",
		message: ({ url, count }) => `Attempting to reload image, attempt ${count}: ${url}`,
	},
	errorGeneratingPreview: {
		level: "error",
		message: ({ url, error }) => `Error generating image preview for ${url}:\n ${error}`,
	},
	errorLoadingImage: {
		level: "error",
		message: ({ url }) => `Error loading image ${url}`,
	},
};
