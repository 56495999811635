<template>
	<ConditionalRouterLink :to="routerLink" :enabled="props.routerLinkEnabled" class="user-card">
		<div class="user-profile">
			<ProfileImage :profile-image="user.profileImage" class="profile-image" :size="profileImageSize" />
			<div class="user-details">
				<span :class="{ fullname: true, highlight: props.highlightName }">{{ name }}</span>
				<span class="username">{{ username }}</span>
				<span v-if="isAdmin" class="email admin-data">{{ email }}</span>
			</div>
		</div>
		<span v-if="props.showControls" class="controls">
			<slot>
				<FollowUserButton :user="user" />
			</slot>
		</span>
	</ConditionalRouterLink>
</template>

<script setup>
import { computed, inject } from "vue";

import { PageNames } from "../constants/index.js";
import { urlBuilder, useRouteParams } from "../helpers/index.js";

import ConditionalRouterLink from "./ConditionalRouterLink.vue";
import ProfileImage from "./ProfileImage.vue";
import FollowUserButton from "./FollowUserButton.vue";

const props = defineProps({
	user: {
		type: Object,
		required: true,
	},
	highlightName: {
		type: Boolean,
		default: false,
	},
	routerLinkEnabled: {
		type: Boolean,
		default: true,
	},
	showControls: {
		type: Boolean,
		default: true,
	},
});

const currentEnvironment = inject("currentEnvironment");

const routeParams = useRouteParams();

const user = computed(() => props.user);
const profileImageSize = computed(() => 60);
const name = computed(() => `${user.value.firstName} ${user.value.lastName}`);
const username = computed(() => user.value.username);
const email = computed(() => user.value.email);
const routerLink = computed(() => urlBuilder(PageNames.OTHER_USER_PROFILE, { cityName: routeParams.cityName, user: user.value }));
const isAdmin = computed(() => currentEnvironment.value?.isAdmin.value);
</script>

<style scoped lang="scss">
@import "../assets/styles/variables_new.scss";

a.user-card .user-profile {
	@include hover-primary;
}

.user-card {
	display: flex;
	align-items: center;
	gap: $spacing;
	overflow: auto;

	.user-profile {
		display: flex;
		gap: $spacing;
		align-items: center;
		flex-grow: 1;
		padding: calc($spacing/2);
		overflow: auto;

		.profile-image {
			flex-shrink: 0;
			width: 60px;
			height: 60px;
		}

		.user-details {
			overflow: auto;
			display: flex;
			flex-direction: column;
			gap: calc($spacing/4);
			justify-content: center;
			overflow: auto;

			.fullname {
				display: block;
				font-size: $text-size-primary;
				@include text-ellipsis;

				&.highlight {
					font-size: $text-size-header;
					font-weight: $text-bold-primary;
				}
			}
			.username {
				display: block;
				font-size: $text-size-tertiary;
				color: $text-color-secondary;
				@include text-ellipsis;
			}
			.email {
				display: block;
				font-size: $text-size-tertiary;
				@include text-ellipsis;
			}
		}
	}

	.controls {
		display: flex;
		justify-content: flex-end;
		// min-width: 84px;
	}
}

// @media (min-width: $bp-medium) {
// 	.user-card {
// 		padding: 0;

// 		.profile-image {
// 			width: 80px;
// 			height: 80px;
// 		}

// 		.user-details {
// 			.fullname {
// 				font-size: $text-size-quaternary;
// 				// font-weight: $text-bold-primary;
// 			}
// 			.username {
// 				font-size: $text-size-secondary;
// 			}
// 		}
// 	}
// }
</style>
