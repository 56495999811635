import { createAuth0Client } from "@auth0/auth0-spa-js";

import { Auth0Client } from "./Auth0Client.js";
import loggingMessages from "./Auth0ClientFactory.logging-messages.js";

export class Auth0ClientFactory {
	constructor({ logger, persistentQueries, defaultEnvironmentName, mobileApp }) {
		this.logger = logger.nested({ name: "Auth0ClientFactory" });
		this.persistentQueries = persistentQueries;
		this.defaultEnvironmentName = defaultEnvironmentName;
		this.mobileApp = mobileApp;
	}

	async create({ authenticationCache, config, screen_hint } = {}) {
		const { domain, clientId, redirectUri, audience } = config;

		this.logger.log(loggingMessages.createAuth0Client, { url: domain });

		const auth0Client = await createAuth0Client({
			authorizeTimeoutInSeconds: 600,
			domain,
			clientId,
			cache: authenticationCache,
			issuer: `https://${domain}/`,
			authorizationParams: {
				screen_hint,
				redirect_uri: redirectUri,
				audience,
			},
			useRefreshTokens: true,
		});

		const client = new Auth0Client({
			logger: this.logger,
			persistentQueries: this.persistentQueries,
			defaultEnvironmentName: this.defaultEnvironmentName,
			mobileApp: this.mobileApp,
			config,
			auth0Client,
		});

		return client;
	}
}
