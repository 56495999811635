<template>
	<div>
		<dl>
			<dt><label for="changeStripeEnvironment">Change Stripe Environment</label></dt>
			<dd>
				<select id="changeStripeEnvironment" v-model="stripeEnvironment" @change="changeStripeEnvironment">
					<option v-for="name in stripeEnvironmentNames" :key="name" :value="name">{{ name }}</option>
				</select>
			</dd>
		</dl>
	</div>
</template>

<script setup>
import { ref, watch } from "vue";

import { useQueryString } from "../../functions/query-string/index.js";
import { StripeStoreEnvironment } from "../../constants/index.js";

const stripeEnvironmentNames = Object.values(StripeStoreEnvironment);

const DEFAULT_STRIPE_ENVIRONMENT = StripeStoreEnvironment.PRODUCTION;

const queryString = useQueryString({
	stripeEnvironment: DEFAULT_STRIPE_ENVIRONMENT,
});

const changeStripeEnvironment = async (event) => {
	const newEnvironmentName = event?.srcElement?.value;
	if (newEnvironmentName !== DEFAULT_STRIPE_ENVIRONMENT) {
		queryString.set("stripeEnvironment", newEnvironmentName);
	} else {
		queryString.remove("stripeEnvironment");
	}
};

const stripeEnvironment = ref(null);

watch(
	queryString,
	() => {
		stripeEnvironment.value = queryString.stripeEnvironment;
	},
	{ immediate: true },
);
</script>

<style lang="scss" scoped>
@import "../../assets/styles/variables.scss";

dl {
	display: flex;
	flex-flow: row wrap;
	gap: $spacing;
	margin: 0;
}
dt {
	flex-basis: calc(35% - ($spacing * 2));
	border: 1px solid $color-border-primary;
	background: $color-background-tertiary;
	color: $color-text-secondary;
	text-align: right;
	text-align: right;
	padding: $spacing $spacing $spacing 0;
	box-sizing: border-box;
}
dd {
	flex-basis: calc(65% - ($spacing * 2));
	flex-grow: 1;
	border: 1px solid $color-border-primary;
	margin-left: 0;
	padding: 0 $spacing;
	display: flex;
	flex-direction: column;
	justify-content: center;
	box-sizing: border-box;

	select {
		max-width: 200px;
	}
}
</style>
