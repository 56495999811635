// import { getErrorStack } from "./errorHelpers.js";

export class ErrorNested extends Error {
	constructor(name, message, extensions) {
		super(message);
		this.name = name;
		this.extensions = extensions;
		// this.stack = getErrorStack(this);
	}
}
