<template>
	<Dialog ref="dialogRef" param-name="venueBookingOpen" class="venue-booking-dialog" width="90vw" height="80dvh" max-width="min(95vw, 420px)" :is-fake-modal="true" @closed="close">
		<DialogSimpleLayout header="Book A Table" :full-width="true" :show-padding="false" :has-back-button="hasBackButton" @close="close" @back="back">
			<div class="booking-dialog-content">
				<VenueBookingStages v-if="dialogRef?.isOpen" :venue-booking="venueBooking" :placement="PagePlacements.DIALOG" />
				<BookingProviderLogo :booking-provider="bookingProvider" />
			</div>
		</DialogSimpleLayout>
	</Dialog>
</template>

<script setup>
import { computed, ref, toRef } from "vue";

import { useVenueBooking } from "../../domain/useVenueBooking.js";
import { useQueryString } from "../../functions/query-string/index.js";
import { BookingProvider, ButtonActions, ButtonStyles, PagePlacements } from "../../constants/index.js";

import Dialog from "../Dialog.vue";
import DialogSimpleLayout from "../DialogSimpleLayout.vue";
import VenueBookingStages from "./VenueBookingStages.vue";
import BookingProviderLogo from "../BookingProviderLogo.vue";

const dialogRef = ref(null);

const queryString = useQueryString(
	{
		venueId: null,
	},
	{ persist: true },
);
const venueBooking = useVenueBooking({ venueId: toRef(queryString, "venueId") }, { label: "VenueBookingDialog", persist: true });

const bookingProvider = computed(() => BookingProvider.OPEN_TABLE);
const currentStage = computed(() => venueBooking.currentStage.value);
const hasBackButton = computed(() => !!currentStage.value?.back ?? false);

defineExpose({
	open,
	close,
	selectTimeSlot,
});

async function open(args = {}) {
	const dialog = dialogRef.value;
	if (!args.venueId) {
		throw new Error("venueId is required to open the venue booking dialog");
	}
	queryString.venueId = args.venueId ?? undefined;
	if (args.startTime) {
		venueBooking.params.startTime = args.startTime;
	}
	if (args.partySize) {
		venueBooking.params.partySize = args.partySize;
	}
	venueBooking.params.timeSlot = args.timeSlot ?? undefined;
	venueBooking.params.seatingPosition = args.seatingPosition ?? undefined;
	if (args.currentStageName) {
		venueBooking.params.currentStageName = args.currentStageName;
	}
	if (dialog && !dialog.isOpen) {
		return dialog.open();
	}
}

async function close() {
	venueBooking.params.currentStageName = undefined;
	queryString.venueId = undefined;
	const dialog = dialogRef.value;
	dialog.close();
	await currentStage.value?.dialogClose?.({ button: { placement: PagePlacements.DIALOG, text: "X", style: ButtonStyles.STANDARD, action: ButtonActions.BOOKING_DIALOG_CLOSE } });
}

async function back() {
	await currentStage.value?.back?.({ button: { placement: PagePlacements.DIALOG, text: "<", style: ButtonStyles.STANDARD, action: ButtonActions.BOOKING_DIALOG_BACK } });
}

async function selectTimeSlot({ venueId, timeSlot, seatingPosition, isCreditCardRequired, bookingProviderAssociation }) {
	const success = await venueBooking.selectTimeSlot({
		venueId,
		timeSlot,
		seatingPosition,
		isCreditCardRequired,
		bookingProviderAssociation,
	});
	if (success) {
		open({
			venueId,
			startTime: venueBooking.params.startTime,
			partySize: venueBooking.params.partySize,
			timeSlot,
			seatingPosition,
			isCreditCardRequired,
		});
	}
}
</script>

<style scoped lang="scss">
@import "../../assets/styles/variables_new.scss";

.venue-booking-dialog {
	.booking-dialog-content {
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		gap: calc($spacing);
		overflow: auto;
		margin: calc($spacing) 0;
	}
}
</style>
