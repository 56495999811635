import { computed } from "vue";
import { useScroll } from "@vueuse/core";

// import { ListDirections } from "./ListDirections.js";

export const useRecyclingList = (containerRef, items, options = {}) => {
	const { viewportSize = 10, gapSize = 0, itemHeight } = options;

	const { y } = useScroll(containerRef, { passive: true });

	// Adjust the visibleItems computation to account for the gap
	const visibleItems = computed(() => {
		const rawItems = items.value;
		const totalItems = rawItems?.length;
		const totalItemHeight = itemHeight + gapSize; // Total width of an item including the gap
		const scrollPosition = Math.floor(y.value / totalItemHeight);
		const start = scrollPosition % totalItems;
		const end = Math.min(start + viewportSize, totalItems);

		return rawItems?.slice(start, end).map((item, index) => ({
			item,
			index: (start + index) % viewportSize,
		}));
	});

	const containerStyle = computed(() => {
		const rawItems = items.value;
		const totalItems = rawItems?.length;
		const totalItemHeight = itemHeight + gapSize; // Total height of an item including the gap
		const scrollPosition = Math.floor(y.value / totalItemHeight);
		const top = scrollPosition * totalItemHeight;
		const totalHeight = (itemHeight + gapSize) * totalItems;
		const height = totalHeight - top;

		console.log("top", top, "height", height);

		return {
			position: "relative",
			top: `${top}px`,
			height: `${height}px`,
		};
	});

	return { visibleItems, containerStyle };
};
