<template>
	<div :class="{ 'form-field-container': true, 'error': validation?.$errors?.length > 0 }">
		<div class="form-field-credit-card">
			<font-awesome-icon icon="fa-solid fa-credit-card" class="icon" />
			<input
				v-model="fieldState.number"
				class="number"
				:placeholder="props.placeholder?.number ?? 'Card Number'"
				:disabled="props.isReadOnly"
				:class="{ disabled: props.isReadOnly }"
				name="cardnumber"
				autocomplete="cc-number"
				@change="onStateChanged"
			/>
			<input
				v-model="fieldState.expiryMonth"
				class="month"
				:placeholder="props.placeholder?.expiryMonth ?? 'MM'"
				:disabled="props.isReadOnly"
				:class="{ disabled: props.isReadOnly }"
				name="ccmonth"
				autocomplete="cc-exp-month"
				@change="onStateChanged"
			/>
			<span class="date-divider-slash">/</span>
			<input
				v-model="fieldState.expiryYear"
				class="year"
				:placeholder="props.placeholder?.expiryYear ?? 'YY'"
				:disabled="props.isReadOnly"
				:class="{ disabled: props.isReadOnly }"
				name="ccyear"
				autocomplete="cc-exp-year"
				@change="onStateChanged"
			/>
			<input
				v-model="fieldState.cvc"
				class="cvc"
				:placeholder="props.placeholder?.cvc ?? 'CVC'"
				:disabled="props.isReadOnly"
				:class="{ disabled: props.isReadOnly }"
				name="cvc"
				autocomplete="cc-csc"
			/>
			<!-- @change="onStateChanged" -->
		</div>
		<FormFieldErrors :validation="validation" />
	</div>
</template>

<script setup>
import { computed, ref, toRef, watch } from "vue";

import FormFieldErrors from "./FormFieldErrors.vue";

const props = defineProps({
	validation: {
		type: Object,
		required: true,
	},
	state: {
		type: Object,
		required: true,
	},
	placeholder: {
		type: Object,
		default: () => null,
	},
	isReadOnly: {
		type: Boolean,
		default: false,
	},
});

const emits = defineEmits(["change"]);

const fieldState = ref();
watch(
	toRef(props, "state"),
	() => {
		fieldState.value = props.state;
	},
	{ immediate: true },
);

const validation = computed(() => props.validation);

const onStateChanged = () => {
	emits("change", { value: { number: fieldState.value?.number, expiryMonth: fieldState.value?.expiryMonth, expiryYear: fieldState.value?.expiryYear, cvc: fieldState.value?.cvc } });
};
</script>

<style scoped lang="scss">
@import "../../assets/styles/variables_new.scss";

.form-field-container {
	display: flex;
	flex-direction: column;
	gap: calc($spacing / 4);

	.form-field-credit-card {
		display: flex;
		flex-direction: row;
		align-items: center;
		// gap: calc($spacing);
		// justify-content: space-between;
		padding: 0 $spacing;
		border-radius: $border-radius-primary;
		border: $border-size-primary solid $border-color-secondary;
		min-width: 50px;
		height: 40px;
		box-sizing: border-box;
		// max-width: 300px;

		> input {
			border: none;

			&::placeholder {
				color: $text-color-tertiary;
			}

			&:focus {
				outline: none;
			}
		}

		.icon {
			width: 1rem;
			height: 1rem;
			// margin-right: calc($spacing);
		}
		.date-divider-slash {
			color: $text-color-tertiary;
		}
		.number {
			margin-left: calc($spacing / 1.5);
			flex-grow: 1;
			// margin-right: calc($spacing);
		}
		.month {
			margin-left: calc($spacing / 2);
			max-width: 24px;
		}
		.year {
			max-width: 24px;
		}
		.cvc {
			margin-left: calc($spacing / 2);
			flex-shrink: 0;
			max-width: 30px;
		}
	}

	// .form-field {
	// 	padding: calc($spacing / 1.5) $spacing;
	// 	border-radius: $border-radius-primary;
	// 	border: $border-size-primary solid $border-color-secondary;

	// 	&::placeholder {
	// 		color: $text-color-tertiary;
	// 	}

	// 	&.disabled {
	// 		color: $text-color-tertiary;
	// 		border: $border-size-primary solid $border-color-primary;
	// 	}
	// }
}
</style>
