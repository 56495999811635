<template>
	<div>
		<Dialog ref="dialogRef" class="dialog" @closed="dialogOnClose">
			<DialogSimpleLayout :has-close-button="true" @close="dialogCloseClick">
				<DialogMessage>{{ state.message }}</DialogMessage>
				<!-- <DialogButtons>
					<DialogButton @click="okOnClick">OK</DialogButton>
				</DialogButtons> -->
			</DialogSimpleLayout>
		</Dialog>
	</div>
</template>

<script setup>
import { reactive, ref } from "vue";

import Dialog from "./Dialog.vue";
import DialogMessage from "./DialogMessage.vue";
// import DialogButtons from "./DialogButtons.vue";
// import DialogButton from "./DialogButton.vue";
import DialogSimpleLayout from "./DialogSimpleLayout.vue";

const dialogRef = ref(null);
const state = reactive({
	message: null,
});

defineExpose({
	open,
	close,
});

function open({ message } = {}) {
	if (!message) {
		throw new Error("You must provide a message to the AlertDialog");
	}
	state.message = message;
	const dialog = dialogRef.value;
	if (dialog && !dialog.isOpen) {
		dialog.open();
	}
}

function close() {
	const dialog = dialogRef.value;
	dialog.close();
}

function dialogOnClose() {}

// function okOnClick() {
// 	close();
// }
function dialogCloseClick() {
	close();
}
</script>

<style scoped lang="scss">
@import "../assets/styles/variables_new.scss";
</style>
