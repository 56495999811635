import { nextTick } from "vue";
import { useDebounceFn } from "@vueuse/core";
import { decode, isBlurhashValid } from "blurhash";

import { useLogger } from "../../functions/logger";
import { decode83 } from "../../helpers/index.js";
import loggingMessages from "./BlurhashRenderer.logging-messages.js";

export class BlurhashRenderer {
	constructor({ blurhashRenderQueue } = {}) {
		this.blurhashRenderQueue = blurhashRenderQueue;
		this.debouncedExecuteQueue = useDebounceFn(this.#executeQueue.bind(this), 10);
		this.imageDataCache = {};
	}

	renderBlurhash({ src, previewJson, refPreview }) {
		const logger = useLogger({ name: "renderBlurhash" });

		let imageData;
		if (this.imageDataCache[src]) {
			imageData = this.imageDataCache[src];
		} else {
			const sizeFlag = decode83(previewJson.blurhash[0]);
			const numY = Math.floor(sizeFlag / 9) + 1;
			const numX = (sizeFlag % 9) + 1;
			const expectedBlurhashLength = 4 + 2 * numX * numY;
			const blurhashWithPadding = previewJson.blurhash + " ".repeat(expectedBlurhashLength - previewJson.blurhash.length);
			const blurhashValidation = isBlurhashValid(blurhashWithPadding);
			if (blurhashValidation.result) {
				const pixels = decode(blurhashWithPadding, previewJson.width, previewJson.height);
				imageData = new ImageData(pixels, previewJson.width, previewJson.height);
				this.imageDataCache[src] = imageData;
			} else {
				logger.log(loggingMessages.invalidBlurhash, { url: src, blurhash: blurhashWithPadding, reason: blurhashValidation.errorReason });
			}
		}
		if (imageData) {
			const context = refPreview.value.getContext("2d");
			context.putImageData(imageData, 0, 0);
		}
	}

	addBlurhashRenderToQueue({ src, previewJson, refPreview }) {
		const queueEntry = { src, previewJson, refPreview };
		this.blurhashRenderQueue.push(queueEntry);

		this.debouncedExecuteQueue(this.blurhashRenderQueue);

		const removeFromQueue = () => {
			const index = this.blurhashRenderQueue.indexOf(queueEntry);
			if (index > -1) {
				this.blurhashRenderQueue.splice(index, 1);
			}
		};

		return removeFromQueue;
	}

	#executeQueue(queue) {
		if (queue.length > 0) {
			const queueEntry = queue.shift();
			this.renderBlurhash(queueEntry);
			// executeQueue(queue);
			nextTick(() => this.#executeQueue(queue));
		}
	}
}
