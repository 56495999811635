export default {
	type: "removeFromArray",
	handler(newEntity, { key, value }, entity) {
		const array = newEntity[key] ?? entity[key];
		const entityId = array.entityIdsInOrder.find((id) => (typeof value === "function" ? value({ id }) : value !== id));
		if (entityId) {
			newEntity[key] = array.cloneAndRemoveEntity({ id: entityId });
		}
	},
};
