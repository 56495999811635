import { reactive } from "vue";

import { BookingStageNames } from "../constants";
import { useQueryString } from "../functions/query-string/index.js";

import VenueBookingStageSelectTimeSlot from "../components/venue/VenueBookingStageSelectTimeSlot.vue";

export class BookingStageStrategyDefault {
	constructor({ logger, model, router, route, sessionStateManager, venueBookingParams }) {
		this.logger = logger.nested({ name: "BookingStageStrategyDefault" });
		this.model = model;
		this.sessionStateManager = sessionStateManager;
		this.venueBookingParams = venueBookingParams;

		this.queryString = useQueryString(
			{
				reservationToken: null,
				reservationTokenExpiresAt: null,
			},
			{ router, route },
		);
		this.isGettingLock = this.sessionStateManager.get("VenueBooking:isGettingLock");
		this.gettingLockError = this.sessionStateManager.get("VenueBooking:gettingLockError");

		this.stages = {
			[BookingStageNames.SELECT_TIME_SLOT]: {
				component: {
					is: VenueBookingStageSelectTimeSlot,
					vBind: reactive({
						error: this.gettingLockError,
						isGettingLock: this.isGettingLock,
					}),
					vOn: {
						timeSlotSelected: async (args) => {
							return await this.lockVenueBookingAvailability(args);
						},
					},
				},
			},
		};
	}

	async lockVenueBookingAvailability({ venueId, timeSlot, seatingPosition, isCreditCardRequired, bookingProviderAssociation }) {
		this.gettingLockError.value = null;
		this.isGettingLock.value = true;

		/* TODO: need to handle error returned here */
		// const { success, error, reservationToken, expiresAt } = await this.model.mutations.LockVenueBookingAvailability({
		const { success, error, reservationToken, expiresAt } = await this.model.mutations.LockVenueBookingAvailability({
			venueId,
			partySize: this.venueBookingParams.partySize,
			time: timeSlot,
			seatingPosition: seatingPosition,
		});
		if (success) {
			this.venueBookingParams.timeSlot = timeSlot;
			this.venueBookingParams.seatingPosition = seatingPosition;
			this.venueBookingParams.isCreditCardRequired = isCreditCardRequired;
			this.venueBookingParams.bookingProviderAssociationId = bookingProviderAssociation.id;
			this.queryString.reservationToken = reservationToken;
			this.queryString.reservationTokenExpiresAt = expiresAt;
			this.venueBookingParams.currentStageName = BookingStageNames.COLLECT_RESERVATION_DETAILS;
		} else {
			this.gettingLockError.value = error;
		}
		this.isGettingLock.value = false;

		return success;
	}
}
