<template>
	<div>
		<Dialog ref="dialogRef" v-slot="{ closeDialog }" class="user-device-permissions-dialog" @closed="dialogOnClose">
			<DialogSimpleLayout @close="closeDialog">
				<UserDevicePermissions :permission="state.permission" @click="userDevicePermissionOnClick" />
			</DialogSimpleLayout>
		</Dialog>
	</div>
</template>

<script setup>
import { reactive, ref } from "vue";

import Dialog from "./Dialog.vue";
import UserDevicePermissions from "./UserDevicePermissions.vue";
import DialogSimpleLayout from "./DialogSimpleLayout.vue";

const dialogRef = ref(null);
const state = reactive({
	permission: null,
});

defineExpose({
	open,
	close,
});

async function open({ permission } = {}) {
	if (!permission) {
		throw new Error("You must provide a permission to the UserDevicePermissionsDialog");
	}
	state.permission = permission;
	const dialog = dialogRef.value;
	if (dialog && !dialog.isOpen) {
		return dialog.open();
	}
}

function close() {
	const dialog = dialogRef.value;
	dialog.close();
}

function dialogOnClose() {
	state.permission = null;
}

function userDevicePermissionOnClick() {
	close();
}
</script>

<style scoped lang="scss">
@import "../assets/styles/variables_new.scss";

.user-device-permissions-dialog {
	width: min(95vw, 420px);

	:deep(.content) {
		margin: calc($spacing) calc($spacing / 2) calc($spacing) calc($spacing / 2);
	}
}
</style>
