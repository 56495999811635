<template>
	<button class="back-button-wrapper" @click="backButtonClicked">
		<slot>
			<FontAwesomeIcon icon="chevron-left" class="back-button" />
		</slot>
	</button>
</template>

<script setup>
import { inject } from "vue";

const props = defineProps({
	enableBackAction: {
		type: Boolean,
		default: true,
	},
});

const navigation = inject("navigation");

function backButtonClicked(e) {
	e.preventDefault();
	if (props.enableBackAction) {
		navigation.back();
	}
}
</script>

<style scoped lang="scss">
@import "../assets/styles/variables_new.scss";

.back-button-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	.back-button {
		font-size: 22px;
	}
}
</style>
