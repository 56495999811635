import { getFileDetails } from "../../helpers/index.js";

const debugTypeFiles = import.meta.glob("./*.vue", { eager: true });

const DebugTypes = Object.entries(debugTypeFiles).map(([fileName, fileContents]) => {
	const name = getFileDetails(fileName).name;
	const type = name.replace("DebugType", "").toLowerCase();
	return { name, type, Class: fileContents.default };
});

export default DebugTypes;
