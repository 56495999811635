<template>
	<div class="mini-card">
		<ConditionalRouterLink :to="props.routerLink" :enabled="props.routerLinkEnabled" class="mini-card-link">
			<span class="image" :style="{ width, height }">
				<div class="decals"><slot name="decals" /></div>
				<div class="icon"><img v-if="props.iconSrc" :src="props.iconSrc" /></div>
				<ImgIxWithPlaceholder v-if="imgIx" :src="props.imageSrc" :preview="props.imagePreview" :is-lazy="props.isImageLazy" :sizes="sizes" :widths="widths" class="image" />
				<img v-else :src="props.imageSrc" class="image-src" />
			</span>
			<div class="venue-info">
				<span class="text" :title="props.text">
					{{ props.text }}
					<span v-if="props.showArrow" class="arrow gradient-link-primary-vertical"></span>
				</span>
				<span v-if="props.middleText" class="middle-text" :title="props.middleText">{{ props.middleText }}</span>
				<span v-if="props.bottomText" class="bottom-text" :title="props.bottomText">{{ props.bottomText }}</span>
			</div>
			<div v-if="props.statusText" class="status-text">{{ props.statusText }}</div>
		</ConditionalRouterLink>

		<div v-if="hasDefaultSlot" class="controls"><slot /></div>
	</div>
</template>

<script setup>
import { computed, useSlots } from "vue";
import ConditionalRouterLink from "./ConditionalRouterLink.vue";
import ImgIxWithPlaceholder from "./imgix/ImgIxWithPlaceholder.vue";

const props = defineProps({
	text: {
		type: String,
		required: true,
	},
	middleText: {
		type: String,
		default: null,
	},
	bottomText: {
		type: String,
		default: null,
	},
	statusText: {
		type: String,
		default: null,
	},
	imageSrc: {
		type: String,
		default: null,
	},
	imagePreview: {
		type: String,
		default: null,
	},
	iconSrc: {
		type: String,
		default: null,
	},
	isImageLazy: {
		type: Boolean,
		default: true,
	},
	routerLink: {
		type: [String, Object],
		default: null,
	},
	routerLinkEnabled: {
		type: Boolean,
		default: true,
	},
	showMini: {
		type: Boolean,
		default: false,
	},
	imgIx: {
		type: Boolean,
		default: true,
	},
	showArrow: {
		type: Boolean,
		default: false,
	},
	imageSize: {
		type: Number,
		default: 60,
	},
});

const slots = useSlots();

const hasDefaultSlot = computed(() => slots.default && slots.default().length > 0);

const width = computed(() => `${props.imageSize}px`);
const height = computed(() => `${props.imageSize}px`);
const sizes = computed(() => `${props.imageSize}px`);
const widths = computed(() => new Array(3).fill(null).map((v, index) => props.imageSize * (index + 1)));
</script>

<style lang="scss" scoped>
@import "../assets/styles/variables_new.scss";

.mini-card {
	display: flex;
	gap: calc($spacing);
	justify-content: space-between;
	flex-grow: 1;
	.mini-card-link {
		display: flex;
		flex-direction: row;
		align-items: center;
		flex-grow: 1;
		gap: calc($spacing);
		min-width: 0;
		padding: calc($spacing / 2);

		@include hover-primary;

		.image {
			position: relative;
			flex-shrink: 0;
			border-radius: $border-radius-primary;
			overflow: hidden;

			.icon {
				display: flex;
				align-items: center;
				position: absolute;
				right: 0;
				padding: calc($spacing/3) calc($spacing/2.5);
				height: 15px;
				z-index: 1;
				@include drop-shadow-secondary;

				> img {
					height: 13px;
				}
			}

			.image-src {
				width: 100%;
			}

			.decals {
				position: absolute;
				z-index: 1;
				display: flex;
				flex-direction: column;
				gap: calc($spacing/2);
				padding: calc($spacing/3) calc($spacing/2.5);

				:deep(> img) {
					height: 15px;
					@include drop-shadow-image-decal;
				}
			}
		}

		.venue-info {
			flex: 1;
			display: flex;
			flex-direction: column;
			gap: calc($spacing / 5);
			overflow: hidden;
			justify-content: center;

			.text {
				display: block;
				font-size: $text-size-primary;
				// font-weight: $text-bold-secondary;
				color: $text-color-primary;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;

				.arrow {
					&::after {
						font-size: $text-size-primary;
					}
				}
			}

			.middle-text {
				display: block;
				font-size: $text-size-tertiary;
				// font-weight: $text-bold-secondary;
				color: $text-color-secondary;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
			}

			.bottom-text {
				display: block;
				font-size: $text-size-tertiary;
				// font-weight: $text-bold-secondary;
				color: $text-color-secondary;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
				max-width: max(50%, 200px);
			}
		}

		.status-text {
			display: flex;
			align-items: center;
			font-size: $text-size-secondary;
			font-weight: $text-bold-primary;
			white-space: nowrap;
		}
	}

	.controls {
		display: flex;
		flex-direction: row;
		gap: $spacing;
		align-items: center;

		&:empty {
			display: none;
		}
	}
}
</style>
