export default {
	settingUpDefaultEnvironment: {
		level: "info",
		message: ({ environmentName }) => `Setting up default environment: ${environmentName}...`,
	},
	discoveredOtherEnvironments: {
		level: "info",
		message: ({ count, environmentNames }) => `Discovered ${count} other environment(s): ${environmentNames}`,
	},
	switchingToEnvironment: {
		level: "info",
		message: ({ environmentName }) => `Switching to environment ${environmentName}...`,
	},
	startProcessing: {
		level: "info",
		message: ({ processingState, processingStateData }) => `Start processing ${processingState} ${processingStateData}`,
	},
	stopProcessing: {
		level: "info",
		message: ({ processingState, processingStateData }) => `Stop processing ${processingState} ${processingStateData}`,
	},
};
