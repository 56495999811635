<template>
	<div class="refreshing-container">
		<div class="refreshing">
			<img src="../assets/icons/font-awesome/arrows-rotate-solid.svg" class="icon" :style="{ opacity: props.stage, transform: `rotate(${props.stage * 360}deg)` }" />
			<!-- <FontAwesomeIcon icon="faArrowsRotate" class="icon" :style="{ opacity: props.stage, transform: `rotate(${props.stage * 360}deg)` }" /> -->
		</div>
	</div>
</template>

<script setup>
const props = defineProps({
	stage: { type: Number, required: true },
});
</script>

<style scoped lang="scss">
@import "../assets/styles/variables_new.scss";

.refreshing-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	z-index: 1;
	padding: calc($spacing) 0;
	overflow: hidden;

	.refreshing {
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: $background-color-secondary;
		width: 34px;
		height: 34px;
		line-height: 34px;
		@include drop-shadow;
		border-radius: 50%;

		.icon {
			width: 20px;
			height: 20px;
			color: $text-color-primary;
		}
	}
}
</style>
