import { GlobalChangeSets, EntityTypeNames } from "../../constants/index.js";

export default class {
	constructor({ changeManager, entityStore }) {
		this.changeManager = changeManager;
		this.entityStore = entityStore;
	}

	add({ followUser, user }) {
		const data = { followUserId: followUser.id, userId: user.id };
		if (!this.changeManager.removeChangeFromQueue({ typeName: "FollowUser", data }, { activeOnly: true })) {
			this.changeManager.addChangeToQueue({ typeName: this.name, data, args: { followUser, user } }, { changeSet: GlobalChangeSets.Followers });
		}
	}

	getChangeEffects(change) {
		return [
			{
				typeName: EntityTypeNames.User,
				id: change.data.followUserId,
				getEffects: () => {
					return [
						(followUser, newEntity) => {
							newEntity.followers = (newEntity.followers ?? followUser.followers)?.cloneAndRemoveEntity({ id: change.data.userId });
						},
					];
				},
			},
			{
				typeName: EntityTypeNames.User,
				id: change.data.userId,
				getEffects: () => {
					return [
						{
							type: "removeFromArray",
							key: "following",
							value: ({ id }) => id === change.data.followUserId,
						},
					];
				},
			},
		];
	}
}
