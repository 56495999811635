import { computed } from "vue";

export default class {
	constructor({ logger, modelBuilder, api, authentication }) {
		this.logger = logger.nested({ name: "Model" });
		this.modelBuilder = modelBuilder;
		this.api = api;
		this.authentication = authentication;
	}

	execute() {
		return this.modelBuilder.build({
			name: "GetCities",
			args: { isSignedIn: computed(() => this.authentication.isAuthenticated.value) },
			execute: async ({ args }) => {
				const cities = await this.api.queries.getCities(args, { useClientCache: true, accessToken: args.isSignedIn ? this.authentication.getAccessToken : undefined });
				return cities;
			},
		});
	}
}
