<template>
	<div class="explore-lists-container">
		<loading-message-with-error
			v-if="(listsQuery.isLoading && (listsQuery.state?.loading ?? LOADING_STATE.LOAD_INITIAL) === LOADING_STATE.LOAD_INITIAL) || listsQuery.error"
			:error="listsQuery.error"
			:retry="listsQuery.retry"
			class="loading-message"
		>
			Loading, please wait...
		</loading-message-with-error>
		<template v-else>
			<SuperList v-slot="{ item: list, style }" :name="`${cityName}-lists-${categoryName}`" :items="lists" :item-size="76" :is-virtual="true" class="explore-lists-list">
				<ListCard :key="list.id" :list="list" :show-mini="true" :style="style" />
			</SuperList>
		</template>
	</div>
</template>

<script setup>
import { inject, computed, ref, onMounted } from "vue";

import { LOADING_STATE } from "../constants/index.js";
import { useRouteParams } from "../helpers/index.js";
// import { breakpoints } from "../domain/index.js";

import ListCard from "./ListCard.vue";
import SuperList from "./SuperList.vue";

const routeParams = useRouteParams();

const isAppHydrated = inject("isAppHydrated");
const model = inject("model");

const isMounted = ref(false);
onMounted(() => {
	isMounted.value = true;
});

// const isSmallerThanMedium = breakpoints.smaller("medium");

const cityName = computed(() => routeParams.cityName);
const categoryName = computed(() => routeParams.categoryName);

const listsQuery = model.queries.GetCuratedLists({
	cityName,
	categoryName,
	search: "",
});

const lists = computed(() => (listsQuery.model?.curatedLists ?? []).sort((a, b) => a.name.localeCompare(b.name)));

if (!isAppHydrated.value) {
	// await listsQuery.promise;
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/variables_new.scss";

.explore-lists-container {
	display: flex;
	flex-direction: column;
	overflow: auto;
	margin-top: $spacing;
	@include centre-loading-message;

	.breadcrumb {
		flex-shrink: 0;
		margin-bottom: calc($spacing * 1.5);
	}

	.explore-lists-list {
		height: 100%;
		width: 100%;
		overflow: auto;
		display: flex;
		flex-direction: column;
		gap: $spacing;
		align-content: start;
	}
}

@media (min-width: $bp-medium) {
	.explore-lists-container {
		.explore-lists-list {
			// flex-wrap: wrap;
			// flex-direction: row;

			.loading-message {
				width: 100%;
			}
		}
	}
}
</style>
