export const TrackingEventNames = {
	SignUpAttempt: "Sign Up Attempt",
	SignInAttempt: "Sign In Attempt",
	SuccessfulSignIn: "Successful Sign In",
	SuccessfulAuthenticationCreatingAccount: "Successful Authentication Creating Account",
	FailedSignIn: "Failed Sign In",
	SignOut: "Sign Out",
	Click: "Click",
	ViewVenue: "View Venue",
	VenueBookPressed: "Venue Book Pressed",
	CancelCreateUserProfile: "Cancel Create User Profile",
};
