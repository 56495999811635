import { PageNames } from "./constants/index.js";
import { ensureArray } from "./helpers/ensureArray.js";

export const getRouterConfig = (components) => {
	return [
		{
			name: "Root",
			path: "/",
			redirect: { name: "City", params: { city: "london" } },
		},
		{
			name: "LoggedIn",
			path: "/loggedIn",
			component: components["LoggedIn"],
			meta: { requiresAuth: false },
		},
		{
			name: "Base",
			path: "/other",
			component: components["Base"],
			children: [
				{
					name: PageNames.PRIVACY_POLICY,
					path: "/privacy",
					component: components[PageNames.PRIVACY_POLICY],
					meta: { requiresAuth: false },
				},
				{
					name: PageNames.DOWNLOAD,
					path: "/download",
					component: components[PageNames.DOWNLOAD],
					meta: { requiresAuth: false },
				},
			],
		},
		{
			name: "City",
			path: "/city/:city",
			redirect: { name: PageNames.HOME_FEED, params: { city: "london" } },
			component: components["City"],
			children: [
				{
					name: PageNames.LOG_IN,
					path: "login",
					component: components["Login"],
					meta: { requiresAuth: false },
				},
				{
					name: PageNames.CREATE_PROFILE,
					path: "create-profile",
					component: components["CreateProfile"],
					meta: { requiresAuth: false },
				},
				{
					name: PageNames.PERKS,
					path: "perks",
					component: components["Premium"],
					meta: { showHeader: false, animate: true },
				},
				{
					name: PageNames.HOME_FEED,
					path: "home",
					component: components["Home"],
					meta: { animate: true },
				},
				{
					name: PageNames.EXPLORE_VENUE,
					path: "explore/areas/venue/:venueId",
					component: components["Venue"],
					meta: {
						pathMatch: false,
						showHeader: false,
						showNavigation: false,
						changeCityFallbackRoute: { name: PageNames.HOME_FEED },
						animate: { enter: "slideInLeft", leave: "slideOutLeft", isMobileOnly: true },
					},
				},
				{
					name: "City/Explore",
					path: "explore",
					redirect: { name: PageNames.EXPLORE_AREAS },
					component: components["ExploreCity"],
					meta: {
						animate: true,
						previousRouteFilter: (route) => {
							if (route.query.showSearchResults) {
								delete route.query.showSearchResults;
							}
							if (route.query.showSearchFilters) {
								delete route.query.showSearchFilters;
							}
							return route;
						},
					},
					children: [
						{
							name: PageNames.EXPLORE_AREAS,
							path: "areas",
							component: components["ExploreAreas"],
						},
						{
							name: PageNames.EXPLORE_AREA,
							path: "areas/:areaId",
							component: components["ExploreAreas"],
							meta: { changeCityFallbackRoute: { name: PageNames.EXPLORE_AREAS } },
						},
						{
							name: PageNames.EXPLORE_ZONE_TAG,
							path: "areas/zones/:zoneId/tags/:tagId",
							component: components["ExploreZoneTag"],
							meta: { changeCityFallbackRoute: { name: PageNames.EXPLORE_AREAS } },
						},
						{
							name: PageNames.EXPLORE_ZONE,
							path: "areas/zones/:zoneId",
							component: components["ExploreZone"],
							meta: { changeCityFallbackRoute: { name: PageNames.EXPLORE_AREAS } },
						},
						{
							name: PageNames.EXPLORE_LISTS,
							path: "lists",
							redirect: { name: PageNames.EXPLORE_LISTS_CATEGORY, params: { categoryName: "top" } },
							component: components["ExploreLists"],
							children: [
								{
									name: PageNames.EXPLORE_LISTS_CATEGORY,
									path: "category/:categoryName",
									component: components["ExploreListsCategory"],
								},
							],
						},
						{
							name: PageNames.EXPLORE_LIST,
							path: "lists/:listId",
							component: components["ExploreList"],
							meta: { changeCityFallbackRoute: { name: PageNames.EXPLORE_LISTS_CATEGORY, params: { categoryName: "top" } } },
						},
						{
							name: PageNames.EXPLORE_TAGS,
							path: "tags",
							component: components["ExploreTags"],
						},
						{
							name: PageNames.EXPLORE_TAG,
							path: "tags/:tagId",
							component: components["ExploreTag"],
						},
					],
				},
				{
					name: PageNames.MAP,
					path: "map",
					component: components["Map"],
					meta: { showHeader: false, hasTransparentBackground: true, animate: true },
				},
				{
					name: "City/Activity",
					path: "activity",
					component: components["Activity"],
					meta: { animate: true, showHeader: false },
				},
				{
					name: PageNames.CURRENT_USER_PROFILE,
					path: "profile",
					redirect: { name: "City/Profile/UserList" },
					component: components["Profile"],
					meta: { animate: true },
					children: [
						{
							name: "City/Profile/UserList",
							path: "list",
							component: components["UserLists"],
							redirect: { name: PageNames.PROFILE_USER_LIST_BEEN },
							children: [
								{
									name: PageNames.PROFILE_USER_LIST_BEEN,
									path: "been",
									component: components["UserListBeen"],
								},
								{
									name: PageNames.PROFILE_USER_LIST_FAVOURITES,
									path: "favourites",
									component: components["UserListFavourites"],
								},
								{
									name: PageNames.PROFILE_USER_LIST_GO_TRY,
									path: "gotry",
									component: components["UserListGoTry"],
								},
							],
						},
					],
				},
				{
					name: PageNames.PROFILE_SETTINGS,
					path: "profile/settings",
					component: components["Settings"],
					meta: { showHeader: false },
				},
				{
					name: PageNames.PROFILE_FOLLOWERS,
					path: "profile/followers",
					component: components["FollowersAndFollowing"],
					meta: { showHeader: false },
				},
				{
					name: PageNames.PROFILE_FOLLOWING,
					path: "profile/following",
					component: components["FollowersAndFollowing"],
					meta: { showHeader: false },
				},
				{
					name: PageNames.OTHER_USER_PROFILE,
					path: "user/:userId/profile",
					redirect: { name: "City/User/Profile/UserList" },
					component: components["Profile"],
					meta: { animate: true },
					children: [
						{
							name: "City/User/Profile/UserList",
							path: "list",
							component: components["UserLists"],
							redirect: { name: "City/User/Profile/UserList/Been" },
							children: [
								{
									name: PageNames.OTHER_USER_USER_LIST_BEEN,
									path: "been",
									component: components["UserListBeen"],
								},
								{
									name: PageNames.OTHER_USER_USER_LIST_FAVOURITES,
									path: "favourites",
									component: components["UserListFavourites"],
								},
								{
									name: PageNames.OTHER_USER_USER_LIST_GO_TRY,
									path: "gotry",
									component: components["UserListGoTry"],
								},
							],
						},
					],
				},
				{
					name: PageNames.OTHER_USER_FOLLOWERS,
					path: "user/:userId/profile/followers",
					component: components["FollowersAndFollowing"],
					meta: { showHeader: false },
				},
				{
					name: PageNames.OTHER_USER_FOLLOWING,
					path: "user/:userId/profile/following",
					component: components["FollowersAndFollowing"],
					meta: { showHeader: false },
				},
				{
					name: PageNames.ADMIN,
					path: "admin",
					component: components["Admin"],
					meta: { requiresAuth: false, animate: true },
				},
			],
		},
	];
};

export const getAllPossibleRoutes = (config, paths = [], parentPath) => {
	config.forEach((route) => {
		const absoluteRoutePath = [...(parentPath && !route.path.startsWith("/") ? [parentPath] : []), route.path].filter((v) => !!v).join("/");
		paths.push({ route, name: route.name, path: absoluteRoutePath });
		if (route.alias) {
			ensureArray(route.alias).forEach((alias) => {
				paths.push({ route, name: route.name, path: alias });
			});
		}
		if (route.children) {
			getAllPossibleRoutes(route.children, paths, absoluteRoutePath);
		}
	});
	return paths;
};
