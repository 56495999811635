<template>
	<RouterLink :to="props.routerLink" class="micro-card" @click.stop>
		<span class="image">
			<img v-if="props.iconSrc" :src="props.iconSrc" class="icon" />
			<ImgIxWithPlaceholder :alt="props.text" :src="props.imageSrc" :preview="props.imagePreview" :is-lazy="props.isImageLazy" sizes="70px" :widths="widths" class="image" />
		</span>
		<div class="info">
			<span class="text" :title="props.text">{{ props.text }}</span>
			<span v-if="props.middleText" class="middle-text" :title="props.middleText">{{ props.middleText }}</span>
			<span v-if="props.bottomText" class="bottom-text" :title="props.bottomText">{{ props.bottomText }}</span>
			<div class="controls"><slot /></div>
		</div>
	</RouterLink>
</template>

<script setup>
import { computed } from "vue";

import ImgIxWithPlaceholder from "./imgix/ImgIxWithPlaceholder.vue";

/* 
	REF: https://github.com/vuejs/vue/issues/12685
	Have to use RouterLink as element because of the issue with the router-link component getting confused with the prop.routerLink, which results in error:
	Component is missing template or render function. (the props.routerLink value seems to be used as component name)
*/

const props = defineProps({
	text: {
		type: String,
		required: true,
	},
	middleText: {
		type: String,
		default: null,
	},
	bottomText: {
		type: String,
		default: null,
	},
	imageSrc: {
		type: String,
		default: null,
	},
	imagePreview: {
		type: String,
		default: null,
	},
	isImageLazy: {
		type: Boolean,
		default: true,
	},
	iconSrc: {
		type: String,
		default: null,
	},
	routerLink: {
		type: [String, Object],
		required: true,
	},
	showMini: {
		type: Boolean,
		default: false,
	},
});

const widths = computed(() => new Array(3).fill(null).map((v, index) => 70 * (index + 1)));
</script>

<style lang="scss" scoped>
@import "../assets/styles/variables_new.scss";

.micro-card {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: calc($spacing / 1.5);

	.image {
		position: relative;
		flex-shrink: 0;
		width: 70px;
		height: 70px;
		border-radius: $border-radius-primary;
		overflow: hidden;

		.icon {
			position: absolute;
			right: calc($spacing/3);
			top: calc($spacing/3.5);
			width: 18px;
			z-index: 1;
			@include drop-shadow-secondary;
		}
	}

	.info {
		flex: 1;
		display: flex;
		flex-direction: column;
		gap: 2px;
		overflow: hidden;

		.text {
			display: block;
			font-size: $text-size-secondary;
			font-weight: $text-bold-secondary;
			color: $text-color-primary;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}

		.middle-text {
			display: block;
			font-size: $text-size-quinary;
			// font-weight: $text-bold-secondary;
			color: $text-color-secondary;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}

		.bottom-text {
			display: block;
			font-size: $text-size-quinary;
			// font-weight: $text-bold-secondary;
			color: $text-color-secondary;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}

		.controls {
			margin-top: calc($spacing/4);
		}
	}
}
</style>
