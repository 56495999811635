<template>
	<div class="loading-more-container">
		<transition name="slide-up" appear>
			<div v-if="props.isVisible" class="loading-more">
				<img src="../assets/icons/font-awesome/arrows-rotate-solid.svg" class="icon" />
			</div>
		</transition>
	</div>
</template>

<script setup>
const props = defineProps({
	isVisible: {
		type: Boolean,
		required: true,
	},
});
</script>

<style scoped lang="scss">
@import "../assets/styles/variables_new.scss";

@keyframes rotate {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.loading-more-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	z-index: 1;
	flex-shrink: 0;
	padding: calc($spacing) 0;
	overflow: hidden;

	.loading-more {
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: $background-color-secondary;
		width: 34px;
		height: 34px;
		line-height: 34px;
		text-align: center;
		vertical-align: middle;
		box-shadow: 1px 1px 5px rgba($text-color-primary, 0.5);
		border-radius: 50%;

		&.slide-up-enter-from,
		&.slide-up-leave-to {
			transform: translateY(150%);
		}

		&.slide-up-enter-active,
		&.slide-up-leave-active {
			transition: transform 0.5s ease-in-out;
		}

		.icon {
			width: 20px;
			height: 20px;
			color: $text-color-quaternary;
			animation: rotate 2s linear infinite;
		}
	}
}
</style>
