import { getFileDetails } from "../../helpers/index.js";

const mutationGraphqls = import.meta.glob("./*.graphql", { eager: true });
const mutationConfigs = import.meta.glob("./*.js", { eager: true });

const mutations = Object.entries(mutationGraphqls).map(([filename, file]) => {
	const { basename: fileWithoutExtension } = getFileDetails(filename);

	const configFile = Object.entries(mutationConfigs).find(([configFilename]) => {
		const { basename: configFileWithoutExtension } = getFileDetails(configFilename);
		return configFileWithoutExtension === fileWithoutExtension;
	});
	const config = configFile ? configFile[1].default : null;

	return { name: fileWithoutExtension, mutation: file.default, config };
});

export default mutations;
