import { ref } from "vue";

export class SessionStateManager {
	constructor({ logger }) {
		this.logger = logger.nested({ name: "SessionStateManager" });

		this.storage = {};
	}

	get(key) {
		if (!this.storage[key]) {
			this.storage[key] = ref();
		}
		return this.storage[key];
	}

	set(key, value) {
		if (!this.storage[key]) {
			this.storage[key] = ref();
		}
		this.storage[key].value = value;
	}
}
