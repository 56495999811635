<template>
	<span @click="copyValueToClipboard">{{ props.item.value }}</span>
</template>

<script setup>
const props = defineProps({
	item: {
		type: Object,
		required: true,
	},
});

function copyValueToClipboard() {
	navigator.clipboard.writeText(props.item.value);
	window.alert("Copied to clipboard!");
}
</script>

<style scoped lang="scss">
@import "../../assets/styles/variables_new.scss";
</style>
