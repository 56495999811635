<template>
	<MiniCard
		v-if="props.showMini"
		:icon-src="DiamondWhite"
		:text="props.perk.name"
		:bottom-text="bottomText"
		:image-src="imageUrl"
		:image-preview="imagePreview"
		:router-link="routerLink"
		:router-link-enabled="canRedeem"
		class="perk-card"
		@click="cardOnClick"
	/>
	<Card
		v-else
		:icon-src="DiamondWhite"
		:top-text="props.perk.name"
		:top-sub-text="topSubText"
		:bottom-text="bottomText"
		:image-src="imageUrl"
		:image-preview="imagePreview"
		:router-link="routerLink"
		:router-link-enabled="canRedeem"
		class="perk-card"
		@click="cardOnClick"
	/>
</template>

<script setup>
import { computed, inject } from "vue";

import { useRouteParams } from "../helpers/index.js";
import { PremiumFeature } from "../constants/index.js";

import Card from "./Card.vue";
import MiniCard from "./MiniCard.vue";

import DiamondWhite from "../assets/icons/Diamond-White.svg";

const props = defineProps({
	perk: {
		type: Object,
		required: true,
		validator: (perk) => {
			return perk.id && perk.name && perk.shortTermsAndConditions && perk.shortDescription && perk.venues?.length > 0;
		},
	},
	showMini: {
		type: Boolean,
		default: false,
	},
});

const routeParams = useRouteParams();

const userActionDialogRef = inject("userActionDialogRef");

const imageUrl = computed(() => {
	return props.perk.image?.url;
});
const imagePreview = computed(() => {
	return props.perk.image?.preview;
});
const bottomText = computed(() => props.perk?.shortTermsAndConditions ?? "");
const topSubText = computed(() => props.perk?.shortDescription ?? "");
const venue = computed(() => props.perk.venues[0]);
const redeem = computed(() => (venue.value ? props.perk.canRedeem[venue.value.id] : {}));
const canRedeem = computed(() => redeem.value.canRedeem);
const action = computed(() => redeem.value.action);
// const message = computed(() => {});
const routerLink = computed(() => {
	return {
		name: "City/Explore/Areas/Venue",
		params: {
			city: routeParams.cityName,
			venueId: venue.value.id,
		},
		query: {
			showPerk: 1,
		},
	};
});

const cardOnClick = () => {
	if (!canRedeem.value) {
		userActionDialogRef.value.open({ feature: PremiumFeature.PERK, userAction: action.value });
	}
};
</script>

<style scoped lang="scss">
@import "../assets/styles/variables_new.scss";
.perk-card {
	cursor: pointer;
}
</style>
