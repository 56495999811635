<template>
	<button v-if="isPremium && isAdmin && (isImpersonating || isStripeEnvironmentNonProduction)" class="push-button-tertiary" @click="adminDeletePremium">
		Delete Premium (<font-awesome-icon icon="fa-solid fa-lock" class="admin" /> Admin)
	</button>
</template>

<script setup>
import { computed, inject, onMounted, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import { PremiumStatus, StripeStoreEnvironment } from "../constants/index.js";
import { useQueryString } from "../functions/query-string/index.js";
import { getUserPremiumContext } from "../helpers/index.js";

const currentEnvironment = inject("currentEnvironment");
const addChange = inject("addChange");
const router = useRouter();
const route = useRoute();
const queryString = useQueryString({ impersonate: null }, { router, route });

const isMounted = ref(false);

const activeUser = computed(() => currentEnvironment.value.activeUser.value);
const userPremiumContext = computed(() => (activeUser.value ? getUserPremiumContext(activeUser.value) : null));
const isPremium = computed(() => userPremiumContext.value?.status === PremiumStatus.PREMIUM && isMounted.value);
const stripeEnvironment = computed(() => userPremiumContext.value?.subscription?.stripeEnvironment);
const isStripeEnvironmentNonProduction = computed(() => !!stripeEnvironment.value && stripeEnvironment.value !== StripeStoreEnvironment.PRODUCTION);
const isImpersonating = computed(() => !!queryString.impersonate);

const isAdmin = computed(() => currentEnvironment.value?.isAdmin?.value);

const adminDeletePremium = () => {
	addChange.DeletePremium({ userId: activeUser.value?.id });
};

onMounted(() => {
	isMounted.value = true;
});
</script>

<style lang="scss" scoped>
@import "../assets/styles/variables_new.scss";

.admin {
	height: 13px;
}
</style>
