import { FilterNames } from "../constants/index.js";
import { ensureArray } from "./ensureArray.js";

export const getFilterNames = (filterState, tags) => {
	const getNameForPrice = (value) => {
		return "£".repeat(value);
	};

	const getNameForTag = (value) => {
		const tag = tags.find(({ id }) => id === value);
		return tag ? tag.name : "??";
	};

	const filterNameLookup = {
		[FilterNames.prices]: getNameForPrice,
		[FilterNames.mealTypes]: getNameForTag,
		[FilterNames.cuisines]: getNameForTag,
		[FilterNames.occasions]: getNameForTag,
		[FilterNames.vibes]: getNameForTag,
		[FilterNames.lists]: ({ name }) => name,
		[FilterNames.zones]: ({ name }) => name,
	};

	const filterNames = Object.entries(filterState)
		.map(([filterType, value]) => {
			const nameResolver = filterNameLookup[filterType];
			if (!value || !nameResolver) {
				return [];
			}
			return ensureArray(value).map((id) => ({ id, name: nameResolver(id), type: filterType }));
		})
		.flat();

	return filterNames;
};
