/* 
function that takes an object and returns a simplified version of it. Should be able to specify a number of levels to simplify to. Anything more than that should be replaced with either '[array]' or '[object]'".
*/
export const simplifyObjectForLogging = (object, depth = 1) => {
	if (object === null || object === undefined) {
		return object;
	}

	const simplifiedObject = {};
	const keys = Object.keys(object);
	keys.forEach((key) => {
		const value = object[key];
		const type = typeof value;

		if (type === "object") {
			if (Array.isArray(value)) {
				simplifiedObject[key] = "[array]";
			} else {
				if (depth > 0) {
					simplifiedObject[key] = simplifyObjectForLogging(value, depth - 1);
				} else {
					simplifiedObject[key] = "[object]";
				}
			}
		} else {
			simplifiedObject[key] = value;
		}
	});
	return simplifiedObject;
};
