import { ref } from "vue";

import { usePlatform } from "./platform.js";
import loggingMessages from "./Authentication.logging-messages.js";
import { useConfig } from "./config.js";
import { AccessTokenRetrievalError } from "../errors/AccessTokenRetrievalError.js";
import { AuthenticationError } from "../errors/index.js";

const platform = usePlatform();

export class Authentication {
	constructor({ logger, authenticationCache, auth0ClientFactory, environmentName, defaultEnvironmentName, auth0Environment }) {
		this.logger = logger.nested({ name: "Authentication" });
		this.authenticationCache = authenticationCache;
		this.auth0ClientFactory = auth0ClientFactory;
		this.environmentName = environmentName;
		this.defaultEnvironmentName = defaultEnvironmentName;
		this.auth0Environment = auth0Environment;
		this.isAuthenticated = ref(false);
		this.auth0User = null;
		this.config = useConfig();
		this.getAccessToken = this.#getAccessToken.bind(this);
	}

	async init() {
		if (!platform.isServer) {
			this.logger.log(loggingMessages.startingInitialisation, { environmentName: this.environmentName });
			this.auth0Client = await this.auth0ClientFactory.create({ authenticationCache: this.authenticationCache, config: this.auth0Environment, screen_hint: "login" });
			try {
				await this.#loginIfAlreadySignedIn();
			} catch (error) {
				this.logger.log(loggingMessages.errorLoggingInAlreadySignedInUser, { environmentName: this.environmentName, error });
			}
		}
	}

	async handleRedirectCallback(url) {
		try {
			await this.auth0Client.handleRedirectCallback(url);
			const user = await this.auth0Client.getUser();
			if (user) {
				await this.#loginUser(user);
			}
		} catch (error) {
			throw new AuthenticationError(this.environmentName, error);
		}
	}

	async createAccount({ returnTo } = {}) {
		const auth0Client = await this.auth0ClientFactory.create({ authenticationCache: this.authenticationCache, config: this.auth0Environment, screen_hint: "signup" });
		return await auth0Client.loginWithRedirect({ returnTo, screen_hint: "signup" });
	}

	async login({ returnTo } = {}) {
		return await this.auth0Client.loginWithRedirect({ returnTo });
	}

	async logout() {
		await this.auth0Client.logout();
	}

	async #loginIfAlreadySignedIn() {
		const auth0User = await this.auth0Client.getUser();
		if (auth0User) {
			this.logger.log(loggingMessages.userRememberedFromPreviousSession, { environmentName: this.environmentName, user: auth0User });
			await this.#loginUser(auth0User);
		} else {
			this.logger.log(loggingMessages.noUserRememberedFromPreviousSession, { environmentName: this.environmentName });
		}
	}

	async #getAccessToken() {
		this.logger.log(loggingMessages.gettingAccessToken);
		let accessToken = null;
		try {
			accessToken = await this.auth0Client.getTokenSilently();
			this.logger.log(loggingMessages.successfullyRetrievedAccessToken);
		} catch (error) {
			throw new AccessTokenRetrievalError(error);
		}
		return accessToken;
	}

	async #loginUser(auth0User) {
		this.auth0User = null;
		let accessToken;
		if (auth0User) {
			this.logger.log(loggingMessages.attemptingToLoginUserWithAuth0, { user: auth0User });
			// this.logger.log(loggingMessages.gettingAccessToken);
			accessToken = await this.#getAccessToken();
			if (accessToken) {
				this.logger.log(loggingMessages.successfullyLoggedInUserWithAuth0, { user: auth0User });
				this.auth0User = auth0User;
			} else {
				this.logger.log(loggingMessages.unexpectedErrorRetrievingAccessToken, { user: auth0User });
			}
		}
		this.isAuthenticated.value = !!accessToken;
		if (auth0User && !this.isAuthenticated.value) {
			this.logger.log(loggingMessages.errorAttemptingToLoginUserWithAuth0, { user: auth0User });
		}
	}
}
