<template>
	<!-- <Dialog :dialog="citySwitchingDialog">
		<DialogLayoutFullscreen :dialog="citySwitchingDialog" title="Change City" class="city-switch-dialog">
			<SwitchCityList />
		</DialogLayoutFullscreen>
	</Dialog> -->
	<Dialog ref="citySwitchingDialogRef" class="city-switch-dialog" param-name="citySwitchOpen" :full-screen="true" enter-direction="right">
		<template #default="{ isOpen, closeDialog }">
			<KeepAlive>
				<DialogFullScreenPageLayout v-if="isOpen" header="Change City" :show-header-on-desktop="true" :auto-wire-back-button="false" @close="closeDialog">
					<SwitchCityList class="switch-city-list" />
				</DialogFullScreenPageLayout>
			</KeepAlive>
		</template>
	</Dialog>
</template>

<script setup>
import { computed, ref } from "vue";
// import { inject } from "vue";
// import { useDebounceFn } from "@vueuse/shared";

// import { useDialog, Dialog } from "../dialog/useDialog.js";
// import DialogLayoutFullscreen from "../dialog/DialogLayoutFullscreen.vue";
import Dialog from "../Dialog.vue";
import DialogFullScreenPageLayout from "../DialogFullScreenPageLayout.vue";
import SwitchCityList from "../SwitchCityList.vue";
// import { awaitableWatchEffect } from "../../helpers/index.js";

// const citySwitchingDialog = useDialog({ paramName: "citySwitchOpen" });

const citySwitchingDialogRef = ref(null);

defineExpose({ open: openDialog, close, toggle: toggleDialog, isOpen: computed(() => citySwitchingDialogRef.value?.isOpen) });

function openDialog() {
	citySwitchingDialogRef.value.open();
}

function close() {
	citySwitchingDialogRef.value.close();
}

function toggleDialog() {
	citySwitchingDialogRef.value.toggle();
}

// defineExpose({ ...citySwitchingDialog });

// const currentEnvironment = inject("currentEnvironment");
// const environmentManager = inject("environmentManager");

// await environmentManager.waitUntilInitialised();

// awaitableWatchEffect(async () => {
// 	if (currentEnvironment.value) {
// 		await useDebounceFn(async () => {
// 			const cities = await currentEnvironment.value?.model.getCities();
// 			console.log("cities", cities);
// 		})();
// 	}
// });
</script>

<style lang="scss" scoped>
@import "../../assets/styles/variables_new.scss";

.city-switch-dialog {
	:deep(.content) {
		// margin: calc($spacing) calc($spacing / 2) calc($spacing) calc($spacing);
		// padding-right: calc($spacing/ 2);
	}
}

@media (min-width: $bp-medium) {
	.city-switch-dialog.dialog-new {
	}
}
</style>
