export default {
	platformDetails: {
		level: "info",
		message: ({ json }) => `🖥 Platform: ${json}`,
	},
	usingAuthenticationCache: {
		level: "info",
		message: ({ name }) => `Using authentication cache: ${name}`,
	},
	sharingStatusChecked: {
		level: "info",
		message: ({ bool }) => `🫳 Sharing status checked: ${bool}`,
	},
};
