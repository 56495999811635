<template>
	<div>
		<teleport v-if="isMounted" to="#modalDialog" :disabled="!isMounted">
			<div>
				<transition v-bind="transitionProps">
					<div v-if="props.dialog.isOpen.value === true" :class="{ 'dialog': true, 'dialog-open': props.dialog.isOpen.value, [props.className ?? 'unnamed']: true }">
						<slot />
					</div>
				</transition>
			</div>
		</teleport>
	</div>
</template>

<script setup>
import { computed, ref, onMounted, onBeforeUnmount } from "vue";
import { breakpoints } from "../../domain/index.js";

const props = defineProps({
	dialog: {
		type: Object,
		required: true,
	},
	className: {
		type: String,
		default: "",
	},
});

const isSmallerThanMedium = breakpoints.smaller("medium");

const transitionProps = ref({
	enterActiveClass: computed(() => `animate__animated ${isSmallerThanMedium.value ? "animate__fadeInLeftBig" : "animate__fadeIn"}`),
	leaveActiveClass: computed(() => `animate__animated ${isSmallerThanMedium.value ? "animate__fadeOutLeftBig" : "animate__fadeOut"}`),
	appear: true,
});

const isMounted = ref(false);
onMounted(() => {
	isMounted.value = true;
});

onBeforeUnmount(() => {
	isMounted.value = false;
});
</script>

<style lang="scss" scoped>
@import "../../assets/styles/variables_new.scss";

.dialog {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100dvh;
	background-color: $background-color-modal;
	z-index: 1000;

	display: none;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	animation-duration: 0.3s;
}

.dialog-open {
	display: flex;
}
</style>
