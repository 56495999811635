<template>
	<VenueDetailSection class="venue-about">
		<div class="description" v-html="longDescriptionWithStrippedTags"></div>
	</VenueDetailSection>
</template>

<script setup>
import { computed } from "vue";

import VenueDetailSection from "./VenueDetailSection.vue";

const props = defineProps({
	venue: {
		type: Object,
		required: true,
	},
});

const longDescription = computed(() => props.venue.longDescriptionHtml);
const longDescriptionWithStrippedTags = computed(() => (longDescription.value ?? "").replace(/<p><span>Tags:.+?<\/p>/m, "").replace(/<p>Tags:.+?<\/p>/m, ""));
</script>

<style scoped lang="scss">
@import "../../assets/styles/variables_new.scss";

.venue-about {
	:deep(p) {
		margin: $spacing 0;
		&:first-child {
			margin-top: 0;
		}
		&:last-child {
			margin-bottom: 0;
		}
	}
}
</style>
