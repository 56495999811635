import { BookingStageStrategyOpenTable } from "./BookingStageStrategyOpenTable.js";

export class BookingStageStrategyFactoryOpenTable {
	constructor({ logger, model, router, route, sessionStateManager, tracking }) {
		this.logger = logger;
		this.model = model;
		this.router = router;
		this.route = route;
		this.sessionStateManager = sessionStateManager;
		this.tracking = tracking;
	}

	create(args) {
		return new BookingStageStrategyOpenTable({
			logger: this.logger,
			model: this.model,
			router: this.router,
			route: this.route,
			sessionStateManager: this.sessionStateManager,
			tracking: this.tracking,
			...args,
		});
	}
}
