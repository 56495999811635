import { computed, inject, isRef, reactive, toRefs } from "vue";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);

import { parseDateTimeInVenueTimeZone } from "../helpers/index.js";
import { DateFormats, BookingStageNames } from "../constants/index.js";
import { useQueryString } from "../functions/query-string/index.js";

const VENUE_TIME_ZONE = "Europe/London";

const sharedSessionParams = reactive({
	startTime: dayjs().tz(VENUE_TIME_ZONE).startOf("hour").add(1, "hour").format(DateFormats.NO_TIME_ZONE),
	partySize: 2,
});

export const useVenueBooking = ({ venueId } = {}, { label, persist = false } = {}) => {
	if (!isRef(venueId)) {
		throw new Error("useVenueBooking: venueId must be a ref");
	}
	const currentEnvironment = inject("currentEnvironment");
	const model = inject("model");

	const queryString = useQueryString(
		{
			startTime: null,
			partySize: null,
			timeSlot: null,
			seatingPosition: null,
			isCreditCardRequired: null,
			currentStageName: null,
			bookingProviderAssociationId: null,
		},
		{ persist },
	);

	/* WHY: only pull from queryString and push to sharedParams if we are persisting queryString (ie, it's actually changing/pulling from route, not just reactive object) */
	if (persist) {
		if (queryString.startTime) {
			sharedSessionParams.startTime = queryString.startTime;
		}
		if (queryString.partySize) {
			sharedSessionParams.partySize = queryString.partySize;
		}
	}

	const { timeSlot, seatingPosition, bookingProviderAssociationId } = toRefs(queryString);
	const startTime = computed({ get: () => sharedSessionParams.startTime ?? null, set: (value) => (sharedSessionParams.startTime = queryString.startTime = value) });
	const partySize = computed({
		get: () => (sharedSessionParams.partySize ? parseInt(sharedSessionParams.partySize) : null),
		set: (value) => (sharedSessionParams.partySize = queryString.partySize = value),
	});
	const isCreditCardRequired = computed({ get: () => queryString.isCreditCardRequired === "true", set: (value) => (queryString.isCreditCardRequired = value) });
	const currentStageName = computed({ get: () => queryString.currentStageName ?? BookingStageNames.SELECT_TIME_SLOT, set: (value) => (queryString.currentStageName = value) });
	const adjustedStartTime = computed(() => {
		if (startTime.value) {
			const parsedDateTimeInVenueTimeZone = parseDateTimeInVenueTimeZone(startTime.value, { timeZone: VENUE_TIME_ZONE });
			const isStartTimeToday = parsedDateTimeInVenueTimeZone.isSame(dayjs(), "day");
			const isStartTimeInPast = parsedDateTimeInVenueTimeZone.isBefore(dayjs());
			return isStartTimeToday && isStartTimeInPast ? dayjs().tz(VENUE_TIME_ZONE).startOf("hour").add(1, "hour").format(DateFormats.NO_TIME_ZONE) : startTime.value;
		} else {
			return null;
		}
	});

	const params = reactive({
		startTime,
		adjustedStartTime,
		partySize,
		timeSlot,
		seatingPosition,
		currentStageName,
		isCreditCardRequired,
		bookingProviderAssociationId,
	});

	const isSignedIn = computed(() => currentEnvironment.value?.isSignedIn.value);
	const query = model.queries.GetVenueDetail({ venueId, isSignedIn }, { isWatchEnabled: computed(() => true) });
	const venue = computed(() => query.model?.venue);

	const bookingProviderAssociations = computed(() => venue.value?.book.bookingProviderAssociations);
	const bookingProviderAssociation = computed(() => bookingProviderAssociations.value?.find(({ id }) => queryString.bookingProviderAssociationId === id) ?? null);
	const bookingProviderName = computed(() => bookingProviderAssociation.value?.name);

	const bookingStageStrategyFactoryDefault = computed(() => currentEnvironment.value?.bookingStageStrategyFactoryDefault);
	const bookingStageStrategyDefault = computed(() =>
		bookingStageStrategyFactoryDefault.value.create({ venueId: venueId.value, venue: venue.value, venueBookingParams: params, bookingProviderAssociation: bookingProviderAssociation.value }),
	);
	const defaultStages = computed(() => bookingStageStrategyDefault.value.stages);

	const bookingStageStrategyFactories = computed(() => currentEnvironment.value?.bookingStageStrategyFactories ?? null);
	const bookingStageStrategies = computed(() =>
		Object.fromEntries(
			bookingProviderAssociations.value?.map(({ name: providerName }) => [
				providerName,
				bookingStageStrategyFactories.value[providerName].create({
					venueId: venueId.value,
					venue: venue.value,
					venueBookingParams: params,
					bookingProviderAssociation: bookingProviderAssociation.value,
				}),
			]) ?? [],
		),
	);

	const bookingStageStrategy = computed(() => (bookingProviderName.value ? bookingStageStrategies.value[bookingProviderName.value] : bookingStageStrategyDefault.value));

	const stages = computed(() => ({ ...(defaultStages.value ?? {}), ...(bookingStageStrategy.value?.stages ?? {}) }));
	const currentStage = computed(() => stages.value[currentStageName.value] ?? null);

	// watch(args, () => {
	// 	if (args.venueId) {
	// 		queryString.venueId = args.venueId;
	// 	}
	// });

	// onMounted(() => {
	// 	if (args.venueId) {
	// 		queryString.venueId = args.venueId;
	// 	}
	// });

	return {
		venueId,
		label,
		query,
		venue,
		bookingProviderAssociations,
		bookingProviderAssociation,
		bookingProviderName,
		bookingStageStrategy,
		currentStageName,
		stages,
		defaultStages,
		currentStage,
		params,
		selectTimeSlot,
		getBookingStrategyForProviderAssociation,
	};

	async function selectTimeSlot(args) {
		return stages.value[BookingStageNames.SELECT_TIME_SLOT].component.vOn.timeSlotSelected(args);
	}

	function getBookingStrategyForProviderAssociation(_bookingProviderAssociation) {
		return bookingStageStrategies.value[_bookingProviderAssociation.name];
	}
};
