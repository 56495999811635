import { ref } from "vue";

export class QueryStore {
	constructor({ logger }) {
		this.logger = logger;
		this.dataStore = {};
	}

	// storeArray({ name, data, index = 0 } = {}) {
	// 	if (!this.dataStore[name]) {
	// 		this.dataStore[name] = reactive([]);
	// 	}

	// 	if (this.index > this.dataStore[name].length) {
	// 		throw new Error(`Index ${index} is out of bounds for ${name} array, which has length ${this.dataStore[name].length}`);
	// 	}

	// 	this.dataStore[name].splice(index, data.length, ...data);
	// 	// data.forEach((dataEntry, dataIndex) => {
	// 	// 	const indexToStore = index + dataIndex;
	// 	// 	this.dataStore[name][indexToStore] = dataEntry;
	// 	// });

	// 	return this.dataStore[name];
	// }

	store({ name, keyData, queryResults }) {
		const cacheKey = this.#generateCacheKey(name, keyData);
		if (!this.dataStore[cacheKey]) {
			this.dataStore[cacheKey] = ref(null);
		}
		this.dataStore[cacheKey].value = queryResults;
	}

	get({ name, keyData }) {
		const cacheKey = this.#generateCacheKey(name, keyData);
		if (!this.dataStore[cacheKey]) {
			this.dataStore[cacheKey] = ref(null);
		}
		return this.dataStore[cacheKey];
	}

	#generateCacheKey(name, keyData) {
		return `${name}-${JSON.stringify(keyData)}`;
	}
}
