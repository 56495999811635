import dayjs from "dayjs";
import { Filesystem, Directory, Encoding } from "@capacitor/filesystem";

import loggingMessages from "./ApiDataCache.logging-messages.js";

const CACHE_EXPIRY = { minutes: 5 };

export class ApiDataCache {
	constructor({ logger, platform, environmentName }) {
		this.logger = logger.nested({ name: "ApiDataCache" });
		this.platform = platform;
		this.environmentName = environmentName;

		this.cacheData = null;
	}

	async initialise() {
		/* TODO: Make sure the cache is tied to the signed-in user, so that if user signs out, cached data is not available to the next user */
		if (this.platform.isNative) {
			const directoryExists = await this.#fileExists();
			if (!directoryExists) {
				await Filesystem.mkdir({
					path: this.#getEnvironmentPath(),
					directory: Directory.Cache,
				});
			}
			const dir = await Filesystem.readdir({
				path: this.#getEnvironmentPath(),
				directory: Directory.Cache,
			});
			this.logger.log(loggingMessages.loadingApiCacheDataFromDisk, { environmentName: this.environmentName, count: dir.files.length });
			const allFiles = dir.files.map(({ name, mtime }) => {
				const expiryDate = dayjs(mtime).add(dayjs.duration(CACHE_EXPIRY));
				const hasExpired = dayjs().isAfter(expiryDate);
				return { name, path: this.#getEnvironmentPath(name), modifiedDate: dayjs(mtime), expiryDate, hasExpired };
			});

			const expiredFiles = allFiles.filter(({ hasExpired }) => hasExpired);
			if (expiredFiles.length > 0) {
				const deleteFilesStartTime = performance.now();
				await Promise.all(
					expiredFiles.map(async ({ name }) => {
						await Filesystem.deleteFile({
							path: this.#getEnvironmentPath(name),
							directory: Directory.Cache,
						});
					}),
				);
				const deleteFilesEndTime = performance.now();
				const deleteFilesDuration = deleteFilesEndTime - deleteFilesStartTime;
				this.logger.log(loggingMessages.removedExpiredApiCacheDataFromDisk, { environmentName: this.environmentName, count: expiredFiles.length, durationMS: deleteFilesDuration });
			}

			const validFiles = allFiles.filter(({ hasExpired }) => !hasExpired);
			if (validFiles.length > 0) {
				const loadFilesStartTime = performance.now();
				this.cacheData = await Promise.all(
					validFiles.map(async ({ name, path }) => {
						const valueString = await this.#getFile({ key: name });
						const value = JSON.parse(valueString);
						return { key: name, path, value };
					}),
				);
				const loadFilesEndTime = performance.now();
				const loadFilesDuration = loadFilesEndTime - loadFilesStartTime;
				this.logger.log(loggingMessages.loadedApiCacheDataFromDisk, { environmentName: this.environmentName, count: validFiles.length, durationMS: loadFilesDuration });
			}
		}
	}

	async exists({ key } = {}) {
		return !!(await this.get({ key }));
	}

	async get({ key } = {}) {
		if (this.platform.isNative) {
			const cachedEntry = this.cacheData?.find((entry) => entry.key === key);
			return cachedEntry?.value ?? null;
		}
	}

	async set({ key, value } = {}) {
		if (this.platform.isNative) {
			const valueString = JSON.stringify(value);
			await Filesystem.writeFile({
				path: this.#getEnvironmentPath(key),
				data: valueString,
				directory: Directory.Cache,
				encoding: Encoding.UTF8,
			});
			if (!this.cacheData) {
				this.cacheData = [];
			}
			this.cacheData.push({ key, path: this.#getEnvironmentPath(key), value });
		}
	}

	async #getFile({ key } = {}) {
		if (this.platform.isNative) {
			const { data } = await Filesystem.readFile({
				path: this.#getEnvironmentPath(key),
				directory: Directory.Cache,
				encoding: Encoding.UTF8,
			});
			return data;
		}
	}

	async #fileExists({ key } = {}) {
		if (this.platform.isNative) {
			try {
				await Filesystem.stat({
					path: this.#getEnvironmentPath(key),
					directory: Directory.Cache,
				});
				return true;
			} catch (e) {
				return false;
			}
		} else {
			return false;
		}
	}

	#getEnvironmentPath(path) {
		return `${this.environmentName}${path ? `/${path}` : ""}`;
	}
}
