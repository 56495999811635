<template>
	<div class="user-list-container">
		<SearchBox :placeholder="searchBoxPlaceholder" :debounce-key-up="0" @change="searchTextOnChange" />
		<!-- <SuperList
			v-slot="{ item: venue, style }"
			:empty-message="noResultsMessage"
			:name="`user-list-${userList.name}:${userList.id}`"
			:items="venues"
			:item-size="76"
			:is-virtual="true"
			class="user-list"
		>
			<VenueCard :venue="venue" :user-lists="props.currentUserLists" :show-mini="true" class="venue-card" :style="style" />
		</SuperList> -->
		<NewList
			v-slot="{ item, isItemVisible }"
			list-class="user-list"
			:name="`user-list-${userList.name}:${userList.id}`"
			:items="venues"
			:direction="ListDirections.VERTICAL"
			:item-height="76"
			:gap-size="0"
			:item-buffer-count="4"
			:empty-message="noResultsMessage"
		>
			<VenueCard v-if="isItemVisible" :venue="item" :user-lists="props.currentUserLists" :show-mini="true" class="venue-card" />
		</NewList>
		<!-- <EfficientList v-slot="{ item: venue, style }" :items="venues" :item-size="76" :buffer="4" :recycle="true" class="user-list">
			<VenueCard :venue="venue" :user-lists="props.currentUserLists" :show-mini="true" class="venue-card" :style="style" />
		</EfficientList> -->
	</div>
</template>

<script setup>
import { capitalize, computed, inject, onMounted, ref } from "vue";

import { UserListDatabaseNames, ListDirections } from "../constants/index.js";
import { useRouteParams, filters } from "../helpers/index.js";

// import EfficientList from "./EfficientList/EfficientList.vue";
import VenueCard from "./VenueCard.vue";
import NewList from "./NewList.vue";
import SearchBox from "./SearchBox.vue";

const { filterVenuesByCityName, filterList } = filters;

const props = defineProps({
	user: {
		type: Object,
		required: true,
	},
	userList: {
		type: Object,
		required: true,
	},
	currentUserLists: {
		type: Array,
		required: true,
	},
});

const currentEnvironment = inject("currentEnvironment");
const routeParams = useRouteParams();
// const isSmallerThanMedium = breakpoints.smaller("medium");

const isMounted = ref(false);
const searchText = ref("");

const activeUser = computed(() => currentEnvironment.value.activeUser.value);
const userId = computed(() => props.user.id);
const isCurrentUser = computed(() => userId.value === activeUser.value?.id);
const userList = computed(() => props.userList);
const filteredUserList = computed(() => (userList.value ? filterList(userList.value, { searchText: searchText.value }) : null));
const venues = computed(() => filterVenuesByCityName(filteredUserList.value?.items.map(({ venue }) => venue) ?? [], routeParams.cityName));
const noResultsMessagesByListName = {
	currentUser: {
		[UserListDatabaseNames.BEEN]: computed(() => `Start ticking off places you've been to in ${capitalize(routeParams.cityName)}`),
		[UserListDatabaseNames.FAVOURITES]: computed(() => `Save your favourite places to your profile in ${capitalize(routeParams.cityName)}`),
		[UserListDatabaseNames.GO_TRY]: computed(() => `Start saving places you want to go try in ${capitalize(routeParams.cityName)}`),
	},
	otherUser: {
		[UserListDatabaseNames.BEEN]: computed(() => `${props.user.firstName} hasn't been to any places in ${capitalize(routeParams.cityName)}`),
		[UserListDatabaseNames.FAVOURITES]: computed(() => `${props.user.firstName} hasn't saved any favourite places in ${capitalize(routeParams.cityName)}`),
		[UserListDatabaseNames.GO_TRY]: computed(() => `${props.user.firstName} hasn't saved any places to go try in ${capitalize(routeParams.cityName)}`),
	},
};
const noResultsMessage = computed(() => {
	return searchText.value.length > 0 ? "No places match your criteria" : noResultsMessagesByListName[isCurrentUser.value ? "currentUser" : "otherUser"][userList.value.name].value;
});
const searchBoxPlaceholder = computed(() => `Search your ${userList.value.name.replace("/", " ").toLowerCase()} list by place, area or tag`);

onMounted(() => {
	isMounted.value = true;
});

function searchTextOnChange(newSearchText) {
	searchText.value = newSearchText;
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/variables_new.scss";
.user-list-container {
	display: flex;
	flex-direction: column;
	gap: $spacing;
	height: 100%;
	padding-top: $spacing;
	box-sizing: border-box;
}
</style>
