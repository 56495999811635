import { computed } from "vue";

import { LOADING_STATE } from "../../../constants/index.js";

const DEFAULT_PAGE_SIZE = 500;

export default class {
	constructor({ logger, modelBuilder, api, authentication }) {
		this.logger = logger.nested({ name: "Model" });
		this.modelBuilder = modelBuilder;
		this.api = api;
		this.authentication = authentication;
	}

	execute({ isSignedIn, pageSize = DEFAULT_PAGE_SIZE, cityName }) {
		return this.modelBuilder.build({
			name: "GetActivityFeed",
			args: { isSignedIn, cityName },
			state: {
				first: pageSize,
				skip: 0,
				loading: LOADING_STATE.LOAD_INITIAL,
				lastCityName: null,
			},
			actions: {
				async refresh({ state, execute }) {
					state.skip = 0;
					state.loading = LOADING_STATE.LOAD_INITIAL;
					await execute();
				},
				async loadMore({ state, execute, model }) {
					state.skip = model?.activityFeed?.length ?? 0;
					state.loading = LOADING_STATE.LOAD_MORE;
					await execute();
				},
			},
			watchArgs: ({ args, state }) => {
				if (state.lastCityName !== args.cityName) {
					state.skip = 0;
					state.loading = LOADING_STATE.LOAD_INITIAL;
					state.lastCityName = args.cityName;
				}
			},
			execute: async ({ args, state }) => {
				let activityFeed = [];
				if (args.isSignedIn) {
					const data = await this.api.queries.getActivityFeed({ skip: state.skip, first: state.first }, { useClientCache: true, accessToken: this.authentication.getAccessToken });
					activityFeed = computed(() => data.me?.activityFeed.events);
				}

				return { activityFeed };
			},
		});
	}
}
