/* copied & modified from vue 3 show directive */
export const visible = {
	beforeMount(el, { value }, { transition }) {
		if (transition && value) {
			transition.beforeEnter(el);
		} else {
			setVisible(el, value);
		}
	},
	mounted(el, { value }, { transition }) {
		if (transition && value) {
			transition.enter(el);
		}
	},
	updated(el, { value, oldValue }, { transition }) {
		if (!value === !oldValue) return;
		if (transition) {
			if (value) {
				transition.beforeEnter(el);
				setVisible(el, true);
				transition.enter(el);
			} else {
				transition.leave(el, () => {
					setVisible(el, false);
				});
			}
		} else {
			setVisible(el, value);
		}
	},
	beforeUnmount(el, { value }) {
		setVisible(el, value);
	},
};

// if (__NODE_JS__) {
// 	visible.getSSRProps = ({ value }) => {
// 		if (!value) {
// 			return { style: { display: "none" } };
// 		}
// 	};
// }

function setVisible(el, value) {
	el.style.visibility = value ? "visible" : "hidden";
}
