import { EntityTypeNames } from "../../constants/index.js";

export default class ApiInfoProcessor {
	static doesSupportType(typeName) {
		return typeName === EntityTypeNames.ApiInfo;
	}

	constructor({ entityStore }) {
		this.entityStore = entityStore;
	}

	process({ entity, entityProcessor }, args, summary) {
		const storedEntity = this.entityStore.store(
			{
				id: "apiInfo",
				typeName: EntityTypeNames.ApiInfo,
				entity: {
					...entityProcessor.processObject(entity, args, summary),
				},
			},
			summary,
		);

		return storedEntity;
	}
}
