<template>
	<div class="user-device-permissions-container">
		<template v-if="!props.permission">No permission provided</template>
		<template v-else-if="isPermissionAlreadyEnabled">{{ alreadyEnabledMessage }}</template>
		<template v-else>
			<div class="icon-container"><img :src="iconUrl" class="icon" /></div>
			<!-- <div class="message">{{ message }}</div> -->
			<template v-if="props.permission === UserDevicePermission.TRACKING">
				<div class="message">You need to enable tracking to use this feature. The app has been previously denied tracking, you need to manually enable it in app's settings.</div>
				<div class="premium-buttons">
					<button class="push-button-primary" @click="openSettingsOnClick">Open Settings</button>
				</div>
			</template>
			<template v-else>Unable to show dialog</template>
		</template>
	</div>
</template>

<script setup>
import { computed, inject } from "vue";

import { UserDevicePermission } from "../constants/index.js";

import loggingMessages from "./UserDevicePermissions.logging-messages.js";

import ProfileIcon from "../assets/icons/profile.svg";

const DEVICE_PERMISSIONS = {
	[UserDevicePermission.TRACKING]: {
		icon: ProfileIcon,
		isAlreadyEnabled: computed(() => mobileApp?.appleAppTrackingTransparency.isAllowed),
		alreadyEnabledMessage: "Tracking is already enabled. No action required.",
	},
};

const props = defineProps({
	permission: {
		type: String,
		default: null,
	},
});

const emit = defineEmits(["click"]);

// const router = useRouter();
const mobileApp = inject("mobileApp");
const logger = inject("logger").nested({ name: "UserDevicePermissions" });
const platform = inject("platform");

const devicePermission = computed(() => DEVICE_PERMISSIONS[props.permission]);
const iconUrl = computed(() => devicePermission.value.icon);

const isPermissionAlreadyEnabled = computed(() => devicePermission.value?.isAlreadyEnabled.value);
const alreadyEnabledMessage = computed(() => devicePermission.value?.alreadyEnabledMessage);

async function openSettingsOnClick() {
	if (mobileApp) {
		await mobileApp.requestAppTrackingTransparencyPermission(true);
	} else {
		if (platform.isWeb) {
			logger.log(loggingMessages.webPlatformNotSupportedForOpeningSettings);
		} else {
			logger.log(loggingMessages.mobileAppNotProvidedForOpeningSettings);
		}
	}
	emit("click");
}
</script>

<style scoped lang="scss">
@import "../assets/styles/variables_new.scss";

.user-device-permissions-container {
	background-color: $background-color-primary;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: calc($spacing * 1.2);
	// min-width: 300px;
	// width: 400px;
	// max-width: 420px;
	border: none;

	.icon-container {
		height: 30px;
		.icon {
			width: 30px;
		}
	}

	.message {
		max-width: 250px;
		font-size: $text-size-header-secondary;
		font-weight: $text-bold-secondary;
		text-align: center;
	}

	.premium-buttons {
		display: flex;
		flex-direction: row;
		gap: calc($spacing/1.5);
		align-items: center;
		// justify-content: center;
	}

	.footer-message {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: calc($spacing);
		font-size: $text-size-secondary;
	}
}
</style>
