<template>
	<div :class="{ 'animation-wrapper': true, [props.position]: true }">
		<div :class="randomThreeDotsClass"></div>
	</div>
</template>

<script setup>
import { onActivated, ref } from "vue";

const props = defineProps({
	position: {
		type: String,
		default: "above",
	},
});

const threeDotsClasses = ["dot-spin"];

const getRandomThreeDotsClass = () => {
	return threeDotsClasses[Math.floor(Math.random() * threeDotsClasses.length)];
};

const randomThreeDotsClass = ref(getRandomThreeDotsClass());

onActivated(() => {
	randomThreeDotsClass.value = getRandomThreeDotsClass();
});
</script>

<style lang="scss" scoped>
@use "three-dots" with (
	$dot-width: 10px,
	$dot-height: 10px,
	$dot-color: #cccccc
);
@import "../assets/styles/variables_new.scss";

.animation-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 20px;

	&.below {
		order: 2;
	}

	&:has(.dot-windmill, .dot-bricks, .dot-fire, .dot-falling) {
		height: 30px;
	}

	&:has(.dot-revolution, .dot-spin) {
		height: 40px;
	}
}
</style>
