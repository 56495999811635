export const encodeJson = (json) => {
	return encodeURIComponent(JSON.stringify(json));
};

/* safely decode json from a string */
export const decodeJson = (json) => {
	if (!json) {
		return null;
	}

	try {
		return JSON.parse(decodeURIComponent(json));
	} catch (e) {
		return null;
	}
};
