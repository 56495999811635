import { computed, inject, onActivated, onDeactivated, onMounted, onUpdated, reactive } from "vue";
import { useRoute } from "vue-router";

import { ensureArray } from "./ensureArray.js";
import loggingMessages from "./useIsRouteActive.logging-messages.js";

export const useIsRouteActive = (routeName, { debugLabel } = {}) => {
	const logger = inject("logger").nested({ name: `useIsRouteActive:${debugLabel}` });
	const route = useRoute();
	const routeNames = reactive(ensureArray(routeName ?? []));
	if (!routeName) {
		captureCurrentRouteName();
		captureRouteNames();
	}

	const isRouteActive = computed(() => {
		const isActive = routeNames.some((_routeName) => !!route.matched.find(({ name: matchedRouteName }) => matchedRouteName === _routeName));
		return isActive;
	});

	return isRouteActive;

	function captureRouteNames() {
		let isActivated = false;
		onMounted(() => {
			isActivated = true;
			captureCurrentRouteName();
		});
		onActivated(() => {
			isActivated = true;
			captureCurrentRouteName();
		});
		onDeactivated(() => {
			isActivated = false;
		});
		onUpdated(() => {
			if (isActivated) {
				captureCurrentRouteName();
			}
		});
	}

	function captureCurrentRouteName() {
		if (!routeNames.some((name) => name === route.name)) {
			routeNames.push(route.name);
			logger.log(loggingMessages.capturedRouteName, { routeName: route.name });
		}
	}
};
