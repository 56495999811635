<template>
	<div>
		<Dialog ref="dialogRef" class="user-action-dialog" @closed="dialogOnClose">
			<template #default="{ closeDialog }">
				<DialogSimpleLayout @close="closeDialog">
					<UserAction :feature="state.feature" :user-action="state.userAction" @click="userActionOnClick" />
				</DialogSimpleLayout>
			</template>
		</Dialog>
	</div>
</template>

<script setup>
import { reactive, ref } from "vue";

import Dialog from "./Dialog.vue";
import UserAction from "./UserAction.vue";
import DialogSimpleLayout from "./DialogSimpleLayout.vue";

const dialogRef = ref(null);
const state = reactive({
	feature: null,
	userAction: null,
});

defineExpose({
	open,
	close,
});

function open({ feature, userAction } = {}) {
	if (!feature) {
		throw new Error("You must provide a feature to the UserActionDialog");
	}
	if (!userAction) {
		throw new Error("You must provide a userAction to the UserActionDialog");
	}
	state.feature = feature;
	state.userAction = userAction;
	const dialog = dialogRef.value;
	if (dialog && !dialog.isOpen) {
		dialog.open();
	}
}

function close() {
	const dialog = dialogRef.value;
	dialog.close();
}

function dialogOnClose() {
	state.feature = null;
	state.userAction = null;
}

function userActionOnClick() {
	close();
}
</script>

<style scoped lang="scss">
@import "../assets/styles/variables_new.scss";

.user-action-dialog {
	width: min(95vw, 420px);

	:deep(.content) {
		margin: calc($spacing) calc($spacing / 2) calc($spacing) calc($spacing / 2);
	}
}
</style>
