export default {
	networkStatusChange: {
		level: "info",
		message: ({ networkStatus }) => `Network status changed to ${networkStatus}`,
	},
	networkStatusError: {
		level: "error",
		message: ({ error }) => `Network status error: ${error}`,
	},
};
