<template>
	<div class="explore-container">
		<LoadingMessageWithError v-if="shouldShowLoadingMessageOrError" v-bind="loadingMessageBind"> Exploring {{ formattedCityName }}, please wait... </LoadingMessageWithError>
		<template v-else-if="isAllowedToViewCity">
			<div id="explore-breadcrumb"></div>
			<SearchBox
				v-bind="searchBoxBind"
				:placeholder="searchBoxPlaceholder"
				:show-details-when-collapsed="false"
				:class="{ 'search-box': true, 'showing-search-results': shouldShowSearchResults }"
				v-on="searchBoxOn"
			>
				<template #results>
					<StandardSearchResults v-bind="searchResultsBind" :result-types="resultTypesSearchBox" :show-venue-count="shouldShowVenueCount" />
				</template>
				<template #footer>
					<button class="push-button-primary" @click="hideResults(true)">Close</button>
				</template>
			</SearchBox>
			<!-- <div v-if="hasResults && !shouldShowSearchResults && !shouldShowSearchFilters" class="universal-results">
				<PageHeader class="header">Search Results</PageHeader>
				<StandardSearchResults v-bind="searchResultsBind" :result-types="resultTypesPage" :show-venue-count="shouldShowVenueCount" :search-context-align-center="false" />
			</div> -->
			<div v-show="!shouldShowSearchResults && !shouldShowSearchFilters" class="tabs-wrapper">
				<Tabs>
					<Tab route-name="City/Explore/Areas" :enable-previous-routes="false">Areas</Tab>
					<Tab route-name="City/Explore/Lists" :enable-previous-routes="false">Lists</Tab>
					<Tab route-name="City/Explore/Tags" :enable-previous-routes="false">Tags</Tab>
				</Tabs>
				<ActivatedRouterView v-slot="{ Component }" class="router-view">
					<KeepAlive>
						<Component :is="Component" />
					</KeepAlive>
				</ActivatedRouterView>
			</div>
			<Dialog ref="confirmDialogRef" enter-name="apply" escape-name="ignore">
				<DialogSimpleLayout :has-close-button="false">
					<DialogMessage>
						You have {{ pluralizeResultCount(pendingFilterCount, "filter") }} which {{ pendingFilterCount > 1 ? "have" : "has" }} not yet been applied. What would you like to do?
					</DialogMessage>
					<DialogButtons>
						<DialogButton name="ignore">Ignore</DialogButton>
						<DialogButton name="apply">Apply Now</DialogButton>
					</DialogButtons>
				</DialogSimpleLayout>
			</Dialog>
		</template>
		<PremiumUpgradeMessage v-else :feature="PremiumFeature.CITY" :user-action="suggestedUserAction">
			Only premium members are allowed to explore {{ formattedCityName }}.<br /><br />
			Access more cities with onezone premium
		</PremiumUpgradeMessage>
		{{ searchContextForRoute }}
	</div>
</template>

<script setup>
import { ref, toRef, reactive, provide, computed } from "vue";
import { useRoute } from "vue-router";
import _ from "lodash";

import { PageNames, PremiumFeature, ResultTypes } from "../constants/index.js";
import { pluralizeResultCount, useRouteParams, useSearchResults } from "../helpers/index.js";

import SearchBox from "../components/SearchBox.vue";
import Tabs from "../components/Tabs.vue";
import Tab from "../components/Tab.vue";
import StandardSearchResults from "./StandardSearchResults.vue";
import PremiumUpgradeMessage from "./PremiumUpgradeMessage.vue";
import LoadingMessageWithError from "./LoadingMessageWithError.vue";
import Dialog from "./Dialog.vue";
import DialogSimpleLayout from "./DialogSimpleLayout.vue";
import DialogMessage from "./DialogMessage.vue";
import DialogButtons from "./DialogButtons.vue";
import DialogButton from "./DialogButton.vue";
// import PageHeader from "./PageHeader.vue";

const RESULT_PAGE_SIZE = 100;
const RESULT_TYPES_SEARCHBOX = reactive([
	{ type: ResultTypes.LISTS, sectionArgs: { subHeader: "Click to view list", showFilter: false } },
	{ type: ResultTypes.TAGS, sectionArgs: { subHeader: "Click to view tag or check box to add as a filter" } },
	{ type: ResultTypes.ZONES, sectionArgs: { subHeader: "Click to view area or check box to add as a filter" } },
	{ type: ResultTypes.USERS, sectionArgs: { subHeader: "Click to view user" } },
	{ type: ResultTypes.VENUES, sectionArgs: { subHeader: "Ordered by relevance" } },
]);
// const RESULT_TYPES_PAGE = reactive([{ type: ResultTypes.VENUES, sectionArgs: { subHeader: "Ordered by relevance" } }]);
const SEARCH_CONTEXT_CONFIG = reactive({
	[PageNames.EXPLORE_TAG]: {
		// getSearchContext: ({ routeParams }) => ({ tagIds: [routeParams.tagId] }),
		// getPlaceholder: () => "Search for a place within this tag",
		// getClientVenueFilter: () => null,
		// shouldShowVenueCount: false /* TODO: this should be changed for a new useSearchResults property called hasSearchContext, which returns true if searchContext is not empty, therefore only show results in universal mode, not contextual mode */,
		// resultTypes: {
		// 	[ResultTypes.LISTS]: {
		// 		sectionArgs: {
		// 			showSection: false,
		// 		},
		// 	},
		// },
	},
	[PageNames.EXPLORE_LIST]: {
		// getSearchContext: ({ routeParams }) => ({ listIds: [routeParams.listId] }),
		// getPlaceholder: () => "Search for a place within this list",
		// getClientVenueFilter: () => null,
		// shouldShowVenueCount: false,
		// resultTypes: {
		// 	[ResultTypes.LISTS]: {
		// 		sectionArgs: {
		// 			showSection: false,
		// 		},
		// 	},
		// },
	},
	[PageNames.EXPLORE_ZONE_TAG]: {
		// getSearchContext: ({ routeParams }) => ({
		// 	tagIds: [SpecialTagIds.VIEW_ALL, SpecialTagIds.PLACES_WITH_PERKS].includes(routeParams.tagId) ? undefined : [routeParams.tagId],
		// 	zoneIds: [routeParams.zoneId],
		// }),
		// getPlaceholder: () => "Search for a place within this area",
		// getClientVenueFilter: ({ routeParams }) => (routeParams.tagId === SpecialTagIds.PLACES_WITH_PERKS ? SpecialTagsLookupById[SpecialTagIds.PLACES_WITH_PERKS].venueSelector : null),
		// shouldShowVenueCount: false,
		// resultTypes: {
		// 	[ResultTypes.LISTS]: {
		// 		sectionArgs: {
		// 			showSection: false,
		// 		},
		// 	},
		// 	[ResultTypes.ZONES]: {
		// 		sectionArgs: {
		// 			showSection: false,
		// 		},
		// 	},
		// },
	},
});

const route = useRoute();
const routeParams = useRouteParams();

const confirmDialogRef = ref(null);

const searchContextConfig = computed(() => SEARCH_CONTEXT_CONFIG[route.name]);
const resultTypesSearchBox = computed(() => RESULT_TYPES_SEARCHBOX.map((resultType) => _.merge({}, resultType, searchContextConfig.value?.resultTypes?.[resultType.type] ?? {})));
// const resultTypesPage = computed(() => RESULT_TYPES_PAGE.map((resultType) => _.merge({}, resultType, searchContextConfig.value?.resultTypes[resultType.type] ?? {})));
const shouldShowVenueCount = computed(() => searchContextConfig.value?.shouldShowVenueCount ?? true);
const searchContextForRoute = computed(() => searchContextConfig.value?.getSearchContext?.({ routeParams }) ?? null);
const hasSearchContextForRoute = computed(() => searchContextForRoute.value !== null);
const clientVenueFilter = computed(() => searchContextConfig.value?.getClientVenueFilter?.({ routeParams }) ?? null);
const searchBoxPlaceholder = computed(() => searchContextConfig.value?.getPlaceholder?.() ?? "Search for a place, area, list or tag");
const searchResults = useSearchResults(
	reactive({
		cityName: toRef(routeParams, "cityName"),
		searchContext: searchContextForRoute,
		clientVenueFilter,
		pageSize: RESULT_PAGE_SIZE,
		confirmDialogRef,
		routeName: "City/Explore",
		shouldShowResultsWhenShowingFilters: computed(() => !hasSearchContextForRoute.value),
		alwaysExpandResults: false,
	}),
);
const {
	formattedCityName,
	suggestedUserAction,
	isAllowedToViewCity,
	shouldShowLoadingMessageOrError,
	// hasResults,
	hideResults,
	shouldShowSearchResults,
	shouldShowSearchFilters,
	pendingFilterCount,
	searchBoxOn,
	searchBoxBind,
	searchResultsBind,
	loadingMessageBind,
	promise,
} = searchResults;

provide("searchResults", searchResults);
await promise;
</script>

<style lang="scss" scoped>
@import "../assets/styles/variables_new.scss";

.explore-container {
	display: flex;
	flex-direction: column;
	height: 100%;
	padding: 0 calc($spacing * 1);
	box-sizing: border-box;

	@include centre-loading-message;

	.search-box {
		// padding: calc($spacing) 0;
		margin-bottom: $spacing;
	}

	// .universal-results {
	// 	display: flex;
	// 	flex-direction: column;
	// 	gap: calc($spacing / 2);
	// 	@include scroller-wrapper;

	// 	:deep(:has(.results-section-list)) {
	// 		@include scroller-wrapper;
	// 		> .results-section-list {
	// 			@include scroller;
	// 		}
	// 	}
	// }

	.tabs-wrapper {
		display: flex;
		flex-direction: column;
		overflow: auto;
		flex: 1;
		.router-view {
		}
	}
}

@media (min-width: $bp-medium) {
	.explore-container {
		padding: 0;
	}
}
</style>
```
