<template>
	<div :class="{ 'form-layout-simple': true, 'with-labels': props.showLabels }">
		<template v-for="entityFieldState in fields" :key="entityFieldState.field.name">
			<FormFieldLabel v-if="shouldShowLabel(entityFieldState.field)">{{ entityFieldState.field.label }}</FormFieldLabel>
			<EntityField :entity-field-state="entityFieldState" />
		</template>
		<div class="save-button-container">
			<ValidationErrorMessage v-if="props.serverErrorMessage">{{ props.serverErrorMessage }}</ValidationErrorMessage>
			<button class="push-button-tertiary save-button" @click="saveOnClick">Save</button>
		</div>
	</div>
</template>

<script setup>
import { computed } from "vue";

import EntityField from "./EntityField.vue";
import FormFieldLabel from "./FormFieldLabel.vue";
import ValidationErrorMessage from "./ValidationErrorMessage.vue";

const props = defineProps({
	entityState: {
		type: Object,
		required: true,
	},
	showLabels: {
		type: Boolean,
		default: true,
	},
	serverErrorMessage: {
		type: String,
		default: null,
	},
});

const emit = defineEmits(["save"]);

const fields = computed(() => props.entityState?.fields ?? []);

function shouldShowLabel(field) {
	return props.showLabels && !field.componentProps?.isLabelHidden;
}

function saveOnClick() {
	emit("save");
}
</script>

<style scoped lang="scss">
@import "../../assets/styles/variables_new.scss";

.form-layout-simple {
	width: 100%;
	display: grid;
	grid-template-columns: auto;
	gap: calc($spacing * 1);
	align-items: center;
	justify-content: center;

	.save-button-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: calc($spacing);

		.save-button {
			width: 100px;
		}
	}

	&.with-labels {
		grid-template-columns: max-content 1fr;
		justify-content: flex-start;

		.save-button-container {
			align-items: flex-start;
			grid-column: 1 / span 2;
		}
	}
}
</style>
