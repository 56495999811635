import { watch, toRef } from "vue";
import { useRouter } from "vue-router";
import { useRouteParams } from "./useRouteParams.js";

import loggingMessages from "./ensureCorrectCity.logging-messages.js";

export const ensureCorrectCity = async (parentLogger, cityName) => {
	const logger = parentLogger.nested({ name: "ensureCorrectCity" });
	const router = useRouter();
	const routeParams = useRouteParams();

	watch(
		[cityName, toRef(routeParams, "cityName")],
		() => {
			const cityNameToTest = cityName.value?.toLowerCase();
			const currentCityName = routeParams.cityName?.toLowerCase();
			if (cityNameToTest && currentCityName) {
				const doesCityNameMatch = cityNameToTest === currentCityName;
				if (!doesCityNameMatch) {
					logger.log(loggingMessages.cityMismatch, { expected: cityNameToTest, actual: currentCityName });
					router.replace({ params: { city: cityName.value?.toLowerCase() } });
				}
			}
		},
		{ immediate: true },
	);
};
