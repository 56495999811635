<template>
	<div id="modalDialog"></div>
	<div v-if="globalErrorMessage.error" class="global-error">
		<DialogOld :dialog="globalErrorDialog">
			<DialogStyleLayout :dialog="globalErrorDialog" :has-close-button="false" class="global-error-message-dialog">
				<template #content>
					<error-message class="global-error-message" :error="globalErrorMessage.error" />
				</template>
			</DialogStyleLayout>
		</DialogOld>
	</div>
	<template v-else>
		<!-- <DebugConsole /> -->
		<Dialog ref="globalMessageDialogRef">
			<DialogSimpleLayout :has-close-button="true" @close="globalMessageDialog.hide()">
				<DialogMessage>{{ globalMessageDialog.message }}</DialogMessage>
				<!-- <DialogButtons>
					<DialogButton name="ok">OK</DialogButton>
				</DialogButtons> -->
			</DialogSimpleLayout>
		</Dialog>
		<Suspense>
			<template #default>
				<App @hydrated="onHydrated" />
			</template>
			<template #fallback>
				<LoadingOnezone />
			</template>
		</Suspense>
		<div v-if="isMounted">
			<teleport to="#app-loading-message">
				<LoadingOnezone />
			</teleport>
		</div>
	</template>
</template>

<script setup>
import { ref, onMounted, inject, watch } from "vue";

import { useDialog, Dialog as DialogOld } from "./components/dialog/useDialog.js";

import DialogStyleLayout from "./components/layouts/DialogStyleLayout.vue";
import LoadingOnezone from "./components/LoadingOnezone.vue";
import App from "./App.vue";
import Dialog from "./components/Dialog.vue";
import DialogSimpleLayout from "./components/DialogSimpleLayout.vue";
import DialogMessage from "./components/DialogMessage.vue";
// import DialogButtons from "./components/DialogButtons.vue";
// import DialogButton from "./components/DialogButton.vue";
// import DebugConsole from "./components/DebugConsole.vue";

const globalErrorMessage = inject("globalErrorMessage");
const platform = inject("platform");

const globalErrorDialog = useDialog({ isOpen: true });

const isMounted = ref(false);
const isPageVisible = ref(false);
const isAppHydrated = ref(false);

const globalMessageDialogRef = ref(null);
const globalMessageDialog = inject("globalMessageDialog");
globalMessageDialog.setRef(globalMessageDialogRef);

watch(globalErrorMessage, () => {
	if (globalErrorMessage.error) {
		makePageVisible();
	}
});

onMounted(async () => {
	isMounted.value = true;
});

const onHydrated = () => {
	isAppHydrated.value = true;
	makePageVisible();
};

function makePageVisible() {
	if (!isPageVisible.value && !platform.isServer) {
		isPageVisible.value = true;
		const styleOverride = document.createElement("style");
		styleOverride.innerHTML = "#app { visibility: visible; transition: opacity 1s; opacity: 1; } #app-loading-message { display: none; }";
		document.head.appendChild(styleOverride);
	}
}
</script>

<style lang="scss" scoped>
@import "./assets/styles/variables_new.scss";

.global-error-message-dialog {
	:deep(.dialog) {
		display: flex;
		flex-direction: column;
		justify-content: center;

		&:has(.global-error-message .detailed-message) {
			max-width: 90vw;
			max-height: 90dvh;
			justify-content: start;
		}

		.global-error-message {
			padding: calc($spacing * 2) $spacing;
			box-sizing: border-box;
		}
	}
}

@media (min-width: $bp-medium) {
	.global-error-message-dialog {
		:deep(.dialog) {
			max-width: 450px;
			height: fit-content;
		}
	}
}
</style>
