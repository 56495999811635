<template>
	<div class="explore-area-container">
		<loading-message-with-error
			v-if="(areasQuery.isLoading && (areasQuery.state?.loading ?? LOADING_STATE.LOAD_INITIAL) === LOADING_STATE.LOAD_INITIAL) || areasQuery.error"
			:error="areasQuery.error"
			:retry="areasQuery.retry"
			class="loading-message"
		>
			Loading, please wait...
		</loading-message-with-error>
		<div v-else class="explore-area">
			<PageHeader>
				<template #breadcrumb>
					<custom-router-link :to="{ name: 'City/Explore/Areas', params: { city: routeParams.cityName } }">{{ formattedCityName }}</custom-router-link>
				</template>
				{{ areaName }}
			</PageHeader>
			<SuperList v-slot="{ item: zone, style }" :name="`area-list-${area?.id}`" :items="zones" :item-size="76" :is-virtual="true" class="explore-area-list">
				<ZoneCard :key="zone.id" :zone="zone" :show-mini="true" :style="style" />
			</SuperList>
		</div>
		<!-- <div v-for="area in areas" :key="area.id">{{ area.id }}</div> -->
	</div>
</template>

<script setup>
import { inject, computed, ref, onMounted, unref, capitalize } from "vue";

import { LOADING_STATE } from "../constants/index.js";
import { useRouteParams } from "../helpers/index.js";
// import { breakpoints } from "../domain/index.js";

import ZoneCard from "./ZoneCard.vue";
import SuperList from "./SuperList.vue";
import PageHeader from "./PageHeader.vue";

const routeParams = useRouteParams();

// const logger = inject("logger");
const isAppHydrated = inject("isAppHydrated");
const model = inject("model");

const isMounted = ref(false);
onMounted(() => {
	isMounted.value = true;
});

// const isSmallerThanMedium = breakpoints.smaller("medium");

const areasQuery = model.queries.GetAreas({
	cityName: computed(() => routeParams.cityName),
});

const areaId = computed(() => routeParams.areaId);
const area = computed(() => areasQuery.model?.areas.city.areas?.find(({ id }) => id === unref(areaId)) ?? {});
const zones = computed(() => unref(area).zones ?? []);
const areaName = computed(() => unref(area).name ?? "");
const formattedCityName = computed(() => capitalize(routeParams.cityName ?? ""));
/* TODO: cannot use at moment as the area data loaded is based on the city and not the areaId. This was an optimization to avoid loading the area data again when the areaId changes. */
// const areaCityName = computed(() => area.value?.city?.()?.name ?? null);
// ensureCorrectCity(logger, areaCityName);

if (!isAppHydrated.value) {
	await areasQuery.promise;
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/variables_new.scss";

.explore-area-container {
	display: flex;
	flex-direction: column;
	width: 100%;
	// height: 100%;
	// padding: 0 calc($spacing * 2);
	margin-top: $spacing;
	overflow: auto;
	overscroll-behavior: none;
	@include centre-loading-message;

	.explore-area {
		display: flex;
		flex-direction: column;
		gap: calc($spacing * 0.5);
		overflow: auto;
		overscroll-behavior: none;

		.explore-area-list {
			// overflow: auto;
			// overscroll-behavior: none;
			display: flex;
			flex-direction: column;
			gap: $spacing;
			align-content: start;
		}
	}
}

@media (min-width: $bp-medium) {
	.explore-area-container {
		padding: 0;
		.loading-message {
			width: 100%;
		}

		.explore-area {
			.explore-area-list {
				// flex-wrap: wrap;
				// flex-direction: row;
			}
		}
	}
}
</style>
