import { markRaw, reactive } from "vue";

import loggingMessages from "./useGlobalErrorMessage.logging-messages.js";

export const useGlobalErrorMessage = ({ logger, app }) => {
	const globalErrorMessage = reactive({
		message: null,
		error: null,
		show: markRaw((error, { message = null } = {}) => {
			logger.log(loggingMessages.globalAppError, { error });
			globalErrorMessage.error = error;
			globalErrorMessage.message = message;
		}),
		hide: markRaw(() => {
			globalErrorMessage.error = null;
			globalErrorMessage.message = null;
		}),
	});

	app.config.errorHandler = (error, vm, info) => {
		// vm: component in which error occured
		// info: Vue specific error information such as lifecycle hooks, events etc.
		globalErrorMessage.show(error, { vm, info });
	};

	return globalErrorMessage;
};
