<template>
	<div :class="{ 'booking-provider-container': true, 'is-aligned-center': props.isAlignedCenter }">
		<div :class="{ 'booking-provider': true, 'show-border': props.showBorder }">
			Powered by
			<img :src="bookingProviderLogo" class="booking-provider-logo" />
		</div>
	</div>
</template>

<script setup>
import { computed } from "vue";

import { BookingProvider } from "../constants/index.js";

import OpenTableLogo from "../assets/open-table-logo.svg";

const props = defineProps({
	bookingProvider: {
		type: String,
		required: true,
	},
	showBorder: {
		type: Boolean,
		default: false,
	},
	isAlignedCenter: {
		type: Boolean,
		default: true,
	},
});

const bookingProviderLogo = computed(() => {
	switch (props.bookingProvider) {
		case BookingProvider.OPEN_TABLE:
			return OpenTableLogo;
		default:
			return null;
	}
});
</script>

<style scoped lang="scss">
@import "../assets/styles/variables_new.scss";

.booking-provider-container {
	display: flex;

	&.is-aligned-center {
		justify-content: center;
	}

	.booking-provider {
		display: flex;
		align-items: center;
		gap: calc($spacing / 2);
		font-size: $text-size-quinary;
		color: $text-color-secondary;

		&.show-border {
			border: $border-size-primary solid $border-color-primary;
			border-radius: $border-radius-tertiary;
			padding: calc($spacing / 4) calc($spacing);
		}

		.booking-provider-logo {
			height: 18px;
		}
	}
}
</style>
