<template>
	<div v-if="props.userLists" class="user-lists-container">
		<div class="user-lists">
			<Tabs class="user-list-tabs">
				<Tab v-for="{ id, name, listCount, routeName } in tabs" :key="name" :route-name="routeName" :class="{ 'user-list-tab': true, [id]: true }">
					<div class="list-name">
						<div class="icon"></div>
						<div class="list-count">{{ listCount }}</div>
					</div>
					<div class="name">{{ name }}</div>
				</Tab>
			</Tabs>

			<div class="user-list-routes">
				<ActivatedRouterView v-slot="{ Component }">
					<transition :enter-active-class="`animate__animated animate__fadeIn`" :leave-active-class="`animate__animated animate__fadeOut`" appear>
						<keep-alive>
							<component :is="Component" :user="props.user" :user-lists="userLists" :current-user-lists="props.currentUserLists" />
						</keep-alive>
					</transition>
				</ActivatedRouterView>
			</div>
		</div>
	</div>
	<div v-else>Loading...</div>
</template>

<script setup>
import { computed, ref, onMounted, reactive, inject } from "vue";

import { PageNames, UserListDatabaseNames, UserListNames } from "../constants";
// import { buildLink } from "../functions/custom-router/useCustomRouter.js";

import Tabs from "../components/Tabs.vue";
import Tab from "../components/Tab.vue";
import { useRouteParams, filters } from "../helpers/index.js";
const { filterVenuesByCityName } = filters;

const props = defineProps({
	user: {
		type: Object,
		required: true,
	},
	userLists: {
		type: Object,
		default: null,
	},
	currentUserLists: {
		type: Array,
		required: true,
	},
});

const currentEnvironment = inject("currentEnvironment");

const routeParams = useRouteParams();
const userLists = computed(() => props.userLists);

const activeUser = computed(() => currentEnvironment.value.activeUser.value);
const userId = computed(() => routeParams.userId ?? activeUser.value?.id);

const isCurrentUser = computed(() => userId.value === activeUser.value?.id);
// const listName = computed(() => currentRoute.params.listName.toLowerCase());

// const databaseListName = computed(() => {
// 	const _databaseListName = Object.entries(UserListDatabaseNamesLookup).find(([, _listName]) => _listName?.toLowerCase() === listName.value)?.[0];
// 	return _databaseListName;
// });
// const userList = computed(() => userLists.value.find(({ name }) => name.toLowerCase() === databaseListName.value?.toLowerCase()));

const isMounted = ref(false);
onMounted(() => {
	isMounted.value = true;
});

const tabs = reactive([
	{
		id: "been",
		name: "Been",
		routeName: computed(() => (isCurrentUser.value ? PageNames.PROFILE_USER_LIST_BEEN : PageNames.OTHER_USER_USER_LIST_BEEN)),
		listNameParam: UserListNames.BEEN.toLowerCase(),
		listCount: computed(
			() =>
				filterVenuesByCityName(userLists.value?.find(({ name }) => name.toLowerCase() === UserListDatabaseNames.BEEN.toLowerCase())?.items.map(({ venue }) => venue) ?? [], routeParams.cityName)
					.length,
		),
	},
	{
		id: "favourites",
		name: "Favourites",
		routeName: computed(() => (isCurrentUser.value ? PageNames.PROFILE_USER_LIST_FAVOURITES : PageNames.OTHER_USER_USER_LIST_FAVOURITES)),
		listNameParam: UserListNames.FAVOURITES.toLowerCase(),
		listCount: computed(
			() =>
				filterVenuesByCityName(userLists.value?.find(({ name }) => name.toLowerCase() === UserListDatabaseNames.FAVOURITES.toLowerCase())?.items.map(({ venue }) => venue) ?? [], routeParams.cityName)
					.length,
		),
	},
	{
		id: "gotry",
		name: "Go Try",
		routeName: computed(() => (isCurrentUser.value ? PageNames.PROFILE_USER_LIST_GO_TRY : PageNames.OTHER_USER_USER_LIST_GO_TRY)),
		listNameParam: UserListNames.GO_TRY.toLowerCase(),
		listCount: computed(
			() =>
				filterVenuesByCityName(userLists.value?.find(({ name }) => name.toLowerCase() === UserListDatabaseNames.GO_TRY.toLowerCase())?.items.map(({ venue }) => venue) ?? [], routeParams.cityName)
					.length,
		),
	},
]);
</script>

<style lang="scss" scoped>
@import "../assets/styles/variables_new.scss";

.user-lists-container {
	height: 100%;
	// overflow: hidden;

	.user-lists {
		display: flex;
		flex-direction: column;
		height: 100%;

		.user-list-tabs {
			display: flex;
			width: 100%;

			:deep(.router-link-active-partial-path) {
				&.user-list-tab {
					.list-name > .icon {
						opacity: 1;
					}
					.name {
						font-weight: $text-bold-primary;
					}
				}
			}

			.user-list-tab {
				display: flex;
				flex-direction: column;
				flex-grow: 1;

				&.been .list-name > .icon {
					background-image: url("../assets/icons/been.svg");
				}

				&.favourites .list-name > .icon {
					background-image: url("../assets/icons/favourite.svg");
				}

				&.gotry .list-name > .icon {
					background-image: url("../assets/icons/go-try.svg");
				}

				.list-name {
					display: flex;
					justify-content: center;
					align-items: center;

					> .icon {
						width: 16px;
						height: 16px;
						background-repeat: no-repeat;
						background-size: 16px;
						background-position: 0 1px;
						opacity: 0.5;
					}

					> .list-count {
						font-size: $text-size-header;
						margin-left: calc($spacing/ 2);
					}
				}
				.name {
					text-align: center;
					font-size: $text-size-header-secondary;
					font-weight: normal;
				}
			}
		}

		.user-list-routes {
			flex: 1;
			overflow: hidden;
			position: relative;
			width: 100%;

			.animate__animated {
				position: absolute;
				width: 100%;
				animation-duration: 0.4s;
			}
		}
	}
}
</style>
