<template>
	<div :class="{ 'form-field-container': true, 'error': validation?.$errors?.length > 0 }">
		<div :class="{ 'form-field': true, 'disabled': props.isReadOnly }">
			<label v-if="selectedCountryFlag" class="select-label-override">
				<div class="flag" v-html="selectedCountryFlag" />
				<FontAwesomeIcon icon="chevron-down" />
			</label>
			<select ref="countryCodeRef" v-model="fieldState.countryCode" class="country">
				<option v-for="{ code, number, name } in PHONE_COUNTRY_CODES" :key="code" :value="code">{{ name }} {{ getUnicodeFlagIcon(normaliseCountryCode(code)) }} ({{ number }})</option>
			</select>
			<input v-model="fieldState.number" class="number" :placeholder="props.placeholder" :disabled="props.isReadOnly" @change="onStateChanged" />
		</div>
		<FormFieldErrors :validation="validation" />
	</div>
</template>

<script setup>
import { computed, ref, toRef, watch } from "vue";
import * as flags from "country-flag-icons/string/3x2";
import getUnicodeFlagIcon from "country-flag-icons/unicode";

import PHONE_COUNTRY_CODES from "../../phone-country-codes.json";

import FormFieldErrors from "./FormFieldErrors.vue";

const props = defineProps({
	validation: {
		type: Object,
		required: true,
	},
	state: {
		type: Object,
		default: () => ({ countryCode: "GB", number: "" }),
	},
	placeholder: {
		type: String,
		default: "",
	},
	isReadOnly: {
		type: Boolean,
		default: false,
	},
});

const emits = defineEmits(["change"]);

const countryCodeRef = ref();
const fieldState = ref();
watch(
	toRef(props, "state"),
	() => {
		fieldState.value = { ...(props.state ?? {}) };
	},
	{ immediate: true },
);

const selectedCountryFlag = computed(() => (fieldState.value?.countryCode ? flags[normaliseCountryCode(fieldState.value?.countryCode)] : null));
const validation = computed(() => props.validation);

const onStateChanged = () => {
	emits("change", { value: { ...fieldState.value } });
};

function normaliseCountryCode(countryCode) {
	if (countryCode === "UK") {
		/* WHY: The country code for the UK is GB, not UK, so we need to change it */
		return "GB";
	}
	return countryCode;
}
</script>

<style scoped lang="scss">
@import "../../assets/styles/variables_new.scss";

.form-field-container {
	display: flex;
	justify-content: center;
	flex-direction: column;
	gap: calc($spacing / 4);
	min-width: 50px;
	// max-width: 300px;

	.form-field {
		position: relative;
		display: flex;
		align-items: center;
		gap: calc($spacing / 2);
		border-radius: $border-radius-primary;
		border: $border-size-primary solid $border-color-secondary;
		padding: 0 $spacing;
		height: 40px;
		box-sizing: border-box;

		> .country,
		.number {
			border: none;

			&:focus {
				outline: none;
			}
		}

		.select-label-override {
			pointer-events: none;
			position: absolute;
			display: flex;
			align-items: center;
			gap: calc($spacing / 2);
			background-color: $background-color-primary;
			width: 50px;

			.flag {
				display: flex;
				align-items: center;
				width: 30px;

				> :deep(svg) {
					width: 100%;
				}
			}
		}

		.country {
			width: 50px;
			background-color: $background-color-primary;
		}

		.number {
			flex: 1;
		}

		&::placeholder {
			color: $text-color-tertiary;
		}

		&.disabled {
			color: $text-color-tertiary;
			border: $border-size-primary solid $border-color-primary;
		}
	}
}
</style>
