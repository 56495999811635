import { ErrorNested } from "./ErrorNested.js";

export class AuthenticationError extends ErrorNested {
	constructor(environmentName, error, extensions) {
		super("AuthenticationError", `Critical error whilst handling authentication for environment: '${environmentName}'`, {
			exception: error,
			code: "AUTHENTICATION_ERROR",
			...extensions,
		});
	}
}
