import { computed, reactive, ref } from "vue";

import { debounce } from "lodash-es";

export const useRouterQueryString = ({ router, mode = "replace" } = {}) => {
	const pendingQueryState = ref(null);

	const debouncedUpdatePendingQueryState = debounce(updatePendingQueryState, 5000);

	router.queryString = reactive({
		query: computed({
			get: () => {
				const currentQuery = router.currentRoute.value.query;
				return { ...currentQuery, ...(pendingQueryState.value ?? {}) };
			},
			set: (v) => {
				if (pendingQueryState.value === null) {
					pendingQueryState.value = {};
				}
				pendingQueryState.value = { ...pendingQueryState.value, ...v };
				debouncedUpdatePendingQueryState();
			},
		}),
		getQuery: (fields = {}) => {
			const queryFields = reactive(
				Object.fromEntries(
					Object.entries(fields).map(([fieldName, defaultValue]) => [
						fieldName,
						computed({
							get: () => router.queryString.query[fieldName] ?? defaultValue,
							set: (v) => {
								console.log("set", fieldName, v);
								if (pendingQueryState.value === null) {
									pendingQueryState.value = {};
								}
								if (v !== undefined) {
									pendingQueryState.value[fieldName] = v;
								} else {
									delete pendingQueryState.value[fieldName];
								}
								debouncedUpdatePendingQueryState();
							},
						}),
					]),
				),
			);

			queryFields.set = (fieldName, value) => {
				queryFields[fieldName] = value;
			};

			return queryFields;
		},
	});

	router.beforeEach((to, from) => {
		if (pendingQueryState.value !== null) {
			const url = new URL(from.fullPath, window.location.origin);
			const searchParams = new URLSearchParams(url.search);
			Object.entries(pendingQueryState.value).forEach(([key, value]) => {
				searchParams.set(key, value);
				from.query[key] = value;
			});
			url.search = searchParams.toString();
			const newFullPath = url.pathname + url.search + url.hash;
			history.replaceState(history.state, null, newFullPath);
			from.fullPath = newFullPath;
			pendingQueryState.value = null;
		}
	});

	router.afterEach(() => {
		pendingQueryState.value = null;
	});

	return router.queryString;

	function updatePendingQueryState() {
		if (pendingQueryState.value !== null) {
			router[mode]({ query: router.queryString.query });
		}
	}
};
