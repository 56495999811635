import { computed } from "vue";

export default class {
	constructor({ logger, modelBuilder, api, authentication }) {
		this.logger = logger.nested({ name: "Model" });
		this.modelBuilder = modelBuilder;
		this.api = api;
		this.authentication = authentication;
	}

	execute({ position, cityName, filterState, size = 20 } = {}) {
		return this.modelBuilder.build({
			name: "GetVenuesNear",
			args: { isSignedIn: computed(() => this.authentication.isAuthenticated.value), position, cityName, filterState, size },
			execute: async ({ args }) => {
				const cities = await this.api.queries.getCitiesAnonymous({}, { useClientCache: true });
				const city = cities.cities.find(({ name }) => name.toLowerCase() === args.cityName);
				if (!city) {
					throw new Error(`City '${args.cityName}' not found`);
				}

				const venuesNear = await this.#getVenuesNear(args, city.id);

				return { venuesNear };
			},
		});
	}

	async #getVenuesNear(args, cityId) {
		let venues = [];

		const prices = args.filterState.prices?.map((price) => parseInt(price));
		const mealTypeIds = args.filterState.mealTypeIds;
		const cuisineIds = args.filterState.cuisineIds;
		const occasionIds = args.filterState.occasionIds;
		const vibeIds = args.filterState.vibeIds;

		const data = args.position
			? await this.api.queries.getVenuesNear(
					{ latitude: args.position.lat, longitude: args.position.lng, prices, mealTypeIds, cuisineIds, occasionIds, vibeIds, size: args.size, distance: 30000, cityId },
					{ useClientCache: false, accessToken: args.isSignedIn ? this.authentication.getAccessToken : undefined },
			  )
			: null;

		if (data) {
			venues = computed(() => data.venuesNear);
		}

		return venues;
	}
}
