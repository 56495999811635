<template>
	<div class="image-wrapper" :style="{ 'aspect-ratio': props.aspectRatio, 'justify-content': props.justifyContent }">
		<img
			v-if="props.src"
			ref="refImage"
			crossorigin="anonymous"
			:alt="props.alt"
			:sizes="props.sizes"
			:srcset="imageSrcSet"
			:src="imageSrc"
			class="image"
			:style="{
				opacity: isLoading ? 0 : 1,
				position: isLoading ? 'absolute' : 'static',
			}"
			:loading="props.isLazy ? 'lazy' : 'eager'"
			:width="100"
			:height="props.aspectRatio * 100"
			@load="imageLoaded"
			@error="imageLoadError"
		/>

		<canvas v-if="previewJson && props.showPreview" ref="refPreview" class="image-placeholder" :style="placeholderStyle" width="32" height="32" />
		<div v-else-if="props.showPreview" class="image-placeholder image-placeholder-no-preview" :style="placeholderStyle">
			<img src="../../assets/icons/onezone-logo-grey.svg" class="icon" />
		</div>
		<div v-if="isLoading && props.showLoadingSpinner" class="image-loading-spinner">
			<div v-if="hasImageLoadError" class="image-load-error" :style="{ ...errorStyle }" @click="reloadImage">
				<span class="message" :style="{ ...messageStyle }">Press to reload</span>
				<FontAwesomeIcon icon="rotate" class="reload" :style="{ ...spinnerStyle }" />
			</div>
			<img v-else-if="isLoading" src="../../assets/icons/loading-spinner.gif" class="spinner" :style="{ ...spinnerStyle }" />
		</div>
	</div>
</template>

<script setup>
import { onMounted, onBeforeUnmount, ref, computed, watch, toRef, inject } from "vue";
import { buildUrlObject } from "@imgix/vue";

import { useLogger } from "../../functions/logger";
import { BlurhashRenderer } from "./BlurhashRenderer.js";
import loggingMessages from "../imgix/ImgIxWithPlaceholder.logging-messages";

const IMAGE_SIZE_SETTINGS = {
	0: {
		spinner: {
			width: "8px",
			height: "8px",
		},
		message: {
			"font-size": "0.55rem",
		},
		error: {
			gap: "4px",
		},
	},
	160: {
		spinner: {
			width: "20px",
			height: "20px",
		},
		message: {
			"font-size": "0.55rem",
		},
		error: {
			gap: "4px",
		},
	},
	320: {
		spinner: {
			width: "25px",
			height: "25px",
		},
		message: {
			"font-size": "0.6rem",
		},
	},
	480: {
		spinner: {
			width: "35px",
			height: "35px",
		},
		message: {
			"font-size": "0.7rem",
		},
	},
	960: {
		spinner: {
			width: "50px",
			height: "50px",
		},
		message: {
			"font-size": "0.9rem",
		},
	},
	1200: {
		spinner: {
			width: "50px",
			height: "50px",
		},
		message: {
			"font-size": "0.9rem",
		},
	},
};

const IMAGE_QUALITY_DPR_LOOKUP = {
	1: 50,
	2: 40,
	3: 20,
};

const logger = useLogger({ name: "ImgIxWithPlaceholder" });

const props = defineProps({
	src: {
		type: String,
		default: null,
	},
	alt: {
		type: String,
		default: null,
	},
	preview: {
		type: String,
		default: null,
	},
	sizes: {
		type: String,
		required: false,
		default: null,
	},
	size: {
		type: String,
		required: false,
		default: "160",
	},
	aspectRatio: {
		type: Number,
		required: false,
		default: 1,
	},
	widths: {
		type: Array,
		default: () => [160, 320, 480],
	},
	isLazy: {
		type: Boolean,
		default: true,
	},
	justifyContent: {
		type: String,
		default: "center",
	},
	placeholderWidth: {
		type: String,
		default: "100%",
	},
	showLoadingSpinner: {
		type: Boolean,
		default: true,
	},
	showPreview: {
		type: Boolean,
		default: true,
	},
});

const blurhashRenderQueue = inject("blurhashRenderQueue");
const blurhashRenderer = new BlurhashRenderer({ blurhashRenderQueue });

const refPreview = ref(null);
const hasImageLoadError = ref(false);
const refImage = ref(null);
const isLoading = ref(true);

const imageQuality = IMAGE_QUALITY_DPR_LOOKUP[window.devicePixelRatio] ?? IMAGE_QUALITY_DPR_LOOKUP[1];
const urlObject = computed(() =>
	props.src
		? buildUrlObject(
				props.src,
				{
					ar: props.aspectRatio,
					fit: "crop",
					auto: "compress",
					q: imageQuality,
					fm: "webp",
				},
				{ widths: props.widths },
		  )
		: {},
);
const imageSrcSet = computed(() => (props.sizes ? urlObject.value.srcset : null));
const imageSrc = computed(() => urlObject.value.src);

const shouldShowPlaceholder = computed(() => isLoading.value);
const placeholderStyle = computed(() => ({
	"opacity": shouldShowPlaceholder.value ? 1 : 0,
	"position": shouldShowPlaceholder.value ? "static" : "absolute",
	"width": props.placeholderWidth,
	"aspect-ratio": props.aspectRatio,
}));
const previewJson = computed(() => {
	let json = null;
	try {
		json = props.preview ? JSON.parse(props.preview) : null;
	} catch (error) {
		logger.log(loggingMessages.invalidPreviewJson, { url: props.src, preview: props.preview, error });
	}
	return json;
});
const nearestSize = computed(() => Object.keys(IMAGE_SIZE_SETTINGS).find((size) => (refImage.value?.width ?? 160) < parseInt(size)));
const nearestSizeImageSettings = computed(() => IMAGE_SIZE_SETTINGS[nearestSize.value]);
const spinnerStyle = computed(() => nearestSizeImageSettings.value?.spinner ?? {});
const messageStyle = computed(() => nearestSizeImageSettings.value?.message ?? {});
const errorStyle = computed(() => nearestSizeImageSettings.value?.error ?? {});

const imageLoaded = () => {
	isLoading.value = false;
};

const imageLoadError = () => {
	logger.log(loggingMessages.errorLoadingImage, { url: props.src });
	hasImageLoadError.value = true;
	reloadImage({ maxAttempts: 3 });
};

let removeFromQueue = null;
const generateBlurhash = () => {
	if (removeFromQueue) {
		removeFromQueue();
	}
	if (previewJson.value) {
		try {
			removeFromQueue = blurhashRenderer.addBlurhashRenderToQueue({ src: props.src, previewJson: previewJson.value, refPreview });
		} catch (error) {
			logger.log(loggingMessages.errorGeneratingPreview, { url: props.src, error });
		}
	}
};

const reset = () => {
	hasImageLoadError.value = false;
	isLoading.value = true;
};

watch(toRef(props, "src"), reset);
watch(previewJson, generateBlurhash);

onMounted(() => {
	reset();
	if (refImage.value?.complete) {
		imageLoaded();
	} else {
		generateBlurhash();
	}
});

onBeforeUnmount(() => {
	if (removeFromQueue) {
		removeFromQueue();
	}
});

function reloadImage(optionsOrEvent) {
	const { maxAttempts = Number.MAX_SAFE_INTEGER } = optionsOrEvent ?? {};
	optionsOrEvent?.stopPropagation?.();
	optionsOrEvent?.preventDefault?.();
	const reloadAttempts = parseInt(refImage.value?.getAttribute("data-reload-attempts") ?? "0");
	const currentReloadAttempt = reloadAttempts + 1;
	if (currentReloadAttempt <= maxAttempts) {
		logger.log(loggingMessages.attemptingToReloadImage, { url: props.src, count: currentReloadAttempt });
		reset();
		refImage.value?.setAttribute("data-reload-attempts", (reloadAttempts + 1).toString());
		refImage.value?.setAttribute("srcset", imageSrcSet.value);
		refImage.value?.setAttribute("src", imageSrc.value);
	}
}
</script>

<style lang="scss" scoped>
@import "../../assets/styles/variables_new.scss";

.image-wrapper {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	// justify-content: center;
	font-size: 0.6rem;
	width: 100%;
	height: 100%;

	.image {
		display: flex;
		width: auto;
		height: auto;
		// height: 100%;
		align-items: center;
		justify-content: center;
		transition: opacity 0.2s linear;
	}

	.image-loading-spinner {
		position: absolute;
		display: flex;
		width: 100%;
		height: 100%;
		align-items: center;
		justify-content: center;

		> .image-load-error {
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: $spacing;
			cursor: pointer;
			> .message {
				color: $background-color-quaternary;
				font-weight: $text-bold-secondary;
				font-size: $text-size-primary;
			}
			> .reload {
				color: $background-color-quaternary;
			}

			@media (pointer: fine) {
				&:hover {
					@keyframes spin {
						0% {
							transform: rotate(0deg);
						}
						100% {
							transform: rotate(360deg);
						}
					}
					> .reload {
						animation: spin 1s linear infinite;
					}
				}
			}
		}

		// > .icon {
		// 	@keyframes spin {
		// 		0% {
		// 			transform: rotate(0deg);
		// 		}
		// 		100% {
		// 			transform: rotate(360deg);
		// 		}
		// 	}

		// 	color: $text-color-quaternary;
		// 	animation: spin 1s linear infinite;
		// }
	}

	.image-placeholder {
		position: absolute;
		display: flex;
		width: 100%;
		height: 100%;
		transition: opacity 0.2s linear;
		align-items: center;
		justify-content: center;

		&.image-placeholder-no-preview {
			background-color: $background-color-tertiary;
			box-sizing: border-box;

			.icon {
				width: 50%;
				max-width: 200px;
			}
		}
	}
}
</style>
