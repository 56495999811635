<template>
	<div :class="{ 'form-field-container': true, 'error': validation?.$errors?.length > 0 }">
		<input v-model="fieldState" class="form-field" :placeholder="props.placeholder" :disabled="props.isReadOnly" :class="{ disabled: props.isReadOnly }" @change="onStateChanged" />
		<FormFieldErrors :validation="validation" />
	</div>
</template>

<script setup>
import { computed, ref, toRef, watch } from "vue";

import FormFieldErrors from "./FormFieldErrors.vue";

const props = defineProps({
	validation: {
		type: Object,
		required: true,
	},
	state: {
		type: String,
		default: "",
	},
	placeholder: {
		type: String,
		default: "",
	},
	isReadOnly: {
		type: Boolean,
		default: false,
	},
});

const emits = defineEmits(["change"]);

const fieldState = ref();
watch(
	toRef(props, "state"),
	() => {
		fieldState.value = props.state;
	},
	{ immediate: true },
);

const validation = computed(() => props.validation);

const onStateChanged = (event) => {
	emits("change", { value: event.target.value });
};
</script>

<style scoped lang="scss">
@import "../../assets/styles/variables_new.scss";

.form-field-container {
	display: flex;
	flex-direction: column;
	gap: calc($spacing / 4);
	min-width: 50px;
	// max-width: 300px;

	.form-field {
		padding: 0 $spacing;
		border-radius: $border-radius-primary;
		border: $border-size-primary solid $border-color-secondary;
		height: 40px;
		box-sizing: border-box;

		&::placeholder {
			color: $text-color-tertiary;
		}

		&:focus {
			outline: none;
		}

		&.disabled {
			color: $text-color-tertiary;
			border: $border-size-primary solid $border-color-primary;
		}
	}
}
</style>
