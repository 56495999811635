import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

import loggingMessages from "./LockVenueBookingAvailability.logging-messages.js";

export default class {
	constructor({ logger, modelBuilder, api, authentication }) {
		this.logger = logger.nested({ name: "Model" });
		this.modelBuilder = modelBuilder;
		this.api = api;
		this.authentication = authentication;
	}

	async execute({ venueId, time, partySize, seatingPosition }) {
		const response = await this.api.mutations.lockVenueBookingAvailability(
			{ venueId, time, partySize, seatingPosition },
			{ accessToken: this.authentication.isAuthenticated.value ? this.authentication.getAccessToken : undefined },
		);

		const errors = response.errors;
		if (errors?.length > 0) {
			this.logger.log(loggingMessages.criticalErrorLockingVenueBookingAvailability, { venueId, time, partySize, seatingPosition, errors: errors.map(({ message }) => message) });
			return { success: false, error: "Error reserving time slot with venue" };
		} else {
			const lockAvailability = response.data?.venue.book.lockAvailability;
			if (!lockAvailability.success) {
				this.logger.log(loggingMessages.errorLockingVenueBookingAvailability, { venueId, time, partySize, seatingPosition, error: lockAvailability.error });
				return { success: false, error: lockAvailability.error };
			} else {
				return lockAvailability;
			}
		}
	}
}
