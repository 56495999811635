import { reactive } from "vue";

import loggingMessages from "./StorePreviousRoutes.logging-messages.js";

const DIALOG_QUERY_PARAMS_TO_STRIP = ["citySwitchOpen"];

export const StorePreviousRoutes = {
	install: (app, { logger: parentLogger, router } = {}) => {
		const logger = parentLogger.nested({ name: "StorePreviousRoutes" });

		let previousParams = null;

		router.previousRoutes = reactive({});
		router.afterEach((to, from, error) => {
			if (!error) {
				if (from) {
					storePreviousRoute(router, from);
				}
				if (previousParams?.city && previousParams?.city !== to.params.city) {
					deleteAllStoredPreviousRoutes(router);
					logger.log(loggingMessages.cityChangedClearedPreviousRoutes, { fromCity: previousParams?.city, toCity: to.params.city });
				}
				previousParams = to.params;
			}
		});
	},
};

function deleteAllStoredPreviousRoutes(router) {
	Object.keys(router.previousRoutes).forEach((key) => {
		delete router.previousRoutes[key];
	});
}

function storePreviousRoute(router, from) {
	const fromSnapshot = { name: from.name, params: from.params, query: from.query };
	const lastMatchedWithMetaPreviousRouteFilter = from.matched.findLast((route) => route.meta.previousRouteFilter)?.meta?.previousRouteFilter;
	const strippedFromSnapshot = stripPersistedQueriesFromRouteMatch(router, fromSnapshot);
	const finalSnapshot = lastMatchedWithMetaPreviousRouteFilter ? lastMatchedWithMetaPreviousRouteFilter(strippedFromSnapshot) : strippedFromSnapshot;
	from.matched.forEach(({ name }) => {
		router.previousRoutes[name] = finalSnapshot;
	});
}

function stripPersistedQueriesFromRouteMatch(router, from) {
	const allQueryKeys = Object.keys(router.persistedQueryNames ?? {}).concat(DIALOG_QUERY_PARAMS_TO_STRIP);
	allQueryKeys.forEach((queryKeyName) => {
		delete from.query[queryKeyName];
	});
	return from;
}
