import { computed } from "vue";

export default class {
	constructor({ logger, modelBuilder, api, authentication }) {
		this.logger = logger.nested({ name: "Model" });
		this.modelBuilder = modelBuilder;
		this.api = api;
		this.authentication = authentication;
	}

	execute() {
		return this.modelBuilder.build({
			name: "GetCurrentUser",
			args: {},
			execute: async () => {
				let currentUser = null;
				if (this.authentication.isAuthenticated.value) {
					const data = await this.api.queries.getCurrentUser({}, { useClientCache: false, accessToken: this.authentication.getAccessToken });
					currentUser = computed(() => data.me);
				}

				return { currentUser };
			},
		});
	}
}
