import { markRaw, reactive } from "vue";

export const useGlobalMessageDialog = () => {
	const globalMessageDialog = reactive({
		ref: null,
		message: "?",
		show: markRaw((message) => {
			globalMessageDialog.message = message;
			globalMessageDialog.ref.open();
		}),
		hide: markRaw(() => {
			globalMessageDialog.ref.close();
		}),
		setRef: markRaw((ref) => {
			globalMessageDialog.ref = ref;
		}),
	});

	return globalMessageDialog;
};
