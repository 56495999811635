import colors from "ansi-colors";

export const printLogo = ({ platform }) => {
	if (!platform.isSafariOrIos) {
		const colorFunc = colors.bgBlack;

		console.log(`           ${colorFunc("██████")}   ${colorFunc("██████")}\r
       ${colorFunc("████")}               ${colorFunc("████")}\r
     ${colorFunc("███")}                     ${colorFunc("███")}\r
    ${colorFunc("███")}       ${colorFunc("█████████")}       ${colorFunc("███")}\r
   ${colorFunc("██")}                ${colorFunc("██")}         ${colorFunc("██")}\r
  ${colorFunc("██")}               ${colorFunc("██")}            ${colorFunc("██")}\r
  ${colorFunc("██")}              ${colorFunc("██")}             ${colorFunc("██")}\r
  ${colorFunc("██")}             ${colorFunc("██")}              ${colorFunc("██")}\r
  ${colorFunc("██")}            ${colorFunc("██")}               ${colorFunc("██")}\r
   ${colorFunc("██")}         ${colorFunc("██")}                ${colorFunc("██")}\r
    ${colorFunc("███")}       ${colorFunc("█████████")}       ${colorFunc("███")}\r
     ${colorFunc("███")}                     ${colorFunc("███")}\r
       ${colorFunc("████")}               ${colorFunc("████")}\r
           ${colorFunc("██████")}   ${colorFunc("██████")}\r\n\n`);
	}
};
