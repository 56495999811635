import { computed } from "vue";

export default class {
	constructor({ logger, modelBuilder, api, authentication }) {
		this.logger = logger.nested({ name: "Model" });
		this.modelBuilder = modelBuilder;
		this.api = api;
		this.authentication = authentication;
	}

	execute({ tagId, ignoreTagIds = [] } = {}) {
		return this.modelBuilder.build({
			name: "GetTag",
			args: { isSignedIn: computed(() => this.authentication.isAuthenticated.value), tagId, ignoreTagIds },
			execute: async ({ args }) => {
				const hasValidTagId = args.tagId && !args.ignoreTagIds.includes(args.tagId);
				const tag = hasValidTagId
					? await this.api.queries.getTag({ tagId: args.tagId }, { useClientCache: true, accessToken: args.isSignedIn ? this.authentication.getAccessToken : undefined })
					: null;
				return { tag };
			},
		});
	}
}
