export default {
	deepLinkSubscribeAlreadyPremiumRedirecting: {
		level: "info",
		message: ({ product }) => `Deep-Link premium subscribe to product ${product}. Already premium, redirecting`,
	},
	deepLinkSubscribeToPremiumProduct: {
		level: "info",
		message: ({ product }) => `Deep-Link premium subscribe to product ${product}`,
	},
	deepLinkSubscribeToPremiumProductNotSignedInCreatingAccount: {
		level: "info",
		message: ({ product }) => `Deep-Link premium subscribe to product ${product}. Not signed in, creating account`,
	},
};
