<template>
	<dialog ref="dialogRef" :class="dialogClasses" @click="dialogClick" @keydown.enter.stop.prevent="handleEnter" @keydown.escape.stop.prevent="handleEscape">
		<slot v-bind="{ isOpen: dialog.isOpen, closeDialog: close }" />
		<!-- <div v-if="hasHeader" class="header">
			<CloseButton v-if="props.hasCloseButton" :show-light="props.showLightCloseButton" @click="close" />
			<h2 v-if="props.title">{{ props.title }}</h2>
		</div>
		<CloseButton v-else-if="props.hasCloseButton" :show-light="props.showLightCloseButton" @click="close" />
		<div :class="{ 'dialog-inner': true, 'padding': props.padding }">
			<slot v-bind="{ isOpen: dialog.isOpen }" />
		</div> -->
	</dialog>
</template>

<script setup>
import { computed, markRaw, nextTick, provide, reactive, ref, toRef, watch } from "vue";

import { ExternallyResolvablePromise, useAnimatedDialog } from "../helpers/index.js";
import { useQueryString } from "../functions/query-string/index.js";

// import CloseButton from "./CloseButton.vue";

const props = defineProps({
	enterName: {
		type: String,
		default: "enter",
	},
	escapeName: {
		type: String,
		default: "escape",
	},
	paramName: {
		type: String,
		default: null,
	},
	padding: {
		type: Boolean,
		default: true,
	},
	fullScreen: {
		type: Boolean,
		default: false,
	},
	enterDirection: {
		type: String,
		default: "left",
		validator: (value) => ["left", "right"].includes(value),
	},
	// hasCloseButton: {
	// 	type: Boolean,
	// 	default: false,
	// },
	// showLightCloseButton: {
	// 	type: Boolean,
	// 	default: false,
	// },
	// title: {
	// 	type: String,
	// 	default: null,
	// },
	// handleCloseEvent: {
	// 	type: Boolean,
	// 	default: false,
	// },
});

const emit = defineEmits(["closed"]);

const queryString = useQueryString({ [props.paramName]: null });

const dialogRef = ref(null);
const modalPromise = ref(null);

const dialog = useAnimatedDialog(dialogRef, {
	animationEndHandler() {
		dialogRef.value?.close();
	},
	closeHandler(...args) {
		if (props.paramName) {
			queryString.remove(props.paramName);
		}
		try {
			modalPromise.value?.resolve(...args);
		} catch (error) {
			modalPromise.value?.reject(error);
		} finally {
			emit("closed", ...args);
			modalPromise.value = null;
		}
	},
});

// const hasHeader = computed(() => props.title);
const showDialog = computed(() => (props.paramName ? queryString[props.paramName] ?? "" !== "" : true));
const dialogClasses = computed(() => ({ "dialog-new": true, "full-screen": props.fullScreen, [props.enterDirection]: true, ...dialog.cssClasses }));

watch(
	[queryString, dialogRef],
	() => {
		if (dialogRef.value && props.paramName) {
			if (showDialog.value && !dialog.isOpen) {
				/* show modal on nexttick to ensure the dialog has been mounted to document and avoid "Failed to execute 'showModal' on 'HTMLDialogElement': The element is not in a document." */
				nextTick(() => {
					/* make sure we check again if dialog ref is still available */
					if (dialogRef.value) {
						dialog.open();
					}
				});
			} else if (!showDialog.value && dialog.isOpen) {
				close();
			}
		}
	},
	{ immediate: true },
);

const externalInterface = reactive({
	open: markRaw(open),
	close: markRaw(close),
	toggle: markRaw(toggle),
	isOpen: toRef(dialog, "isOpen"),
});

provide("modal", externalInterface);
defineExpose(externalInterface);

async function open(value) {
	if (props.paramName) {
		queryString.set(props.paramName, value ?? "true");
	} else {
		if (modalPromise.value === null) {
			modalPromise.value = new ExternallyResolvablePromise();
			dialog.open();
		}
		const args = await modalPromise.value;
		return args;
	}
}

async function close(...args) {
	dialog.close(...args);
	await modalPromise.value;
}

function toggle() {
	if (dialog.isOpen) {
		return close();
	} else {
		return open();
	}
}

function handleEnter() {
	close({ name: props.enterName });
}

function handleEscape() {
	close({ name: props.escapeName });
}

function dialogClick(e) {
	const target = e.target ?? e.currentTarget;
	if (target === dialogRef.value) {
		const backdrop = getComputedStyle(target, "::backdrop");
		if (backdrop) {
			const wasBackdropClicked = didMouseIntersect(
				{ x: e.clientX, y: e.clientY },
				{
					top: parseFloat(backdrop.top.replace("px", "")),
					left: parseFloat(backdrop.right.replace("px", "")),
					width: parseFloat(backdrop.width.replace("px", "")),
					height: parseFloat(backdrop.height.replace("px", "")),
				},
			);
			const wasTargetClicked = didMouseIntersect({ x: e.clientX, y: e.clientY }, target.getBoundingClientRect());
			if (wasBackdropClicked && !wasTargetClicked) {
				e.stopPropagation();
				close({ name: props.escapeName });
			}
		}
	}
}

function didMouseIntersect({ x, y } = {}, { top, left, width, height } = {}) {
	return x >= left && x <= left + width && y >= top && y <= top + height;
}
</script>

<style scoped lang="scss">
@import "../assets/styles/variables_new.scss";

.dialog-new {
	animation: slideInLeft 0.3s ease normal;
	-webkit-user-select: none; /* Safari */
	-ms-user-select: none; /* IE 10 and IE 11 */
	user-select: none; /* Standard syntax */
	flex-direction: column;
	// justify-content: center;
	min-width: min(300px, 90vw);
	max-height: 80dvh;
	border-radius: $border-radius-primary;
	border: none;
	padding: 0;
	@include drop-shadow($opacity: 0.2);
	z-index: 1000;
	overflow: auto;

	&[open] {
		display: flex;
	}

	&.full-screen {
		margin: 0;
		width: 100vw;
		max-width: none;
		height: 100dvh;
		max-height: none;
	}

	&::backdrop {
		animation: fadeIn 0.3s ease normal;
		background-color: $background-color-modal;
	}

	&.close {
		animation: slideOutLeft 0.3s ease normal;

		&::backdrop {
			animation: fadeOut 0.3s ease normal;
		}
	}

	&.right {
		animation-name: slideInRight;

		&.close {
			animation-name: slideOutRight;
		}
	}

	&:focus {
		outline: none;
	}

	// &:has(.header) {
	// 	padding-top: 0;
	// }

	.header {
		position: relative;
		display: flex;
		border-bottom: $border-size-primary solid $border-color-primary;
		padding: calc($spacing / 2) 0;
		min-height: 22px;

		// a {
		// 	position: absolute;
		// 	font-size: 1.5rem;
		// 	font-weight: 500;
		// 	line-height: 1.4rem;
		// 	padding: 0 $spacing;
		// }

		h2 {
			width: 100vw;
			margin: 0;
			text-align: center;
			font-size: $text-size-header-secondary;
		}
	}

	.dialog-inner {
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		// align-items: center;
		gap: calc($spacing);
		// width: 100%;
		// height: 100%;
		mix-blend-mode: normal;

		box-sizing: border-box;
		overflow: auto;

		&.padding {
			padding: 0 calc($spacing / 2);
			margin: calc($spacing * 1.25);
		}
	}
}

@media (min-width: $bp-medium) {
	.dialog-new {
		animation-name: fadeIn;
		// padding: $spacing;
		box-sizing: border-box;

		&.full-screen {
			max-width: 400px;
			max-height: 80vh;
			margin: auto;
		}

		> :deep(.dialog-fullscreen-page-layout) {
			// flex-grow: 1;
			// margin-bottom: 0;
			// border: none;
		}

		&.close {
			animation-name: fadeOut;
		}
	}
}
</style>
