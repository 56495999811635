import { computed, ref } from "vue";

import { EnvironmentDetailsLoadingError } from "../errors/EnvironmentDetailsLoadingError.js";
import loggingMessages from "./Environment.logging-messages.js";
import { AdminFeatureSwitchNames } from "../constants/AdminFeatureSwitchNames.js";

export class Environment {
	constructor({
		logger,
		platform,
		appConfig,
		config,
		authenticationManager,
		tracking,
		name,
		model,
		api,
		changeManager,
		authentication,
		subscriptionManager,
		logging,
		persistentQueries,
		bookingStageStrategyFactoryDefault,
		bookingStageStrategyFactories,
	} = {}) {
		this.logger = logger.nested({ name: "Environment" });
		this.platform = platform;
		this.appConfig = appConfig;
		this.config = config;
		this.authenticationManager = authenticationManager;
		this.tracking = tracking;
		this.name = name;
		this.model = model;
		this.api = api;
		this.changeManager = changeManager;
		this.authentication = authentication;
		this.subscriptionManager = subscriptionManager;
		this.logging = logging;
		this.persistentQueries = persistentQueries;
		this.bookingStageStrategyFactoryDefault = bookingStageStrategyFactoryDefault;
		this.bookingStageStrategyFactories = bookingStageStrategyFactories;

		this.isInitialised = false;

		this.apiVersion = ref(null);
		this.loggedInUser = ref(null);
		this.activeUser = ref(null);
		this.isAdmin = computed(() => {
			return this.loggedInUser.value?.role === "ADMIN" ?? false;
		});
		this.isAuthenticated = computed(() => {
			return this.authentication?.isAuthenticated.value ?? false;
		});
		this.hasUserProfile = computed(() => {
			return this.loggedInUser.value !== null;
		});
		this.isSignedIn = computed(() => {
			return this.isAuthenticated.value && this.hasUserProfile.value;
		});
	}

	getPermission({ namespace, name }) {
		if (!this.loggedInUser.value) {
			return null;
		}

		return this.loggedInUser.value.permissions.find((permission) => {
			return permission.namespace.toLowerCase() === namespace.toLowerCase() && permission.name.toLowerCase() === name.toLowerCase();
		});
	}

	async initialise({ handlers = {} } = {}) {
		if (this.isInitialised) {
			throw new Error(`Environment '${this.name}' is already initialised`);
		}
		this.isInitialised = true;

		this.handlers = handlers;

		const logger = this.logger;
		logger.log(loggingMessages.initialisingEnvironment, { environmentName: this.name });

		await Promise.all([
			this.#getApiInfo(),
			(async () => {
				await this.#initialiseTracking();
				if (!this.platform.isServer) {
					await this.#authenticate();
				}
			})(),
		]);

		logger.log(loggingMessages.successfullyInitialisedEnvironment, { environmentName: this.name });
	}

	async createAccount(...args) {
		await this.tracking.createAccount();
		return await this.authentication.createAccount(...args);
	}

	async login(...args) {
		await this.tracking.login();
		return await this.authentication.login(...args);
	}

	async handleRedirectCallback(url) {
		await this.authentication.handleRedirectCallback(url);
		await this.#loginUser();
		const isSignedInAndHasProfile = await this.#trackSignInAttemptResult();
		await this.#invokeOnAuthenticatedHandler();
		return isSignedInAndHasProfile;
	}

	async cancelCreateUserProfile() {
		await this.tracking.cancelCreateUserProfile();
		await this.authentication.logout();
	}

	async logout() {
		await this.tracking.logout();
		await this.authentication.logout();
	}

	async #initialiseTracking() {
		await this.tracking?.reset();
		await this.tracking?.init(this.name, this.config);
	}

	async #authenticate() {
		this.api.clearCache();
		await this.authentication.init();
		await this.#loginUser();
	}

	async #loginUser() {
		try {
			if (this.authentication.isAuthenticated.value) {
				const results = await this.api.queries.getCurrentUser({}, { accessToken: this.authentication.getAccessToken, shouldImpersonate: false });
				const user = results?.me;
				this.loggedInUser.value = user;
				const userId = user?.id;
				if (userId) {
					this.logging.identifyUser({ user });
					await this.tracking.identifyUser({ user });
					await this.subscriptionManager.init({ authentication: this.authentication });
				}
				const query = this.persistentQueries.getQuery() ?? {};
				if (this.isAdmin.value) {
					this.logger.log(loggingMessages.userIsAdmin, { user });
					if (query[AdminFeatureSwitchNames.IMPERSONATE]) {
						const userModel = await this.api.queries.getCurrentUser({}, { accessToken: this.authentication.getAccessToken, shouldImpersonate: true });
						this.activeUser.value = userModel?.me;
						this.logger.log(loggingMessages.impersonatingUser, { user: this.activeUser.value });
					}
				}
				if (!this.activeUser.value) {
					this.activeUser.value = user;
				}
			} else {
				this.logging.ensureLoggedOut();
				await this.tracking.ensureLoggedOut();
			}
		} catch (error) {
			throw new EnvironmentDetailsLoadingError(error);
		}
	}

	async #getApiInfo() {
		try {
			const apiInfo = await this.api.queries.getApiInfo();
			this.apiVersion.value = apiInfo?.about?.packageVersion;
			this.logging.setApiEnvironment({ name: this.name, version: this.apiVersion.value });
		} catch (error) {
			throw new EnvironmentDetailsLoadingError(error);
		}
	}

	async #trackSignInAttemptResult() {
		const isSignedInAndHasProfile = this.isAuthenticated.value && this.hasUserProfile.value;
		const isSignedInAndDoesNotHaveProfile = this.isAuthenticated.value && !this.hasUserProfile.value;

		if (isSignedInAndHasProfile) {
			await this.tracking.successfulSignIn();
		} else if (isSignedInAndDoesNotHaveProfile) {
			await this.tracking.successfulAuthenticationCreatingAccount();
		} else {
			await this.tracking.failedSignIn();
		}

		return isSignedInAndHasProfile;
	}

	async #invokeOnAuthenticatedHandler() {
		if (this.handlers?.onAuthenticated) {
			await this.handlers.onAuthenticated({ isAuthenticated: this.isAuthenticated.value, hasUserProfile: this.hasUserProfile.value });
		}
	}
}
