<template>
	<div class="breadcrumb-separator">&gt;</div>
</template>

<style lang="scss" scoped>
@import "../assets/styles/variables_new.scss";
.breadcrumb-separator {
	width: calc($spacing * 1);
	flex-shrink: 1;
	text-decoration: none;
	overflow: visible;
	text-align: center;
	padding-left: 0;
	padding-right: 0;
}
</style>
