import { EntityTypeNames } from "../EntityTypeNames.js";
import { VenueCountPropertyNameLookup, UserListDatabaseNamesLookup, GlobalChangeSets } from "../../constants/index.js";

export default class {
	constructor({ changeManager, entityStore }) {
		this.changeManager = changeManager;
		this.entityStore = entityStore;
	}

	add({ venue, cityId, list }) {
		const listName = UserListDatabaseNamesLookup[list.name];
		const data = { venueId: venue.id, cityId, listId: list.id, listName };
		if (!this.changeManager.removeChangeFromQueue({ typeName: "RemoveVenueFromUserNamedList", data }, { activeOnly: true })) {
			this.changeManager.addChangeToQueue({ typeName: this.name, data, args: { venue } }, { changeSet: GlobalChangeSets.UserLists });
		}
	}

	getChangeEffects(change) {
		return [
			{
				typeName: EntityTypeNames.List,
				id: change.data.listId,
				getEffects: () => {
					return [
						{
							type: "insertAtStart",
							key: "items",
							value: { id: change.data.venueId, entity: { venue: change.args.venue } },
						},
					];
				},
			},
			{
				typeName: EntityTypeNames.Venue,
				id: change.data.venueId,
				getEffects: (venue) => {
					const countPropertyName = VenueCountPropertyNameLookup[change.data.listName];
					const effects = [];
					if (countPropertyName) {
						effects.push({
							type: "set",
							key: countPropertyName,
							value: venue[countPropertyName] + 1,
						});
					}
					return effects;
				},
			},
		];
	}
}
