<template>
	<NewList
		v-slot="{ item, isItemVisible }"
		class="home-feed"
		list-class="home-feed-list"
		item-class="home-feed-item"
		:name="listName"
		:query="props.query"
		:items="isShowAdsFeatureSwitchEnabled ? feedListsWithAds : feedListsOriginal"
		:direction="ListDirections.VERTICAL"
		:item-height="250"
		:gap-size="28"
		:item-buffer-count="2"
		:enable-pull-to-refresh="true"
		:enable-load-more="true"
		:is-loading-more="props.query.isLoading && (props.query.state?.loading ?? LOADING_STATE.LOAD_INITIAL) === LOADING_STATE.LOAD_MORE"
		:show-debug-panel="false"
		@refresh="onRefresh"
		@load-more="onLoadMore"
	>
		<KeepAlive>
			<div v-if="isItemVisible && item.isAd" style="width: 100%; min-height: 100px">
				<ins class="adsbygoogle" style="display: block" data-ad-format="fluid" data-ad-layout-key="-gm+2+3u-bn+76" data-ad-client="ca-pub-2578556753064062" data-ad-slot="8371025905"></ins>
				<component :is="'script'"> (adsbygoogle = window.adsbygoogle || []).push({}); </component>
			</div>
			<FeedListNew v-else-if="isItemVisible" :key="`feed-list:${item.id}`" :feed-list="item" />
		</KeepAlive>
	</NewList>
	<!-- <EfficientList v-slot="{ item: feedList, style }" class="home-feed" :items="feedLists" :item-size="250" :buffer="2" :recycle="true">
		<FeedListNew :feed-list="feedList" :style="style" />
	</EfficientList> -->
</template>

<script setup>
import { computed, inject } from "vue";

import { ListDirections } from "./IntersectionObserverList/index.js";
import { LOADING_STATE } from "../constants/index.js";
import { FeatureSwitchNames } from "../domain/index.js";

import NewList from "./NewList.vue";
import FeedListNew from "./FeedListNew.vue";
// import EfficientList from "./EfficientList/EfficientList.vue";

const ADS_EVERY_NTH_ITEM = 5;

const props = defineProps({
	cityName: {
		type: String,
		required: true,
	},
	feedLists: {
		type: Array,
		required: true,
	},
	query: {
		type: Object,
		required: true,
	},
});

const emits = defineEmits(["refresh", "loadMore"]);

const featureSwitchManager = inject("featureSwitchManager");

const listName = computed(() => `home-feed:${props.cityName}`);
const isShowAdsFeatureSwitchEnabled = computed(() => featureSwitchManager.isActive(FeatureSwitchNames.ShowAds));
const feedListsOriginal = computed(() => props.feedLists);
const feedListsWithAds = computed(() =>
	feedListsOriginal.value.reduce((acc, feedList, index) => {
		acc.push(feedList);
		if ((index + 1) % ADS_EVERY_NTH_ITEM === 0) {
			acc.push({ isAd: true, id: `ads-${index}` });
		}
		return acc;
	}, []),
);

function onRefresh() {
	emits("refresh");
}

function onLoadMore() {
	emits("loadMore");
}
</script>

<style scoped lang="scss">
@import "../assets/styles/variables_new.scss";

.adsbygoogle {
	width: 100%;
}

.home-feed {
	width: 100%;
	// @include fade-out-content;

	:deep(.home-feed-list) {
		padding: 0;
	}
}
</style>
