export default {
	userAuthenticatedAndHasProfileRedirectingToPreviousPage: {
		level: "info",
		message: ({ url }) => `User authenticated and has profile, redirecting to previous page ${url}`,
	},
	userAuthenticatedAndHasProfileRedirectingToHomePage: {
		level: "info",
		message: () => `User authenticated and has profile, redirecting to Home Page`,
	},
	userAuthenticatedButDoesNotHaveProfileRedirectingToCreateProfile: {
		level: "info",
		message: () => `User authenticated but does not have profile, redirecting to Create Profile page...`,
	},
	handlingLoginCallback: {
		level: "info",
		message: ({ url }) => `Handling auth0 login callback for url ${url}`,
	},
	branchInitialisationError: {
		level: "error",
		message: ({ error }) => `Error initialising branch: ${error}`,
	},
};
