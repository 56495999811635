import { ErrorNested } from "./ErrorNested.js";

export class AccessTokenRetrievalError extends ErrorNested {
	constructor(error, extensions) {
		super("AccessTokenRetrievalError", `Error whilst attempting to retrieve access token from auth0`, {
			exception: error,
			code: "AUTHENTICATION_ACCESS_TOKEN_RETRIEVAL_ERROR",
			...extensions,
		});
	}
}
