<template>
	<ul class="venue-navigation">
		<li class="menu">
			<a v-if="menuUrl" v-external-link :href="menuUrl" target="_blank" class="navigation-item push-button-primary">Menu</a>
			<span v-else class="disabled navigation-item push-button-primary">No Menu</span>
		</li>
		<!-- <li>
			<a v-if="directionsUrl" :href="directionsUrl" target="_blank" class="navigation-item push-button-primary">Directions</a>
			<span v-else class="disabled navigation-item push-button-primary">Directions</span>
		</li> -->
		<li class="book">
			<div v-if="!canBook && suggestedUserAction === SuggestedUserAction.UPGRADE_PREMIUM" class="upgrade navigation-item push-button-primary" @click="bookBlockedOnClick">Upgrade To Book</div>
			<div v-else-if="!canBook && suggestedUserAction === SuggestedUserAction.SIGN_IN" class="upgrade navigation-item push-button-call-to-action" @click="bookBlockedOnClick">Book</div>
			<span v-else-if="!canBook && suggestedUserAction === SuggestedUserAction.WAIT" class="disabled navigation-item push-button-primary">Coming Soon</span>
			<a v-else-if="bookingMethod" v-external-link="{ ...bookingMethod }" :href="bookingMethod.href" :class="{ 'navigation-item': true, ...(bookingMethod.class ?? {}) }">
				<img v-if="bookingMethod.showIcon" :src="onezoneLogo" class="onezone-logo" />
				{{ bookingMethod.label }}
			</a>
			<span v-else class="disabled navigation-item push-button-primary">No Booking</span>
		</li>
	</ul>
</template>

<script setup>
import { computed, inject, reactive } from "vue";

import { PremiumFeature, SuggestedUserAction, BookingMethods, VenueType } from "../../constants/index.js";
import { safeHapticsVibrate } from "../../helpers/index.js";
// import { getDirectionsUrl } from "../../helpers/index.js";

import onezoneLogo from "../../assets/icons/logo-black-background.svg";

const BOOKING_METHODS = reactive({
	[BookingMethods.IN_APP]: {
		label: computed(() => VENUE_TYPE_BOOK_LABEL_LOOKUP[venueType.value]),
		clickHandler: () => {
			safeHapticsVibrate({ duration: 20 });
			venueBookingDialogRef.value.open({
				venueId: props.venue.id,
			});
		},
		class: { "push-button-call-to-action": true },
		showIcon: true,
	},
	[BookingMethods.URL]: {
		label: computed(() => VENUE_TYPE_BOOK_LABEL_LOOKUP[venueType.value]),
		href: computed(() => props.venue.bookingUrl ?? null),
		clickHandler: async () => {
			await tracking.venueBookPressed(props.venue, BookingMethods.URL);
		},
		class: { "push-button-call-to-action": true },
	},
	// [BookingMethods.PHONE]: {
	// 	label: "Call to Book",
	// 	href: computed(() => (props.venue.contactNumber ? `tel:${props.venue.contactNumber}` : null)),
	// 	openWindow: false,
	// 	clickHandler: async () => {
	// 		await tracking.venueBookPressed(props.venue, BookingMethods.PHONE);
	// 	},
	// 	class: { "push-button-primary": true },
	// },
});

const VENUE_TYPE_TAG_LOOKUP = {
	Hotel: VenueType.HOTEL,
	Spa: VenueType.SPA,
};

const VENUE_TYPE_BOOK_LABEL_LOOKUP = {
	[VenueType.RESTAURANT]: "Book A Table",
	[VenueType.HOTEL]: "Book A Room",
	[VenueType.SPA]: "Book A Treatment",
};

const props = defineProps({
	venue: {
		type: Object,
		required: true,
	},
});

const userActionDialogRef = inject("userActionDialogRef");
const tracking = inject("tracking");
const venueBookingDialogRef = inject("venueBookingDialogRef");
// const safeArea = inject("safeArea");

const menuUrl = computed(() => filterEmptyMenuUrlValues(props.venue.menuUrl) ?? null);
// const directionsUrl = computed(() => getDirectionsUrl(props.venue));
const canBook = computed(() => props.venue.book.canBook);
const suggestedUserAction = computed(() => props.venue.book.action);
const bookingMethod = computed(() => BOOKING_METHODS[props.venue.book.bookingMethod]);
const venueType = computed(() => Object.entries(VENUE_TYPE_TAG_LOOKUP).find(([key]) => props.venue.tags?.some((tag) => tag.name === key))?.[1] ?? VenueType.RESTAURANT);
// const isInAppBooking = computed(() => props.venue?.book.bookingMethod === BookingMethods.IN_APP);
// const listStyle = { padding: `${safeArea.insets?.bottom / 2}px 0` };

function bookBlockedOnClick() {
	userActionDialogRef.value.open({ feature: PremiumFeature.LAUNCH, userAction: suggestedUserAction.value });
}

function filterEmptyMenuUrlValues(url) {
	return url === "" || ["no menu", "not available"].some((value) => url?.toLowerCase().includes(value)) ? null : url;
}
</script>

<style lang="scss" scoped>
@import "../../assets/styles/variables_new.scss";

.venue-navigation {
	// width: 100%;

	box-sizing: content-box;
	display: flex;
	flex-direction: row;
	gap: calc($spacing * 1);
	justify-content: center;
	padding: calc($spacing / 1.5) calc($spacing * 1.3) 0 calc($spacing * 1.3);
	margin: 0;
	list-style: none;
	border-top: $border-size-primary solid $border-color-primary;

	li {
		height: 53px; /* fix to this height to match main navigation */
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		// max-width: 200px;
		// margin-top: calc($spacing * 1.5);

		box-sizing: content-box;
		font-size: $text-size-header-secondary;
		color: $text-color-primary;
		font-weight: $text-bold-secondary;

		.navigation-item {
			display: flex;
			flex-grow: 1;
			justify-content: center;
			gap: calc($spacing / 2);
			// border-right: $border-size-primary solid $border-color-primary;
			// border: $border-size-primary solid $border-color-primary;
			// border-radius: $border-radius-quaternary;
			padding: calc($spacing) 0;

			> * {
				text-align: center;
			}

			.onezone-logo {
				height: 18px;
			}
		}

		&.menu {
			flex: 1;
		}
		&.book {
			flex: 2;
		}
	}

	.upgrade {
		cursor: pointer;
	}

	.disabled {
		color: $text-color-secondary;
	}
}

@media (min-width: $bp-medium) {
	.venue-navigation {
		padding: 0;
		li {
			height: auto;
			margin: 0;
		}
	}
}
</style>
