import graphqlMutations from "./mutations/index.js";
import loggingMessages from "./MutationProcessorService.logging-messages.js";

export class MutationProcessorService {
	constructor({ logger, queryStore, entityProcessorService }) {
		this.logger = logger.nested({ name: "MutationProcessorService" });
		this.queryStore = queryStore;
		this.entityProcessorService = entityProcessorService;

		this.mutations = this.generateMutations();
	}

	generateMutations() {
		return graphqlMutations.map(({ name, mutation, config }) => {
			const mutationProcessor = async ({ data, variables }) => {
				const summary = {};
				const processedMutation = config?.process
					? await config.process({ queryStore: this.queryStore, entityProcessorService: this.entityProcessorService, data, variables, summary })
					: this.entityProcessorService.processRawData(data, summary);

				this.logger.log(loggingMessages.processedMutation, { operationName: name }, summary);

				return processedMutation;
			};

			return {
				name,
				mutation,
				mutationProcessor,
			};
		});
	}
}
