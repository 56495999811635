<template>
	<label class="form-field-label"><slot /></label>
</template>

<style scoped lang="scss">
@import "../../assets/styles/variables_new.scss";

.form-field-label {
	font-size: $text-size-secondary;
	font-weight: $text-bold-secondary;
}
</style>
