import { EntityTypeNames } from "../../constants/index.js";

export default class CitySearchResultsListsProcessor {
	static doesSupportType(typeName) {
		return typeName === EntityTypeNames.CitySearchResultsLists;
	}

	constructor({ entityStore }) {
		this.entityStore = entityStore;
	}

	process() {
		return false;
	}
}
