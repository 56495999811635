<template>
	<div class="create-profile-page">
		<Dialog v-if="isAuthenticated" :dialog="loginDialog">
			<DialogLayoutFullscreen :dialog="loginDialog" class="create-profile-dialog-layout" :has-safe-area="false" :is-full-screen-mobile="false" :has-close-button="false">
				<div class="create-profile-container">
					<div class="image-container">
						<ImgIxWithPlaceholder
							src="https://onezone.imgix.net/venue_image_cky8tf31izlq20a985v34p2ql_1688140030564"
							sizes="400px"
							:widths="[400, 800, 1200, 1600]"
							:aspect-ratio="0.74"
							:is-lazy="false"
							class="image"
						/>
					</div>
					<LoadingMessage v-if="isProcessing" class="processing-loading-message">Please wait...</LoadingMessage>
					<div v-else-if="!hasUserProfile" class="create-profile-form">
						<img src="../assets/onezone-logo.png" class="logo" />
						<div class="intro-text">Complete your profile</div>
						<div class="fields">
							<EntityForm ref="entityFormRef" v-slot="{ bind, on }" :entity="state" :fields-config="fieldsConfig" :type-name="EntityTypeNames.User" @save="createProfileOnSave">
								<FormLayoutSimple v-bind="bind" :show-labels="false" v-on="on" />
							</EntityForm>
							<div class="cancel">
								<span>or</span>
								<a href="#" @click="cancelCreateUserProfileOnClick">cancel</a>
							</div>
						</div>
					</div>
				</div>
			</DialogLayoutFullscreen>
		</Dialog>
		<div v-else>Cannot create profile, user not signed in.</div>
	</div>
</template>

<script setup>
import { computed, inject, onActivated, onDeactivated, onUnmounted, reactive, ref, unref, watch } from "vue";
import { useEventListener } from "@vueuse/core";

import { Dialog, useDialog } from "../components/dialog/useDialog.js";
import { EntityTypeNames } from "../constants/index.js";
import loggingMessages from "./CreateProfile.logging-messages.js";

import DialogLayoutFullscreen from "../components/dialog/DialogLayoutFullscreen.vue";
import ImgIxWithPlaceholder from "../components/imgix/ImgIxWithPlaceholder.vue";
import EntityForm from "../components/forms/EntityForm.vue";
import FormLayoutSimple from "../components/forms/FormLayoutSimple.vue";

const loginDialog = useDialog({ isOpen: true });

const logger = inject("logger").nested({ name: "CreateProfile" });
const currentEnvironment = inject("currentEnvironment");

const fieldsConfig = { profileImage: { isHidden: true }, email: { isHidden: true } };

const state = reactive({
	username: "",
	firstName: "",
	lastName: "",
});
const entityFormRef = ref(null);
const isProcessing = ref(false);

const isAuthenticated = computed(() => currentEnvironment.value?.isAuthenticated.value);
const hasUserProfile = computed(() => currentEnvironment.value.hasUserProfile.value);
const auth0Profile = computed(() => currentEnvironment.value.authentication.auth0User);

watch(
	auth0Profile,
	() => {
		const { nickname, name, sub } = auth0Profile.value ?? {};
		const provider = sub?.split("|")[0];
		const isDatabaseUser = provider === "auth0";
		/* WHY: When the user is a database user, the name is the email and the nickname is first part of email, which doesn't make sense for us to use */
		if (!isDatabaseUser) {
			state.username = nickname;
			const nameParts = name?.split(" ") ?? [];
			state.firstName = nameParts[0];
			state.lastName = nameParts.slice(1).join(" ");
		}
	},
	{ immediate: true },
);

useEventListener(window, "pageshow", (event) => {
	if (event.persisted) {
		isProcessing.value = false;
	}
});

onActivated(() => {
	loginDialog.open();
});

onDeactivated(() => {
	loginDialog.close();
});

onUnmounted(() => {
	loginDialog.close();
});

async function cancelCreateUserProfileOnClick() {
	state.username = "";
	state.firstName = "";
	state.lastName = "";
	unref(entityFormRef).validation.$reset();
	await currentEnvironment.value.cancelCreateUserProfile();
}

async function createProfileOnSave() {
	logger.log(loggingMessages.userProfileCreatedReInitialisingEnvironment, { environmentName: currentEnvironment.value.name });
	window.location.reload();
}
</script>

<style scoped lang="scss">
@import "../assets/styles/variables_new.scss";

.create-profile-page {
	height: 100%;
}

.create-profile-dialog-layout {
	width: 100vw;
	max-width: 100vw;
	height: 100dvh;
	max-height: 100dvh;
	box-shadow: none;
	background-color: $background-color-primary;

	:deep(.create-profile-container) {
		height: 100%;

		.image-container {
			display: none;
		}

		.processing-loading-message,
		.create-profile-form {
			height: 100%;
			display: flex;
			gap: calc($spacing * 1.5);
			flex-direction: column;
			justify-content: center;
			align-items: center;

			.logo {
				width: 180px;
			}

			.intro-text {
				font-size: $text-size-primary;
				font-weight: $text-bold-tertiary;
			}
		}

		.create-profile-form {
			.server-error-message {
				color: $text-color-error;
				font-size: $text-size-secondary;
				text-align: center;
				margin: 0 calc($spacing * 2);
			}
			.fields {
				display: flex;
				flex-direction: column;
				align-items: center;
				gap: calc($spacing / 2);

				.cancel {
					color: $text-color-secondary;
					font-size: $text-size-tertiary;
					display: flex;
					gap: calc($spacing/4);

					> a {
						color: $text-color-secondary;
						font-size: $text-size-tertiary;
					}
				}
			}
		}
	}
}

@media (min-width: $bp-medium) {
	.create-profile-page {
		background-image: url("../assets/home-feed-screenshot.png");
		background-size: 1008px auto;
		background-position: 5px top;
		background-repeat: no-repeat;
		image-rendering: pixelated;
		filter: blur(4px);
		-webkit-filter: blur(4px);
	}

	.create-profile-dialog-layout {
		max-width: min(800px, 90vw) !important;
		max-height: min(500px, 90dvh) !important;
		@include drop-shadow($opacity: 0.2);

		:deep(.content) {
			padding: 0 !important;
		}

		:deep(.create-profile-container) {
			display: flex;
			height: 100%;

			.image-container {
				display: block;
				width: 50%;
				overflow: hidden;
			}

			.processing-loading-message,
			.create-profile-form {
				flex-shrink: 0;
				width: 50%;
				min-width: 400px;
			}
		}
	}
}
</style>
