<template>
	<div :class="{ 'search-context-label': true, 'has-drop-shadow': props.hasDropShadow, 'invert-colors': props.invertColors, 'has-remove-button': props.hasRemoveButton }" @click="removeButtonClick">
		<div class="text"><slot /></div>
		<img :src="closeButtonIcon" draggable="false" class="close-button" />
	</div>
</template>

<script setup>
import { computed } from "vue";

import RemoveButtonWhite from "../assets/icons/font-awesome/circle-xmark-solid-white.svg";
import RemoveButtonBlack from "../assets/icons/font-awesome/circle-xmark-solid.svg";

const props = defineProps({
	hasRemoveButton: {
		type: Boolean,
		default: true,
	},
	hasDropShadow: {
		type: Boolean,
		default: false,
	},
	invertColors: {
		type: Boolean,
		default: false,
	},
});

const emit = defineEmits(["remove"]);

const closeButtonIcon = computed(() => (props.invertColors ? RemoveButtonWhite : RemoveButtonBlack));

const removeButtonClick = () => {
	if (props.hasRemoveButton) {
		emit("remove");
	}
};
</script>

<style scoped lang="scss">
@import "../assets/styles/variables_new.scss";

.search-context-label {
	// position: relative;
	display: flex;
	gap: calc($spacing / 2);
	align-items: center;
	background-color: $background-color-primary;
	border-radius: $border-radius-quinary;
	border: $border-size-primary $border-color-primary solid;
	padding: calc($spacing / 2) $spacing;
	box-sizing: border-box;
	font-size: $text-size-tertiary;
	font-weight: $text-bold-secondary;
	color: $text-color-primary;
	// width: 100%;
	height: 28px;

	&.invert-colors {
		background-color: $text-color-primary;
		color: $background-color-primary;
		border-color: $text-color-primary;

		.close-button {
			color: $background-color-primary;
		}
	}

	&.has-remove-button {
		cursor: pointer;

		.close-button {
			display: block;
		}
	}

	.text {
		display: flex;
		gap: calc($spacing / 2);
		width: 100%;
		@include text-ellipsis;
	}

	&.has-drop-shadow {
		@include drop-shadow-primary;
	}

	.close-button {
		display: none;
		height: 14px;
		margin-right: 0 - calc($spacing / 2);
		color: $text-color-tertiary;
	}
}
</style>
