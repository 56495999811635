export default {
	deviceReadyEventTriggered: {
		level: "verbose",
		message: "Device ready event triggered",
	},
	addListenerAfterDeviceReadyEventTriggered: {
		level: "verbose",
		message: "Device ready event has already triggered, adding listener immediately",
	},
	addListenerBeforeDeviceReadyEventTriggered: {
		level: "verbose",
		message: "Device ready event has not yet triggered, adding listener to queue",
	},
};
