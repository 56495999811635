import { reactiveComputed } from "@vueuse/core";

import loggingMessages from "./CurrentEnvironmentDecorator.logging-messages.js";

export class CurrentEnvironmentDecorator {
	constructor({ logger }) {
		this.logger = logger.nested({ name: "CurrentEnvironmentDecorator" });
		// this.decoratedModelInstances = {};
	}

	decorateChangeManager(environmentManager, addChangeKeys) {
		const decoratedChangeManager = Object.fromEntries(
			addChangeKeys.map((changeType) => {
				const wrappedMethodMethod = (...args) => {
					return environmentManager.currentEnvironment.value?.changeManager.addChange[changeType].apply(environmentManager.currentEnvironment.value?.changeManager.addChange, args);
				};

				return [changeType, wrappedMethodMethod];
			}),
		);
		return decoratedChangeManager;
	}

	decorateGetStoredEntity(environmentManager) {
		const wrappedMethodMethod = (...args) => {
			return environmentManager.currentEnvironment.value?.model.getStoredEntity.apply(environmentManager.currentEnvironment.value?.model, args);
		};

		return wrappedMethodMethod;
	}

	decorateMutations(environmentManager, modelKeys) {
		const wrappedModel = Object.fromEntries(
			modelKeys.map((modelMethodName) => {
				const wrappedMethodMethod = (...args) => {
					return environmentManager.currentEnvironment.value?.model.mutations[modelMethodName].apply(environmentManager.currentEnvironment.value?.model.mutations, args);
				};

				return [modelMethodName, wrappedMethodMethod];
			}),
		);
		return wrappedModel;
	}

	decorateQueries(environmentManager, modelKeys) {
		const wrappedModel = Object.fromEntries(
			modelKeys.map((modelMethodName) => {
				let realResult = null,
					realResultEnvironment = null;

				const setEnvironment = (...args) => {
					realResultEnvironment = environmentManager.currentEnvironment.value;
					this.logger.log(loggingMessages.createModelWrapperForNewEnvironment, { modelMethodName, environmentName: realResultEnvironment?.name });
					realResult = realResultEnvironment?.model.queries[modelMethodName].apply(realResultEnvironment?.model.queries, args);
				};

				return [
					modelMethodName,
					(...args) => {
						setEnvironment(...args);

						return reactiveComputed(() => {
							if (realResultEnvironment !== environmentManager.currentEnvironment.value) {
								if (realResult) {
									/* TODO don't think this is need anymore as we pause model watchers when deactiveated */
									realResult.stopWatching();
								}
								setEnvironment(...args);
							}
							return realResult;
						});
					},
				];
			}),
		);
		return wrappedModel;
	}
}
