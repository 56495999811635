<template>
	<custom-router-link
		:enable-previous-routes="props.enablePreviousRoutes"
		:to="{ name: props.routeName, params: props.routeParams }"
		:class="{ tab: true, [props.type]: true }"
		:replace="props.replace"
	>
		<slot />
	</custom-router-link>
</template>

<script setup>
const props = defineProps({
	routeName: {
		type: String,
		required: true,
	},
	routeParams: {
		type: Object,
		required: false,
		default: () => ({}),
	},
	type: {
		type: String,
		required: false,
		default: "primary",
	},
	enablePreviousRoutes: {
		type: Boolean,
		required: false,
		default: false,
	},
	replace: {
		type: Boolean,
		default: true,
	},
});
</script>

<style lang="scss" scoped>
@import "../assets/styles/variables_new.scss";
.tab {
	display: block;
	box-sizing: border-box;
	width: 100%;
	text-align: center;
	color: $text-color-secondary;
	font-weight: $text-bold-primary;

	&.primary {
		padding: calc($spacing / 2) 0 calc($spacing/2) 0;
		border-bottom: $border-size-secondary $border-color-primary solid;

		&.router-link-active-partial-path {
			color: $text-color-primary;
			border-bottom: $border-size-secondary $border-color-secondary solid;
		}
	}

	&.secondary {
		padding: calc($spacing / 2) 0;
		font-size: $text-size-secondary;
		border: $border-size-primary $border-color-secondary solid;
		border-radius: $border-radius-primary;

		&.router-link-active-partial-path {
			background-color: $text-color-primary;
			color: $background-color-primary;
		}
	}
}
</style>
