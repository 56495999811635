<template>
	<button class="arrow">
		<img :src="iconSrc" />
	</button>
</template>

<script setup>
import { computed } from "vue";
import ChevronLeft from "../assets/icons/font-awesome/circle-chevron-left-solid.svg";
import ChevronRight from "../assets/icons/font-awesome/circle-chevron-right-solid.svg";

const props = defineProps({ direction: { type: String, required: true } });

const iconSrc = computed(() => (props.direction === "left" ? ChevronLeft : ChevronRight));
</script>

<style scoped lang="scss">
@import "../assets/styles/variables_new.scss";

.arrow {
	// position: absolute;
	// top: 50%;
	// transform: translateY(-12px);
	background-color: black;
	// padding: 1px;
	// box-sizing: border-box;
	border-radius: 50%;
	@include drop-shadow;
	width: 24px;
	height: 24px;
	transition: opacity 0.2s ease-in;
	opacity: 0.5;
	cursor: pointer;

	img {
		width: 100%;
		height: 100%;
	}
}
</style>
