export default {
	nativePlatformUrlOpenedAndMatchedPattern: {
		level: "info",
		message: ({ url, pattern }) => `✅ Native platform URL opened ${url} and matched pattern ${pattern}`,
	},
	nativePlatformBrowserClosed: {
		level: "verbose",
		message: () => `❌ Sign in/up browser closed by user`,
	},
};
