import { AppLifeCycleError } from "../errors/index.js";
import loggingMessages from "./AppLifeCycle.logging-messages.js";

export class AppLifeCycle {
	constructor({ logger }) {
		this.logger = logger.nested({ name: "AppLifeCycle" });

		this.listeners = {};
	}

	addListener(appLifeCycleStageName, listener) {
		this.logger.log(loggingMessages.addingListener, { name: appLifeCycleStageName });
		if (!this.listeners[appLifeCycleStageName]) {
			this.listeners[appLifeCycleStageName] = [];
		}
		this.listeners[appLifeCycleStageName].push(listener);
	}

	async trigger(appLifeCycleStageName) {
		this.logger.log(loggingMessages.triggeringAppLifeCycleStage, { name: appLifeCycleStageName });
		const listeners = this.listeners[appLifeCycleStageName] ?? [];
		try {
			for (const listener of listeners) {
				await listener();
			}
			this.logger.log(loggingMessages.appLifeCycleStageComplete, { name: appLifeCycleStageName });
		} catch (error) {
			throw new AppLifeCycleError(appLifeCycleStageName, error);
		}
	}
}
