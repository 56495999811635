<template>
	<div class="explore-zone-tag-container">
		<PageHeader :show-share-button="!showLoadingOrErrorMessage" :share-title="tag?.name">
			<template #breadcrumb>
				<custom-router-link :to="{ name: 'City/Explore/Areas', params: { city: routeParams.cityName } }">{{ formattedCityName }}</custom-router-link>
				<BreadcrumbSeparator />
				<custom-router-link :to="{ name: 'City/Explore/Areas/Area', params: { city: routeParams.cityName, areaId } }">{{ areaName }}</custom-router-link>
				<BreadcrumbSeparator />
				<custom-router-link :to="{ name: 'City/Explore/Zone', params: { city: routeParams.cityName, zoneId } }">{{ zoneName }}</custom-router-link>
			</template>
			<template v-if="!showLoadingOrErrorMessage">{{ tag?.name }}</template>
			<template v-if="!showLoadingOrErrorMessage" #controls>
				<VenueFilterClientSide ref="venueFilterClientSideRef" :tags="tags" :venues="sortedVenues" :exclude-tags="[tagId]" />
			</template>
		</PageHeader>
		<StandardSearchContext :align-center="false" :tags="tags" :filter-state-manager="venueFilterClientSideRef?.filterStateManager" :show-search-text="false" />
		<loading-message-with-error v-if="showLoadingOrErrorMessage" :error="query.error" :retry="query.retry" class="loading-message"> Loading, please wait... </loading-message-with-error>
		<div v-else class="explore-zone-tag">
			<SuperList
				v-slot="{ item: venue, style }"
				:name="`zone-tag-list-${zoneId}-${tag?.id}`"
				:items="venueFilterClientSideRef?.filteredVenues ?? []"
				:item-size="76"
				:is-virtual="true"
				class="explore-zone-tag-list"
			>
				<VenueCard :key="venue.id" :venue="venue" :show-mini="true" :user-lists="userLists" :style="style" />
			</SuperList>
		</div>
	</div>
</template>

<script setup>
import { inject, computed, ref, onMounted, capitalize } from "vue";

import { SpecialTagIds, SpecialTagsLookupById, SortOrder } from "../constants/index.js";
import { combineQueries, useRouteParams, sorters } from "../helpers/index.js";
// import { breakpoints } from "../domain/index.js";

import PageHeader from "./PageHeader.vue";
import VenueCard from "./VenueCard.vue";
import SuperList from "./SuperList.vue";
import VenueFilterClientSide from "./VenueFilterClientSide.vue";
import StandardSearchContext from "./StandardSearchContext.vue";

const DEFAULT_TAG_BASED_VENUE_SELECTOR = (venue) => venue.tags.find(({ id }) => routeParams.tagId === id);
const SPECIAL_TAGS = [SpecialTagIds.VIEW_ALL, SpecialTagIds.PLACES_WITH_PERKS].map((id) => SpecialTagsLookupById[id]);

const routeParams = useRouteParams();

// const isAppHydrated = inject("isAppHydrated");
const model = inject("model");
const currentEnvironment = inject("currentEnvironment");
// const { venueResults, hasResults, allQueries: allSearchQueries } = inject("searchResults");

const venueFilterClientSideRef = ref(null);

const isMounted = ref(false);
onMounted(() => {
	isMounted.value = true;
});

// const isSmallerThanMedium = breakpoints.smaller("medium");

const isSignedIn = computed(() => currentEnvironment.value?.isSignedIn.value);

const query = combineQueries({
	zoneQuery: model.queries.GetZone({
		zoneId: computed(() => routeParams.zoneId),
	}),
	tagQuery: model.queries.GetTag({
		tagId: computed(() => routeParams.tagId),
		ignoreTagIds: computed(() => SPECIAL_TAGS.map(({ id }) => id)),
	}),
	tagsQuery: model.queries.GetTags({
		cityName: computed(() => routeParams.cityName),
	}),
	userListsQuery: model.queries.GetCurrentUserLists({ isSignedIn }),
});

const formattedCityName = computed(() => capitalize(routeParams.cityName));
const areaId = computed(() => query.model?.zone?.zone.area()?.id ?? "not-present");
const areaName = computed(() => query.model?.zone?.zone.area()?.name ?? null);
const zoneId = computed(() => query.model?.zone?.zone?.id ?? "not-present");
const zoneName = computed(() => query.model?.zone?.zone?.name ?? null);
const zoneTagVenues = computed(() => query.model?.zone?.zone.venues);
const tagId = computed(() => routeParams.tagId);
const tags = computed(() => query.model?.tags ?? []);
// const venues = computed(() => (hasResults.value ? venueResults.value : zoneTagVenues.value));
const showLoadingOrErrorMessage = computed(() => query.isLoading || query.error);
const venues = computed(() => zoneTagVenues.value);
const venueSorter = computed(() => sorters.sortVenues[SortOrder.NAME]);
const sortedVenues = computed(() => {
	const venueSelector = SPECIAL_TAGS.find(({ id }) => routeParams.tagId === id)?.venueSelector ?? DEFAULT_TAG_BASED_VENUE_SELECTOR;
	return (
		venues.value
			?.filter((venue) => {
				return venue.published && venueSelector(venue);
			})
			.sort(venueSorter.value) ?? []
	);
});
const tag = computed(() => SPECIAL_TAGS.find(({ id }) => routeParams.tagId === id) ?? query.model?.tag?.tag ?? null);
const userLists = computed(() => query.model?.currentUserLists ?? null);

// if (!isAppHydrated.value) {
// 	await zoneQuery.promise;
// }
</script>

<style lang="scss" scoped>
@import "../assets/styles/variables_new.scss";

.explore-zone-tag-container {
	display: flex;
	flex-direction: column;
	height: 100%;
	gap: calc($spacing / 2);
	// padding: 0 calc($spacing * 2);
	margin-top: $spacing;
	overflow: auto;
	@include centre-loading-message;

	.explore-zone-tag {
		display: flex;
		flex-direction: column;
		gap: calc($spacing * 0.5);
		overflow: auto;
		overscroll-behavior: none;

		:deep(.super-list) {
			padding-right: calc($spacing / 2);
		}

		.explore-zone-tag-list {
			overflow: auto;
			display: flex;
			flex-direction: column;
			gap: $spacing;
			align-content: start;

			.image {
				width: 160px;
				height: 160px;
			}
		}
	}
}

@media (min-width: $bp-medium) {
	.explore-zone-tag-container {
		padding: 0;
	}
}
</style>
