export const filterVenueByFilterState = (venue, filterState) => {
	const prices = filterState?.prices ?? [];
	const isPriceMatch = prices.length > 0 ? prices.includes(venue.price.toString()) : true;

	const isTagMealTypeMatch = filterState?.mealTypeIds?.length > 0 ? filterState.mealTypeIds.some((tagId) => venue.tags?.some((tag) => tag.id === tagId)) : true;
	const isTagCuisineMatch = filterState?.cuisineIds?.length > 0 ? filterState.cuisineIds.some((tagId) => venue.tags?.some((tag) => tag.id === tagId)) : true;
	const isTagOccasionMatch = filterState?.occasionIds?.length > 0 ? filterState.occasionIds.every((tagId) => venue.tags?.some((tag) => tag.id === tagId)) : true;
	const isTagVibeMatch = filterState?.vibeIds?.length > 0 ? filterState.vibeIds.every((tagId) => venue.tags?.some((tag) => tag.id === tagId)) : true;

	return isPriceMatch && isTagMealTypeMatch && isTagCuisineMatch && isTagOccasionMatch && isTagVibeMatch;
};
