import { EntityTypeNames } from "../EntityTypeNames.js";

export default class CitySearchResultsVenuesProcessor {
	static doesSupportType(typeName) {
		return typeName === EntityTypeNames.CitySearchResultsVenues;
	}

	constructor({ entityStore }) {
		this.entityStore = entityStore;
	}

	process() {
		return false;
	}
}
