import { ref, shallowRef, shallowReactive, watch, computed } from "vue";

import loggingMessages from "./EnvironmentManager.logging-messages.js";
import { useConfig } from "./config.js";
import { EnvironmentProcessingStates } from "./EnvironmentProcessingStates.js";
import { EnvironmentInitialisationError, EnvironmentManagerInitialisationError } from "../errors/index.js";

const config = useConfig();

export class EnvironmentManager {
	constructor({ logger, defaultEnvironmentName, currentEnvironmentDecorator, modelKeys, changeManagerKeys, environmentFactory } = {}) {
		this.logger = logger.nested({ name: "EnvironmentManager" });
		this.defaultEnvironmentName = defaultEnvironmentName;
		this.currentEnvironmentDecorator = currentEnvironmentDecorator;
		this.modelKeys = modelKeys;
		this.changeManagerKeys = changeManagerKeys;
		this.environmentFactory = environmentFactory;

		this.isProcessing = ref(false);
		this.processingState = ref(null);
		this.processingStateData = ref(null);
		this.currentEnvironment = shallowRef(null);
		this.allEnvironments = shallowReactive({});
		this.isAdmin = computed(() => this.defaultEnvironment?.isAdmin.value || this.currentEnvironment.value?.isAdmin.value);
		this.addChange = this.currentEnvironmentDecorator.decorateChangeManager(this, this.changeManagerKeys);

		this.model = {
			queries: this.currentEnvironmentDecorator.decorateQueries(this, this.modelKeys.queries),
			mutations: this.currentEnvironmentDecorator.decorateMutations(this, this.modelKeys.mutations),
			getStoredEntity: this.currentEnvironmentDecorator.decorateGetStoredEntity(this),
		};
	}

	async initialise({ handlers = {} } = {}) {
		this.handlers = handlers;
		this.#startProcessing(EnvironmentProcessingStates.INITIALISING);
		try {
			this.logger.log(loggingMessages.settingUpDefaultEnvironment, { environmentName: this.defaultEnvironmentName });
			this.defaultEnvironment = await this.environmentFactory({ environmentName: this.defaultEnvironmentName, environmentConfig: this.#getEnvironmentConfig(this.defaultEnvironmentName) });
			await this.#switchToEnvironment(this.defaultEnvironment);
			watch(this.defaultEnvironment.loggedInUser, async () => {
				await this.#discoverOtherEnvironments();
			});
			await this.#discoverOtherEnvironments();
			/* TODO: Want to externalise this logic into createApp. It needs to be excuted AFTER the environment manager is initialised and the current environment is set */
			// if (this.mobileApp) {
			// 	await this.mobileApp.initialise();
			// }
			// await this.branch.initialise();
			// this.initialisationPromise.resolve();
		} catch (error) {
			throw new EnvironmentManagerInitialisationError(error);
		} finally {
			this.#stopProcessing();
		}
	}

	async #discoverOtherEnvironments() {
		const environmentAccessPerm = this.defaultEnvironment.getPermission({ namespace: "Development", name: "EnvironmentAccess" });
		const otherEnvironments = environmentAccessPerm?.scope?.environments ? [...environmentAccessPerm.scope.environments] : [];

		const hardCodedContext = {
			"localhost": {
				endpointUrl: config.localhost.api.url,
				auth0_domain: config.localhost.auth0.domain,
				auth0_audience: config.localhost.auth0.audience,
			},
			"production-preview": {
				endpointUrl: "https://5cafelbp81.execute-api.eu-west-1.amazonaws.com/staging/",
				auth0_domain: "onezone-production.eu.auth0.com",
				auth0_audience: "https://api.onezone.app",
			},
		};
		Object.keys(hardCodedContext).forEach((environmentName) => {
			otherEnvironments.push(environmentName);
		});

		if (otherEnvironments.length > 0) {
			this.logger.log(loggingMessages.discoveredOtherEnvironments, { count: otherEnvironments.length, environmentNames: otherEnvironments });
		}

		const otherEnvironmentInstances = await Promise.all(
			otherEnvironments.map(async (environmentName) => {
				const environmentContext = environmentAccessPerm?.context[environmentName.toLowerCase()] ?? hardCodedContext[environmentName.toLowerCase()];
				if (!environmentContext) {
					throw new Error(`No environment context found for environment name '${environmentName}'`);
				}

				const environmentConfig = this.#getEnvironmentConfig(environmentName, environmentContext, false);
				const environment = await this.environmentFactory({ environmentName, environmentConfig });
				return environment;
			}),
		);

		[this.defaultEnvironment, ...otherEnvironmentInstances].forEach((environment) => (this.allEnvironments[environment.name] = environment));
	}

	#startProcessing(processingState, processingStateData = {}) {
		/* TODO: should really stack the processing states, but for now just keep the last one */
		this.logger.log(loggingMessages.startProcessing, { processingState, processingStateData });
		this.isProcessing.value = true;
		this.processingState.value = processingState;
		this.processingStateData.value = processingStateData;
	}

	#stopProcessing() {
		this.logger.log(loggingMessages.stopProcessing, { processingState: this.processingState.value, processingStateData: this.processingStateData.value });
		this.isProcessing.value = false;
		this.processingState.value = null;
		this.processingStateData.value = null;
	}

	async #switchToEnvironment(environment) {
		if (!environment) {
			throw new Error("No environment provided");
		}
		this.logger.log(loggingMessages.switchingToEnvironment, { environmentName: environment.name });
		if (!environment.isInitialised) {
			await environment.initialise({ handlers: this.handlers });
		}
		this.currentEnvironment.value = environment;
	}

	async switchToName(environmentName) {
		this.#startProcessing(EnvironmentProcessingStates.SWITCHING_ENVIRONMENT, { environmentName });
		const environment = this.allEnvironments[environmentName];
		if (!environment) {
			throw new Error(`No environment found for environment name '${environmentName}'. Available environments: [${Object.keys(this.allEnvironments).join(", ")}]`);
		}
		try {
			const result = await this.#switchToEnvironment(environment);
			return result;
		} catch (error) {
			throw new EnvironmentInitialisationError(environmentName, error);
		} finally {
			this.#stopProcessing();
		}
	}

	waitUntilInitialised() {
		return this.initialisationPromise.isFullfilled ? Promise.resolve() : this.initialisationPromise;
	}

	#getEnvironmentConfig(environmentName, environmentContext, throwIfNotFound = true) {
		const standardEnvironmentConfig = config[environmentName];
		if (!standardEnvironmentConfig && throwIfNotFound) {
			throw new Error(`No environment config found for environment '${environmentName}'`);
		}

		const configFromContext = environmentContext
			? {
					api: { url: environmentContext.endpointUrl },
					auth0: { clientId: standardEnvironmentConfig?.auth0.clientId, audience: environmentContext.auth0_audience, domain: environmentContext.auth0_domain },
			  }
			: {};

		const environmentConfig = { ...standardEnvironmentConfig, ...configFromContext };

		return environmentConfig;
	}
}
