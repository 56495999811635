/* REF Based on function from @vueuse/router. Fixed to add pendingingQuery, without this multiple calls overrides each other as their queries don't update until the router actually navigates */
import { computed, nextTick, reactive } from "vue";
import { useRoute, useRouter } from "vue-router";
import { toValue } from "@vueuse/shared";

var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => (key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : (obj[key] = value));
var __spreadValues = (a, b) => {
	for (var prop in b || (b = {})) if (__hasOwnProp.call(b, prop)) __defNormalProp(a, prop, b[prop]);
	if (__getOwnPropSymbols)
		for (var prop2 of __getOwnPropSymbols(b)) {
			if (__propIsEnum.call(b, prop2)) __defNormalProp(a, prop2, b[prop2]);
		}
	return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));

let _queue = reactive({});
function useRouteQuery(name, defaultValue, options = {}) {
	const { mode = "replace", route = useRoute(), router = useRouter(), transform = (value) => value } = options;

	router.afterEach(() => {
		/* WHY: Make sure we clear the pending query after navigation, so it doesn't re-use old values on next navigation */
		route.pendingQuery = null;
		Object.keys(_queue).forEach((key) => {
			delete _queue[key];
		});
	});

	return computed({
		get() {
			var _a = route.query[name];
			let data;
			if (_a != null) {
				// console.log("getting query from route", name, _a);
				data = _a;
				// } else if (_queue[name]) {
				// 	console.log("getting query from queue", name, _queue[name]);
				// 	data = _queue[name];
			} else {
				// console.log("getting query default", name, defaultValue);
				data = defaultValue;
			}

			// console.log("getting query", name, data, route.fullPath, route.query[name]);
			return transform(data);
		},
		set(v) {
			_queue[name] = v === defaultValue || v === null ? void 0 : v;
			nextTick(() => {
				const query = __spreadValues(__spreadValues({}, route.pendingQuery ?? route.query), _queue);
				// route.pendingQuery = query;
				router[toValue(mode)](__spreadProps(__spreadValues({}, route), { query }));
				// _queue = {};
			});
		},
	});
}

export { useRouteQuery };
