import { markRaw, onActivated, onDeactivated, onUnmounted, reactive } from "vue";
import { watchPausable } from "@vueuse/core";

import { ExternallyResolvablePromise, deepDiff, useIsRouteActive } from "../helpers/index.js";
import loggingMessages from "./ModelBuilder.logging-messages.js";

export class ModelBuilder {
	constructor({ logger }) {
		this.logger = logger.nested({ name: "ModelBuilder" });
	}

	build({ name, args = {}, state = {}, watchArgs, execute, actions = {}, isWatchEnabled, routeName } = {}) {
		isWatchEnabled = isWatchEnabled ?? useIsRouteActive(routeName, { debugLabel: name });

		const reactiveArgs = reactive(args);

		const reactiveState = reactive(state);

		const result = reactive({
			args: reactiveArgs,
			state: reactiveState,
			isLoading: false,
			hasLoadQueued: false,
			error: null,
			model: null,
			actions: markRaw(
				Object.fromEntries(
					Object.entries(actions).map(([actionName, action]) => [
						actionName,
						() => {
							return action({
								state: reactiveState,
								execute: async () => {
									this.logger.log(loggingMessages.executingAction, { modelMethodName: name, actionName });
									return executeModelLogic();
								},
								model: result.model,
							});
						},
					]),
				),
			),
			promise: markRaw(new ExternallyResolvablePromise()),
			stopWatching: null,
			startWatching: null,
		});

		/* suppress warning uncaught promise error, as errors will bubble up to global error handler, we don't want to show error */
		result.promise.catch(() => {});

		let previousArgs = null;

		const executeModelLogic = async (isQueuedInvocation = false) => {
			try {
				if (result.isLoading === true) {
					this.logger.log(loggingMessages.modelMethodAlreadyInvoking, { modelMethodName: name });
					result.hasLoadQueued = true;
					return true;
				}
				if (isQueuedInvocation) {
					this.logger.log(loggingMessages.invokingQueuedModelMethod, { modelMethodName: name, args: reactiveArgs });
				} else {
					this.logger.log(loggingMessages.invokingModelMethod, { modelMethodName: name, args: reactiveArgs });
				}
				result.isLoading = true;
				result.error = null;
				const model = await execute({ args: reactiveArgs, state: reactiveState });
				result.model = model;
				result.isLoading = false;
				if (result.hasLoadQueued) {
					result.hasLoadQueued = false;
					await executeModelLogic(true);
				}
				if (!result.promise.isFullfilled) {
					result.promise.resolve();
				}
			} catch (error) {
				this.logger.log(loggingMessages.errorInvokingModelMethod, { modelMethodName: name, error });
				result.isLoading = false;
				result.error = error;
				if (!result.promise.isFullfilled) {
					result.promise.reject(error);
				}
			}
		};

		const updateModel = () => {
			if (isWatchEnabled.value) {
				const isInitialDataFetch = !result.isLoading && result.model === null;
				const newArgs = JSON.parse(JSON.stringify(reactiveArgs));
				const diff = deepDiff(previousArgs ?? {}, newArgs);
				const hasChanged = diff.length > 0; /* TODO: UPDATE TO USE LODASH WHEN POSSIBLE */
				// const hasChanged = _.isEqual(previousArgs ?? {}, newArgs) === false;

				if (isInitialDataFetch || hasChanged) {
					previousArgs = newArgs;
					if (isInitialDataFetch) {
						this.logger.log(loggingMessages.initialDataFetch, { modelMethodName: name });
					} else {
						this.logger.log(loggingMessages.watchArgumentChanged, { modelMethodName: name, diff });
					}

					if (watchArgs) {
						try {
							watchArgs({ args: reactiveArgs, state: reactiveState });
						} catch (error) {
							this.logger.log(loggingMessages.errorInvokingWatchArgs, { modelMethodName: name, error });
							result.isLoading = false;
							result.error = error;
							result.promise.resolve();
							result.promise = markRaw(new ExternallyResolvablePromise());
						}
					}

					executeModelLogic();
					// .then((wasAddedToQueue) => {
					// 	if (!wasAddedToQueue) {
					// 		console.log("after executeModelLogic", name, result.isLoading, result.hasLoadQueued);
					// 		if (result.hasLoadQueued) {
					// 			result.hasLoadQueued = false;
					// 			executeModelLogic(true);
					// 		}
					// 	}
					// });
				}
			}
		};

		this.logger.log(loggingMessages.startingWatcher, { modelMethodName: name });
		const watcher = markRaw(
			watchPausable(
				[reactiveArgs, isWatchEnabled],
				() => {
					updateModel();
				},
				{ immediate: true },
			),
		);

		onActivated(() => {
			result.startWatching();
			updateModel();
		});

		onDeactivated(() => {
			result.stopWatching();
		});

		onUnmounted(() => {
			this.logger.log(loggingMessages.destroyingWatcher, { modelMethodName: name });
			watcher.stop();
		});

		result.stopWatching = markRaw(() => {
			if (watcher.isActive.value) {
				this.logger.log(loggingMessages.pausingWatcher, { modelMethodName: name });
				watcher.pause();
			}
		});

		result.startWatching = markRaw(() => {
			if (!watcher.isActive.value) {
				this.logger.log(loggingMessages.resumingWatcher, { modelMethodName: name });
				watcher.resume();
			}
		});

		return result;
	}
}
