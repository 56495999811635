<template>
	<div class="filter-type">
		<div class="heading" @click="toggleCollapsed">
			<h3>{{ props.title }}</h3>
			<button class="link-button-primary" @click.stop="clearFilters">Clear</button>
		</div>
		<div v-for="option in options" :key="option.value" :class="{ 'filter-item': true }">
			<label :for="`${props.name}-${option.value}`" :class="{ 'is-selected': isOptionSelected(option) }" @click.prevent="labelOnClick(option.value)">{{ option.name }}</label>
			<input :id="`${props.name}-${option.value}`" ref="refs" v-model="model" :value="option.value" type="checkbox" @change="updateFilter" />
		</div>
		<button v-if="props.isCollapsible" class="expand link-button-primary" @click="toggleCollapsed">
			{{ isExpanded ? "Hide all" : `See all (${props.options.length})` }}
		</button>
	</div>
</template>

<script setup>
import { computed, inject, ref } from "vue";

const props = defineProps({
	title: {
		type: String,
		required: true,
	},
	name: {
		type: String,
		required: true,
	},
	options: {
		type: Array,
		required: true,
	},
	isCollapsible: {
		type: Boolean,
		default: true,
	},
	collapsedDisplayCount: {
		type: Number,
		default: 3,
	},
});

const filterStateManager = inject("filterStateManager");

const refs = ref([]),
	isExpanded = ref(false);

const options = computed(() => {
	const _options = props.options.map(({ name, value }, index) => ({ name, value, checked: model.value.includes(value), index }));
	if (props.isCollapsible && !isExpanded.value) {
		let optionsSortedWithCheckedFirst;
		const areAllCheckedAtTop = model.value.every((value) => props.options.findIndex((option) => option.value === value) < props.collapsedDisplayCount);
		if (areAllCheckedAtTop) {
			/* This condition attempts to isolate the scenario where a user is selecting items in the unexpanded list, so to NOT re-order items as they are checked/unchecked */
			optionsSortedWithCheckedFirst = _options;
		} else {
			optionsSortedWithCheckedFirst = _options.sort((a, b) => (a.checked === b.checked ? 0 : a.checked ? -1 : 1));
		}
		return optionsSortedWithCheckedFirst.filter((option, index) => index < props.collapsedDisplayCount || option.checked);
	} else {
		return _options;
	}
});
const model = computed({
	get: () => filterStateManager.pendingFilterState[props.name] ?? [],
	set: (value) => {
		filterStateManager.setPendingFilter(props.name, value);
	},
});

function toggleCollapsed() {
	isExpanded.value = !isExpanded.value;
}

function clearFilters() {
	model.value = [];
}

function isOptionSelected(option) {
	return model.value.includes(option.value);
}

function labelOnClick(optionValue) {
	/* WHY: This is a workaround to allow the checkbox to be checked/unchecked when the label is clicked, as the label.for behaviour seems to close any active dialog */
	const inputRef = refs.value.find((singleRef) => singleRef.value === optionValue);
	if (inputRef) {
		inputRef.click();
	}
}

defineExpose({ clearFilters });
</script>

<style scoped lang="scss">
@import "../assets/styles/variables_new.scss";

.filter-type {
	display: flex;
	flex-direction: column;
	gap: calc($spacing / 2);
	// padding: 0 calc($spacing / 2);

	.heading {
		position: relative;
		margin-bottom: calc($spacing);

		h3 {
			margin: 0;
		}

		button {
			position: absolute;
			top: 4px;
			right: 0;
			font-size: $text-size-tertiary;
			color: $text-color-secondary;
		}
	}

	.filter-item {
		display: flex;

		label {
			flex-grow: 1;
			font-size: $text-size-secondary;
			color: $text-color-secondary;
			cursor: pointer;

			&.is-selected {
				color: $text-color-primary;
			}
		}
	}

	&:not(:last-child)::after {
		content: "";
		border-bottom: $border-size-primary solid $border-color-quinary;
		margin: calc($spacing * 2) 0;
	}

	.expand {
		margin-top: $spacing;
		text-align: left;
	}
}
</style>
