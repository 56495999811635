import { usePlatform } from "./platform.js";

// eslint-disable-next-line
import pkg from "../../package.json" assert { type: "json" };

const oneSignal = {
	production: { appId: "cdf83e5b-e0bf-4061-bdb2-838a3a9f4f11" },
	staging: { appId: "26b2520f-b6fc-473f-b57e-372a9fbb2ef0" },
	test: { appId: "b19ec49b-710e-4703-b7c5-173f1cb43f73" },
};

const PLATFORM_ENVIRONMENT_NAMES = {
	UNKNOWN: "unknown",
	IOS: "ios",
	ANDROID: "android",
	LOCALHOST: "localhost",
	STAGING: "staging",
	PRODUCTION: "production",
};

const ORIGIN_SPECIFIC_CONFIG = {
	"ios": {
		platformEnvironmentName: PLATFORM_ENVIRONMENT_NAMES.IOS,
	},
	"android": {
		platformEnvironmentName: PLATFORM_ENVIRONMENT_NAMES.ANDROID,
	},
	"http://localhost:5173": {
		platformEnvironmentName: PLATFORM_ENVIRONMENT_NAMES.LOCALHOST,
		production: {
			oneSignal: {
				appId: oneSignal.test.appId,
			},
		},
	},
	"https://www.onezone.app": {
		platformEnvironmentName: PLATFORM_ENVIRONMENT_NAMES.PRODUCTION,
	},
	"https://web.staging.onezone.app": {
		platformEnvironmentName: PLATFORM_ENVIRONMENT_NAMES.STAGING,
		production: {
			oneSignal: {
				appId: oneSignal.staging.appId,
			},
		},
	},
	"https://onezone.dexnet.com": {
		platformEnvironmentName: PLATFORM_ENVIRONMENT_NAMES.LOCALHOST,
		production: {
			oneSignal: {
				appId: null,
			},
		},
	},
};

export const useConfig = () => {
	const platform = usePlatform();
	let origin = null;
	if (platform.isNative) {
		origin = platform.name;
	} else if (platform.isWeb && !platform.isServer) {
		origin = window?.location?.origin;
	}

	process.env.ENVIRONMENT_NAME;
	const originSpecificConfig = origin ? ORIGIN_SPECIFIC_CONFIG[origin] : null;

	let platformEnvironmentName;
	if (platform.isServer) {
		platformEnvironmentName = `server-${process.env.ENVIRONMENT_NAME}`;
	} else {
		platformEnvironmentName = originSpecificConfig?.platformEnvironmentName ?? PLATFORM_ENVIRONMENT_NAMES.UNKNOWN;
	}

	const config = {
		isDevelopment: true,
		defaultCityName: "london",
		platformEnvironmentName,
		clientName: `onezone-hybrid-${platform.name}`,
		clientVersion: pkg.version,
		clientVersionCode: pkg.versionCode,
		appBuilds: {
			android: `https://s3.eu-west-1.amazonaws.com/onezone-client-app-builds/android-${process.env.PR_NUMBER}.apk`,
			ios: `https://s3.eu-west-1.amazonaws.com/onezone-client-app-builds/ios-${process.env.PR_NUMBER}.ipa`,
		},
		origin,
		googleMapsApiKey: "AIzaSyAuLfYEekgwvqSaL0rX6V64f-84OZ8op9I",
		dataDog: {
			clientToken: "pub26a52923ae80002bb047cc5b031ba631",
			site: "datadoghq.eu",
		},
	};

	config.production = {
		auth0: {
			clientId: "iZQAu6xBwVyNqg5Di6e6hss1vSXFUBZC",
			domain: "onezone-production.eu.auth0.com",
			audience: "https://api.onezone.app",
		},
		api: {
			url: "https://api.onezone.app",
		},
		googleAnalytics: {
			tagId: "G-8YDTLM2EN2",
		},
		firebase: {
			apiKey: "AIzaSyAaZG9_JRkulTyr2Dvq2MKdQzQAHVhuTdg",
			authDomain: "onezone-67e71.firebaseapp.com",
			projectId: "onezone-67e71",
			storageBucket: "onezone-67e71.appspot.com",
			messagingSenderId: "12845480740",
			appId: "1:12845480740:web:767b9646ed4f2e7ef6d1b1",
			measurementId: "G-NF9PXB4E1Q",
		},
		clarity: {
			projectId: "inv7j1007i",
		},
		mixpanel: {
			projectToken: "f6e7d77a68d28c45b9e72b634777f714",
		},
		oneSignal: {
			appId: oneSignal.production.appId,
			supportsExtraTags: true,
		},
		...(originSpecificConfig?.production ?? {}),
	};

	config["production-preview"] = { ...config.production };
	config["production-new"] = { ...config.production };
	config["production-new-preview"] = { ...config.production };

	/* NOTE: The remaining details are returned from the API via permissions */
	config.staging = {
		auth0: {
			clientId: "Lou6QR28hrt0ub0ByYyLRg2RMi5FJrCR",
		},
		mixpanel: {
			projectToken: "4cc4b2f145969ddda6716aff2248bf8e",
		},
		...(originSpecificConfig?.staging ?? {}),
	};

	config.localhost = {
		auth0: {
			clientId: "Lou6QR28hrt0ub0ByYyLRg2RMi5FJrCR",
			domain: "onezone-staging-eu.eu.auth0.com",
			audience: "https://api-staging.onezone.app",
			// clientId: "iZQAu6xBwVyNqg5Di6e6hss1vSXFUBZC",
			// domain: "onezone-production.eu.auth0.com",
			// audience: "https://api.onezone.app",
		},
		api: {
			url: "http://localhost:4000",
		},
		mixpanel: {
			projectToken: "4cc4b2f145969ddda6716aff2248bf8e",
		},
		...(originSpecificConfig?.localhost ?? {}),
	};

	config.branchDeepLinkUri = "https://i1kog-alternate.app.link";

	if (platform.isAndroid) {
		config.origin = "onezone://localhost";
		config.linkOrigin = "https://www.onezone.app";
		config.redirectUri = "onezone://localhost/capacitor/onezone/callback";
		config.logoutUri = "onezone://localhost/capacitor/onezone/logout";
	} else if (platform.isIos) {
		config.origin = "onezone://localhost";
		config.linkOrigin = "https://www.onezone.app";
		config.redirectUri = "onezone://localhost/capacitor/onezone/callback";
		config.logoutUri = "onezone://localhost/capacitor/onezone/logout";
	} else if (platform.isWeb) {
		config.origin = origin;
		config.linkOrigin = origin;
		config.redirectUri = `${origin}/loggedIn`;
		config.logoutUri = `${origin}/`;
	}

	return config;
};
