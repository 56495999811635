<template>
	<div class="safe-area-wrapper">
		<div v-if="shouldShowSafeArea" class="safe-area" :style="style"></div>
	</div>
</template>

<script setup>
import { inject, computed } from "vue";
import { usePageSize } from "../domain/index.js";

const LOCATIONS = {
	TOP: "top",
	BOTTOM: "bottom",
};

const props = defineProps({
	location: {
		type: String,
		default: "top",
	},
});

const safeArea = inject("safeArea");

const pageSize = usePageSize();

const paddingTop = `${safeArea.insets?.top}px`;
const paddingBottom = `${safeArea.insets?.bottom}px`;

const shouldShowSafeArea = computed(() => pageSize.isSmallerThanMedium);
const style = computed(() => {
	return {
		paddingTop: props.location === LOCATIONS.TOP ? paddingTop : 0,
		paddingBottom: props.location === LOCATIONS.BOTTOM ? paddingBottom : 0,
	};
});
</script>

<style lang="scss" scoped>
@import "../assets/styles/variables.scss";

.safe-area-wrapper {
	width: 100%;
}
.safe-area {
	// background-color: $color-background-primary;
	width: 100%;
}
</style>
