<template>
	<div class="tree-node">
		<slot />
		<div ref="childrenRef" :style="{ 'margin-left': props.margin, 'height': expandedHeight }" :class="{ children: true, animate: !componentState.hasJustLoaded }">
			<slot name="children" />
		</div>
	</div>
</template>

<script setup>
import { computed, ref } from "vue";

import { useComponentState, useElementScrollSize } from "../helpers/index.js";

const props = defineProps({
	isExpanded: {
		type: Boolean,
		default: false,
	},
	margin: {
		type: String,
		default: "26px",
	},
});

const childrenRef = ref(null);

const componentState = useComponentState();
const childrenScrollSize = useElementScrollSize(childrenRef);

const expandedHeight = computed(() => {
	/* NOTE: Make sure we access childrenScrollSize so that the computed property is re-evaluated when either of them changes. Accessing within the ternary would result in some branches not executing. */
	const childrenElementHeight = childrenScrollSize.height;
	return props.isExpanded && childrenRef.value ? `${childrenElementHeight}px` : `0`;
});
</script>

<style scoped lang="scss">
@import "../assets/styles/variables_new.scss";

.tree-node {
	flex-shrink: 0; /* prevent the node from shrinking if the parent container is a flexbox and too small */

	.children {
		overflow: hidden;

		&.animate {
			transition: height 0.35s ease-in-out;
		}
	}
}
</style>
