<template>
	<div v-if="shouldShowIsLoading || shouldShowResultCountAndSearchText || shouldShowFilters" :class="{ 'search-context-container': true, 'align-center': props.alignCenter }">
		<SearchContext class="search-context" :align-center="props.alignCenter">
			<!-- <div class="search-context-group"> -->
			<SearchContextLabel v-if="shouldShowIsLoading" class="loading-label" :has-drop-shadow="props.hasDropShadow" :has-remove-button="false">
				<img src="../assets/icons/loading-spinner.gif" class="spinner" />
				<div>Loading, please wait...</div>
				<!-- <div class="animation-wrapper"><div class="animation dot-flashing"></div></div> -->
			</SearchContextLabel>
			<SearchContextLabel v-else-if="shouldShowResultCountAndSearchText" :has-remove-button="false" :has-drop-shadow="props.hasDropShadow">
				<template v-if="venueResults.length > 0 && showShouldResultCount">Showing {{ pluralizeResultCount(venueResults.length, "place", { pageSize }) }}</template>
				<template v-else-if="venueResults.length === 0 && showShouldResultCount">No places found</template>
				<template v-if="showShouldResultCount && shouldShowSearchText">&nbsp;matching&nbsp;</template>
				<template v-if="shouldShowSearchText">"{{ searchText }}"</template>
			</SearchContextLabel>
			<!-- </div> -->
			<!-- <div class="search-context-group"> -->
			<SearchContextLabel v-for="filter in appliedFilterNameDisplay" :key="filter.id" :invert-colors="true" :has-drop-shadow="props.hasDropShadow" @remove="clearFilter(filter)">
				{{ filter.name }}
			</SearchContextLabel>
			<!-- </div> -->
		</SearchContext>
	</div>
</template>

<script setup>
import { computed, inject, onMounted, ref } from "vue";

import { getFilterNames, pluralizeResultCount } from "../helpers/index.js";

import SearchContextLabel from "./SearchContextLabel.vue";
import SearchContext from "./SearchContext.vue";

const props = defineProps({
	alignCenter: {
		type: Boolean,
		default: true,
	},
	showSearchText: {
		type: Boolean,
		default: true,
	},
	showResultCount: {
		type: Boolean,
		default: false,
	},
	showIsLoading: {
		type: Boolean,
		default: false,
	},
	/* optional tags for now (used when useSearchResults was not used)*/
	tags: {
		type: Array,
		default: () => [],
	},
	hasDropShadow: {
		type: Boolean,
		default: false,
	},
	filterStateManager: {
		type: Object,
		default: null,
	},
});

const injectedFilterStateManager = inject("filterStateManager");
const { venueResults, searchText, tags: searchResultTags, pageSize } = inject("searchResults", {});

const isMounted = ref(false);

const finalTags = computed(() => searchResultTags?.value ?? props.tags);
const filterStateManager = computed(() => props.filterStateManager ?? injectedFilterStateManager);
const appliedFilterNameDisplay = computed(() => getFilterNames(filterStateManager.value?.filterState, finalTags.value));
const showShouldResultCount = computed(() => props.showResultCount);
const shouldShowSearchText = computed(() => props.showSearchText && searchText?.value.length > 0);
const shouldShowResultCountAndSearchText = computed(() => showShouldResultCount.value || shouldShowSearchText.value);
const shouldShowFilters = computed(() => appliedFilterNameDisplay.value.length > 0 && finalTags.value.length > 0);
const shouldShowIsLoading = computed(() => props.showIsLoading);

onMounted(async () => {
	isMounted.value = true;
});

async function clearFilter({ id, type }) {
	await filterStateManager.value?.confirmPendingFilterState();
	filterStateManager.value?.removePendingFilter(type, id);
	filterStateManager.value?.applyPendingFilterState();
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/variables_new.scss";
.search-context-container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: calc($spacing / 2);
	margin: 0 calc($spacing / 2);

	&.align-center {
		align-items: center;
	}

	.search-context {
		display: flex;
		// flex-direction: column;
		max-width: 100%;
		// .search-context-group {
		// 	max-width: 100%;
		// 	display: flex;
		// 	// flex-direction: row;
		// 	justify-content: center;
		// 	gap: calc($spacing / 2);
		// 	flex-wrap: wrap;
		// }
	}

	.loading-label {
		// padding: 0px 30px;
		.spinner {
			width: 16px;
			height: 16px;
		}
	}

	.search-context-status {
		margin-top: calc($spacing / 2);
	}
}
</style>
