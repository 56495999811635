import { computed, reactive } from "vue";

export const combineQueries = (queries, { isLoading = {}, error = {} } = {}) => {
	return reactive({
		...queries,
		promise: computed(() =>
			Promise.all(
				Object.entries(queries)
					.filter(([queryName]) => !isLoading.include || isLoading.include.includes(queryName))
					.map(([, query]) => query.promise),
			),
		),
		args: computed(() => Object.values(queries).reduce((args, query) => ({ ...args, ...query.args }), {})),
		state: computed(() => Object.values(queries).reduce((state, query) => ({ ...state, ...query.state }), {})),
		actions: computed(() => Object.values(queries).reduce((actions, query) => ({ ...actions, ...query.actions }), {})),
		model: computed(() => Object.values(queries).reduce((model, query) => ({ ...model, ...query.model }), {})),
		isLoading: computed(() => Object.entries(queries).some(([queryName, query]) => (!isLoading.include || isLoading.include.includes(queryName)) && query.isLoading)),
		error: computed(
			() =>
				Object.entries(queries)
					.filter(([queryName]) => !error.include || error.include.includes(queryName))
					.find(([, query]) => query.error)?.[1].error ?? null,
		),
		retry: null,
	});
};
