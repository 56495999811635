<template>
	<div class="search-results-container">
		<StandardSearchContext :search-text="searchText" :tags="tags" :align-center="props.searchContextAlignCenter" />

		<loading-message-with-error v-if="query.isLoading || query.error" :error="query.error">Loading results, please wait... </loading-message-with-error>

		<EmptyResultsMessage v-else-if="!hasSearchCriteria && !hasResults">Type to search or select filters<br />and start discovering</EmptyResultsMessage>
		<EmptyResultsMessage v-else-if="hasSearchCriteria && !hasResults" :include-submit="true">
			Sorry we can't find "{{ searchText }}" on onezone.<br /><br />
			Click send to suggest this place for onezone approval.
		</EmptyResultsMessage>

		<SearchResults v-else ref="searchResultsRef" class="search-results">
			<SearchResultsSection
				v-if="hasVenueResults"
				v-slot="{ isItemVisible }"
				:result-type="ResultTypes.VENUES"
				type-name="place"
				:header="venueSearchResultsHeader"
				:data="venueSearchResults"
				:show-all="hasOnlyOneResultSection"
				:collapse-count="2"
				v-bind="getSectionArgs(ResultTypes.VENUES, venueSearchResults)"
			>
				<template v-for="(venue, index) in venueSearchResults" :key="venue.id">
					<VenueCard v-show="isItemVisible(index).value" class="venue" :user-lists="userLists" :venue="venue" :show-mini="true" v-bind="getResultArgs(ResultTypes.VENUES, venue)" />
				</template>
			</SearchResultsSection>

			<SearchResultsSection
				v-if="hasGooglePlacesResults"
				v-slot="{ data }"
				:result-type="ResultTypes.GOOGLE_PLACES"
				:data="googlePlacesResults"
				:show-all="hasOnlyOneResultSection"
				:collapse-count="2"
				:header="googleLocationsResultsHeader"
				v-bind="getSectionArgs(ResultTypes.GOOGLE_PLACES, venueSearchResults)"
			>
				<GooglePlaceCard v-for="place in data" :key="place.place_id" :place="place" :city-name="routeParams.cityName" :filter-state="filterStateManager.filterState" />
			</SearchResultsSection>

			<SearchResultsSection
				v-if="hasZoneResults"
				v-slot="{ data, toggleFilter, toggleOnChange, isAlreadyAPendingFilter }"
				:result-type="ResultTypes.ZONES"
				type-name="area"
				:data="zoneSearchResults"
				:show-all="hasOnlyOneResultSection"
				:header="zonesSearchResultsHeader"
				:collapse-count="2"
				v-bind="getSectionArgs(ResultTypes.ZONES, venueSearchResults)"
			>
				<ZoneCard
					v-for="zone in data"
					:key="zone.id"
					:zone="zone"
					:show-mini="true"
					:show-venue-count="props.showVenueCount"
					class="zone"
					v-bind="getResultArgs(ResultTypes.ZONES, zone)"
					@click="wholeRowToggleFilters ? toggleFilter(zone) : null"
				>
					<label v-if="getSectionArgs(ResultTypes.ZONES, venueSearchResults)?.showFilter ?? true" class="filter-controls" @click.prevent>
						<input type="checkbox" :checked="isAlreadyAPendingFilter(zone)" :value="zone.id" @change="toggleOnChange" @click.stop />
						Add Filter
					</label>
				</ZoneCard>
			</SearchResultsSection>

			<SearchResultsSection
				v-if="hasListResults"
				v-slot="{ data, toggleFilter, toggleOnChange, isAlreadyAPendingFilter }"
				:result-type="ResultTypes.LISTS"
				type-name="curated list"
				:data="listSearchResults"
				:show-all="hasOnlyOneResultSection"
				:header="listsSearchResultsHeader"
				:collapse-count="2"
				v-bind="getSectionArgs(ResultTypes.LISTS, venueSearchResults)"
			>
				<ListCard
					v-for="list in data"
					:key="list.id"
					:list="list"
					:show-mini="true"
					:show-venue-count="props.showVenueCount"
					class="list"
					v-bind="getResultArgs(ResultTypes.LISTS, list)"
					@click="wholeRowToggleFilters ? toggleFilter(list) : null"
				>
					<label v-if="getSectionArgs(ResultTypes.LISTS, venueSearchResults)?.showFilter ?? true" class="filter-controls" @click.prevent>
						<input type="checkbox" :checked="isAlreadyAPendingFilter(list)" :value="list.id" @change="toggleOnChange" @click.stop />
						Add Filter
					</label>
				</ListCard>
			</SearchResultsSection>

			<SearchResultsSection
				v-if="hasTagResults"
				v-slot="{ data, toggleFilter, toggleOnChange, isAlreadyAPendingFilter }"
				:result-type="ResultTypes.TAGS"
				type-name="tag"
				:data="tagSearchResults"
				:show-all="hasOnlyOneResultSection"
				:header="tagsSearchResultsHeader"
				:collapse-count="2"
				v-bind="getSectionArgs(ResultTypes.TAGS, venueSearchResults)"
			>
				<TagCard
					v-for="tag in data"
					:key="tag.id"
					:tag="tag"
					:show-mini="true"
					:show-tag-category="true"
					:show-venue-count="props.showVenueCount"
					class="tag"
					v-bind="getResultArgs(ResultTypes.TAGS, tag)"
					@click="wholeRowToggleFilters ? toggleFilter(tag) : null"
				>
					<label v-if="getSectionArgs(ResultTypes.TAGS, venueSearchResults)?.showFilter ?? true" class="filter-controls" @click.prevent>
						<input type="checkbox" :checked="isAlreadyAPendingFilter(tag)" :value="tag.id" @change="toggleOnChange" @click.stop />
						Add Filter
					</label>
				</TagCard>
			</SearchResultsSection>

			<SearchResultsSection
				v-if="hasUserResults"
				v-slot="{ data }"
				:result-type="ResultTypes.USERS"
				type-name="user"
				:header="userSearchResultsHeader"
				:data="userSearchResults"
				:show-all="hasOnlyOneResultSection"
				:collapse-count="2"
				v-bind="getSectionArgs(ResultTypes.USERS, userSearchResults)"
			>
				<UserCard v-for="user in data" :key="user.id" class="user" :user="user" v-bind="getResultArgs(ResultTypes.USERS, user)" />
			</SearchResultsSection>
		</SearchResults>
	</div>
</template>

<script setup>
import { computed, inject, onMounted, reactive, ref, toRef, watch } from "vue";

import { useRouteParams, pluralizeResultCount, waitForDataSSR, getFilterCount } from "../helpers/index.js";
import { useScrollMemory } from "../functions/scroll-memory/index.js";
import { ResultTypes } from "../constants/index.js";

import EmptyResultsMessage from "./EmptyResultsMessage.vue";
import SearchResults from "./SearchResults.vue";
import SearchResultsSection from "./SearchResultsSection.vue";
import VenueCard from "./VenueCard.vue";
import TagCard from "./TagCard.vue";
import ListCard from "./ListCard.vue";
import ZoneCard from "./ZoneCard.vue";
import UserCard from "./UserCard.vue";
import GooglePlaceCard from "./GooglePlaceCard.vue";
import StandardSearchContext from "./StandardSearchContext.vue";

const props = defineProps({
	searchText: {
		type: String,
		required: true,
	},
	searchResults: {
		type: Object,
		default: () => ({}),
	},
	tags: {
		type: Array,
		default: () => [],
	},
	userLists: {
		type: Object,
		default: null,
	},
	query: {
		type: Object,
		required: true,
	},
	resultTypes: {
		type: Array,
		default: () => [],
	},
	pageSize: {
		type: Number,
		required: true,
	},
	wholeRowToggleFilters: {
		type: Boolean,
		default: false,
	},
	showVenueCount: {
		type: Boolean,
		default: true,
	},
	searchContextAlignCenter: {
		type: Boolean,
		default: true,
	},
});

const searchResultsRef = ref(null);

const filterStateManager = inject("filterStateManager");
const logger = inject("logger").nested({ name: "StandardSearchResults" });
const routeParams = useRouteParams();
const scrollMemory = useScrollMemory({ containerRef: searchResultsRef, focusOnScroll: true });

const isMounted = ref(false);

const searchText = computed(() => props.searchText);
const userLists = computed(() => props.userLists);
const venueSearchResults = computed(() => props.searchResults?.venues ?? []);
const tagSearchResults = computed(() => props.searchResults?.tags ?? []);
const listSearchResults = computed(() => props.searchResults?.lists ?? []);
const zoneSearchResults = computed(() => props.searchResults?.zones ?? []);
const googlePlacesResults = computed(() => props.searchResults?.places ?? []);
const userSearchResults = computed(() => props.searchResults?.users ?? []);
const hasVenueResults = computed(() => venueSearchResults.value.length > 0 && shouldShowResultType(ResultTypes.VENUES));
const hasTagResults = computed(() => searchText.value.length > 0 && tagSearchResults.value.length > 0 && shouldShowResultType(ResultTypes.TAGS));
const hasListResults = computed(() => searchText.value.length > 0 && listSearchResults.value.length > 0 && shouldShowResultType(ResultTypes.LISTS));
const hasZoneResults = computed(() => searchText.value.length > 0 && zoneSearchResults.value.length > 0 && shouldShowResultType(ResultTypes.ZONES));
const hasUserResults = computed(() => searchText.value.length > 0 && userSearchResults.value.length > 0 && shouldShowResultType(ResultTypes.USERS));
const hasGooglePlacesResults = computed(() => searchText.value.length > 0 && googlePlacesResults.value.length > 0 && shouldShowResultType(ResultTypes.GOOGLE_PLACES));
const hasSearchCriteria = computed(() => searchText.value.length > 0 || getFilterCount(filterStateManager.filterState) > 0);
const hasResults = computed(() => hasVenueResults.value || hasTagResults.value || hasListResults.value || hasZoneResults.value || hasGooglePlacesResults.value || hasUserResults.value);
const hasOnlyOneResultSection = computed(() => [hasTagResults, hasVenueResults, hasListResults, hasZoneResults, hasGooglePlacesResults, hasUserResults].filter((x) => x.value === true).length <= 1);
const tags = computed(() => props.tags);
const googleLocationsResultsHeader = computed(() => `${pluralizeResultCount(googlePlacesResults.value.length, "location")} found`);
const tagsSearchResultsHeader = computed(() => `${pluralizeResultCount(tagSearchResults.value.length, "tag")} found`);
const listsSearchResultsHeader = computed(() => `${pluralizeResultCount(listSearchResults.value.length, "curated list")} found`);
const zonesSearchResultsHeader = computed(() => `${pluralizeResultCount(zoneSearchResults.value.length, "area")} found`);
const venueSearchResultsHeader = computed(() => `${pluralizeResultCount(venueSearchResults.value.length, "place", { pageSize: props.pageSize })} found`);
const userSearchResultsHeader = computed(() => `${pluralizeResultCount(userSearchResults.value.length, "user", { pageSize: props.pageSize })} found`);
const query = computed(() => props.query);

watch(toRef(props, "searchResults"), () => {
	/* TODO: this interferes with the ability to remember position and restore when navigating to specific result and back */
	scrollMemory.scrollToTop();
});

onMounted(async () => {
	isMounted.value = true;
});

await waitForDataSSR(toRef(props, "query"), logger);

function shouldShowResultType(resultTypeName) {
	const resultType = props.resultTypes.find(({ type }) => type === resultTypeName);
	return resultType ? resultType?.sectionArgs?.showSection ?? true : false;
}

function getResultType(resultTypeName) {
	return props.resultTypes.find(({ type }) => type === resultTypeName);
}

function getResultArgs(resultTypeName, resultRecord) {
	const args = getResultType(resultTypeName)?.resultArgs;
	return reactive((typeof args === "function" ? args(resultRecord) : args) ?? {});
}

function getSectionArgs(resultTypeName, resultRecords) {
	const args = props.resultTypes.find(({ type }) => type === resultTypeName)?.sectionArgs;
	return reactive((typeof args === "function" ? args(resultRecords) : args) ?? {});
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/variables_new.scss";

.search-results-container {
	height: 100%;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	gap: $spacing;
	@include scroller-wrapper;

	.search-results {
		// height: 100%;
		@include scroller;

		> h2 {
			font-size: $text-size-primary;
			font-weight: $text-bold-primary;
			margin-left: calc($spacing / 2);
		}

		.filter-controls {
			display: flex;
			flex-direction: column;
			align-items: center;
			font-size: $text-size-quinary;
			color: $text-color-secondary;
			white-space: nowrap;
			// padding: $spacing;
		}
	}
}
</style>
