export default {
	webPlatformNotSupportedForOpeningSettings: {
		level: "warn",
		message: () => `🌐 Web platform not supported for opening settings, you should not be seeing this message.`,
	},
	mobileAppNotProvidedForOpeningSettings: {
		level: "error",
		message: () => `📱 Mobile app not provided for opening settings, you should not be seeing this message.`,
	},
};
