<template>
	<div class="nav-wrapper">
		<div class="nav">
			<custom-router-link :enable-previous-routes="true" :to="{ name: 'City/Home', params: { city: currentCityName } }" class="link" :style="paddingBottomStyle">
				<img draggable="false" src="../assets/icons/home.svg" alt="home" class="link-image home" />
				<span class="link-label">Home</span>
			</custom-router-link>
			<custom-router-link :enable-previous-routes="true" :to="{ name: 'City/Explore', params: { city: currentCityName } }" class="link" :style="paddingBottomStyle">
				<img draggable="false" src="../assets/icons/explore.svg" alt="explore" class="link-image explore" />
				<span class="link-label">Explore</span>
			</custom-router-link>
			<custom-router-link :enable-previous-routes="true" :to="{ name: 'City/Map', params: { city: currentCityName } }" class="link" :style="paddingBottomStyle">
				<img draggable="false" src="../assets/icons/map.svg" alt="map" class="link-image map" />
				<span class="link-label">Map</span>
			</custom-router-link>
			<custom-router-link :enable-previous-routes="true" :to="{ name: 'City/Activity', params: { city: currentCityName } }" class="link" :style="paddingBottomStyle">
				<img draggable="false" src="../assets/icons/activity.svg" alt="activity" class="link-image activity" />
				<span class="link-label">Activity</span>
			</custom-router-link>
			<custom-router-link :enable-previous-routes="true" :to="{ name: 'City/Profile', params: { city: currentCityName } }" class="link" :style="paddingBottomStyle">
				<img draggable="false" src="../assets/icons/profile.svg" alt="profile" class="link-image profile" />
				<span class="link-label">Profile</span>
			</custom-router-link>
			<custom-router-link v-if="showAdmin" :enable-previous-routes="true" :to="{ name: 'City/Admin', params: { city: currentCityName } }" class="link" :style="paddingBottomStyle">
				<font-awesome-icon icon="fa-solid fa-lock" class="link-image admin" />
				<span class="link-label">Admin</span>
			</custom-router-link>
		</div>
	</div>
</template>

<script setup>
import { inject, computed, onMounted, ref } from "vue";

import { useRouteParams } from "../helpers/index.js";
import { useConfig } from "../domain/index.js";
// import { buildLinkLastChild } from "../functions/custom-router/useCustomRouter.js";

const config = useConfig();
const routeParams = useRouteParams();
const currentCityName = computed(() => routeParams.cityName ?? config.defaultCityName);

const environmentManager = inject("environmentManager");
const safeArea = inject("safeArea");

const paddingBottomStyle = { "padding-bottom": `${safeArea.insets?.bottom}px` };

const isMounted = ref(false);
onMounted(() => {
	isMounted.value = true;
});

const showAdmin = computed(() => {
	return isMounted.value ? environmentManager?.isAdmin.value : false;
});
</script>

<style lang="scss">
@import "../assets/styles/variables_new.scss";

.nav-wrapper {
	background-color: $background-color-primary;

	> .nav {
		display: flex;
		justify-content: space-around;
		border-top: $border-size-primary solid $border-color-primary;
		box-sizing: border-box;

		> .link {
			display: flex;
			flex-direction: column;
			align-items: center;
			box-sizing: border-box;
			padding-top: calc($spacing / 1.2);
			// padding: calc($spacing/1.5) calc($spacing) 0 calc($spacing);
			width: 100%;

			&:not(.router-link-active-partial-path) {
				color: $text-color-secondary;
				> img {
					filter: opacity(30%);
				}
			}

			> .link-image {
				width: 24px;
				height: 24px;
			}

			> .link-label {
				font-size: $text-size-tertiary;
				margin-top: 3px;
			}
		}
	}
}

@media (min-width: $bp-medium) {
	.nav-wrapper {
		> .nav {
			height: auto;
			flex-direction: column;
			border: none;
			gap: calc($spacing / 1.2);
			padding-top: 0;

			> .link {
				flex-direction: row;
				margin: 0;
				padding: 0;

				&:not(.router-link-active-partial-path) {
					color: $text-color-primary;
					font-weight: $text-bold-tertiary;
					> img {
						filter: none;
					}
				}

				&.router-link-active-partial-path {
					> .link-label {
						color: $text-color-primary;
						font-weight: $text-bold-primary;
					}
				}

				> .link-image {
					margin-right: $spacing;
				}

				> .link-label {
					font-size: $text-size-header;
					margin: 0;
				}
			}
		}
	}
}
</style>
