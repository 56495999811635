import { GlobalChangeSets } from "../../constants/index.js";

export default class {
	constructor({ changeManager, entityStore }) {
		this.changeManager = changeManager;
		this.entityStore = entityStore;
	}

	add({ userId }) {
		if (!this.changeManager.findChange({ typeName: "DeletePremium", data: { userId } })) {
			this.changeManager.addChangeToQueue({ typeName: "DeletePremium", data: { userId } }, { changeSet: GlobalChangeSets.Instant });
		}
	}

	getChangeEffects() {
		return [];
	}
}
