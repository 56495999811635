import { deepDiff } from "./deepDiff.js";
import { getFilterValue } from "./getFilterValue.js";

export const getPendingFilterCount = (originalFilterState, newFilterState) => {
	const filtersAdded = Object.entries(newFilterState).reduce((acc, [filterType, value]) => {
		const count = Array.isArray(value)
			? value.filter((v) => !originalFilterState[filterType]?.find((entry) => getFilterValue(entry) === getFilterValue(v))).length
			: deepDiff(originalFilterState[filterType] ?? {}, value ?? {}).length > 0
			? 1
			: 0;
		return acc + count;
	}, 0);
	const filtersRemoved = Object.entries(originalFilterState).reduce((acc, [filterType, value]) => {
		const count = Array.isArray(value) ? value.filter((v) => !newFilterState[filterType]?.find((entry) => getFilterValue(entry) === getFilterValue(v))).length : 0;
		return acc + count;
	}, 0);

	const pendingFilterCount = filtersAdded + filtersRemoved;

	return pendingFilterCount;
};
