import { computed, reactive } from "vue";
import { useQueryString } from "../functions/query-string/index.js";
import loggingMessages from "./useFeatureSwitches.logging-messages.js";

export const FeatureSwitchNames = {
	NativeMap: "NativeMap",
	ShowAds: "ShowAds",
};

export const FeatureSwitchConfig = {
	[FeatureSwitchNames.NativeMap]: {
		paramName: "nativeMap",
		adminOnly: false,
	},
	[FeatureSwitchNames.ShowAds]: {
		paramName: "showAds",
		adminOnly: false,
	},
};

export const useFeatureSwitches = ({ logger, platform, router, route }) => {
	const featureSwitchManager = new FeatureSwitchManager({ logger, platform, router, route });

	return featureSwitchManager;
};

class FeatureSwitchManager {
	constructor({ logger, platform, router, route }) {
		this.logger = logger.nested({ name: "FeatureSwitchManager" });
		this.platform = platform;
		this.router = router;
		this.route = route;
		this.queryString = null;
	}

	initialise(environment) {
		this.environment = environment;

		const featureSwitchQueryNames = this.getFeatureSwitchQueryNames();
		const queryStringDefinition = Object.fromEntries(featureSwitchQueryNames.map((featureSwitchQueryName) => [featureSwitchQueryName, null]));
		this.queryString = useQueryString(queryStringDefinition, { router: this.router, route: this.route });

		Object.values(FeatureSwitchNames).forEach((featureName) => {
			if (this.isActive(featureName)) {
				this.logger.log(loggingMessages.featureSwitchEnabled, { featureName });
			}
		});
	}

	getFeatureSwitchQueryNames() {
		const featureSwitchQueryNames = Object.values(FeatureSwitchConfig).map((featureSwitchConfig) => featureSwitchConfig.paramName);

		return featureSwitchQueryNames;
	}

	isActive(featureName) {
		if (!FeatureSwitchNames[featureName]) {
			throw new Error(`Unknown feature name: ${featureName}`);
		}
		const featureSwitchConfig = FeatureSwitchConfig[featureName];

		const isFeatureSwitchEnabled = this.queryString[featureSwitchConfig.paramName] === "true" || featureSwitchConfig.forceEnabled?.({ platform: this.platform });

		if (isFeatureSwitchEnabled && featureSwitchConfig.adminOnly && !this.environment.isAdmin.value) {
			throw new Error(`Feature switch '${featureName}' is enabled, admin is required but user is not an admin`);
		}

		return isFeatureSwitchEnabled;
	}

	set(featureName, isActive) {
		if (!FeatureSwitchNames[featureName]) {
			throw new Error(`Unknown feature name: ${featureName}`);
		}
		const featureSwitchConfig = FeatureSwitchConfig[featureName];
		if (isActive) {
			this.queryString.set(featureSwitchConfig.paramName, "true");
		} else {
			this.queryString.set(featureSwitchConfig.paramName, "");
		}
	}

	getFeatureSwitches() {
		return Object.fromEntries(
			Object.values(FeatureSwitchNames).map((featureName) => [
				featureName,
				reactive({
					name: featureName,
					config: FeatureSwitchConfig[featureName],
					isActive: computed({
						get: () => this.isActive(featureName),
						set: (value) => {
							this.set(featureName, value);
						},
					}),
				}),
			]),
		);
	}
}
