<template>
	<div class="explore-areas-container">
		<KeepAlive>
			<loading-message-with-error
				v-if="(areasQuery.isLoading && (areasQuery.state?.loading ?? LOADING_STATE.LOAD_INITIAL) === LOADING_STATE.LOAD_INITIAL) || areasQuery.error"
				:error="areasQuery.error"
				:retry="areasQuery.retry"
				class="loading-message"
			>
				Loading, please wait...
			</loading-message-with-error>
			<div v-else class="explore-areas">
				<PageHeader>{{ formattedCityName }}</PageHeader>
				<div ref="listRef" class="explore-areas-list">
					<AreaCard
						v-for="area in areas"
						ref="areaCardRefs"
						:key="area.id"
						:area="area"
						:show-mini="true"
						:show-zones="true"
						:should-animate-expanding="isMounted"
						:is-expanded="selectedAreaId === area.id"
					/>
				</div>
			</div>
		</KeepAlive>
	</div>
</template>

<script setup>
import { inject, computed, reactive, ref, onMounted, capitalize, watch, onActivated } from "vue";
import { useRouter } from "vue-router";

import { LOADING_STATE, PageNames, SortOrder } from "../constants/index.js";
import { sorters, urlBuilder, useIsRouteActive, useRouteParams, waitForDataSSR } from "../helpers/index.js";
import { useScrollMemory } from "../functions/scroll-memory/index.js";

import AreaCard from "./AreaCard.vue";
import PageHeader from "./PageHeader.vue";

const model = inject("model");
const logger = inject("logger").nested({ name: "ExploreAreas" });

const listRef = ref(null),
	areaCardRefs = reactive([]);
const isMounted = ref(false);

const { isRouteActive } = useIsRouteActive(null, { debugLabel: "ExploreAreas" });
const router = useRouter();
const routeParams = useRouteParams();
const formattedCityName = computed(() => capitalize(routeParams.cityName));
const selectedAreaId = computed(() => routeParams.areaId);
useScrollMemory({ containerRef: listRef, name: computed(() => `explore-areas-${routeParams.cityName}-${selectedAreaId.value}`), isPersistent: false });

const areasQuery = model.queries.GetAreas({
	cityName: computed(() => routeParams.cityName),
});

const areas = computed(() => [...(areasQuery.model?.areas.city.areas ?? [])].sort(sorters.sortAreas[SortOrder.NAME]));

onMounted(() => {
	isMounted.value = true;
});

onActivated(() => {
	selectFirstAreaIfNoneSelected();
});

watch(areas, () => {
	selectFirstAreaIfNoneSelected();
});

await waitForDataSSR(areasQuery, logger);

function selectFirstAreaIfNoneSelected() {
	if (areas.value && !selectedAreaId.value) {
		const firstArea = areas.value[0];
		if (firstArea && isRouteActive.value) {
			router.replace(urlBuilder(PageNames.EXPLORE_AREA, { cityName: routeParams.cityName, area: firstArea }, router.currentRoute.value.query));
		}
	}
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/variables_new.scss";

.explore-areas-container {
	display: flex;
	flex-direction: column;
	height: 100%;
	overflow: auto;
	overscroll-behavior: none;
	margin-top: $spacing;
	@include centre-loading-message;

	.explore-areas {
		display: flex;
		flex-direction: column;
		gap: calc($spacing * 0.5);
		overflow: auto;
		overscroll-behavior: none;

		.explore-areas-list {
			height: 100%;
			width: 100%;
			overflow: auto;
			overscroll-behavior: none;
			display: flex;
			flex-direction: column;
			align-content: start;
			padding: 0 0 calc($spacing * 1.5) 0;
			box-sizing: border-box;
		}
	}
}
</style>
