<template>
	<div>
		<dl>
			<dt>Feature Switches</dt>
			<dd>
				<label v-for="featureSwitch in featureSwitches" :key="featureSwitch.name" class="feature-switch">
					<input :id="featureSwitch.name" v-model="featureSwitch.isActive" type="checkbox" />
					{{ featureSwitch.name }}
				</label>
			</dd>
		</dl>
	</div>
</template>

<script setup>
import { inject } from "vue";

const featureSwitchManager = inject("featureSwitchManager");
const featureSwitches = featureSwitchManager.getFeatureSwitches();
</script>

<style scoped lang="scss">
@import "../../assets/styles/variables_new.scss";

dl {
	display: flex;
	flex-flow: row wrap;
	gap: $spacing;
	margin: 0;
}
dt {
	flex-basis: calc(35% - ($spacing * 2));
	border: 1px solid $border-color-primary;
	background: $background-color-tertiary;
	color: $text-color-secondary;
	text-align: right;
	text-align: right;
	padding: $spacing $spacing $spacing 0;
	box-sizing: border-box;
}
dd {
	flex-basis: calc(65% - ($spacing * 2));
	flex-grow: 1;
	border: 1px solid $border-color-primary;
	display: flex;
	flex-direction: column;
	justify-content: center;
	box-sizing: border-box;
	padding: $spacing;
	gap: calc($spacing / 2);
	margin-left: 0;

	.feature-switch {
		display: flex;
		gap: calc($spacing/2);
	}
}
</style>
