<template>
	<div :class="{ 'loading-message-with-error': true, 'full-screen': props.fullScreen }">
		<LoadingMessage v-if="!hasError" :show-padding="props.showPadding"><slot>Loading, please wait...</slot></LoadingMessage>
		<ErrorMessage v-else :error="props.error" :retry="props.retry" />
	</div>
</template>

<script setup>
import { computed } from "vue";
import LoadingMessage from "./LoadingMessage.vue";
import ErrorMessage from "./ErrorMessage.vue";

const props = defineProps({
	error: {
		type: [String, Object],
		default: null,
	},
	retry: {
		type: Function,
		default: null,
	},
	fullScreen: {
		type: Boolean,
		default: false,
	},
	showPadding: {
		type: Boolean,
		default: true,
	},
});

const hasError = computed(() => !!props.error);
</script>

<style lang="scss" scoped>
@import "../assets/styles/variables_new.scss";

.loading-message-with-error {
	display: flex;
	flex-direction: column;
	justify-content: center;
	// height: 100%;

	&.full-screen {
		height: 100%;
	}
}
</style>
