<template>
	<div class="app-container">
		<Dialog :dialog="environmentProcessingDialog">
			<DialogLayoutFullscreen :dialog="environmentProcessingDialog" :has-close-button="false" class="environment-processing-dialog">
				<loading-message class="loading-message">{{ processingMessage }}</loading-message>
			</DialogLayoutFullscreen>
		</Dialog>

		<RouterView v-if="!isProcessing" v-slot="{ Component }" class="router-view">
			<Component :is="Component" />
		</RouterView>
	</div>
</template>

<script setup>
import "animate.css";
import "normalize.css";
import { inject, provide, onMounted, computed, watchEffect, ref, watch, toRef } from "vue";

import { useDialog, Dialog, DialogLayoutFullscreen } from "./components/dialog/index.js";
import { EnvironmentProcessingStates } from "./domain/index.js";
import { AppLifeCycleStageNames } from "./constants/index.js";
import { StripePaymentError } from "./errors/index.js";

import LoadingMessage from "./components/LoadingMessage.vue";

const PROCESSING_MESSAGES = {
	[EnvironmentProcessingStates.INITIALISING]: "Checking user profile, please wait...",
	[EnvironmentProcessingStates.SWITCHING_ENVIRONMENT]: "Switching environment, please wait...",
};

const emit = defineEmits(["hydrated"]);

const queryString = inject("queryString");
const environmentManager = inject("environmentManager");
const globalErrorMessage = inject("globalErrorMessage");
const appLifeCycle = inject("appLifeCycle");

const environmentProcessingDialog = useDialog({ isOpen: false });

const isMounted = ref(false);
const isAppHydrated = ref(false);

const isProcessing = computed(() => isMounted.value && environmentManager.isProcessing.value);
const processingMessage = computed(() => (isProcessing.value ? PROCESSING_MESSAGES[environmentManager.processingState.value] : null));

watchEffect(() => {
	if (isProcessing.value) {
		environmentProcessingDialog.open();
	} else {
		environmentProcessingDialog.close();
	}
});

watch(
	toRef(queryString, "stripePaymentError"),
	(stripePaymentError) => {
		if (stripePaymentError) {
			globalErrorMessage.show(new StripePaymentError(stripePaymentError));
		}
	},
	{ immediate: true },
);

onMounted(async () => {
	isMounted.value = true;
	isAppHydrated.value = true;
	await appLifeCycle.trigger(AppLifeCycleStageNames.APP_MOUNTED);
});

provide("isAppHydrated", isAppHydrated);

await appLifeCycle.trigger(AppLifeCycleStageNames.INITIALISATION);
await appLifeCycle.trigger(AppLifeCycleStageNames.MOBILE_APP_INITIALISATION);

emit("hydrated");
</script>
``

<style lang="scss" scoped>
@import "./assets/styles/variables_new.scss";

.app-container {
	height: 100%;
}

.environment-processing-dialog {
	.dialog {
		animation-duration: 0.5s;

		& > .content {
			display: flex;
			align-items: flex-start;
			justify-content: center;

			.loading-message {
				height: 100%;
			}
		}
	}
}

@media (min-width: $bp-medium) {
	.environment-processing-dialog {
		max-height: 200px !important;
		max-width: min(500px, 80vw) !important;
	}
}
</style>
