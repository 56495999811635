<template>
	<div class="venue-booking-stage-reservation-confirmed">
		<VenueBookingHeader :venue="venue" />
		<div class="confirmation-message">
			<div class="heading">Booking Confirmed</div>
			<div class="reservation-date">{{ formattedReserationDate }} at {{ formattedReservationTime }} for {{ props.partySize }}</div>
			<div v-html="confirmationMessage" />
			<div class="note">To amend or cancel your booking, please do so through your email confirmation with the booking provider.</div>
		</div>
	</div>
</template>

<script setup>
import { computed } from "vue";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import utc from "dayjs/plugin/utc";
dayjs.extend(duration);
dayjs.extend(utc);

import VenueBookingHeader from "./VenueBookingHeader.vue";

const props = defineProps({
	venueBooking: {
		type: Object,
		required: true,
	},
	confirmationMessage: {
		type: String,
		required: true,
	},
	confirmationCode: {
		type: String,
		required: true,
	},
	time: {
		type: String,
		required: true,
	},
	partySize: {
		type: String,
		required: true,
	},
	manageReservationUrl: {
		type: String,
		required: true,
	},
});

const venue = computed(() => props.venueBooking.venue.value);
const formattedReserationDate = computed(() => dayjs.utc(props.time).format("dddd, MMMM D, YYYY"));
const formattedReservationTime = computed(() => dayjs.utc(props.time).format("h:mm A"));
</script>

<style scoped lang="scss">
@import "../../assets/styles/variables_new.scss";

.venue-booking-stage-reservation-confirmed {
	display: flex;
	flex-direction: column;
	gap: calc($spacing * 1.5);
	font-size: $text-size-primary;
	flex-grow: 1;
	align-items: center;
	// justify-content: center;

	.confirmation-message {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: calc($spacing);
		box-sizing: border-box;
		max-width: 80%;
		padding: calc($spacing * 2);
		border: $border-size-primary solid $border-color-quinary;
		border-radius: $border-radius-primary;

		> div {
			text-align: center;
		}

		.heading {
			font-weight: $text-bold-primary;
			font-size: $text-size-quaternary;
		}

		.reservation-date {
			font-weight: $text-bold-primary;
		}

		.note {
			// font-style: italic;
		}
	}
}
</style>
