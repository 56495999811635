import { ExternallyResolvablePromise } from "./ExternallyResolvablePromise.js";

import loggingMessages from "./DeviceReady.logging-messages.js";

export class DeviceReady {
	constructor({ logger, platform }) {
		this.logger = logger.nested({ name: "DeviceReady" });
		this.platform = platform;
		this.deviceReadyEventQueue = [];
		this.hasDeviceReadyEventAlreadyTriggered = false;
		this.deviceReadyPromise = new ExternallyResolvablePromise();

		if (!this.platform.isServer) {
			document.addEventListener(
				"deviceready",
				() => {
					this.logger.log(loggingMessages.deviceReadyEventTriggered);
					this.hasDeviceReadyEventAlreadyTriggered = true;
					this.deviceReadyEventQueue.forEach((callback) => callback());
					this.deviceReadyEventQueue.length = 0;
					this.deviceReadyPromise.resolve();
				},
				false,
			);
		}
	}

	async addListener(callback) {
		if (this.hasDeviceReadyEventAlreadyTriggered) {
			this.logger.log(loggingMessages.addListenerAfterDeviceReadyEventTriggered);
			callback();
		} else {
			this.logger.log(loggingMessages.addListenerBeforeDeviceReadyEventTriggered);
			this.deviceReadyEventQueue.push(callback);
		}
		await this.deviceReadyPromise;
	}
}
