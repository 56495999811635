<template>
	<div class="activity-feed-page-preview">
		<div class="row">
			<div class="col row1-1"></div>
			<div class="col row1-2"></div>
		</div>
	</div>
</template>

<script setup></script>

<style scoped lang="scss">
@import "../assets/styles/variables_new.scss";

.activity-feed-page-preview {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	padding: calc($spacing) 0;
	box-sizing: border-box;
	overflow: hidden;

	.row {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		height: 100%;

		.col {
			height: 100%;

			&.row1-1 {
				flex: 1;
				background: url(../assets/page-previews/activity-feed/row1-1.png) no-repeat left top/622px;
			}
			&.row1-2 {
				flex: 1;
				max-width: 100px;
				background: url(../assets/page-previews/activity-feed/row1-2.png) no-repeat right top/470px;
			}
		}
	}
}
</style>
