<template>
	<div class="activity-event">
		<ConditionalRouterLink :enabled="leftSideLink.enabled" :to="leftSideLink.to">
			<ProfileImage :profile-image="leftSideImage" class="profile-image" :size="IMAGE_SIZE" />
		</ConditionalRouterLink>
		<div class="activity-info">
			<!-- <div class="event-description" v-html="eventDescription" /> -->
			<div class="event-description">
				<ConditionalRouterLink :enabled="leftSideLink.enabled" :to="leftSideLink.to" class="left-side">{{ leftSideText }}</ConditionalRouterLink>
				{{ joiningDescription }}
				<ConditionalRouterLink v-if="!isEventForCurrentUser" :enabled="rightSideLink.enabled" :to="rightSideLink.to" class="right-side">{{ rightSideText }}</ConditionalRouterLink>
				{{ suffixDescription }}
			</div>
			<div class="time-from-now">{{ timeFromNow }}</div>
		</div>
		<FollowUserButton v-if="isEventForCurrentUser" :user="event.user" />
		<ConditionalRouterLink v-else :enabled="rightSideLink.enabled" :to="rightSideLink.to">
			<ProfileImage v-if="rightSideImageType === ActivityEventImageTypes.USER" :profile-image="rightSideImage" class="profile-image" :size="IMAGE_SIZE" />
			<ImgIxWithPlaceholder
				v-else
				:src="rightSideImage.url"
				:preview="rightSideImage.preview"
				:sizes="`${IMAGE_SIZE}px`"
				:widths="[IMAGE_SIZE, IMAGE_SIZE * 2, IMAGE_SIZE * 3]"
				:aspect-ratio="1"
				class="target-image"
			/>
		</ConditionalRouterLink>
	</div>
</template>

<script setup>
import { computed, inject, unref } from "vue";
import dayjs from "dayjs";

import { PageNames, UserListDatabaseNames } from "../constants/index.js";
import { urlBuilder } from "../helpers/index.js";

import ProfileImage from "./ProfileImage.vue";
import ImgIxWithPlaceholder from "./imgix/ImgIxWithPlaceholder.vue";
import ConditionalRouterLink from "./ConditionalRouterLink.vue";
import FollowUserButton from "./FollowUserButton.vue";

const IMAGE_SIZE = 48;

const ActivityEventTypes = {
	ADDED_TO_LIST: "ADDED_TO_LIST",
	FOLLOWED: "FOLLOWED",
};

const ActivityEventImageTypes = {
	USER: "USER",
	VENUE: "VENUE",
};

const EVENT_TYPE_INFO = {
	[ActivityEventTypes.ADDED_TO_LIST]: {
		// description: ({ source: venue, target: list, user }) => {
		// 	return {
		// 		[UserListDatabaseNames.BEEN]: `<em>${user?.username}</em> has been to <em>${venue?.name}</em>`,
		// 		[UserListDatabaseNames.FAVOURITES]: `<em>${user?.username}</em> has added <em>${venue?.name}</em> to their favourites`,
		// 		[UserListDatabaseNames.GO_TRY]: `<em>${user?.username}</em> wants to go try <em>${venue?.name}</em>`,
		// 	}[list.name];
		// },
		leftSideText: ({ target: list, user }) => {
			return {
				[UserListDatabaseNames.BEEN]: user?.username,
				[UserListDatabaseNames.FAVOURITES]: user?.username,
				[UserListDatabaseNames.GO_TRY]: user?.username,
			}[list.name];
		},
		rightSideText: ({ source: venue, target: list }) => {
			return {
				[UserListDatabaseNames.BEEN]: venue?.name,
				[UserListDatabaseNames.FAVOURITES]: venue?.name,
				[UserListDatabaseNames.GO_TRY]: venue?.name,
			}[list.name];
		},
		joiningDescription: ({ target: list }) => {
			return {
				[UserListDatabaseNames.BEEN]: "has been to",
				[UserListDatabaseNames.FAVOURITES]: "has added",
				[UserListDatabaseNames.GO_TRY]: "wants to go try",
			}[list.name];
		},
		suffixDescription: ({ target: list }) => {
			return {
				[UserListDatabaseNames.BEEN]: "",
				[UserListDatabaseNames.FAVOURITES]: "to their favourites",
				[UserListDatabaseNames.GO_TRY]: "",
			}[list.name];
		},
		leftSideImage: ({ user }) => user?.profileImage,
		leftSideLink: ({ user }) => ({ enabled: true, to: urlBuilder(PageNames.OTHER_USER_PROFILE, { user }) }),
		rightSideImage: ({ source: venue }) => venue?.images?.[0],
		rightSideImageType: ActivityEventImageTypes.VENUE,
		rightSideLink: ({ source: venue }) => ({ enabled: true, to: urlBuilder(PageNames.EXPLORE_VENUE, { venue, cityName: venue.zone().area().city().name }) }),
	},
	[ActivityEventTypes.FOLLOWED]: {
		// description: ({ user, target }, { isEventForCurrentUser }) =>
		// 	isEventForCurrentUser ? `<em>${user?.username}</em> is now following you` : `<em>${user?.username}</em> is now following <em>${target?.username}</em>`,
		leftSideText: ({ user }) => user?.username,
		rightSideText: ({ target }, { isEventForCurrentUser }) => (isEventForCurrentUser ? null : target?.username),
		joiningDescription: (data, { isEventForCurrentUser }) => (isEventForCurrentUser ? "is now following you" : "is now following"),
		leftSideImage: ({ user }) => user?.profileImage,
		leftSideLink: ({ user }) => ({ enabled: true, to: urlBuilder(PageNames.OTHER_USER_PROFILE, { user }) }),
		rightSideImage: ({ target }, { isEventForCurrentUser }) => (isEventForCurrentUser ? null : target?.profileImage),
		rightSideImageType: ActivityEventImageTypes.USER,
		rightSideLink: ({ target }) => ({ enabled: true, to: urlBuilder(PageNames.OTHER_USER_PROFILE, { user: target }) }),
	},
};

const props = defineProps({
	event: {
		type: Object,
		required: true,
	},
});

const currentEnvironment = inject("currentEnvironment");

const activeUser = computed(() => currentEnvironment.value.activeUser.value);
const userId = computed(() => activeUser.value?.id);

const isEventForCurrentUser = computed(() => props.event.target?.id === userId.value);
const leftSideText = computed(() => EVENT_TYPE_INFO[props.event.eventType].leftSideText(props.event, { isEventForCurrentUser: unref(isEventForCurrentUser) }));
const rightSideText = computed(() => EVENT_TYPE_INFO[props.event.eventType].rightSideText(props.event, { isEventForCurrentUser: unref(isEventForCurrentUser) }));
const joiningDescription = computed(() => EVENT_TYPE_INFO[props.event.eventType].joiningDescription(props.event, { isEventForCurrentUser: unref(isEventForCurrentUser) }));
const suffixDescription = computed(() => EVENT_TYPE_INFO[props.event.eventType].suffixDescription?.(props.event, { isEventForCurrentUser: unref(isEventForCurrentUser) }));
const leftSideImage = computed(() => EVENT_TYPE_INFO[props.event.eventType].leftSideImage(props.event, { isEventForCurrentUser: unref(isEventForCurrentUser) }));
const leftSideLink = computed(() => EVENT_TYPE_INFO[props.event.eventType].leftSideLink(props.event, { isEventForCurrentUser: unref(isEventForCurrentUser) }));
const rightSideImage = computed(() => EVENT_TYPE_INFO[props.event.eventType].rightSideImage(props.event, { isEventForCurrentUser: unref(isEventForCurrentUser) }));
const rightSideImageType = computed(() => EVENT_TYPE_INFO[props.event.eventType].rightSideImageType);
const rightSideLink = computed(() => EVENT_TYPE_INFO[props.event.eventType].rightSideLink(props.event, { isEventForCurrentUser: unref(isEventForCurrentUser) }));

const timeFromNow = computed(() => dayjs(props.event.createdAt).fromNow());
</script>

<style scoped lang="scss">
@import "../assets/styles/variables_new.scss";

$IMAGE-SIZE: 48px;

.activity-event {
	display: flex;
	gap: calc($spacing);
	overflow: auto;
	align-items: flex-start;
	margin: 0 calc($spacing/2);

	.profile-image {
		flex-shrink: 0;
		width: $IMAGE-SIZE;
		height: $IMAGE-SIZE;
	}

	.target-image {
		flex-shrink: 0;
		width: $IMAGE-SIZE;
		height: $IMAGE-SIZE;
		border-radius: $border-radius-primary;
		overflow: hidden;
	}

	.activity-info {
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		gap: calc($spacing/4);
		justify-content: center;
		align-self: center;
		overflow: auto;

		.event-description {
			font-size: $text-size-primary;
			// text-wrap: nowrap;
			// @include text-ellipsis;

			.left-side,
			.right-side {
				font-weight: $text-bold-secondary;
				font-style: normal;
			}

			// :deep(> em) {
			// 	font-weight: $text-bold-secondary;
			// 	font-style: normal;
			// }
		}

		.time-from-now {
			font-size: $text-size-tertiary;
			color: $text-color-secondary;
			@include text-ellipsis;
		}
	}
}
</style>
