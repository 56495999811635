<template>
	<div class="search-results" tabindex="1">
		<slot />
	</div>
</template>

<style lang="scss" scoped>
@import "../assets/styles/variables_new.scss";

.search-results {
	display: flex;
	flex-direction: column;
	gap: calc($spacing * 2);

	&:focus {
		outline: none;
	}
}
</style>
