<template>
	<div class="download">
		<BackButton class="back-button">back to onezone</BackButton>
		<h1>Download</h1>
	</div>
</template>

<script setup>
import { onActivated, onMounted } from "vue";
import { useRouter } from "vue-router";

import BackButton from "../components/BackButton.vue";

const router = useRouter();

onMounted(() => {
	router.replace("/");
});

onActivated(() => {
	router.replace("/");
});
</script>

<style scoped lang="scss">
@import "../assets/styles/variables_new.scss";

.download {
	background-color: $background-color-primary;
	padding: $spacing;

	.back-button {
		margin: $spacing 0;
	}

	h1 {
		font-size: $text-size-header-tertiary;
	}
}

@media (min-width: $bp-medium) {
	.download {
		padding: 0 calc($spacing / 2) $spacing 0;
	}
}
</style>
