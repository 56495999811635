<template>
	<div :class="{ 'empty-results-message': true, 'include-submit': props.includeSubmit }">
		<slot />
		<button v-if="props.includeSubmit" type="submit" class="push-button-primary" @click="submitOnClick">Send</button>
	</div>
</template>

<script setup>
import { inject } from "vue";

const props = defineProps({
	includeSubmit: {
		type: Boolean,
		default: false,
	},
});

const globalMessageDialog = inject("globalMessageDialog");
const searchResults = inject("searchResults", null);

function submitOnClick() {
	globalMessageDialog.show("Thanks for helping us curate the world.");
	searchResults.clearSearchText();
}
</script>

<style scoped lang="scss">
@import "../assets/styles/variables_new.scss";

.empty-results-message {
	// height: 100%;
	text-align: center;
	font-size: $text-size-primary;
	font-weight: $text-bold-secondary;
	box-sizing: border-box;
	padding: calc($spacing * 4) calc($spacing * 2);

	&.include-submit {
		// height: auto;
		max-width: 380px;
		align-self: center;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: calc($spacing * 1.5);
		border: $border-size-primary solid $border-color-primary;
		border-radius: $border-radius-primary;
		padding: calc($spacing) calc($spacing * 2);
		margin: calc($spacing) calc($spacing * 2);
	}
}
</style>
