const HORIZONTAL_LINE = `${"-".repeat(60)}\n`;

export function getMultipleErrorStacks(errors) {
	const multipleErrors = errors.map((error, index) => indent(2, `${HORIZONTAL_LINE}Error #${index + 1} -->\n${error.stack}`)).join("\n");
	return multipleErrors;
}

export function getErrorStack(error, level = 0) {
	const stack = getErrorMessageAndStack(error);
	const otherProps = getOtherProps(error);
	const extensions = getApolloExtensions(error, level);
	return indent(level * 2, `${stack}\n${otherProps}${extensions}`);
}

export function getApolloExtensions(error, level = 0) {
	let output = "";

	if (error.extensions && error.extensions.exception) {
		output += indent((level + 1) * 2, `${HORIZONTAL_LINE}Caused by:\n`) + getErrorStack(error.extensions.exception, level + 1);
	}
	return output;
}

function getOtherProps(error) {
	let output = "";
	const errorProps = Object.entries(error).filter(([errorPropKey, errorPropValue]) => ["name", "message", "extensions"].indexOf(errorPropKey) < 0 && errorPropValue);
	if (errorProps.length > 0) {
		output += `${JSON.stringify(Object.fromEntries(errorProps), null, 2)}\n`;
	}
	return output;
}

/* function to indent a string across multiple lines */
function indent(indentation, str, char = " ") {
	const indentSequence = char.repeat(indentation);
	return str
		.split("\n")
		.map((line) => indentSequence + line)
		.join("\n");
}

export const getErrorMessageAndStack = (error) => {
	let errorMessage = error.stack;
	if (!errorMessage.includes(error.message)) {
		errorMessage = `${error.message}\n${errorMessage}`;
	}
	return errorMessage;
};
