import { ErrorNested } from "./ErrorNested.js";

export class AppLifeCycleError extends ErrorNested {
	constructor(appLifeCycleStageName, error, extensions) {
		super("AppLifeCycleError", `Critical error whilst executing app life cycle stage : ${appLifeCycleStageName}`, {
			exception: error,
			code: "APP_LIFE_CYCLE_ERROR",
			...extensions,
		});
	}
}
