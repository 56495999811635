<template>
	<div ref="dialogRef" :class="{ 'dialog-container': true, 'full-screen-mobile': props.isFullScreenMobile }">
		<safe-area v-if="props.hasSafeArea" />
		<div class="dialog">
			<a v-if="props.hasCloseButton" href="javascript:;" class="close-button" @click="props.dialog.close()">
				<!-- <span v-if="pageSize.isSmallerThanMedium">&lt;</span>
			<span v-if="!pageSize.isSmallerThanMedium">X</span> -->
				<FontAwesomeIcon icon="xmark" class="icon" />
			</a>
			<div v-if="hasHeader" class="header">
				<h2 v-if="props.title">{{ props.title }}</h2>
			</div>
			<div class="content">
				<slot />
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, computed } from "vue";
import { onClickOutside } from "@vueuse/core";

// import { usePageSize } from "../../domain/index.js";

const props = defineProps({
	dialog: {
		type: Object,
		required: true,
	},
	title: {
		type: String,
		default: null,
	},
	hasCloseButton: {
		type: Boolean,
		default: true,
	},
	hasSafeArea: {
		type: Boolean,
		default: true,
	},
	isFullScreenMobile: {
		type: Boolean,
		default: true,
	},
});

const dialogRef = ref(null);
onClickOutside(dialogRef, () => {
	if (props.hasCloseButton) {
		props.dialog.close();
	}
});

// const pageSize = usePageSize();

const hasHeader = computed(() => props.title);
</script>

<style lang="scss" scoped>
@import "../../assets/styles/variables_new.scss";
.dialog-container {
	background-color: $background-color-primary;
	border-radius: $border-radius-primary;
	@include drop-shadow($opacity: 0.2);
	overflow: hidden;
	max-width: 90vw;
	max-height: 90dvh;

	display: flex;
	flex-direction: column;

	&::backdrop {
		background-color: rgba(0, 0, 0, 0.5);
	}

	&.full-screen-mobile {
		max-width: none;
		max-height: none;
		width: 100vw;
		height: 100vh;
		border-radius: 0;
	}

	.dialog {
		position: relative;
		overflow: auto;
		height: 100%;
		display: flex;
		flex-direction: column;

		.close-button {
			position: absolute;
			top: calc($spacing/1.5);
			right: calc($spacing/1.5);
			z-index: 1;
			color: $background-color-primary;
			// mix-blend-mode: difference;

			:deep(path) {
				stroke: $text-color-quaternary;
				stroke-width: 6%;
				stroke-linejoin: round;
			}

			.icon {
				width: 22px;
				height: 22px;
			}
		}

		.header {
			display: flex;
			border-bottom: $border-size-primary solid $border-color-primary;
			padding: calc($spacing / 2) 0;
			min-height: 22px;

			a {
				position: absolute;
				font-size: 1.5rem;
				font-weight: 500;
				line-height: 1.4rem;
				padding: 0 $spacing;
			}

			h2 {
				width: 100vw;
				margin: 0;
				text-align: center;
			}
		}

		.content {
			display: flex;
			flex-direction: column;
			mix-blend-mode: normal;
			height: 100%;
			overflow: auto;
			box-sizing: border-box;
			@include centre-loading-message;
		}
	}
}

@media (min-width: $bp-medium) {
	.dialog-container {
		width: 80vw;
		height: 90vh;
		max-width: 90vw;
		max-height: 90dvh;

		&.full-screen-mobile {
			width: 80vw;
			height: 90vh;
			max-width: 90vw;
			max-height: 90dvh;
			border-radius: $border-radius-primary;
		}

		.dialog {
			.header {
				a {
					font-size: 1.2rem;
				}
			}
		}
	}
}
</style>
