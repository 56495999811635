import { computed } from "vue";

export default class {
	constructor({ logger, modelBuilder, api, authentication }) {
		this.logger = logger.nested({ name: "Model" });
		this.modelBuilder = modelBuilder;
		this.api = api;
		this.authentication = authentication;
	}

	execute({ isSignedIn } = {}) {
		return this.modelBuilder.build({
			name: "GetProfile",
			args: { isSignedIn },
			execute: async ({ args }) => {
				let profile = null;

				if (args.isSignedIn) {
					const currentUser = await this.api.queries.getCurrentUser({}, { useClientCache: true, accessToken: this.authentication.getAccessToken });
					profile = computed(() => currentUser.me);
				}

				return { profile };
			},
		});
	}
}
