import { onMounted, reactive } from "vue";

import { breakpoints } from "./breakpoints.js";

export const usePageSize = () => {
	const pageSize = reactive({
		isSmallerThanMedium: false,
	});

	onMounted(() => {
		pageSize.isSmallerThanMedium = breakpoints.smaller("medium");
	});

	return pageSize;
};
