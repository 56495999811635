<template>
	<button class="share-button" @click="shareOnClick">
		<img :src="shareIconSrc" class="icon" />
		<span v-if="props.showLabel">Share</span>
	</button>
</template>

<script setup>
import { computed, inject } from "vue";
import { useRoute } from "vue-router";
import { Share } from "@capacitor/share";

import { getAbsoluteUrl } from "../helpers/index.js";

import ShareIconAndroid from "../assets/icons/share-button-android.svg";
import ShareIconIOS from "../assets/icons/share-button-ios.svg";

const props = defineProps({
	url: {
		type: String,
		default: null,
	},
	title: {
		type: String,
		default: null,
	},
	text: {
		type: String,
		default: null,
	},
	dialogTitle: {
		type: String,
		default: null,
	},
	showLabel: {
		type: Boolean,
		default: true,
	},
});

const route = useRoute();

const canShare = inject("canShare");
const alertDialogRef = inject("alertDialogRef");
const platform = inject("platform");

const shareIconSrc = computed(() => (platform.isAnyAndroid ? ShareIconAndroid : ShareIconIOS));

async function shareOnClick() {
	/* make the url absolute if it isn't already */
	const initialUrl = props.url ?? route.fullPath;
	const url = getAbsoluteUrl(initialUrl);
	// const url = initialUrl.startsWith(window.location.origin) ? initialUrl : `${window.location.origin}${initialUrl}`;
	try {
		if (canShare) {
			const text = [props.title, props.text, url].filter((v) => !!v).join("\n");
			await Share.share({
				title: props.title,
				text,
				dialogTitle: props.dialogTitle,
			});
		} else {
			await navigator.clipboard.writeText(url);
			alertDialogRef.value.open({
				message: "Link copied to clipboard",
			});
		}
	} catch (error) {
		/* we swallow errors as they just mean either used cancelled or browser doesn't support */
	}
}
</script>

<style scoped lang="scss">
@import "../assets/styles/variables_new.scss";

.share-button {
	cursor: pointer;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: calc($spacing/6);
	font-size: $text-size-tertiary;
	color: $text-color-primary;

	.icon {
		padding: 2px;
		box-sizing: border-box;
		height: 26px;
		aspect-ratio: 1;
	}
}
</style>
