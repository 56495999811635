export default {
	initialisingBranch: {
		level: "info",
		message: () => `👣 Initialising Branch`,
	},
	branchDeepLinkNavigating: {
		level: "info",
		message: ({ relativeUrl }) => `👣 Branch deep link navigating to ${relativeUrl}`,
	},
	branchDeepLinkNoNavigationData: {
		level: "info",
		message: ({ event }) => `👣 Branch deep link contains no navigation data.\n${event}`,
	},
	intialisationError: {
		level: "error",
		message: ({ errorJson }) => `Error initialising Branch:\n${errorJson}`,
	},
	identifyingUserToBranch: {
		level: "info",
		message: ({ userId }) => `🪪 ⇨ 👣 Identifying user ${userId} to Branch`,
	},
	loggingOutUserFromBranch: {
		level: "info",
		message: () => `👣 Logging out user from Branch`,
	},
};
