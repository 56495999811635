<template>
	<Dialog ref="confirmDialogRef" enter-name="apply" escape-name="ignore">
		<DialogSimpleLayout :has-close-button="false">
			<DialogMessage>
				You have {{ pluralizeResultCount(pendingFilterCount, "filter") }} which {{ pendingFilterCount > 1 ? "have" : "has" }} not yet been applied. What would you like to do?
			</DialogMessage>
			<DialogButtons>
				<DialogButton name="ignore">Ignore</DialogButton>
				<DialogButton name="apply">Apply Now</DialogButton>
			</DialogButtons>
		</DialogSimpleLayout>
	</Dialog>
	<Dialog ref="dialogRef" class="dialog" @closed="dialogClosed">
		<!-- <SearchFilters ref="searchFiltersRef" :search-text="props.searchText" :tags="props.tags" @close="close" @filters-clear="filtersOnClear" @filters-apply="filtersOnApply" /> -->

		<div class="venue-filter-dialog">
			<SearchFilters ref="searchFiltersRef" :search-text="''" :tags="props.tags" @close="close" />
			<div class="filter-footer">
				<div class="button-group">
					<button :disabled="!appliedFilterCount" class="push-button-primary" @click="clearAllFilters">Clear All{{ appliedFilterCount ? ` (${appliedFilterCount})` : "" }}</button>
					<button :disabled="!pendingFilterCount" class="push-button-primary" @click="persistFilters">
						Apply {{ pluralize(pendingFilterCount, "Filter") }}{{ pendingFilterCountDisplay ? ` (${pendingFilterCountDisplay})` : "" }}
					</button>
				</div>
				<div class="button-group">
					<button class="push-button-primary" @click="close">Close</button>
				</div>
			</div>
		</div>
	</Dialog>
</template>

<script setup>
import { computed, reactive, ref } from "vue";

import { formatFilterCountForDisplay, pluralize, useFilterState, useIsRouteActive, pluralizeResultCount } from "../helpers/index.js";

import Dialog from "./Dialog.vue";
import DialogSimpleLayout from "./DialogSimpleLayout.vue";
import DialogMessage from "./DialogMessage.vue";
import DialogButtons from "./DialogButtons.vue";
import DialogButton from "./DialogButton.vue";
import SearchFilters from "./SearchFilters.vue";

const props = defineProps({
	tags: {
		type: Array,
		default: () => [],
	},
});

const isRouteActive = useIsRouteActive();
const filterStateManager = useFilterState({ confirmPendingFilterState });

defineExpose(
	reactive({
		open,
		close,
		filterStateManager,
	}),
);

const dialogRef = ref(null),
	searchFiltersRef = ref(null),
	confirmDialogRef = ref(null);
const isConfirmingPendingFilterState = ref(false);

const appliedFilterCount = computed(() => filterStateManager.appliedFilterCount);
const pendingFilterCount = computed(() => filterStateManager.pendingFilterCount);
const pendingFilterCountDisplay = computed(() => (pendingFilterCount.value ? formatFilterCountForDisplay(pendingFilterCount.value) : null));

function open() {
	const dialog = dialogRef.value;
	if (dialog && !dialog.isOpen) {
		dialog.open();
	}
}

async function dialogClosed() {
	await confirmPendingFilterState();
}

async function close() {
	await confirmPendingFilterState();
	dialogRef.value.close();
}

function clearAllFilters() {
	filterStateManager.clearFilterState();
	dialogRef.value.close();
}

function persistFilters() {
	filterStateManager.applyPendingFilterState();
	dialogRef.value.close();
}

async function confirmPendingFilterState() {
	if (pendingFilterCount.value > 0 && isRouteActive.value) {
		// logger.log(loggingMessages.confirmPendingFilterState, { count: pendingFilterCount.value });
		isConfirmingPendingFilterState.value = true;
		const result = await confirmDialogRef.value.open();
		isConfirmingPendingFilterState.value = false;
		// logger.log(loggingMessages.confirmPendingFilterStateHandlingResult, { result: result.name });
		handleConfirmResult(result);
		return result;
	}
	return null;
}

function handleConfirmResult(result) {
	if (result?.name === "apply") {
		filterStateManager.applyPendingFilterState();
	} else if (result) {
		filterStateManager.clearPendingFilterState();
	}
}
</script>

<style scoped lang="scss">
@import "../assets/styles/variables_new.scss";

.dialog {
	width: min(90vw, 355px);
	padding: $spacing;
	box-sizing: border-box;

	:deep(.venue-filter-dialog) {
		display: flex;
		flex-direction: column;
		overflow: auto;

		.filter-footer {
			display: flex;
			justify-content: space-between;
			gap: calc($spacing/4);
			border-top: $border-size-primary solid $border-color-primary;
			padding-top: calc($spacing / 1.2);
			box-sizing: border-box;
			// margin-bottom: calc($spacing / 2);

			.button-group {
				display: flex;
				gap: calc($spacing / 4);
				overflow: auto;
			}

			// > * {
			// 	flex: 1;
			// 	white-space: nowrap;
			// 	max-width: 120px;
			// }
		}
	}
}
</style>
