<template>
	<div class="message"><slot /></div>
</template>

<script setup></script>

<style scoped lang="scss">
@import "../assets/styles/variables_new.scss";

.message {
	font-size: $text-size-primary;
	text-align: center;
}
</style>
