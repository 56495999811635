<template>
	<NewList
		v-slot="{ item, isItemVisible }"
		class="activity-feed-wrapper"
		name="activity-feed"
		list-class="activity-feed-list"
		:items="props.feed"
		:direction="ListDirections.VERTICAL"
		:item-height="48"
		:gap-size="14"
		:item-buffer-count="2"
		:enable-pull-to-refresh="false"
		:enable-load-more="false"
		:query="props.query"
		:is-loading-more="props.query.isLoading && (props.query.state?.loading ?? LOADING_STATE.LOAD_INITIAL) === LOADING_STATE.LOAD_MORE"
		:show-debug-panel="false"
		empty-message="Search for friends and start following to see their activity"
		@refresh="onRefresh"
		@load-more="onLoadMore"
	>
		<ActivityEvent v-if="isItemVisible" :event="item" />
	</NewList>
</template>

<script setup>
import { ListDirections } from "./IntersectionObserverList/index.js";
import { LOADING_STATE } from "../constants/index.js";

import NewList from "./NewList.vue";
import ActivityEvent from "./ActivityEvent.vue";

const props = defineProps({
	feed: {
		type: Array,
		required: true,
	},
	query: {
		type: Object,
		required: true,
	},
});

const emits = defineEmits(["refresh", "loadMore"]);

function onRefresh() {
	emits("refresh");
}

function onLoadMore() {
	emits("loadMore");
}
</script>

<style scoped lang="scss">
@import "../assets/styles/variables_new.scss";

.activity-feed-wrapper {
	:deep(.activity-feed-list) {
		padding: calc($spacing) calc($spacing / 2) calc($spacing) calc($spacing / 2);
	}
}

@media (min-width: $bp-medium) {
	.activity-feed-wrapper {
		margin: calc($spacing / 2);
	}
}
</style>
