export default {
	searchHereClicked: {
		level: "verbose",
		message: () => `Map search here clicked`,
	},
	searchCityClicked: {
		level: "verbose",
		message: ({ city }) => `Map search city clicked for ${city}`,
	},
	searchTextChanged: {
		level: "verbose",
		message: ({ searchText }) => `Map search text changed to ${searchText}`,
	},
	searchBoxFocused: {
		level: "verbose",
		message: () => `Map search box focused`,
	},
	searchBoxFiltersToggled: {
		level: "verbose",
		message: () => `Map search box filters toggled`,
	},
	searchBoxResultsToggled: {
		level: "verbose",
		message: () => `Map search box results toggled`,
	},
	onClickOutside: {
		level: "verbose",
		message: () => `Map clicked outside search box`,
	},
	confirmPendingFilterState: {
		level: "verbose",
		message: ({ count }) => `User has ${count} pending map filters, showing confirmation dialog...`,
	},
	confirmPendingFilterStateHandlingResult: {
		level: "verbose",
		message: ({ result }) => `...user pressed ${result} on pending map filters confirmation dialog, handling result`,
	},
	errorOnClickOutside: {
		level: "error",
		message: ({ error }) => `Error occurred while handling click outside:\n${error}`,
	},
};
