<template>
	<router-link v-if="props.enabled" v-bind="props" @click.stop><slot /></router-link>
	<div v-else class="no-link"><slot /></div>
</template>

<script setup>
import { RouterLink } from "vue-router";

const props = defineProps({
	...RouterLink.props,
	enabled: {
		type: Boolean,
		default: true,
	},
});
</script>
