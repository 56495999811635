<template>
	<div v-if="previewUrl" class="profile-image-preview">
		<img :src="previewUrl" />
	</div>
	<ImgIxWithPlaceholder v-else-if="hasProfileImage" :src="props.profileImage?.url" :preview="props.profileImage?.preview" :sizes="sizes" :widths="widths" :aspect-ratio="1" class="profile-image" />
	<div v-else class="no-profile-image">
		<img src="../assets/icons/no-profile-image.png" />
	</div>
</template>

<script setup>
import { computed } from "vue";
import ImgIxWithPlaceholder from "../components/imgix/ImgIxWithPlaceholder.vue";

const props = defineProps({
	profileImage: {
		type: Object,
		default: null,
	},
	previewUrl: {
		type: String,
		default: null,
	},
	size: {
		type: Number,
		default: 80,
	},
});

const sizes = computed(() => `${props.size}px`);
const widths = computed(() => [props.size, props.size * 2, props.size * 3]);
const hasProfileImage = computed(() => !!props.profileImage);
</script>

<style scoped lang="scss">
@import "../assets/styles/variables_new.scss";

.profile-image,
.profile-image-preview {
	// width: 100%;
	border-radius: 50%;
	overflow: hidden;
	aspect-ratio: 1;
}

// .profile-image {
// 	:deep(> img) {
// 		// width: 100% !important;
// 	}
// }

.profile-image-preview,
.no-profile-image {
	display: flex;
	align-items: center;
	:deep(img) {
		width: 100% !important;
	}
}

.no-profile-image {
	background-color: $background-color-quinary;
	:deep(img) {
		padding: calc($spacing/ 2);
		box-sizing: border-box;
	}
}
</style>
