import { computed } from "vue";

export default class {
	constructor({ logger, modelBuilder, api, authentication }) {
		this.logger = logger.nested({ name: "Model" });
		this.modelBuilder = modelBuilder;
		this.api = api;
		this.authentication = authentication;
	}

	execute({ cityName, isSignedIn } = {}) {
		return this.modelBuilder.build({
			name: "GetPremium",
			args: { cityName, isSignedIn },
			execute: async ({ args }) => {
				const cities = await this.api.queries.getCitiesAnonymous({}, { useClientCache: true });
				const foundCity = cities.cities.find(({ name }) => name.toLowerCase() === args.cityName);
				if (!foundCity) {
					throw new Error(`City '${args.cityName}' not found`);
				}

				const data = await this.api.queries.getPremium({ cityId: foundCity.id }, { useClientCache: false, accessToken: args.isSignedIn ? this.authentication.getAccessToken : undefined });
				const launches = computed(() => data.city.launches);
				const perks = computed(() => data.city.perks);

				return { launches, perks };
			},
		});
	}
}
