<template>
	<Page>
		<DialogFullScreenPageLayout class="profile-settings-page-layout" :show-header-on-desktop="true">
			<template #header>
				<h2>Edit Profile</h2>
			</template>
			<template #default>
				<Settings />
			</template>
			<template #header-tray-right>
				<button v-if="isSignedIn" class="link-button-secondary log-out-button-mobile" @click="currentEnvironment?.logout">Logout</button>
			</template>
		</DialogFullScreenPageLayout>
	</Page>
</template>

<script setup>
import { computed, inject } from "vue";

import Page from "../components/Page.vue";
import DialogFullScreenPageLayout from "../components/DialogFullScreenPageLayout.vue";
import Settings from "../components/Settings.vue";

const currentEnvironment = inject("currentEnvironment");

const isSignedIn = computed(() => currentEnvironment.value.isSignedIn.value);
</script>

<style lang="scss" scoped>
@import "../assets/styles/variables_new.scss";

.profile-settings-page-layout {
	.log-out-button-mobile {
		display: flex;
		height: 22px;
		box-sizing: content-box;
		align-items: center;
		font-size: $text-size-primary;
		font-weight: $text-bold-tertiary;
	}
}

@media (min-width: $bp-medium) {
	.profile-settings-page-layout {
		.log-out-button-mobile {
			display: none;
		}
	}
}
</style>
