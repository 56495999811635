<template>
	<VenueDetailSection title="Tags" class="venue-tags">
		<router-link v-for="tag in sortedTagsToShow" :key="tag" :to="{ name: PageNames.EXPLORE_TAG, params: { tagId: tag.id } }" class="tag">
			<SearchContextLabel :has-remove-button="false" :invert-colors="false">{{ tag.name }}</SearchContextLabel>
		</router-link>
	</VenueDetailSection>
</template>

<script setup>
import { computed } from "vue";

import { TagCategory, PageNames, SortOrder } from "../../constants/index.js";
import { sortZones } from "../../helpers/sorters/index.js";

import VenueDetailSection from "./VenueDetailSection.vue";
import SearchContextLabel from "../SearchContextLabel.vue";

const props = defineProps({
	venue: {
		type: Object,
		required: true,
	},
});

const tagsToShow = computed(() => props.venue.tags?.filter(({ category }) => category !== TagCategory.MEAL_TYPE) ?? []);
const sortedTagsToShow = computed(() => [...tagsToShow.value].sort(sortZones[SortOrder.NAME]));
</script>

<style scoped lang="scss">
@import "../../assets/styles/variables_new.scss";

.venue-tags {
	> :deep(.detail) {
		flex-direction: row;
		flex-wrap: wrap;
		gap: calc($spacing / 2);
	}
}
</style>
