<template>
	<div class="venue-filter-client-side">
		<SearchFiltersButton :show-label="false" :applied-filter-count="appliedFilterCountDisplay" @click.stop="showFilters" />
		<VenueFilterDialog ref="venueFilterDialogRef" :tags="tags" />
	</div>
</template>

<script setup>
import { ref, computed, reactive } from "vue";

import { formatFilterCountForDisplay } from "../helpers/index.js";
import { filterVenuesByFilterState } from "../helpers/filters/index.js";

import SearchFiltersButton from "./SearchFiltersButton.vue";
import VenueFilterDialog from "./VenueFilterDialog.vue";

const props = defineProps({
	venues: {
		type: Array,
		required: true,
	},
	tags: {
		type: Array,
		required: true,
	},
	excludeTags: {
		type: Array,
		default: null,
	},
});

const venueFilterDialogRef = ref(null);

const venues = computed(() => props.venues);
const tags = computed(() => props.tags.filter((tag) => (props.excludeTags ? !props.excludeTags.includes(tag.id) : true)));
const filterStateManager = computed(() => venueFilterDialogRef.value?.filterStateManager);
const filteredVenues = computed(() => filterVenuesByFilterState(venues.value, filterStateManager.value.filterState));
const appliedFilterCountDisplay = computed(() => formatFilterCountForDisplay(filterStateManager.value?.appliedFilterCount));

defineExpose(
	reactive({
		filteredVenues,
		filterStateManager,
	}),
);

function showFilters() {
	venueFilterDialogRef.value?.open();
}
</script>

<style scoped lang="scss">
@import "../assets/styles/variables_new.scss";

.venue-filter-client-side {
	display: flex;
	align-items: center;
}
</style>
