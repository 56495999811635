import { toRef, watch } from "vue";
import { PageNames, AppLifeCycleStageNames } from "../constants/index.js";
import { urlBuilder } from "../helpers/index.js";
import loggingMessages from "./AppLogic.logging-messages.js";

export class AppLogic {
	constructor({ logger, appLifeCycle, router, environmentManager, featureSwitchManager, globalErrorMessage, queryString, tracking, mobileApp, branch }) {
		this.logger = logger.nested({ name: "AppLogic" });
		this.appLifeCycle = appLifeCycle;
		this.router = router;
		this.environmentManager = environmentManager;
		this.featureSwitchManager = featureSwitchManager;
		this.globalErrorMessage = globalErrorMessage;
		this.queryString = queryString;
		this.tracking = tracking;
		this.mobileApp = mobileApp;
		this.branch = branch;

		this.loginCallbackHandled = false;

		this.#wireUpLifeCycleStageEvents();
	}

	#wireUpLifeCycleStageEvents() {
		this.#lifeCycleStage_preInitialisation();
		this.#lifeCycleStage_initialisation();
		this.#lifeCycleStage_mobileAppInitialisation();
		this.#lifeCycleStage_appMounted();
	}

	#lifeCycleStage_preInitialisation() {
		this.appLifeCycle.addListener(AppLifeCycleStageNames.PRE_INITIALISATION, async () => {
			if (this.mobileApp) {
				await this.mobileApp.preInitialise();
			}
		});
	}

	#lifeCycleStage_initialisation() {
		this.appLifeCycle.addListener(AppLifeCycleStageNames.INITIALISATION, async () => {
			await this.environmentManager.initialise({ handlers: { onAuthenticated: this.#ensureUserLoggedInIfRequired.bind(this) } });
			this.featureSwitchManager.initialise(this.environmentManager.currentEnvironment.value);

			watch(toRef(this.queryString, "environment"), async () => {
				/* TODO does isMounted need to be checked? */
				// if (isMounted.value) {
				await this.switchEnvironment();
				// }
			});
			await this.switchEnvironment();

			this.router.beforeEach(async (to) => {
				this.#ensureUserLoggedInIfRequired({ route: to });
			});
			await this.#handleLoginCallback();

			this.router.afterEach((to) => {
				this.tracking?.trackPageView(to);
			});
			this.tracking?.trackPageView(this.router.currentRoute.value);

			try {
				await this.branch.initialise();
			} catch (error) {
				this.logger.log(loggingMessages.branchInitialisationError, { error });
			}
		});
	}

	#lifeCycleStage_mobileAppInitialisation() {
		this.appLifeCycle.addListener(AppLifeCycleStageNames.MOBILE_APP_INITIALISATION, async () => {
			if (this.mobileApp) {
				await this.mobileApp.initialise();
			}
		});
	}

	#lifeCycleStage_appMounted() {
		this.appLifeCycle.addListener(AppLifeCycleStageNames.APP_MOUNTED, async () => {
			// await this.environmentManager.initialisationPromise;
			this.#ensureUserLoggedInIfRequired();
		});
	}

	async switchEnvironment() {
		const currentEnvironmentName = this.environmentManager.currentEnvironment.value?.name;
		const newEnvironmentName = this.queryString.environment;
		if (!!currentEnvironmentName && newEnvironmentName && currentEnvironmentName !== newEnvironmentName) {
			// await this.environmentManager.initialisationPromise;
			try {
				await this.environmentManager.switchToName(newEnvironmentName);
			} catch (error) {
				this.globalErrorMessage.show(error);
			}
		}
	}

	async #handleLoginCallback() {
		const route = this.router.currentRoute.value;
		if (route.name === "LoggedIn" && !this.loginCallbackHandled) {
			try {
				this.loginCallbackHandled = true;
				const url = window.location.href;
				this.logger.log(loggingMessages.handlingLoginCallback, { url });

				await this.environmentManager.currentEnvironment.value.handleRedirectCallback(url);
			} catch (error) {
				this.globalErrorMessage.show(error);
			}
		}
	}

	/* TODO: can move this to router.js */
	#ensureUserLoggedInIfRequired(options = {}) {
		const currentEnvironment = this.environmentManager.currentEnvironment;
		const route = options.route ?? this.router.currentRoute.value;

		if (!currentEnvironment.value) {
			throw new Error("A current environment must be set before calling ensureUserLoggedInIfRequired");
		}
		const isAuthenticated = options.isAuthenticated ?? currentEnvironment.value.isAuthenticated.value;
		const hasUserProfile = options.hasUserProfile ?? currentEnvironment.value.hasUserProfile.value;

		if (!this.globalErrorMessage.error) {
			const returnTo = this.queryString.returnTo ? decodeURIComponent(this.queryString.returnTo) : null;
			const requiresAuth = route.meta?.requiresAuth ?? true;

			if (isAuthenticated && hasUserProfile && [PageNames.LOGGED_IN, PageNames.CREATE_PROFILE, PageNames.LOG_IN].includes(route.name)) {
				if (returnTo) {
					this.logger.log(loggingMessages.userAuthenticatedAndHasProfileRedirectingToPreviousPage, { url: returnTo });
					this.router.replace(returnTo);
				} else {
					this.logger.log(loggingMessages.userAuthenticatedAndHasProfileRedirectingToHomePage);
					this.router.replace(urlBuilder(PageNames.HOME_FEED, { cityName: "london" }));
				}
			} else if (isAuthenticated && !hasUserProfile && route.name !== PageNames.CREATE_PROFILE) {
				const routeTo = urlBuilder(PageNames.CREATE_PROFILE, { cityName: "london", returnTo });
				this.logger.log(loggingMessages.userAuthenticatedButDoesNotHaveProfileRedirectingToCreateProfile);
				this.router.replace(routeTo);
				return false;
			} else if (!isAuthenticated && requiresAuth && route.name !== PageNames.LOG_IN) {
				return true;
				// if (featureSwitchManager.isActive(FeatureSwitchNames.AnonymousUsage)) {
				// 	return true;
				// } else {
				// 	redirectToLoginPage(returnTo ?? router.resolve(route).href);
				// 	return false;
				// }
			}
		}

		return true;
	}
}
