import { computed } from "vue";

export default class {
	constructor({ logger, modelBuilder, api, authentication }) {
		this.logger = logger.nested({ name: "Model" });
		this.modelBuilder = modelBuilder;
		this.api = api;
		this.authentication = authentication;
	}

	execute({ cityName } = {}) {
		return this.modelBuilder.build({
			name: "GetPremiumProducts",
			args: { cityName },
			execute: async ({ args }) => {
				const cities = await this.api.queries.getCitiesAnonymous({}, { useClientCache: true, usePersistentCache: true });
				const foundCity = cities.cities.find(({ name }) => name.toLowerCase() === args.cityName);
				if (!foundCity) {
					throw new Error(`City '${args.cityName}' not found`);
				}

				const data = await this.api.queries.getPremiumProducts({ cityId: foundCity.id }, { useClientCache: true, usePersistentCache: true });

				const products = computed(() => data.premium.products);

				return { products };
			},
		});
	}
}
