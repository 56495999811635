import { createRouter as _createRouter, createMemoryHistory, createWebHistory } from "vue-router";

import { useLogger } from "./functions/logger/index.js";
// import { safeHapticsVibrate } from "./helpers/index.js";
import loggingMessages from "./router.logging-messages.js";
import { getAllPossibleRoutes, getRouterConfig } from "./router-config.js";
import { usePlatform } from "./domain/platform.js";
import { deepDiff } from "./helpers/deepDiff.js";

import TestRoot from "./pages/TestRoot.vue";
import TestPage1 from "./pages/TestPage1.vue";
import TestPage2 from "./pages/TestPage2.vue";
import Login from "./pages/Login.vue";
import CreateProfile from "./pages/CreateProfile.vue";
import City from "./pages/City.vue";
import Home from "./pages/Home.vue";
import Premium from "./pages/Premium.vue";
import Profile from "./pages/Profile.vue";
import Activity from "./pages/Activity.vue";
import Map from "./pages/Map.vue";
import UserLists from "./pages/UserLists.vue";
import UserListFavourites from "./pages/UserListFavourites.vue";
import UserListBeen from "./pages/UserListBeen.vue";
import UserListGoTry from "./pages/UserListGoTry.vue";
import Admin from "./pages/Admin.vue";
import Settings from "./pages/Settings.vue";
import Venue from "./pages/Venue.vue";
import ExploreCity from "./components/ExploreCity.vue";
import ExploreAreas from "./components/ExploreAreas.vue";
import ExploreArea from "./components/ExploreArea.vue";
import ExploreZoneTag from "./components/ExploreZoneTag.vue";
import ExploreZone from "./components/ExploreZone.vue";
import ExploreLists from "./components/ExploreLists.vue";
import ExploreListsCategory from "./components/ExploreListsCategory.vue";
import ExploreList from "./components/ExploreList.vue";
import ExploreTags from "./components/ExploreTags.vue";
import ExploreTag from "./components/ExploreTag.vue";
import LoggedIn from "./components/LoggedIn.vue";
import Download from "./pages/Download.vue";
import PrivacyPolicy from "./pages/PrivacyPolicy.vue";
import FollowersAndFollowing from "./pages/FollowersAndFollowing.vue";
import Base from "./pages/Base.vue";

const PAGE_COMPONENTS = {
	TestRoot,
	TestPage1,
	TestPage2,
	Login,
	CreateProfile,
	City,
	Home,
	Premium,
	Profile,
	Activity,
	Map,
	UserLists,
	UserListFavourites,
	UserListBeen,
	UserListGoTry,
	Admin,
	Settings,
	Venue,
	ExploreCity,
	ExploreAreas,
	ExploreArea,
	ExploreZoneTag,
	ExploreZone,
	ExploreLists,
	ExploreListsCategory,
	ExploreList,
	ExploreTags,
	ExploreTag,
	LoggedIn,
	Download,
	PrivacyPolicy,
	FollowersAndFollowing,
	Base,
};

export async function createRouter() {
	const logger = useLogger({ name: "router" });
	const platform = usePlatform();

	const routerConfig = getRouterConfig(PAGE_COMPONENTS);
	const allPossibleRoutes = getAllPossibleRoutes(routerConfig);

	if (!platform.isServer) {
		logger.log(loggingMessages.routerConfigurationLoaded, { routeConfigs: allPossibleRoutes });
	}

	const router = _createRouter({
		// use appropriate history implementation for server/client
		// import.meta.env.SSR is injected by Vite.
		history: import.meta.env.SSR ? createMemoryHistory("/") : createWebHistory("/"),
		routes: routerConfig,
	});

	router.afterEach((to, from, error) => {
		if (!error) {
			const diffQuery = deepDiff(from.query, to.query);
			logger.log(loggingMessages.routeChanged, { routeFrom: from, fromParams: from.params, routeTo: to, toParams: to.params, diff: diffQuery, meta: to.meta });
			// safeHapticsVibrate({ duration: 20 });
		}
	});

	router.onError((error, to, from) => {
		logger.log(loggingMessages.errorDuringRouteNavigation, { error, to: to?.path, from: from?.path });
		return true;
	});

	return router;
}
