<template>
	<div class="date-time-picker">
		<div class="date-picker">
			<font-awesome-icon icon="fa-regular fa-calendar" class="icon" />
			<input v-model="datePickerValue" type="date" @change="dateOrTimePickerChanged" @click="(e) => e.srcElement.showPicker?.()" />
		</div>
		<div class="time-picker">
			<font-awesome-icon icon="fa-regular fa-clock" class="icon" />
			<select v-model="timePickerValue" @change="dateOrTimePickerChanged">
				<option v-for="{ display, value } in timeSlots" :key="value" :value="value">{{ display }}</option>
			</select>
		</div>
	</div>
</template>

<script setup>
import { computed, ref, watch } from "vue";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);

import { TimeFormats, DateFormats } from "../constants/index.js";

const props = defineProps({
	dateTime: {
		type: String,
		default: null,
	},
});

const emit = defineEmits(["change"]);

const datePickerValue = ref(),
	timePickerValue = ref();

const dateTime = computed(() => props.dateTime);
const timeSlots = computed(() => {
	const slots = [];
	let time = dayjs().startOf("day");
	for (let i = 0; i < 48; i++) {
		slots.push({ display: time.format(TimeFormats.DISPLAY), value: time.format(TimeFormats.IGNORE_SECONDS) });
		time = time.add(30, "minute");
	}
	return slots;
});

watch(
	dateTime,
	() => {
		datePickerValue.value = dateTime.value ? dayjs.utc(dateTime.value).format(DateFormats.ISO_DATE) : null;
		timePickerValue.value = dateTime.value ? dayjs.utc(dateTime.value).format(TimeFormats.IGNORE_SECONDS) : null;
	},
	{ immediate: true },
);

function dateOrTimePickerChanged() {
	const updatedDateTime = datePickerValue.value && timePickerValue.value ? `${datePickerValue.value}T${timePickerValue.value}` : null;
	emit("change", updatedDateTime);
}
</script>

<style scoped lang="scss">
@import "../assets/styles/variables_new.scss";

.date-time-picker {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: calc($spacing / 2);

	input,
	select {
		font-size: $text-size-tertiary;

		&:focus {
			outline: none;
		}
	}

	.date-picker,
	.time-picker {
		display: flex;
		align-items: center;
		border: $border-size-primary solid $border-color-primary;
		border-radius: $border-radius-tertiary;
	}

	.date-picker {
		position: relative;
		padding: calc($spacing / 1.5) 0 calc($spacing / 1.5) calc($spacing / 1.5);

		input[type="date"] {
			border: none;
			margin-left: calc($spacing / 2);
			width: 85px;
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
		}
		input[type="date"]::-webkit-inner-spin-button,
		input[type="date"]::-webkit-calendar-picker-indicator {
			display: none;
			// background: transparent;
			// bottom: 0;
			// color: transparent;
			// cursor: pointer;
			// height: auto;
			// left: 0;
			// position: absolute;
			// right: 0;
			// top: 0;
			// width: auto;
		}
	}

	.time-picker {
		padding: calc($spacing / 1.5) calc($spacing / 1.5);

		> select {
			margin-left: calc($spacing / 2);
			border: none;
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
		}
	}
}
</style>
