<template>
	<div :class="{ 'loading-message': true, 'show-padding': props.showPadding, 'full-screen': props.fullScreen }">
		<div :class="{ 'animation-wrapper': true, [props.position]: true }">
			<div :class="randomThreeDotsClass"></div>
		</div>
		<span><slot /></span>
	</div>
</template>

<script setup>
import { ref, useSlots, watch } from "vue";

const props = defineProps({
	position: {
		type: String,
		default: "above",
	},
	showPadding: {
		type: Boolean,
		default: true,
	},
	fullScreen: {
		type: Boolean,
		default: false,
	},
});

const threeDotsClasses = [
	// "dot-elastic",
	// "dot-pulse",
	// "dot-flashing",
	// "dot-collision",
	// "dot-revolution",
	// "dot-carousel",
	// "dot-typing",
	// "dot-windmill",
	// "dot-bricks",
	// "dot-fire",
	"dot-spin",
	// "dot-falling",
	// "dot-stretching",
];

const getRandomThreeDotsClass = () => {
	return threeDotsClasses[Math.floor(Math.random() * threeDotsClasses.length)];
};

const randomThreeDotsClass = ref(getRandomThreeDotsClass());

const slots = useSlots();

watch(slots.default, () => {
	randomThreeDotsClass.value = getRandomThreeDotsClass();
});
</script>

<style lang="scss" scoped>
@use "three-dots" with (
	$dot-width: 10px,
	$dot-height: 10px,
	$dot-color: #cccccc
);
@import "../assets/styles/variables_new.scss";

.loading-message {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: $spacing;
	box-sizing: border-box;
	font-size: $text-size-quaternary;
	color: $text-color-tertiary;
	overflow: hidden;

	&.full-screen {
		height: 100%;
	}

	&.show-padding {
		padding: calc($spacing * 3) 0;
	}

	.animation-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 20px;

		&.below {
			order: 2;
		}

		&:has(.dot-windmill, .dot-bricks, .dot-fire, .dot-falling) {
			height: 30px;
		}

		&:has(.dot-revolution) {
			height: 40px;
		}

		&:has(.dot-spin) {
			height: 50px;
		}
	}
}
</style>
