<template>
	<MiniCard
		v-if="props.showMini"
		:text="venueName"
		:bottom-text="description"
		:image-src="imageUrl"
		:image-preview="imagePreview"
		:router-link="routerLink"
		:router-link-enabled="isRouterLinkEnabled"
	/>
	<WideCard
		v-else-if="props.showWide"
		:top-text="venueName"
		:top-sub-text="cityName"
		:bottom-text="description"
		:image-src="imageUrl"
		:image-preview="imagePreview"
		:router-link="routerLink"
		:decal-text="launchStatus"
		:disable-image="isImageDisabled"
		:router-link-enabled="isRouterLinkEnabled"
	/>
	<Card
		v-else
		:top-text="venueName"
		:top-sub-text="cityName"
		:bottom-text="description"
		:image-src="imageUrl"
		:image-preview="imagePreview"
		:router-link="routerLink"
		:router-link-enabled="isRouterLinkEnabled"
	/>
</template>

<script setup>
import { computed } from "vue";

import { useRouteParams } from "../helpers/index.js";
import { LaunchStatus } from "../constants/index.js";

import WideCard from "./WideCard.vue";
import Card from "./Card.vue";
import MiniCard from "./MiniCard.vue";

const LAUNCH_STATUS_DISPLAY_TEXT = {
	[LaunchStatus.UPCOMING]: "Coming Soon",
	[LaunchStatus.ACTIVE]: "<div>Book Now</div>",
	[LaunchStatus.ENDED]: "Past Exclusive",
};
const FULLY_BOOKED = "<div>Fully Booked</div>";

const props = defineProps({
	launch: {
		type: Object,
		required: true,
		validator: (launch) => {
			return launch.venue?.name && launch.launchImage && launch.venue.id;
		},
	},
	showMini: {
		type: Boolean,
		default: false,
	},
	showWide: {
		type: Boolean,
		default: false,
	},
});

const imageUrl = computed(() => {
	return props.launch.launchImage;
});

const imagePreview = computed(() => {
	return props.launch.launchImagePreview;
});

const description = computed(() => props.launch?.description ?? "");

const venueName = computed(() => props.launch.venue.name);
const cityName = computed(() => props.launch.venue.zone().area().city().name ?? "");
const isFullyBooked = computed(() => props.launch.isFullyBooked);
const launchStatus = computed(() => (isFullyBooked.value && props.launch.status === LaunchStatus.ACTIVE ? FULLY_BOOKED : LAUNCH_STATUS_DISPLAY_TEXT[props.launch.status]));
const isImageDisabled = computed(() => props.launch.status === LaunchStatus.ENDED);
const isRouterLinkEnabled = computed(() => props.launch.status !== LaunchStatus.ENDED);

const routeParams = useRouteParams();

const routerLink = computed(() => {
	return {
		name: "City/Explore/Areas/Venue",
		params: {
			city: routeParams.cityName,
			venueId: props.launch.venue.id,
		},
	};
});
</script>
