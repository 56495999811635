<template>
	<div class="page-header">
		<Breadcrumb v-if="hasBreadcrumbSlot">
			<slot name="breadcrumb" />
		</Breadcrumb>
		<div class="title">
			<h2>
				<slot />
			</h2>
			<div class="controls">
				<slot name="controls" />
				<ShareButton v-if="props.showShareButton" :show-label="false" :title="props.shareTitle" :text="props.shareText" />
			</div>
		</div>
	</div>
</template>

<script setup>
import { computed, useSlots } from "vue";

import Breadcrumb from "./Breadcrumb.vue";
import ShareButton from "./ShareButton.vue";

const props = defineProps({
	showShareButton: {
		type: Boolean,
		default: false,
	},
	shareTitle: {
		type: String,
		default: null,
	},
	shareText: {
		type: String,
		default: null,
	},
});

const slots = useSlots();

const hasBreadcrumbSlot = computed(() => !!slots.breadcrumb);
</script>

<style scoped lang="scss">
@import "../assets/styles/variables_new.scss";

.page-header {
	display: flex;
	flex-direction: column;
	gap: calc($spacing / 2);

	margin: 0 calc($spacing / 2);

	.title {
		display: flex;
		align-items: center;
		gap: calc($spacing / 1.5);

		> h2 {
			font-size: $text-size-header;
			font-weight: $text-bold-primary;
			color: $text-color-primary;
			// line-height: $text-size-header-tertiary;
			@include text-ellipsis;
		}

		:deep(.share-button .icon) {
			height: 22px;
		}

		.controls {
			display: flex;
			gap: calc($spacing / 2);
		}
	}
}
</style>
