import { markRaw, reactive } from "vue";
import { Geolocation } from "@capacitor/geolocation";
import { Motion } from "@capacitor/motion";
import _ from "lodash";

import { usePlatform } from "../domain/index.js";

import loggingMessages from "./useGeoLocation.logging-messages.js";

const PERMISSION_STATUS = {
	GRANTED: "granted",
	PROMPT: "prompt",
};

export const useGeoLocation = ({ logger: parentLogger } = {}) => {
	const logger = parentLogger.nested({ name: "useGeoLocation" });
	const platform = usePlatform();

	let watcherId,
		isInitialized = false,
		locationOptions = {};

	const debouncedUpdateLocation = _.debounce(updateLocation, 1000, { leading: true, trailing: true, maxWait: 0 });

	const init = async () => {
		if (!isInitialized) {
			isInitialized = true;
			location.isLoading = true;
			if (!platform.isServer) {
				location.permissions = await Geolocation.checkPermissions();
				const isAllowed = [PERMISSION_STATUS.GRANTED, PERMISSION_STATUS.PROMPT].indexOf(location.permissions.location) > -1;
				if (isAllowed) {
					await forceUpdate();
				}
				location.isAllowed = isAllowed;
			}
			location.isLoading = false;
		}
	};

	const forceUpdate = async () => {
		await init();

		logger.log(loggingMessages.gettingLocation);
		try {
			const newLocation = await Geolocation.getCurrentPosition(locationOptions);
			logger.log(loggingMessages.locationReceived, { location: { heading: newLocation.coords.heading, lat: newLocation.coords.latitude, lng: newLocation.coords.longitude } });
			debouncedUpdateLocation({
				lat: newLocation.coords.latitude,
				lng: newLocation.coords.longitude,
				accuracy: newLocation.coords.accuracy,
				// heading: newLocation.coords.heading,
			});
		} catch (error) {
			logger.log(loggingMessages.gettingLocationError, { errorJson: { code: error.code, message: error.message } });
			updateError(error);
			return;
		}
	};

	const startWatching = async () => {
		await init();

		if (location.isWatching) {
			return;
		}
		if (!location.isAllowed) {
			logger.log(loggingMessages.cannotStartWatchingWithoutPermission, { permissions: location.permissions });
			return;
		}
		logger.log(loggingMessages.startingWatching);
		location.isWatching = true;
		try {
			watcherId = await Geolocation.watchPosition(locationOptions, (newLocation, error) => {
				if (error) {
					logger.log(loggingMessages.watchedLocationReceivedError, { errorJson: { code: error.code, message: error.message } });
				} else {
					logger.log(loggingMessages.locationReceived, { location: { heading: newLocation.coords.heading, lat: newLocation.coords.latitude, lng: newLocation.coords.longitude } });
					debouncedUpdateLocation({
						lat: newLocation.coords.latitude,
						lng: newLocation.coords.longitude,
						accuracy: newLocation.coords.accuracy,
					});
				}
			});
		} catch (error) {
			logger.log(loggingMessages.watchingForLocationError, { errorJson: { code: error.code, message: error.message } });
			updateError(error);
			return;
		}

		await Motion.addListener("orientation", (event) => {
			debouncedUpdateLocation({
				heading: event.alpha,
			});
		});
	};

	const stopWatching = async () => {
		await init();

		if (location.isWatching) {
			logger.log(loggingMessages.stoppingWatching);
			location.isWatching = false;
			if (watcherId) {
				Geolocation.clearWatch({ id: watcherId });
			}
		}
	};

	const location = reactive({
		permissions: null,
		isAllowed: false,
		isLoading: false,
		isWatching: false,
		hasLocation: false,
		lat: null,
		lng: null,
		heading: null,
		accuracy: null,
		error: null,
		init: markRaw(init),
		forceUpdate: markRaw(forceUpdate),
		startWatching: markRaw(startWatching),
		stopWatching: markRaw(stopWatching),
	});

	return location;

	function updateLocation(newLocation) {
		location.hasLocation = true;
		location.error = null;
		Object.entries(newLocation).forEach(([key, value]) => {
			location[key] = value;
		});
	}

	function updateError(error) {
		location.error = error;
		location.hasLocation = false;
		location.lat = null;
		location.lng = null;
		location.heading = null;
		location.accuracy = null;
	}
};
