<template>
	<div class="explore-zone-container">
		<loading-message-with-error v-if="zoneQuery.isLoading || zoneQuery.error" :error="zoneQuery.error" :retry="zoneQuery.retry" class="loading-message">
			Loading, please wait...
		</loading-message-with-error>
		<div v-else class="explore-zone">
			<PageHeader>
				<template #breadcrumb>
					<custom-router-link :to="{ name: 'City/Explore/Areas', params: { city: routeParams.cityName } }">{{ formattedCityName }}</custom-router-link>
					<BreadcrumbSeparator />
					<custom-router-link :to="{ name: 'City/Explore/Areas/Area', params: { city: routeParams.cityName, areaId } }">{{ areaName }}</custom-router-link>
				</template>
				{{ zone?.name }}
			</PageHeader>
			<!-- <SuperList v-slot="{ item: tag, style }" :name="`zone-list-${zone?.id}`" :items="tags" :item-size="76" :is-virtual="true" class="explore-zone-list">
				<TagCard :key="tag.id" :tag="tag" :zone="zone" :show-mini="true" :show-hash-tag-icon="false" :style="style" />
			</SuperList> -->
			<NewList
				v-slot="{ item: tag, isItemVisible }"
				class="explore-zone-list"
				:name="`zone-list-${zone?.id}`"
				:items="tags"
				:direction="ListDirections.VERTICAL"
				:item-height="76"
				:gap-size="0"
				:item-buffer-count="4"
			>
				<TagCard v-if="isItemVisible" :key="tag.id" :tag="tag" :zone="zone" :show-mini="true" :show-hash-tag-icon="false" />
			</NewList>
		</div>
	</div>
</template>

<script setup>
import { inject, computed, ref, onMounted, capitalize } from "vue";

import { useRouteParams, sortArrayBasedOnOrderArray } from "../helpers/index.js";
import { SpecialTagIds, SpecialTagsLookupById, ListDirections } from "../constants/index.js";

import TAG_SORT_ORDER_BY_NAME from "../tag-order-meal-type-hardcoded.json";

import TagCard from "./TagCard.vue";
// import SuperList from "./SuperList.vue";
import NewList from "./NewList.vue";
import PageHeader from "./PageHeader.vue";

const routeParams = useRouteParams();

const isAppHydrated = inject("isAppHydrated");
const model = inject("model");

const isMounted = ref(false);
onMounted(() => {
	isMounted.value = true;
});

const zoneQuery = model.queries.GetZone({
	zoneId: computed(() => routeParams.zoneId),
});

const formattedCityName = computed(() => capitalize(routeParams.cityName));
const areaId = computed(() => zoneQuery.model?.zone?.zone.area().id ?? "not-present");
const areaName = computed(() => zoneQuery.model?.zone?.zone.area().name ?? "");
const zone = computed(() => zoneQuery.model?.zone?.zone ?? null);
const venues = computed(() => zoneQuery.model?.zone?.zone.venues?.filter(({ published }) => published) ?? []);
const tags = computed(() => {
	const mealTypeTags = Object.values(
		venues.value.reduce((acc, venue) => {
			venue.tags
				.filter(({ category }) => category === "MEAL_TYPE")
				.forEach((tag) => {
					if (!acc[tag.id]) {
						acc[tag.id] = { ...tag };
						acc[tag.id].venues = [];
					}
					acc[tag.id].venues.push(venue);
				});
			return acc;
		}, {}),
	);

	mealTypeTags.unshift({
		...SpecialTagsLookupById[SpecialTagIds.VIEW_ALL],
		venues: venues.value.filter(SpecialTagsLookupById[SpecialTagIds.VIEW_ALL].venueSelector),
	});

	const venuesWithPerks = venues.value.filter(SpecialTagsLookupById[SpecialTagIds.PLACES_WITH_PERKS].venueSelector);
	if (venuesWithPerks.length > 0) {
		mealTypeTags.unshift({
			...SpecialTagsLookupById[SpecialTagIds.PLACES_WITH_PERKS],
			venues: venuesWithPerks,
		});
	}

	const venuesWithInstantAvailability = venues.value.filter(SpecialTagsLookupById[SpecialTagIds.LIVE_AVAILABILITY].venueSelector);
	if (venuesWithInstantAvailability.length > 0) {
		mealTypeTags.unshift({
			...SpecialTagsLookupById[SpecialTagIds.LIVE_AVAILABILITY],
			venues: venuesWithInstantAvailability,
		});
	}

	return mealTypeTags.sort(sortArrayBasedOnOrderArray(TAG_SORT_ORDER_BY_NAME, (v) => v.name));
});

if (!isAppHydrated.value) {
	await zoneQuery.promise;
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/variables_new.scss";

.explore-zone-container {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	// padding: 0 calc($spacing * 2);
	margin-top: $spacing;
	overflow: auto;
	@include centre-loading-message;

	.explore-zone {
		display: flex;
		flex-direction: column;
		gap: calc($spacing * 0.5);
		overflow: auto;
		overscroll-behavior: none;

		.explore-zone-list {
			overflow: auto;
			display: flex;
			flex-direction: column;
			gap: $spacing;
			align-content: start;

			.image {
				width: 160px;
				height: 160px;
			}
		}
	}
}

@media (min-width: $bp-medium) {
	.explore-zone-container {
		padding: 0;
	}
}
</style>
