<template>
	<div class="venue-booking-stages">
		<loading-message-with-error v-if="query.isLoading || query.error" :error="query.error" :full-screen="true">Loading place, please wait...</loading-message-with-error>
		<div v-else-if="currentStage" class="current-stage">
			<ScrollableContainer class="stage-container">
				<VenueBookingStage :venue-booking="props.venueBooking" :stage="currentStage" :placement="props.placement" />
			</ScrollableContainer>
		</div>
		<div v-else class="error-message">Booking stage not activating '{{ currentStageName }}' for venue '{{ venue?.id }}'</div>
	</div>
</template>

<script setup>
import { computed } from "vue";

import VenueBookingStage from "./VenueBookingStage.vue";
import ScrollableContainer from "../ScrollableContainer.vue";

const props = defineProps({
	venueBooking: {
		type: Object,
		required: true,
	},
	placement: {
		type: String,
		required: true,
	},
});

const currentStageName = computed(() => props.venueBooking.currentStageName.value);
const currentStage = computed(() => props.venueBooking.currentStage.value);
const venue = computed(() => props.venueBooking.venue.value);
const query = computed(() => props.venueBooking.query);
</script>

<style scoped lang="scss">
@import "../../assets/styles/variables_new.scss";

.venue-booking-stages {
	flex-grow: 1;
	overflow: auto;

	.current-stage {
		height: 100%;
		display: flex;
		flex-direction: column;

		.stage-container {
			flex-grow: 1;
		}
	}

	.error-message {
		text-align: center;
		font-size: $text-size-quaternary;
		color: $text-color-primary;
		margin: calc($spacing * 2);
	}
}
</style>
