import { onActivated, onBeforeUnmount, onMounted, reactive, watch } from "vue";

export const useElementScrollSize = (targetRef) => {
	const scrollSize = reactive({});
	setScrollSize(targetRef.value);

	const resizeObserver = new ResizeObserver((entries) => {
		const entry = entries[0];
		setScrollSize(entry.target);
	});

	let watchStop = null;

	onMounted(() => {
		watchStop = watch(
			targetRef,
			() => {
				if (resizeObserver) {
					resizeObserver.disconnect();
				}
				if (targetRef.value) {
					resizeObserver.observe(targetRef.value);
					setScrollSize(targetRef.value);
				}
			},
			{ immediate: true },
		);
	});

	onBeforeUnmount(() => {
		if (watchStop) {
			watchStop();
			watchStop = null;
		}
		if (resizeObserver) {
			resizeObserver.disconnect();
		}
	});

	onActivated(() => {
		if (targetRef.value) {
			setScrollSize(targetRef.value);
		}
	});

	return scrollSize;

	function setScrollSize(target) {
		scrollSize.width = target?.scrollWidth ?? 0;
		scrollSize.height = target?.scrollHeight ?? 0;
	}
};
