export default {
	addingListener: {
		level: "debug",
		message: ({ name }) => `🔃 Adding listener for app life cycle stage ${name}`,
	},
	triggeringAppLifeCycleStage: {
		level: "info",
		message: ({ name }) => `🔃 Triggering app life cycle stage ${name}...`,
	},
	appLifeCycleStageComplete: {
		level: "info",
		message: ({ name }) => `🔃 ...app life cycle stage complete ${name}`,
	},
};
