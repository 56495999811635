import { helpers, minLength, required } from "@vuelidate/validators";

import { EntityTypeNames } from "../../../constants/index.js";

import FormFieldInput from "../../../components/forms/FormFieldInput.vue";
import FormFieldImage from "../../../components/forms/FormFieldImage.vue";

export default class UserType {
	static typeName = EntityTypeNames.User;

	constructor({ addChange }) {
		this.addChange = addChange;

		this.fields = [
			{
				name: "profileImage",
				component: FormFieldImage,
				componentProps: {
					imageSize: 150,
					apiPath: "/upload-profile-image",
					imageFieldName: "avatar",
					isFieldCentered: true,
					isLabelHidden: true,
				},
				label: "Profile Image",
				placeholder: "Select an image for your profile",
				style: {
					maxWidth: "150px",
					borderRadius: "50%",
				},
			},
			{
				name: "email",
				isReadOnly: true,
				component: FormFieldInput,
				label: "Email",
			},
			{
				name: "username",
				component: FormFieldInput,
				label: "Username",
				placeholder: "Enter your username",
				validation: {
					required: helpers.withMessage("Please enter a username", required),
					minLength: helpers.withMessage("Your username must be atleast 3 characters long", minLength(3)),
				},
			},
			{
				name: "firstName",
				component: FormFieldInput,
				label: "First Name",
				placeholder: "Enter your first name",
				validation: { required: helpers.withMessage("Please enter a first name", required) },
			},
			{
				name: "lastName",
				component: FormFieldInput,
				label: "Last Name",
				placeholder: "Enter your last name",
				validation: { required: helpers.withMessage("Please enter a last name", required) },
			},
		];
	}

	async onSave(state) {
		const { changeResult, error } = await this.addChange.UpsertCurrentUser({ ...state });
		return { changeResult, error };
	}
}
