export default {
	cancellingLock: {
		level: "info",
		message: ({ venueId }) => `Cancelling lock for venue ${venueId}`,
	},
	unexpectedChallengeWhileMakingReservation: {
		level: "error",
		message: ({ venueId, typeName }) => `Unexpected challenge while making reservation for venue ${venueId} of type ${typeName}`,
	},
};
