import { EntityTypeNames } from "../EntityTypeNames.js";

export default class ChangeResultsProcessor {
	static doesSupportType(typeName) {
		return typeName === EntityTypeNames.ChangeResults;
	}

	constructor({ entityStore }) {
		this.entityStore = entityStore;
	}

	process({ entity, entityProcessor }, args, summary) {
		/* TODO: The current method of storing processed entities is causing issues. The method wrappers added during storage are being passed back and double wrapped, which is not the intended behavior. 
		processEntities calls entityStore.store, which adds the method wrappers.
		*/
		const updatedEntities = entityProcessor.processEntities(entity.updatedEntities, args, summary);

		/* are we storing again? aren't these already stored via processEntities? */
		// const storedEntities = updatedEntities.map((updatedEntity) => this.entityStore.store({ id: updatedEntity.id, typeName: updatedEntity.__typename, entity: updatedEntity }, summary));

		// return { changes: entity.changes, updatedEntities: storedEntities };
		return { changes: entity.changes, updatedEntities };
	}
}
