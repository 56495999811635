import { debounce } from "lodash-es";

import { PageNames } from "../constants/index.js";
import { urlBuilder } from "./urlBuilder.js";

export class Navigation {
	constructor({ config, router }) {
		this.config = config;
		this.router = router;
		this.back = debounce(this.#back.bind(this), 500, {
			leading: true,
			trailing: false,
		});
		this.goto = debounce(this.#goto.bind(this), 500, {
			leading: true,
			trailing: false,
		});
	}

	#back() {
		/* REF history.state.back only seems to be set by vue router, so only present when navigated from within app */
		if (history.state.back) {
			history.back();
		} else {
			this.router.push(urlBuilder(PageNames.HOME_FEED, { cityName: this.config.defaultCityName }));
		}
	}

	#goto(pageName, args) {
		this.router.push(urlBuilder(pageName, args));
	}
}
