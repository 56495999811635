import { EntityTypeNames } from "../../constants/index.js";

export default class UpdatedEntitiesProcessor {
	static doesSupportType(typeName) {
		return typeName === EntityTypeNames.UpdatedEntities;
	}

	constructor({ entityStore }) {
		this.entityStore = entityStore;
	}

	process({ entity, entityProcessor }, args, summary) {
		const allUpdatedEntities = Object.entries(entity)
			.filter(([key, value]) => key !== "__typename" && !!value)
			.map(([, value]) => value)
			.flat();
		const allEntities = entityProcessor.processEntities(allUpdatedEntities, args, summary);

		const storedEntities = allEntities.map((_entity) => _entity);

		return storedEntities;
	}
}
