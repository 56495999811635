<template>
	<div class="loading-onezone">
		<div class="logos">
			<img src="../assets/icons/logo.svg" alt="onezone Logo" class="loading-logo" />
			<img src="../assets/onezone-logo.png" alt="onezone Text Logo" class="text-logo" />
		</div>
		<loading-message position="below" class="loading-message">curating the world, please wait...</loading-message>
	</div>
</template>

<script setup></script>

<style scoped lang="scss">
@import "../assets/styles/variables_new.scss";

.loading-onezone {
	width: 100dvw;
	height: 100dvh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: calc($spacing * 2);

	.logos {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: $spacing;

		.loading-logo {
			width: 80px;
			opacity: 0.15;
		}

		.text-logo {
			width: 100px;
			opacity: 0.15;
		}
	}

	.loading-message {
		padding: 0;
		height: auto;
	}
}
</style>
