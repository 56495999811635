import { useConfig } from "./config.js";

export class SubscriptionManager {
	constructor({ logger, queryString, route, deviceReady }) {
		this.logger = logger;
		this.queryString = queryString;
		this.route = route;
		this.config = useConfig();
		this.deviceReady = deviceReady;
	}

	async init({ authentication }) {
		this.authentication = authentication;
		// this.deviceReady.addListener(() => {
		// 	const { store, ProductType, Platform } = window.CdvPurchase;
		// 	console.log("HERE!!", store);
		// 	store.register([
		// 		{
		// 			type: ProductType.PAID_SUBSCRIPTION,
		// 			id: "com.onezone.premium",
		// 			platform: Platform.GOOGLE_PLAY,
		// 		},
		// 	]);
		// 	store
		// 		.when()
		// 		.productUpdated(async () => {
		// 			const myProduct = store.get("com.onezone.premium", Platform.GOOGLE_PLAY);
		// 			const offer = myProduct.offers[0];
		// 			console.log("productUpdated", myProduct, offer);
		// 			const order = await offer.order();
		// 			console.log("ordered", myProduct, order);
		// 		})
		// 		.approved(() => {
		// 			console.log("approved");
		// 		});
		// 	store.initialize([Platform.GOOGLE_PLAY]);
		// });
	}

	async subscribeToPremium({ premiumProduct, successUrl, cancelUrl }) {
		const accessToken = await this.authentication.getAccessToken();

		const form = document.createElement("form");
		form.method = "POST";
		form.action = premiumProduct.purchase.postUrl;

		const data = {
			stripe_environment: this.queryString.stripeEnvironment,
			lookup_key: premiumProduct.purchase.lookupKey,
			domain: this.config.origin,
			access_token: accessToken,
			impersonate_user: this.queryString.impersonate,
			success_url: successUrl ?? this.route.fullPath,
			cancel_url: cancelUrl ?? this.route.fullPath,
			client_name: this.config.clientName,
			client_version: this.config.clientVersion,
		};

		for (const [key, value] of Object.entries(data)) {
			if (value !== undefined) {
				const input = document.createElement("input");
				input.type = "hidden";
				input.name = key;
				input.value = value;
				form.appendChild(input);
			}
		}

		document.body.appendChild(form);
		form.submit();
	}
}
