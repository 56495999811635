import { createSSRApp, reactive } from "vue";
import { reactiveComputed } from "@vueuse/core";
import { LocalStorageCache } from "@auth0/auth0-spa-js";
import { Share } from "@capacitor/share";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
	faLock,
	faSearch,
	faLocationDot,
	faTriangleExclamation,
	faLocationCrosshairs,
	faCamera,
	faChevronLeft,
	faSpinner,
	faRotate,
	faCreditCard,
	faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import { faCalendar, faClock, faUser } from "@fortawesome/free-regular-svg-icons";
import { RecycleScroller } from "vue-virtual-scroller";
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import VueImgix from "@imgix/vue";

import { createRouter } from "./router.js";
import { visible, externalLink } from "./directives/index.js";
import {
	useConfig,
	usePlatform,
	EnvironmentManager,
	AuthenticationManager,
	EnvironmentNames,
	CurrentEnvironmentDecorator,
	ModelBuilder,
	Model,
	SubscriptionManager,
	NativeAppAuthenticationStorageCache,
	Auth0ClientFactory,
	EntityManager,
	Logging,
	Tracking,
	UserDevicePermissionsManager,
	DebugContext,
	useGlobalErrorMessage,
	useGlobalMessageDialog,
	AppLifeCycle,
	AppLogic,
	Environment,
	BookingStageStrategyFactoryOpenTable,
	BookingStageStrategyFactoryDefault,
	SessionStateManager,
} from "./domain/index.js";
import entityProcessors from "./onezoneApi/entity-processors/index.js";
import { OnezoneApi, DataService, EntityStore, EntityProcessorService, ChangeManager, QueryStore, QueryProcessorService, MutationProcessorService, ApiDataCache } from "./onezoneApi/index.js";
import { useLogger } from "./functions/logger/index.js";
import { useQueryString } from "./functions/query-string/index.js";
import { DeviceReady, Navigation, onPushStateEventPolyFill, persistQueryAcrossNavigations, useRouterQueryString } from "./helpers/index.js";
import { useGeoLocation } from "./helpers/useGeoLocation.js";
import { useImgixWithPlaceholder } from "./components/imgix/index.js";
import { AnimatedRoutes, StorePreviousRoutes } from "./plugins/index.js";
import { useFeatureSwitches } from "./domain/useFeatureSwitches.js";
import { Branch } from "./integrations/branch/index.js";
import { AdminFeatureSwitchNames, AppLifeCycleStageNames, BookingProvider } from "./constants/index.js";
import { printLogo } from "./onezone-logo-ascii.js";
import loggingMessages from "./createApp.logging-messages.js";

import LoadingMessage from "./components/LoadingMessage.vue";
import LoadingMessageWithError from "./components/LoadingMessageWithError.vue";
import ErrorMessage from "./components/ErrorMessage.vue";
import SafeAreaComponent from "./components/SafeArea.vue";
import AppWrapper from "./AppWrapper.vue";
import CustomRouterLink from "./components/CustomRouterLink.vue";
import ActivatedRouterView from "./components/ActivatedRouterView.vue";
import Breadcrumb from "./components/Breadcrumb.vue";
import BreadcrumbSeparator from "./components/BreadcrumbSeparator.vue";
import TransitionAnimation from "./components/TransitionAnimation.vue";

dayjs.extend(duration);
dayjs.extend(relativeTime);

/* add the font-icons we need to use to the library */
library.add(faLock, faSearch, faLocationDot, faTriangleExclamation, faLocationCrosshairs, faCamera, faChevronLeft, faSpinner, faRotate, faUser, faCalendar, faClock, faCreditCard, faChevronDown);

export async function createApp(initialState) {
	// window.alert("connect debug");
	const platform = usePlatform();
	if (!platform.isServer) {
		onPushStateEventPolyFill();
		printLogo({ platform });
	}

	const app = createSSRApp(AppWrapper);

	const logger = useLogger({ name: "createApp" });
	app.provide("logger", logger);

	logger.log(loggingMessages.platformDetails, { json: platform });
	app.provide("platform", platform);

	const config = useConfig();
	app.provide("config", config);

	const debugContext = new DebugContext();
	app.provide("debugContext", debugContext);

	const logging = new Logging({ logger, config, debugContext });
	app.provide("logging", logging);
	logging.init();

	const router = await createRouter();
	const route = reactiveComputed(() => router.currentRoute.value);
	app.use(router);
	app.use(AnimatedRoutes, { router });
	app.use(StorePreviousRoutes, { logger, router });

	const routerQuery = useRouterQueryString({ router });
	app.provide("routerQuery", routerQuery);

	const globalErrorMessage = useGlobalErrorMessage({ logger, app });
	app.provide("globalErrorMessage", globalErrorMessage);

	const globalMessageDialog = useGlobalMessageDialog();
	app.provide("globalMessageDialog", globalMessageDialog);

	const { value: canShare } = await Share.canShare();
	logger.log(loggingMessages.sharingStatusChecked, { bool: canShare });
	app.provide("canShare", canShare);

	const deviceReady = new DeviceReady({ logger, platform });

	const branch = new Branch({ logger, platform, router });
	app.provide("branch", branch);

	const tracking = !platform.isServer ? new Tracking({ logger, deviceReady, platform, router, branch, debugContext }) : null;
	app.provide("tracking", tracking);

	let mobileApp = null;
	const excludeUris = [config.redirectUri, config.logoutUri, config.branchDeepLinkUri];
	if (platform.isNative) {
		const { MobileApp } = await import("./domain/MobileApp.js");
		mobileApp = new MobileApp({ logger, router, excludeUris, platform, globalMessageDialog, tracking, debugContext });
	}
	app.provide("mobileApp", mobileApp);

	const safeArea = (await mobileApp?.getSafeArea()) ?? { insets: { top: 16, bottom: 10 } };
	app.provide("safeArea", safeArea);

	useImgixWithPlaceholder(app);
	app.use(VueImgix, {
		domain: "onezone.imgix.net",
		defaultIxParams: {
			auto: "format",
		},
	});

	const defaultEnvironmentName = EnvironmentNames.production;

	app.provide("geoLocation", useGeoLocation({ logger }));

	const positionCache = reactive({});
	app.provide("positionCache", positionCache);

	const navigation = new Navigation({ config, router });
	app.provide("navigation", navigation);

	const queryString = useQueryString({ ...Object.fromEntries(Object.values(AdminFeatureSwitchNames).map((adminFeatureName) => [adminFeatureName, null])), returnTo: null }, { router, route });
	app.provide("queryString", queryString);

	const featureSwitchManager = useFeatureSwitches({ logger, platform, router, route });
	app.provide("featureSwitchManager", featureSwitchManager);
	const featureSwitchQueryNames = featureSwitchManager.getFeatureSwitchQueryNames();

	const persistentQueries = persistQueryAcrossNavigations(router, [...Object.values(AdminFeatureSwitchNames), ...featureSwitchQueryNames]);
	app.provide("persistentQueries", persistentQueries);

	const userDevicePermissionsManager = new UserDevicePermissionsManager({ logger, mobileApp });
	app.provide("userDevicePermissionsManager", userDevicePermissionsManager);

	const authenticationCacheType = platform.isWeb ? "LocalStorageCache" : "NativeAppAuthenticationStorageCache";
	const authenticationCache = platform.isWeb ? new LocalStorageCache() : new NativeAppAuthenticationStorageCache({ logger, platform });
	logger.log(loggingMessages.usingAuthenticationCache, { name: authenticationCacheType });
	const auth0ClientFactory = new Auth0ClientFactory({ logger, persistentQueries, defaultEnvironmentName, mobileApp });
	const authenticationManager = new AuthenticationManager({ logger, authenticationCache, auth0ClientFactory });
	const modelBuilder = new ModelBuilder({ logger });

	const sessionStateManager = new SessionStateManager({ logger });

	const currentEnvironmentDecorator = new CurrentEnvironmentDecorator({ logger });
	const environmentManager = new EnvironmentManager({
		logger,
		defaultEnvironmentName,
		currentEnvironmentDecorator,
		modelKeys: Model.getKeys(),
		changeManagerKeys: ChangeManager.getKeys(),
		environmentFactory: async ({ environmentName, environmentConfig }) => {
			const auth0Environment = { ...environmentConfig.auth0, redirectUri: config.redirectUri, logoutUri: config.logoutUri };
			const authentication = authenticationManager.create({ environmentName: environmentName, defaultEnvironmentName, auth0Environment });
			// const { model, api, changeManager, subscriptionManager } = this.modelFactory({ environmentName, endpointUrl: environmentConfig.api.url, authentication });

			const apiDataCache = new ApiDataCache({ logger, platform, environmentName });
			// await apiDataCache.initialise({ environmentName });
			const dataService = new DataService({
				logger,
				platform,
				endpointUrl: environmentConfig.api.url,
				environmentName,
				ssrState: environmentName === defaultEnvironmentName ? initialState : null,
				queryString,
			});
			const changeManager = new ChangeManager({ logger });
			const entityStore = new EntityStore({ logger, platform, changeManager });
			const entityProcessorService = new EntityProcessorService({ logger, environmentName, entityProcessors, entityStore });
			const queryStore = new QueryStore({ logger });
			const queryProcessorService = new QueryProcessorService({ logger, entityStore, queryStore, entityProcessorService });
			const mutationProcessorService = new MutationProcessorService({ logger, entityStore, queryStore, entityProcessorService });
			const subscriptionManager = new SubscriptionManager({ logger, queryString, route, deviceReady });
			const api = new OnezoneApi({
				logger,
				environmentName,
				dataService,
				apiDataCache,
				entityProcessorService,
				queryProcessorService,
				mutationProcessorService,
			});
			const model = new Model({ logger, modelBuilder, entityStore, api, router, authentication, changeManager });
			changeManager.init({ entityStore, model });
			const bookingStageStrategyFactoryDefault = new BookingStageStrategyFactoryDefault({ logger, model, router, route, sessionStateManager, tracking });
			const bookingStageStrategyFactories = {
				[BookingProvider.OPEN_TABLE]: new BookingStageStrategyFactoryOpenTable({ logger, model, router, route, sessionStateManager, tracking }),
			};

			const environment = new Environment({
				logger,
				platform,
				appConfig: config,
				config: environmentConfig,
				authenticationManager,
				tracking,
				name: environmentName,
				model,
				api,
				changeManager,
				subscriptionManager,
				authentication,
				logging,
				persistentQueries,
				bookingStageStrategyFactoryDefault,
				bookingStageStrategyFactories,
			});

			return environment;
		},
	});
	app.provide("environmentManager", environmentManager);
	app.provide("currentEnvironment", environmentManager.currentEnvironment);
	app.provide("defaultEnvironment", environmentManager.defaultEnvironment);
	app.provide("model", environmentManager.model);
	app.provide("addChange", environmentManager.addChange);
	const entityManager = new EntityManager({ logger, addChange: environmentManager.addChange });
	app.provide("entityManager", entityManager);

	app.component("RecycleScroller", RecycleScroller);
	app.component("FontAwesomeIcon", FontAwesomeIcon);
	app.component("LoadingMessage", LoadingMessage);
	app.component("LoadingMessageWithError", LoadingMessageWithError);
	app.component("ErrorMessage", ErrorMessage);
	app.component("SafeArea", SafeAreaComponent);
	app.component("CustomRouterLink", CustomRouterLink);
	app.component("ActivatedRouterView", ActivatedRouterView);
	app.component("Breadcrumb", Breadcrumb);
	app.component("BreadcrumbSeparator", BreadcrumbSeparator);
	app.component("TransitionAnimation", TransitionAnimation);

	app.directive("visible", visible);
	app.directive("externalLink", externalLink);

	const appLifeCycle = new AppLifeCycle({ logger });
	app.provide("appLifeCycle", appLifeCycle);

	const appLogic = new AppLogic({ logger, appLifeCycle, router, environmentManager, featureSwitchManager, globalErrorMessage, queryString, tracking, mobileApp, branch });
	app.provide("appLogic", appLogic);

	await appLifeCycle.trigger(AppLifeCycleStageNames.PRE_INITIALISATION);

	return { app, router };
}
