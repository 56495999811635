import colors from "ansi-colors";
import stripColor from "strip-color";

import formatters from "./formatters.js";
import { usePlatform } from "../../domain/platform.js";
import { simplifyObjectForLogging } from "../../helpers/simplifyObjectForLogging.js";

const MAX_MEMORY_WARNING_THRESHOLD_PERCENTAGE = 0.15;

const LOG_LEVEL = {
	ERROR: 0,
	WARN: 1,
	INFO: 2,
	VERBOSE: 3,
	DEBUG: 4,
	SILLY: 5,
};

const LOG_LEVEL_NAMES = {
	[LOG_LEVEL.ERROR]: "error",
	[LOG_LEVEL.WARN]: "warn",
	[LOG_LEVEL.INFO]: "info",
	[LOG_LEVEL.VERBOSE]: "verbose",
	[LOG_LEVEL.DEBUG]: "debug",
	[LOG_LEVEL.SILLY]: "silly",
};

const LOG_LEVEL_COLOURS = {
	[LOG_LEVEL.ERROR]: "red",
	[LOG_LEVEL.WARN]: "magenta",
	[LOG_LEVEL.INFO]: "green",
	[LOG_LEVEL.VERBOSE]: "cyan",
	[LOG_LEVEL.DEBUG]: "blue",
	[LOG_LEVEL.SILLY]: "grey",
};

const LOG_LEVEL_LOGGER_METHODS = {
	[LOG_LEVEL.ERROR]: "error",
	[LOG_LEVEL.WARN]: "warn",
	[LOG_LEVEL.INFO]: "info",
	[LOG_LEVEL.VERBOSE]: "debug",
	[LOG_LEVEL.DEBUG]: "debug",
	[LOG_LEVEL.SILLY]: "debug",
};

const POSSIBLE_COLOURS = [
	colors.bgBlue.blueBright,
	colors.bgCyan.yellowBright,
	colors.bgRed.black,
	colors.bgYellow.white,
	colors.bgBlue.magenta,
	colors.bgMagenta.blue,
	colors.bgBlack.yellow,
	colors.bgCyan.redBright,
	colors.bgCyan.black,
	colors.bgCyan.red,
	colors.bgMagenta.greenBright,
	colors.bgCyan.blueBright,
	colors.bgGreen.yellowBright,
	colors.bgYellow.magenta,
	colors.bgYellow.redBright,
	colors.bgMagenta.white,
	colors.bgMagenta.cyanBright,
	colors.bgMagenta.green,
	colors.bgYellow.black,
	colors.bgCyan.magenta,
	colors.bgYellow.whiteBright,
	colors.bgGreen.greenBright,
	colors.bgBlack.white,
	colors.bgBlue.yellowBright,
	colors.bgBlue.cyanBright,
	colors.bgYellow.red,
	colors.bgGreen.black,
	colors.bgBlue.whiteBright,
	colors.bgBlack.greenBright,
	colors.bgBlack.whiteBright,
	colors.bgYellow.greenBright,
	colors.bgBlack.magenta,
	colors.bgGreen.magenta,
	colors.bgGreen.blueBright,
	colors.bgGreen.cyanBright,
	colors.bgGreen.red,
	colors.bgBlue.red,
	colors.bgBlue.black,
	colors.bgBlack.redBright,
	colors.bgGreen.redBright,
	colors.bgBlack.yellowBright,
	colors.bgBlack.blueBright,
	colors.bgCyan.greenBright,
	colors.bgBlack.cyan,
	colors.bgRed.redBright,
	colors.bgCyan.yellow,
	colors.bgMagenta.redBright,
	colors.bgGreen.yellow,
	colors.bgRed.whiteBright,
	colors.bgCyan.cyanBright,
	colors.bgRed.blueBright,
	colors.bgRed.white,
	colors.bgBlack.cyanBright,
	colors.bgRed.cyanBright,
	colors.bgYellow.yellowBright,
	colors.bgMagenta.whiteBright,
	colors.bgRed.yellowBright,
	colors.bgYellow.cyanBright,
	colors.bgBlack.green,
	colors.bgBlue.greenBright,
	colors.bgGreen.whiteBright,
	colors.bgRed.yellow,
	colors.bgMagenta.black,
	colors.bgBlue.redBright,
	colors.bgMagenta.yellowBright,
	colors.bgCyan.whiteBright,
	colors.bgBlue.yellow,
	colors.bgRed.greenBright,
];

// POSSIBLE_COLOURS.forEach((colour, index) => {
// 	console.log(colour(`colour ${index}`));
// });

const registeredNames = {};

class Logger {
	constructor({ parentLogger, name, level = LOG_LEVEL.VERBOSE } = {}) {
		if (parentLogger) {
			this.parentLogger = parentLogger;
		}
		this.name = name;
		this.level = level;
		this.platform = usePlatform();
	}

	log(messageObject, data, ...extraMessages) {
		this.#log({ messageObject, data, extraMessages });
	}

	nested({ name }) {
		return new Logger({ name, parentLogger: this });
	}

	#log({ messageObject = {}, data = {}, extraMessages }) {
		let { level: levelName = "debug", message } = messageObject;
		const formattedData = Object.entries(data).length > 0 ? this.#formatData({ data }) : null;
		if (typeof levelName === "function") {
			levelName = levelName(data);
		}
		let level = LOG_LEVEL[levelName.toUpperCase()];
		const processedMessage = this.#getProcessedMessage({ message, formattedData, data });
		const { output } = this.#buildOutput({ level, processedMessage });
		this.#writeOutput({ level, output, extraMessages });

		if (!this.platform.isServer && level <= this.level) {
			const datadogLogs = window.DD_LOGS && window.DD_LOGS;
			const datadogLogFn = datadogLogs.logger[LOG_LEVEL_LOGGER_METHODS[level]].bind(datadogLogs.logger);
			const simpleData = simplifyObjectForLogging(data, 2);
			datadogLogFn(stripColor(processedMessage), { logSourceName: this.name, level: LOG_LEVEL_NAMES[level], logData: simpleData });
		}
	}

	#getProcessedMessage({ message, formattedData, data }) {
		let processedMessage = message;
		if (typeof processedMessage === "function") {
			processedMessage = processedMessage(formattedData || {}, data);
		} else if (formattedData) {
			processedMessage = `${processedMessage}\n${JSON.stringify(formattedData, null, 2)}`;
		}
		return processedMessage;
	}

	#buildOutput({ level, processedMessage }) {
		let output = `${LOG_LEVEL_NAMES[level]}`;
		let formattedName = "";
		if (this.name) {
			const colourForName = this.#getRegisteredColourForName(this.name);
			formattedName = colourForName(`<${this.name}>`) + " ";
		}
		output = `${formattedName}[${colors.bold(output)}] -> ${processedMessage}`;
		return { output };
	}

	#getRegisteredColourForName(name) {
		if (!registeredNames[name]) {
			const colourIndex = Object.keys(registeredNames).length % POSSIBLE_COLOURS.length;
			const colour = POSSIBLE_COLOURS[colourIndex];
			registeredNames[name] = { colour };
		}
		return registeredNames[name].colour;
	}

	#writeOutput({ level, output, extraMessages = [] }) {
		if (level <= this.level) {
			const colour = LOG_LEVEL_COLOURS[level];
			const colourFn = colors[colour];
			let finalOutput = output;
			if (!this.platform.isSafariOrIos) {
				let colourisedOutput = colourFn(output);
				if (!this.platform.isServer) {
					colourisedOutput = `${colourisedOutput} ${getBrowserMemoryUsageFormatted()}`;
				}
				finalOutput = colourisedOutput;
			} else {
				finalOutput = stripColor(output);
			}
			const logFn = console[LOG_LEVEL_LOGGER_METHODS[level]];
			logFn(finalOutput, ...extraMessages);
		}
	}

	#formatData({ data }) {
		return data && formatters ? Object.fromEntries(Object.entries(data).map(([k, v]) => [k, formatters[k] ? formatters[k](v) : v])) : data;
	}
}

const defaultLogger = new Logger({ name: "default" });

export const useLogger = ({ name = null } = {}) => {
	return name ? new Logger({ name }) : defaultLogger;
};

const getBrowserMemoryUsageFormatted = () => {
	const { usedJSHeapSize = 0, jsHeapSizeLimit = 0 } = window.performance?.memory ?? {};
	const maxMemoryThreshold = jsHeapSizeLimit * MAX_MEMORY_WARNING_THRESHOLD_PERCENTAGE;
	const thresholdDifference = usedJSHeapSize - maxMemoryThreshold;
	const usedPercentage = Math.round((usedJSHeapSize / maxMemoryThreshold) * 100);
	const warningMessage = thresholdDifference > 0 ? ` MEMORY USAGE WARNING: Exceeded threshold by ${formatSize(thresholdDifference)}` : "";
	let colorFn = colors.green.italic;
	if (usedPercentage > 80) {
		colorFn = colors.red.bold;
	} else if (usedPercentage > 60) {
		colorFn = colors.magenta.italic;
	} else if (usedPercentage > 40) {
		colorFn = colors.yellow.italic;
	}
	return colorFn(`[${usedPercentage}% (${formatSize(usedJSHeapSize)}/${formatSize(maxMemoryThreshold)})]${warningMessage}`);
};

/* function takes a size in bytes and returns a human readable string */
const formatSize = (size) => {
	const units = ["B", "KB", "MB", "GB", "TB"];
	let unitIndex = 0;
	while (size > 1024) {
		size /= 1024;
		unitIndex++;
	}
	return `${Math.round(size * 100) / 100} ${units[unitIndex]}`;
};
