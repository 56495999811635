<template>
	<div style="display: flex; flex-direction: column">
		TEST PAGE2 - {{ city }}
		<router-link :to="{ name: 'TestPage1', params: { city: 'london' } }">PAGE 1</router-link>
	</div>
</template>

<script setup>
import { computed, watch } from "vue";
import { useRoute } from "vue-router";

import { useRouteParams } from "../helpers/index.js";

const routeParams = useRouteParams();
const route = useRoute();

const city = computed(() => route.params.city);

watch(routeParams, () => {
	console.log("route params changed on Test Page 2", city.value);
});
</script>

<style lang="scss" scoped></style>
