import debounce from "p-debounce";

import { useLogger } from "../functions/logger/index.js";
import loggingMessages from "./onezoneApi.logging-messages.js";

export const useOnezoneApi = ({ initialState, authentication, dataService, entityProcessorService, queryProcessorService, mutationProcessorService }) => {
	const logger = useLogger({ name: "useOnezoneApi" });

	const onezoneApi = new OnezoneApi({
		logger,
		state: initialState,
		authentication,
		dataService,
		entityProcessorService,
		queryProcessorService,
		mutationProcessorService,
	});

	return onezoneApi;
};

class OnezoneApi {
	constructor({ logger, dataService, entityProcessorService, queryProcessorService, mutationProcessorService }) {
		this.logger = logger;
		this.dataService = dataService;
		this.entityProcessorService = entityProcessorService;
		this.queryProcessorService = queryProcessorService;
		this.mutationProcessorService = mutationProcessorService;
		this.queryCache = {};

		this.queries = Object.fromEntries(
			this.queryProcessorService.queries.map(({ name, query, queryProcessor }) => {
				const queryExecutor = debounce(
					async (variables, options) => {
						let results;
						const cacheKey = this.#getCacheKey({ query, variables, options });
						if (this.queryCache[cacheKey] && options?.useClientCache) {
							this.logger.log(loggingMessages.usingClientCachedDataForOperation, { operationName: name, variables, timeRemaining: "TODO" });
							results = this.queryCache[cacheKey];
						} else {
							const summary = {};
							const data = await this.dataService.get({ name, query, variables, options });
							results = await queryProcessor({ data, variables, summary });
							this.queryCache[cacheKey] = results;
						}
						return results;
					},
					0,
					{ before: true },
				);

				return [name, queryExecutor];
			}),
		);

		this.mutations = Object.fromEntries(
			this.mutationProcessorService.mutations.map(({ name, mutation, mutationProcessor }) => {
				const mutationExecutor = debounce(
					async (variables, options) => {
						const { data, errors } = await this.dataService.update({ name, mutation, variables, options });
						const mutationProcessorResults = data ? await mutationProcessor({ data, variables }) : null;

						return { data: mutationProcessorResults, errors };
					},
					0,
					{ before: true },
				);

				return [name, mutationExecutor];
			}),
		);
	}

	clearCache() {
		this.queryCache = {};
	}

	#getCacheKey({ query, variables, options }) {
		const json = JSON.stringify({
			query: query.loc.source.body,
			variables,
			options: options ? Object.fromEntries(Object.entries(options).filter(([key]) => !["useClientCache"].includes(key))) : undefined,
		});
		return json;
	}
}
