<template>
	<button class="push-button-tertiary" @click="buttonClicked"><slot /></button>
</template>

<script setup>
import { inject } from "vue";

const props = defineProps({
	name: {
		type: String,
		default: null,
	},
});

const modal = inject("modal");

function buttonClicked() {
	if (!modal) {
		throw new Error("Dialog button is not contained within a dialog");
	}
	modal.close({ name: props.name });
}
</script>

<style scoped lang="scss">
@import "../assets/styles/variables_new.scss";
</style>
