import { BranchDeepLinks } from "capacitor-branch-deep-links";

import { ExternallyResolvablePromise, getRelativeUrl, promisify } from "../../helpers/index.js";
import loggingMessages from "./Branch.logging-messages.js";

export class Branch {
	constructor({ logger, platform, router }) {
		this.logger = logger.nested({ name: "Branch" });
		this.platform = platform;
		this.router = router;
		this.initialisationPromise = new ExternallyResolvablePromise();

		this.#preInitialise();
	}

	async #preInitialise() {
		if (this.platform.isNative) {
			BranchDeepLinks.addListener("init", this.#handleDeepLink.bind(this));
			BranchDeepLinks.addListener("initError", (error) => {
				this.logger.log(loggingMessages.intialisationError, { errorJson: error });
			});
		} else if (!this.platform.isServer) {
			this.logger.log(loggingMessages.initialisingBranch);
			this.#importBranchWebSDK();
			await promisify(window.branch.init.bind(window.branch))("key_live_kElNDzjlyoFZe84r2KyEpdohrvoVGfH6", {});
		}
	}

	async initialise() {
		this.initialisationPromise.resolve();
	}

	async setIdentity(id) {
		this.logger.log(loggingMessages.identifyingUserToBranch, { userId: id });
		if (this.platform.isNative) {
			return BranchDeepLinks.setIdentity({ newIdentity: id });
		} else {
			await promisify(window.branch.setIdentity.bind(window.branch))(id);
		}
	}

	async logout() {
		this.logger.log(loggingMessages.loggingOutUserFromBranch);
		if (this.platform.isNative) {
			return BranchDeepLinks.logout();
		} else {
			await promisify(window.branch.logout.bind(window.branch))();
		}
	}

	#importBranchWebSDK() {
		(function (b, r, a, n, c, h, _, s, d, k) {
			if (!b[n] || !b[n]._q) {
				for (; s < _.length; ) c(h, _[s++]);
				d = r.createElement(a);
				d.async = 1;
				d.src = "https://cdn.branch.io/branch-latest.min.js";
				k = r.getElementsByTagName(a)[0];
				k.parentNode.insertBefore(d, k);
				b[n] = h;
			}
		})(
			window,
			document,
			"script",
			"branch",
			function (b, r) {
				b[r] = function () {
					b._q.push([r, arguments]);
				};
			},
			{ _q: [], _v: 1 },
			"addListener banner closeBanner closeJourney data deepview deepviewCta first init link logout removeListener setBranchViewData setIdentity track trackCommerceEvent logEvent disableTracking getBrowserFingerprintId crossPlatformIds lastAttributedTouchData setAPIResponseCallback qrCode setRequestMetaData setAPIUrl getAPIUrl setDMAParamsForEEA".split(
				" ",
			),
			0,
		);
	}

	async #handleDeepLink(event) {
		await this.initialisationPromise;

		const { referringParams } = event;
		const { "$canonical_url": canonicalUrl, "+clicked_branch_link": clickedBranchLink } = referringParams;
		if (clickedBranchLink && canonicalUrl) {
			const relativeUrl = getRelativeUrl(canonicalUrl);
			this.logger.log(loggingMessages.branchDeepLinkNavigating, { relativeUrl });
			this.router.push(relativeUrl);
		} else {
			this.logger.log(loggingMessages.branchDeepLinkNoNavigationData, { event });
		}
	}
}
