import { computed, ref } from "vue";
import loggingMessages from "./Logging.logging-messages.js";

export class Logging {
	constructor({ logger, config, debugContext }) {
		this.logger = logger.nested({ name: "Logging" });
		this.config = config;
		this.debugContext = debugContext;
		this.sessionId = null;
	}

	async init() {
		const { platformEnvironmentName: env, clientVersion: version, clientName, clientVersion } = this.config;
		this.logger.log(loggingMessages.initialisingDatadogLogs, { env, version });
		const datadogLogs = await this.#getDatadogLogs();

		if (datadogLogs) {
			datadogLogs.init({
				...this.config.dataDog,
				forwardErrorsToLogs: false,
				sessionSampleRate: 100,
			});
			const { session_id } = datadogLogs.getInternalContext() ?? { session_id: "internal_context_not_found" };

			this.sessionId = ref(session_id);
			this.dataDogLink = computed(() => this.#getDatadogLink(this.sessionId.value));

			this.debugContext.add({ name: "dataDogSessionId", type: String, value: this.sessionId.value });
			this.debugContext.add({ name: "dataDogSessionLink", type: "link", value: { text: "open datadog session log", link: this.dataDogLink.value } });

			datadogLogs.setGlobalContext({
				env,
				version,
				clientName,
				clientVersion,
				service: "hybrid-client",
			});
			this.logger.log(loggingMessages.initialisedDatadogLogs, { sessionId: this.sessionId.value });
		}
	}

	async identifyUser({ user }) {
		this.logger.log(loggingMessages.identifyingUser, { userId: user.id });
		const datadogLogs = await this.#getDatadogLogs();
		if (datadogLogs) {
			const { id, firstName, lastName } = user;
			datadogLogs.setUser({
				id,
				firstName,
				lastName,
				name: [firstName, lastName].join(" "),
			});
		}
	}

	async ensureLoggedOut() {
		this.logger.log(loggingMessages.ensuringLoggedOutFromDatadog);
		const datadogLogs = await this.#getDatadogLogs();
		if (datadogLogs) {
			datadogLogs.clearUser();
		}
	}

	async setApiEnvironment({ name, version }) {
		this.logger.log(loggingMessages.settingApiEnvironment, { name, version });
		const datadogLogs = await this.#getDatadogLogs();
		if (datadogLogs) {
			datadogLogs.setGlobalContextProperty("apiEnvironmentName", name);
			datadogLogs.setGlobalContextProperty("apiEnvironmentVersion", version);
		}
	}

	async #getDatadogLogs() {
		let datadogLogs = typeof window !== "undefined" ? window.DD_LOGS : null;
		if (!datadogLogs && typeof window !== "undefined") {
			const datadogScript = document.getElementById("datadogScript");
			if (datadogScript) {
				this.logger.log(loggingMessages.waitingForDatadogLogsScriptToLoad);
				await new Promise((resolve) => {
					this.logger.log(loggingMessages.dataDogScriptLoaded);
					const listener = datadogScript.addEventListener("load", resolve);
					setTimeout(() => {
						datadogScript.removeEventListener("load", listener);
						this.logger.log(loggingMessages.timedOutWaitingForDatadogLogsScriptToLoad);
						resolve();
					}, 5000);
				});
				datadogLogs = typeof window !== "undefined" ? window.DD_LOGS : null;
			}
		}
		return datadogLogs;
	}

	#getDatadogLink(sessionId) {
		return `https://app.datadoghq.eu/logs?query=%40session_id%3A${sessionId}%20&cols=host%2Cservice%2CclientName&fromUser=true&index=%2A&messageDisplay=inline&refresh_mode=sliding&storage=hot&stream_sort=desc&view=spans&viz=stream&from_ts=1709821881703&to_ts=1709825481703&live=true`;
	}
}
