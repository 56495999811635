export default {
	userAuthenticatedAndHasProfileRedirecting: {
		level: "info",
		message: ({ environmentName }) => `User authenticated and has a profile for ${environmentName}, redirecting...`,
	},
	userProfileCreatedReInitialisingEnvironment: {
		level: "info",
		message: ({ environmentName }) => `User profile created for ${environmentName}, re-initialising environment`,
	},
};
