import { computed } from "vue";

export default class {
	constructor({ logger, modelBuilder, api, authentication }) {
		this.logger = logger.nested({ name: "Model" });
		this.modelBuilder = modelBuilder;
		this.api = api;
		this.authentication = authentication;
	}

	execute({ venueId, startTime, partySize, isSignedIn, isEnabled } = {}, { isWatchEnabled, onExecuted } = {}) {
		return this.modelBuilder.build({
			name: "GetVenueBookingAvailability",
			args: { venueId, startTime, partySize, isSignedIn, isEnabled },
			isWatchEnabled,
			execute: async ({ args }) => {
				let venue, timesAvailable, noAvailabilityReasons;
				if (args.venueId && args.isEnabled && args.startTime && args.partySize) {
					const data = await this.api.queries.getVenueBookingAvailability(args, {
						useClientCache: false,
						accessToken: args.isSignedIn ? this.authentication.getAccessToken : undefined,
					});
					venue = computed(() => data.venue);
					timesAvailable = computed(() => data.venue.book.availabilityTimes.timesAvailable);
					noAvailabilityReasons = computed(() => data.venue.book.availabilityTimes.noAvailabilityReasons);
					if (onExecuted) {
						onExecuted({ timesAvailable });
					}
				}
				return { venue, timesAvailable, noAvailabilityReasons };
			},
		});
	}
}
