<template>
	<Dialog ref="perkDialogRef" v-slot="{ closeDialog }" class="perk-dialog-layout" param-name="showPerk">
		<DialogSimpleLayout :show-light-close-button="isSmallerThanMedium" @close="closeDialog">
			<PerkDetail :perk="props.perk" />
		</DialogSimpleLayout>
	</Dialog>
</template>

<script setup>
import { ref } from "vue";

import { breakpoints } from "../../domain/index.js";

import Dialog from "../Dialog.vue";
import PerkDetail from "./PerkDetail.vue";
import DialogSimpleLayout from "../DialogSimpleLayout.vue";

const props = defineProps({
	perk: {
		type: Object,
		default: null,
	},
});

const perkDialogRef = ref(null);

const isSmallerThanMedium = breakpoints.smaller("medium");

defineExpose({ showModal, close });

function showModal(...args) {
	perkDialogRef.value.open(...args);
}

function close(...args) {
	perkDialogRef.value.close(...args);
}
</script>

<style lang="scss" scoped>
@import "../../assets/styles/variables_new.scss";

h3 {
	font-size: 1rem;
}

.perk-dialog-layout {
	width: 90vw;
	// max-width: 90vw !important;
	// height: 100% !important;
	// max-height: 90dvh !important;
	// padding: 0 !important;
}

@media (min-width: $bp-medium) {
	.perk-dialog-layout {
		max-width: 800px !important;
		// max-height: min(600px, 90dvh) !important;
	}
}
</style>
