export function computeDistanceBetween(point1, point2) {
	const toRadians = (degree) => (degree * Math.PI) / 180;
	const R = 6378137; // Earth's radius in meters

	const lat1 = toRadians(point1.lat);
	const lat2 = toRadians(point2.lat);
	const deltaLat = lat2 - lat1;
	const deltaLng = toRadians(point2.lng - point1.lng);

	const a = Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) + Math.cos(lat1) * Math.cos(lat2) * Math.sin(deltaLng / 2) * Math.sin(deltaLng / 2);
	const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

	return R * c; // Distance in meters
}
