export default {
	criticalErrorUnlockingVenueBookingAvailability: {
		level: "error",
		message: ({ venueId, errors }) => `Critical error unlocking venue booking availability for venue ${venueId}:\n${errors}`,
	},
	errorUnlockingVenueBookingAvailability: {
		level: "error",
		message: ({ venueId, error }) => `Error unlocking venue booking availability for venue ${venueId}:\n${error}`,
	},
};
