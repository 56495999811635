export default {
	imageUploadNotAllowedSizeExceeded: {
		level: "warn",
		message: ({ data }) => `Image upload not allowed as size exceeded maxiumum allowed: ${data}`,
	},
	uploadingImage: {
		level: "info",
		message: ({ data }) => `Uploading image: ${data}`,
	},
	uploadedImage: {
		level: "info",
		message: ({ data }) => `Image uploaded: ${data}`,
	},
};
