import { EntityTypeNames } from "../../constants/index.js";

export default class ChangeResultsProcessor {
	static doesSupportType(typeName) {
		return typeName === EntityTypeNames.Premium;
	}

	constructor({ entityStore }) {
		this.entityStore = entityStore;
	}

	process({ entity, entityProcessor }, args, summary) {
		const products = entityProcessor.processEntities(entity.products, args, summary);

		// const storedProducts = products.map((product) => this.entityStore.store({ id: product.id, typeName: product.__typename, entity: product }, summary));

		// return { products: storedProducts };
		return { products };
	}
}
