import { Storage } from "@capacitor/storage";

const CACHE_KEY_PREFIX = "@@auth0spajs@@";

export class NativeAppAuthenticationStorageCache {
	constructor() {}

	async set(key, entry) {
		await Storage.set({ key, value: JSON.stringify(entry) });
	}
	async get(key) {
		const { value: json } = await Storage.get({ key });
		if (!json) return;
		try {
			const payload = JSON.parse(json);
			return payload;
		} catch (e) {
			return;
		}
	}
	async remove(key) {
		await Storage.remove({ key });
	}
	async allKeys() {
		const { keys } = await Storage.keys();
		const filteredKeys = keys.filter((key) => key.startsWith(CACHE_KEY_PREFIX));
		return filteredKeys;
	}
}
