import { ErrorNested } from "./ErrorNested.js";

export class EnvironmentInitialisationError extends ErrorNested {
	constructor(environmentName, error, extensions) {
		super("EnvironmentInitialisationError", `Critical error whilst initialising environment: ${environmentName}`, {
			exception: error,
			code: "ENVIRONMENT_INITIALISATION_ERROR",
			...extensions,
		});
	}
}
