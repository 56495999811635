import { computed } from "vue";

export default class {
	constructor({ logger, modelBuilder, api, authentication }) {
		this.logger = logger.nested({ name: "Model" });
		this.modelBuilder = modelBuilder;
		this.api = api;
		this.authentication = authentication;
	}

	/* isSignedIn is passed in so any changes to the user's sign-in status will trigger a re-fetch, anonymously */
	execute({ venueId, isSignedIn } = {}, { isWatchEnabled } = {}) {
		return this.modelBuilder.build({
			name: "GetVenueDetail",
			args: { venueId, isSignedIn },
			isWatchEnabled,
			execute: async ({ args }) => {
				let venue;
				if (args.venueId) {
					const data = await this.api.queries.getVenueDetail(args, {
						useClientCache: true,
						clientCacheMaxAge: { minutes: 10 },
						accessToken: args.isSignedIn ? this.authentication.getAccessToken : undefined,
					});
					venue = computed(() => data.venue);
				} else {
					venue = null;
				}
				return { venue };
			},
		});
	}
}
