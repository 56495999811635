<template>
	<div class="venue-detail-book">
		<a name="book"><h3>Find A Table</h3></a>
		<div class="booking-stage">
			<VenueBookingStage
				:placement="PagePlacements.EMBEDDED"
				:venue-booking="venueBooking"
				:stage="stage"
				v-bind="{ showVenueHeader: false, isAutomaticAvailabilityCheck: false, isAlignedCenter: false }"
				:v-on-override="{ timeSlotSelected }"
			/>
			<!-- <VenueBookingStageSelectTimeSlot :venue-booking="venueBooking" :is-getting-lock="isGettingLock" @time-slot-selected="timeSlotSelected" /> -->
			<BookingProviderLogo :booking-provider="bookingProvider" :show-border="false" :is-aligned-center="false" />
		</div>
	</div>
</template>

<script setup>
import { computed, inject, toRef } from "vue";

import { useVenueBooking } from "../../domain/index.js";
import { BookingProvider, BookingStageNames, PagePlacements } from "../../constants/index.js";

import VenueBookingStage from "./VenueBookingStage.vue";
import BookingProviderLogo from "../BookingProviderLogo.vue";

const props = defineProps({
	venueId: {
		type: String,
		required: true,
	},
});

const venueBookingDialogRef = inject("venueBookingDialogRef");

const venueBooking = useVenueBooking({ venueId: toRef(props, "venueId") }, { label: "VenueDetailBook" });

const stage = computed(() => venueBooking.stages.value[BookingStageNames.SELECT_TIME_SLOT]);
const bookingProvider = computed(() => BookingProvider.OPEN_TABLE);

async function timeSlotSelected({ timeSlot, seatingPosition, isCreditCardRequired, bookingProviderAssociation }) {
	venueBookingDialogRef.value.selectTimeSlot({ venueId: props.venueId, timeSlot, seatingPosition, isCreditCardRequired, bookingProviderAssociation });
}
</script>

<style scoped lang="scss">
@import "../../assets/styles/variables_new.scss";

.venue-detail-book {
	display: flex;
	flex-direction: column;
	gap: calc($spacing / 2);

	h3 {
		font-size: $text-size-quaternary;
		margin-bottom: calc($spacing/2);
	}

	.booking-stage {
		display: flex;
		flex-direction: column;
		gap: calc($spacing * 1.5);
	}
}
</style>
