import { computed } from "vue";

export default class {
	constructor({ logger, modelBuilder, api, authentication }) {
		this.logger = logger.nested({ name: "Model" });
		this.modelBuilder = modelBuilder;
		this.api = api;
		this.authentication = authentication;
	}

	execute({ isSignedIn }) {
		return this.modelBuilder.build({
			name: "GetCurrentUserLists",
			args: { isSignedIn },
			execute: async ({ args }) => {
				let currentUserLists = [];
				if (args.isSignedIn) {
					const data = await this.api.queries.getCurrentUserLists({}, { useClientCache: true, accessToken: args.isSignedIn ? this.authentication.getAccessToken : undefined });
					currentUserLists = computed(() => data.me.lists);
				}

				return { currentUserLists };
			},
		});
	}
}
