<template>
	<button :class="{ 'filters-container': true, 'is-highlighted': props.isHighlighted }">
		<div class="filters">
			<img src="../assets/icons/filters.svg" alt="filters" class="filters-icon" />
			<div v-if="props.showLabel" class="filters-text">Filter</div>
			<div v-if="props.appliedFilterCount !== null" class="filters-applied-decal">{{ props.appliedFilterCount }}</div>
		</div>
	</button>
</template>

<script setup>
const props = defineProps({
	isHighlighted: {
		type: Boolean,
		default: false,
	},
	appliedFilterCount: {
		type: [String, Number],
		default: null,
	},
	showLabel: {
		type: Boolean,
		default: true,
	},
});
</script>

<style scoped lang="scss">
@import "../assets/styles/variables_new.scss";

.filters-container {
	cursor: pointer;
	position: relative;
	// width: 32px;
	color: $text-color-primary;

	&.is-highlighted {
		// color: $text-color-primary;

		.filters {
			.filters-icon {
				// filter: none;
			}
		}
	}

	.filters {
		display: flex;
		flex-direction: column;
		align-items: center;
		.filters-icon {
			// filter: opacity(0.3);
			width: 17px;
			overflow: visible;
		}

		.filters-text {
			font-size: $text-size-quinary;
		}

		.filters-applied-decal {
			position: absolute;
			box-sizing: border-box;
			width: 14px;
			height: 14px;
			line-height: 14px;
			border-radius: 50%;
			background-color: $text-color-primary;
			font-size: $text-size-quinary;
			font-weight: $text-bold-primary;
			color: $background-color-primary;
			text-align: center;
		}
	}
}
</style>
