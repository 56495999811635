<template>
	<div class="app-container">
		<Dialog :dialog="environmentProcessingDialog">
			<DialogLayoutFullscreen :dialog="environmentProcessingDialog" :has-close-button="false" class="environment-processing-dialog">
				<loading-message class="loading-message">{{ processingMessage }}</loading-message>
			</DialogLayoutFullscreen>
		</Dialog>

		<RouterView v-if="!isProcessing" v-slot="{ Component }" class="router-view">
			<Component :is="Component" />
		</RouterView>
	</div>
</template>

<script setup>
import "animate.css";
import "normalize.css";
import { inject, provide, onMounted, computed, watchEffect, ref, watch, toRef } from "vue";
import { useRouter } from "vue-router";

import { useDialog, Dialog, DialogLayoutFullscreen } from "./components/dialog/index.js";
import { EnvironmentProcessingStates } from "./domain/index.js";
import { PageNames } from "./constants/index.js";
import { StripePaymentError } from "./errors/index.js";
import loggingMessages from "./App.logging-messages.js";
import { urlBuilder } from "./helpers/index.js";

import LoadingMessage from "./components/LoadingMessage.vue";

const PROCESSING_MESSAGES = {
	[EnvironmentProcessingStates.INITIALISING]: "Checking user profile, please wait...",
	[EnvironmentProcessingStates.SWITCHING_ENVIRONMENT]: "Switching environment, please wait...",
};

const emit = defineEmits(["hydrated"]);

const logger = inject("logger").nested({ name: "App" });
const tracking = inject("tracking");
const queryString = inject("queryString");
const featureSwitchManager = inject("featureSwitchManager");
const environmentManager = inject("environmentManager");
const currentEnvironment = inject("currentEnvironment");
const globalErrorMessage = inject("globalErrorMessage");

const router = useRouter();
const environmentProcessingDialog = useDialog({ isOpen: false });

router.beforeEach(async (to) => {
	ensureUserLoggedInIfRequired({ route: to });
});

router.afterEach((to) => {
	tracking?.trackPageView(to);
});

const loginCallbackHandled = ref(false);
const isMounted = ref(false);
const isAppHydrated = ref(false);

const isProcessing = computed(() => isMounted.value && environmentManager.isProcessing.value);
const processingMessage = computed(() => (isProcessing.value ? PROCESSING_MESSAGES[environmentManager.processingState.value] : null));

watchEffect(() => {
	if (isProcessing.value) {
		environmentProcessingDialog.open();
	} else {
		environmentProcessingDialog.close();
	}
});

watch(toRef(queryString, "environment"), async () => {
	if (isMounted.value) {
		await switchEnvironment();
	}
});

watch(
	toRef(queryString, "stripePaymentError"),
	(stripePaymentError) => {
		if (stripePaymentError) {
			globalErrorMessage.show(new StripePaymentError(stripePaymentError));
		}
	},
	{ immediate: true },
);

onMounted(async () => {
	isMounted.value = true;
	logger.log(loggingMessages.mounted);
	await environmentManager.initialisationPromise;
	isAppHydrated.value = true;
	logger.log(loggingMessages.hydrated);
	ensureUserLoggedInIfRequired();
});

provide("isAppHydrated", isAppHydrated);

await intialiseEnvironment();

async function intialiseEnvironment() {
	await environmentManager.initialise({ handlers: { onAuthenticated: ensureUserLoggedInIfRequired } });
	featureSwitchManager.initialise(environmentManager.currentEnvironment.value);

	await switchEnvironment();

	await handleLoginCallback();
	logger.log(loggingMessages.appFullyInitialised, { environmentName: environmentManager.currentEnvironment.value?.name });
	tracking?.trackPageView(router.currentRoute.value);
	emit("hydrated");
}

async function switchEnvironment() {
	const currentEnvironmentName = environmentManager.currentEnvironment.value?.name;
	const newEnvironmentName = queryString.environment;
	if (!!currentEnvironmentName && newEnvironmentName && currentEnvironmentName !== newEnvironmentName) {
		await environmentManager.initialisationPromise;
		try {
			await environmentManager.switchToName(newEnvironmentName);
		} catch (error) {
			globalErrorMessage.show(error);
		}
	}
}

async function handleLoginCallback() {
	const route = router.currentRoute.value;
	if (route.name === "LoggedIn" && !loginCallbackHandled.value) {
		try {
			loginCallbackHandled.value = true;
			const url = window.location.href;
			logger.log(loggingMessages.handlingLoginCallback, { url });

			await currentEnvironment.value.handleRedirectCallback(url);
		} catch (error) {
			globalErrorMessage.show(error);
		}
	}
}

function ensureUserLoggedInIfRequired(options = {}) {
	const route = options.route ?? router.currentRoute.value;
	if (!currentEnvironment.value) {
		throw new Error("A current environment must be set before calling ensureUserLoggedInIfRequired");
	}
	const isAuthenticated = options.isAuthenticated ?? currentEnvironment.value.isAuthenticated.value;
	const hasUserProfile = options.hasUserProfile ?? currentEnvironment.value.hasUserProfile.value;

	if (!globalErrorMessage.error) {
		const returnTo = queryString.returnTo ? decodeURIComponent(queryString.returnTo) : null;
		const requiresAuth = route.meta?.requiresAuth ?? true;

		if (isAuthenticated && hasUserProfile && [PageNames.LOGGED_IN, PageNames.CREATE_PROFILE, PageNames.LOG_IN].includes(route.name)) {
			if (returnTo) {
				redirectToPreviousPageWhenSignedIn(returnTo);
			} else {
				redirectToHomePageWhenSignedIn();
			}
		} else if (isAuthenticated && !hasUserProfile && route.name !== PageNames.CREATE_PROFILE) {
			redirectToCreateProfilePage(returnTo ?? router.resolve(route).href);
			return false;
		} else if (!isAuthenticated && requiresAuth && route.name !== PageNames.LOG_IN) {
			return true;
			// if (featureSwitchManager.isActive(FeatureSwitchNames.AnonymousUsage)) {
			// 	return true;
			// } else {
			// 	redirectToLoginPage(returnTo ?? router.resolve(route).href);
			// 	return false;
			// }
		}
	}

	return true;
}

function redirectToPreviousPageWhenSignedIn(returnTo) {
	logger.log(loggingMessages.userAuthenticatedAndHasProfileRedirectingToPreviousPage, { url: returnTo });
	router.replace(returnTo);
}

function redirectToHomePageWhenSignedIn() {
	logger.log(loggingMessages.userAuthenticatedAndHasProfileRedirectingToHomePage);
	router.replace(urlBuilder(PageNames.HOME_FEED, { cityName: "london" }));
}

function redirectToCreateProfilePage(returnTo) {
	const routeTo = urlBuilder(PageNames.CREATE_PROFILE, { cityName: "london", returnTo });
	logger.log(loggingMessages.userAuthenticatedButDoesNotHaveProfileRedirectingToCreateProfile);
	router.replace(routeTo);
}

// function redirectToLoginPage(returnTo) {
// 	const routeTo = urlBuilder(PageNames.LOG_IN, { cityName: "london", returnTo });
// 	logger.log(loggingMessages.userNotAuthenticatedRedirectingToLogin);
// 	router.replace(routeTo);
// }
</script>
``

<style lang="scss" scoped>
@import "./assets/styles/variables_new.scss";

.app-container {
	height: 100%;
}

.environment-processing-dialog {
	.dialog {
		animation-duration: 0.5s;

		& > .content {
			display: flex;
			align-items: flex-start;
			justify-content: center;

			.loading-message {
				height: 100%;
			}
		}
	}
}

@media (min-width: $bp-medium) {
	.environment-processing-dialog {
		max-height: 200px !important;
		max-width: min(500px, 80vw) !important;
	}
}
</style>
