<template>
	<div :class="{ 'search-context': true, 'align-center': props.alignCenter }"><slot /></div>
</template>

<script setup>
const props = defineProps({
	alignCenter: {
		type: Boolean,
		default: true,
	},
});
</script>

<style scoped lang="scss">
@import "../assets/styles/variables_new.scss";

.search-context {
	display: flex;
	// flex-direction: column;
	flex-wrap: wrap;
	gap: calc($spacing / 2);

	&.align-center {
		justify-content: center;
	}
}
</style>
