<template>
	<router-link :to="link" class="place" @click.stop>
		<div class="icon"><font-awesome-icon icon="fa-location-dot" /></div>
		<div class="details">
			<div class="name">{{ name }}</div>
			<div class="address">{{ address }}</div>
		</div>
	</router-link>
</template>

<script setup>
import { computed } from "vue";

import { toArray } from "../functions/query-string/index.js";

const props = defineProps({
	place: {
		type: Object,
		required: true,
	},
	cityName: {
		type: String,
		required: true,
	},
	filterState: {
		type: Object,
		required: true,
	},
});

const name = computed(() => props.place.name);
const address = computed(() => props.place.address);
const cityName = computed(() => props.cityName);
const filterState = computed(() => props.filterState);
const link = computed(() => buildLinkToMapPlaceSearch(props.place));

function buildLinkToMapPlaceSearch(place) {
	const _location = toArray(place, ["latitude", "longitude"]);
	return {
		name: "City/Map",
		params: {
			city: cityName.value,
		},
		query: {
			center: _location,
			searchLocation: _location,
			showSearchResults: false,
			prices: filterState.value.prices ?? [],
			mealTypeIds: filterState.value.mealTypeIds ?? [],
			cuisineIds: filterState.value.cuisineIds ?? [],
			occasionIds: filterState.value.occasionIds ?? [],
			vibeIds: filterState.value.vibeIds ?? [],
		},
	};
}
</script>

<style scoped lang="scss">
@import "../assets/styles/variables_new.scss";

.place {
	display: flex;
	align-items: center;
	gap: calc($spacing / 1.5);
	padding: calc($spacing / 2);
	box-sizing: border-box;
	@include hover-primary;

	.details {
		display: flex;
		flex-direction: column;
		overflow: auto;

		.name {
			font-weight: $text-bold-primary;
			font-size: $text-size-secondary;
		}

		.address {
			font-size: $text-size-tertiary;
			color: $text-color-secondary;
			@include text-ellipsis;
		}
	}

	.icon {
		flex-shrink: 0;
		width: 40px;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: $border-radius-secondary;
		border: $border-size-primary solid $border-color-secondary;

		svg {
			width: 22px;
			height: 22px;
		}
	}
}
</style>
