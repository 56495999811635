<template>
	<MicroCard
		v-if="props.showMicro"
		:text="venueName"
		:middle-text="venueAddress"
		:bottom-text="tags"
		:image-src="imageUrl"
		:image-preview="imagePreview"
		:icon-src="perkIcon"
		:router-link="routerLink"
		class="micro-card"
	>
		<template #default>
			<VenueInUserLists
				v-if="props.userLists && props.showUserLists"
				:venue="props.venue"
				:city-id="cityId"
				:user-lists="userLists"
				orientation="vertical"
				:show-mini="true"
				:show-labels="false"
				class="venue-in-user-lists"
			/>
			<div v-else class="venue-in-user-lists-place-holder"></div>
		</template>
	</MicroCard>
	<MiniCard
		v-else-if="props.showMini"
		:text="venueName"
		:middle-text="venueAddress"
		:bottom-text="tags"
		:image-src="imageUrl"
		:image-preview="imagePreview"
		:icon-src="perkIcon"
		:router-link="routerLink"
	>
		<template #default>
			<VenueInUserLists v-if="props.userLists && props.showUserLists" :venue="props.venue" :city-id="cityId" :user-lists="userLists" orientation="vertical" :show-mini="true" />
		</template>
	</MiniCard>
	<Card v-else :top-text="venueName" :top-sub-text="venueAddress" :bottom-text="tags" :image-src="imageUrl" :image-preview="imagePreview" :icon-src="perkIcon" :router-link="routerLink">
		<template #default>
			<VenueInUserLists v-if="props.userLists && props.showUserLists" :venue="props.venue" :city-id="cityId" :user-lists="userLists" orientation="vertical" :show-mini="true" />
		</template>
	</Card>
</template>

<script setup>
import { computed, reactive, toRefs } from "vue";
import { useRoute } from "vue-router";

import DiamondWhite from "../assets/icons/Diamond-White.svg";

import Card from "./Card.vue";
import MiniCard from "./MiniCard.vue";
import MicroCard from "./MicroCard.vue";
import VenueInUserLists from "./VenueInUserLists.vue";

const props = defineProps({
	venue: {
		type: Object,
		required: true,
		validator: (venue) => {
			return venue.id && venue.name && venue && venue.images && venue.addressLine2 && venue.tags;
		},
	},
	userLists: {
		type: Object,
		default: null,
	},
	showUserLists: {
		type: Boolean,
		default: true,
	},
	showMini: {
		type: Boolean,
		default: false,
	},
	showMicro: {
		type: Boolean,
		default: false,
	},
	routerLink: {
		type: Object,
		default: null,
	},
});

const userLists = computed(() => props.userLists);

const { venueName, venueAddress, cityId, imageUrl, imagePreview, tags, perkIcon } = toRefs(
	reactive({
		venueName: computed(() => props.venue.name),
		venueAddress: computed(() => props.venue.addressLine2),
		perkIcon: computed(() => (props.venue.perks()?.length > 0 ? DiamondWhite : null)),
		cityId: computed(() => props.venue.zone().area().city().id),
		imageUrl: computed(() => {
			const finalImageUrl = props.venue.images?.length ?? 0 > 0 ? props.venue.images[0].url : null;
			return finalImageUrl;
		}),
		imagePreview: computed(() => {
			const preview = props.venue.images?.length ?? 0 > 0 ? props.venue.images[0].preview : null;
			return preview;
		}),
		tags: computed(() =>
			props.venue.tags
				.filter(({ category }) => ["MEAL_TYPE"].indexOf(category) === -1)
				.map((tag) => tag.name)
				.join(", "),
		),
	}),
);

const currentRoute = useRoute();
const routerLink = computed(() => {
	return (
		props.routerLink ?? {
			name: "City/Explore/Areas/Venue",
			params: {
				city: currentRoute.params.city,
				venueId: props.venue.id,
			},
		}
	);
});
</script>

<style lang="scss" scoped>
@import "../assets/styles/variables_new.scss";

.micro-card {
	:deep(.venue-in-user-lists) {
		gap: calc($spacing * 1.5) !important;
	}
	:deep(.venue-in-user-lists-place-holder) {
		height: 18px;
	}
}
</style>
