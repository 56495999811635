import { ErrorNested } from "./ErrorNested.js";

export class TrackingIdFetchError extends ErrorNested {
	constructor(error, extensions) {
		super("TrackingIdFetchError", `Error whilst attempting to fetch tracking ID (idfa/aaid)`, {
			exception: error,
			code: "TRACKING_ID_FETCH_ERROR",
			...extensions,
		});
	}
}
