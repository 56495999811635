import { GlobalChangeSets } from "../../constants/index.js";

export default class {
	constructor({ changeManager, entityStore }) {
		this.changeManager = changeManager;
		this.entityStore = entityStore;
	}

	add({ firstName, lastName, username }) {
		this.changeManager.removeChangeFromQueue({ typeName: "UpsertCurrentUser" }, { ignoreData: true });
		return this.changeManager.addChangeToQueue({ typeName: "UpsertCurrentUser", data: { firstName, lastName, username } }, { changeSet: GlobalChangeSets.Instant });
	}

	getChangeEffects() {
		return [];
	}
}
