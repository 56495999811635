<template>
	<div class="results-section">
		<div class="results-section-top">
			<h2 class="results-section-heading" @click="toggle">{{ props.header }}</h2>
			<span v-if="props.subHeader" class="results-section-sub-heading" v-html="props.subHeader"></span>
			<div class="controls"><slot name="controls" /></div>
		</div>
		<div v-if="displayResults" class="results-section-list">
			<slot name="default" v-bind="{ showAll: isShowingAll, data, isItemVisible, toggleFilter, toggleOnChange, isAlreadyAPendingFilter }" />
		</div>
		<div v-if="displaySeeAllButton" class="results-expander">
			<button class="see-all-button link-button-primary" @click="toggle">
				<template v-if="!isExpanded">Show all</template>
				<template v-else>Hide</template>
				{{ props.typeName ? ` ${pluralize(dataLength, props.typeName)}` : null }}{{ dataLength ? ` (${dataLength})` : null }}
			</button>
		</div>
	</div>
</template>

<script setup>
import { computed, inject, ref, toRef, watch } from "vue";

import { pluralize } from "../helpers/index.js";

const props = defineProps({
	resultType: {
		type: String,
		required: true,
	},
	header: {
		type: String,
		required: true,
	},
	subHeader: {
		type: String,
		default: null,
	},
	typeName: {
		type: String,
		default: null,
	},
	showAll: {
		type: Boolean,
		default: false,
	},
	data: {
		type: Array,
		default: null,
	},
	collapseCount: {
		type: Number,
		default: 3,
	},
});

const filterStateManager = inject("filterStateManager");

const isExpanded = ref(false);

const dataLength = computed(() => props.data?.length ?? null);
const displaySeeAllButton = computed(() => !props.showAll && (!dataLength.value === null || dataLength.value > props.collapseCount));
const displayResults = computed(() => dataLength.value === null || dataLength.value > 0);
const isShowingAll = computed(() => (props.showAll ? true : isExpanded.value));
const data = computed(() => (!props.showAll && props.data ? props.data.slice(0, isExpanded.value ? Infinity : props.collapseCount) : props.data));

watch([toRef(props, "data"), toRef(props, "header"), toRef(props, "subHeader"), toRef(props, "resultType"), toRef(props, "typeName")], () => {
	isExpanded.value = false;
});

defineExpose({
	hide,
	show,
	toggle,
});

function hide() {
	isExpanded.value = false;
}
function show() {
	isExpanded.value = true;
}
function toggle() {
	isExpanded.value = !isExpanded.value;
}

function toggleOnChange(el) {
	const entity = data.value.find(({ id }) => id === el.target.value);
	toggleFilter(entity);
}

function isAlreadyAPendingFilter(entity) {
	return filterStateManager.checkAlreadyAPendingFilter(props.resultType, entity);
}

function toggleFilter(entity) {
	const filterKey = filterStateManager.getFilterKeyFromEntity(props.resultType, entity);
	const isAlreadyAFilter = isAlreadyAPendingFilter(entity);
	if (isAlreadyAFilter) {
		filterStateManager.removePendingFilter(filterKey, entity.id);
	} else {
		filterStateManager.addPendingFilter(filterKey, entity);
	}
}

function isItemVisible(itemIndex) {
	return computed(() => isExpanded.value || props.showAll || itemIndex < props.collapseCount);
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/variables_new.scss";

.results-section {
	display: flex;
	flex-direction: column;
	gap: calc($spacing / 2);

	.results-section-top {
		display: flex;
		flex-direction: column;
		gap: calc($spacing / 6);
		margin-left: calc($spacing / 2);

		.results-section-heading {
			font-size: $text-size-secondary;
			@include text-ellipsis;
		}

		.results-section-sub-heading {
			font-size: $text-size-tertiary;
			color: $text-color-secondary;
			@include text-ellipsis;

			> :deep(em) {
				font-style: normal;
				font-weight: $text-bold-primary;
			}
		}

		.controls {
			flex-grow: 1;
			display: flex;
			justify-content: flex-end;
			white-space: nowrap;
		}
	}

	.results-section-list {
		display: flex;
		flex-direction: column;
	}

	.results-expander {
		margin: 0 0 0 calc($spacing / 2);
		display: flex;

		.see-all-button {
			white-space: nowrap;
			font-size: $text-size-secondary;
			@include text-ellipsis;
		}
	}
}

@media (min-width: $bp-medium) {
	.results-section {
		.results-section-top {
			gap: calc($spacing / 2);
			flex-direction: row;
			align-items: baseline;
		}
	}
}
</style>
