<template>
	<ConditionalRouterLink :to="props.routerLink" :enabled="props.routerLinkEnabled" class="card">
		<span class="top-text" :title="props.topText">{{ props.topText }}</span>
		<span class="top-sub-text" :title="props.topSubText">{{ props.topSubText }}</span>
		<span class="image">
			<div class="decals"><slot name="decals" /></div>
			<div class="icon"><img v-if="props.iconSrc" :src="props.iconSrc" /></div>
			<ImgIxWithPlaceholder v-if="props.imgIx" :src="props.imageSrc" :preview="props.imagePreview" sizes="170px" :widths="widths" :is-lazy="props.isImageLazy" />
			<img v-else :src="props.imageSrc" loading="lazy" />
		</span>
		<div class="bottom">
			<span class="bottom-text" :title="props.bottomText">{{ props.bottomText }}</span>
			<span class="controls"><slot /></span>
		</div>
	</ConditionalRouterLink>
</template>

<script setup>
import { computed } from "vue";

import ConditionalRouterLink from "./ConditionalRouterLink.vue";
import ImgIxWithPlaceholder from "./imgix/ImgIxWithPlaceholder.vue";

const props = defineProps({
	topText: {
		type: String,
		required: true,
	},
	topSubText: {
		type: String,
		required: true,
	},
	bottomText: {
		type: String,
		required: true,
	},
	imageSrc: {
		type: String,
		default: null,
	},
	imagePreview: {
		type: String,
		default: null,
	},
	isImageLazy: {
		type: Boolean,
		default: true,
	},
	iconSrc: {
		type: String,
		default: null,
	},
	routerLink: {
		type: [String, Object],
		default: null,
	},
	routerLinkEnabled: {
		type: Boolean,
		default: true,
	},
	showMini: {
		type: Boolean,
		default: false,
	},
	imgIx: {
		type: Boolean,
		default: true,
	},
});

const widths = computed(() => new Array(3).fill(null).map((v, index) => 170 * (index + 1)));
</script>

<style lang="scss" scoped>
@import "../assets/styles/variables_new.scss";

.card {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 170px;
	font-size: $text-size-tertiary;

	.top-text {
		display: block;
		font-weight: $text-bold-secondary;
		color: $text-color-primary;
		width: 100%;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		line-height: 130%;
	}

	.top-sub-text {
		display: block;
		color: $text-color-secondary;
		width: 100%;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		margin-bottom: calc($spacing / 6);
		line-height: 130%;
	}

	.image {
		position: relative;
		width: 100%;
		aspect-ratio: 1;
		// width: 160px;
		// height: 160px;
		border-radius: $border-radius-primary;
		overflow: hidden;

		.icon {
			display: flex;
			align-items: center;
			position: absolute;
			right: 0;
			padding: calc($spacing/2) calc($spacing/1.5);
			height: 20px;
			z-index: 1;
			@include drop-shadow-image-decal;

			> img {
				height: 16px;
			}
		}

		.decals {
			position: absolute;
			z-index: 1;
			display: flex;
			flex-direction: column;
			gap: calc($spacing/2);
			padding: calc($spacing/2) calc($spacing/1.5);

			:deep(> img) {
				height: 20px;
				@include drop-shadow-image-decal;
			}
		}
	}

	.bottom {
		display: flex;
		width: 100%;
		align-items: center;
		margin-top: calc($spacing / 4);

		.bottom-text {
			color: $text-color-secondary;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			// font-weight: 600;
		}

		.controls {
			margin-left: calc($spacing/2);
		}
	}
}
</style>
