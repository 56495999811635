export const entityArray = (entities, entityIdsInOrder) => {
	entities.entityIdsInOrder = entityIdsInOrder ?? entities.map((entity) => entity.id);

	entities.cloneAndRemoveEntity = ({ id }) => {
		const index = entities.entityIdsInOrder.indexOf(id);
		const cloned = entityArray(
			entities.filter((entity, entityIndex) => entityIndex !== index),
			entities.entityIdsInOrder.filter((entityId) => entityId !== id),
		);
		return cloned;
	};

	entities.cloneAndInsertAtStart = ({ id, entity }) => {
		return entityArray([entity, ...entities], [id, ...entities.entityIdsInOrder]);
	};

	return entities;
};
