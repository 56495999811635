import { App } from "@capacitor/app";
import { Browser } from "@capacitor/browser";
import { Capacitor } from "@capacitor/core";

import { ExternallyResolvablePromise } from "../helpers/index.js";
import loggingMessages from "./Auth0Client.logging-messages.js";

export class Auth0Client {
	constructor({ logger, config, persistentQueries, mobileApp, defaultEnvironmentName, auth0Client }) {
		this.logger = logger.nested({ name: "Auth0Client" });
		this.config = config;
		this.persistentQueries = persistentQueries;
		this.mobileApp = mobileApp;
		this.defaultEnvironmentName = defaultEnvironmentName;
		this.auth0Client = auth0Client;

		const auth0ClientProto = Object.getPrototypeOf(auth0Client);
		const thisProto = Object.getPrototypeOf(this);
		const methodsToCopy = Object.getOwnPropertyNames(auth0ClientProto).filter((key) => typeof auth0Client[key] === "function" && !Object.getOwnPropertyNames(thisProto).includes(key));

		methodsToCopy.forEach((key) => {
			this[key] = auth0Client[key].bind(auth0Client);
		});
	}

	async loginWithRedirect({ screen_hint, returnTo } = {}) {
		const redirectUri = this.config.redirectUri;
		const authorizationParams = { ...this.auth0Client.options.authorizationParams, screen_hint, redirect_uri: this.#getUriWithPersistentQueries({ uri: redirectUri, returnTo }) };

		let didLogin = true;

		if (Capacitor.isNativePlatform()) {
			const auth0SuccessRedirectUrl = await this.#executeAuth0ClientAction({
				matchUrl: redirectUri,
				matchHandler: async ({ url, resolve, reject }) => {
					const parsedUrl = new URL(url);
					const searchParamKeys = Array.from(parsedUrl.searchParams.keys());
					if (searchParamKeys.includes("state") && searchParamKeys.includes("code")) {
						if (Capacitor.getPlatform() === "ios") {
							await Browser.close();
						}
						resolve(`/loggedIn${parsedUrl.search}`);
					} else {
						await Browser.close();
						const error = new Error(parsedUrl.searchParams.get("error") ?? "Unknown error");
						reject(error);
					}
				},
				action: () => {
					return this.auth0Client.loginWithRedirect({
						authorizationParams,
						openUrl: async (url) => {
							return Browser.open({ url });
						},
					});
				},
			});

			if (auth0SuccessRedirectUrl) {
				window.location.href = auth0SuccessRedirectUrl;
			} else {
				didLogin = false;
			}
		} else {
			await this.auth0Client.loginWithRedirect({ authorizationParams });
		}

		return didLogin;
	}

	async logout() {
		const logoutUri = this.config.logoutUri;
		const logoutParams = { returnTo: this.#getUriWithPersistentQueries({ uri: logoutUri }) };

		if (Capacitor.isNativePlatform()) {
			const auth0SuccessRedirectUrl = await this.#executeAuth0ClientAction({
				matchUrl: logoutUri,
				matchHandler: async ({ resolve }) => {
					if (Capacitor.getPlatform() === "ios") {
						await Browser.close();
					}
					resolve("/");
				},
				action: () => {
					return this.auth0Client.logout({
						logoutParams,
						openUrl: async (url) => {
							return Browser.open({ url });
						},
					});
				},
			});

			if (auth0SuccessRedirectUrl) {
				window.location.href = auth0SuccessRedirectUrl;
			}
		} else {
			await this.auth0Client.logout({ logoutParams });
		}
	}

	async #executeAuth0ClientAction({ matchUrl, matchHandler, action }) {
		let wasLastUrlProcessed = false;

		const promise = new ExternallyResolvablePromise();
		const resolve = promise.resolve.bind(promise);
		const reject = promise.reject.bind(promise);

		const appUrlOpenHandler = await App.addListener("appUrlOpen", async ({ url }) => {
			if (url?.startsWith(matchUrl)) {
				this.logger.log(loggingMessages.nativePlatformUrlOpenedAndMatchedPattern, { url, pattern: matchUrl });
				wasLastUrlProcessed = true;
				matchHandler({ url, resolve, reject });
				removeHandlers();
			}
		});

		const browserFinishedHandler = await Browser.addListener("browserFinished", () => {
			if (!wasLastUrlProcessed) {
				this.logger.log(loggingMessages.nativePlatformBrowserClosed);
				removeHandlers();
				resolve();
			} else {
				wasLastUrlProcessed = false;
			}
		});

		const removeHandlers = () => {
			appUrlOpenHandler.remove();
			browserFinishedHandler.remove();
		};

		action().catch(reject);

		return promise;

		// return new Promise((resolve, reject) => {
		// 	let wasLastUrlProcessed = false;

		// 	const appUrlOpenHandler = App.addListener("appUrlOpen", async ({ url }) => {
		// 		if (url?.startsWith(matchUrl)) {
		// 			this.logger.log(loggingMessages.nativePlatformUrlOpenedAndMatchedPattern, { url, pattern: matchUrl });
		// 			wasLastUrlProcessed = true;
		// 			matchHandler({ url, resolve, reject });
		// 			removeHandlers();
		// 		}
		// 	});

		// 	const browserFinishedHandler = Browser.addListener("browserFinished", () => {
		// 		if (!wasLastUrlProcessed) {
		// 			this.logger.log(loggingMessages.nativePlatformBrowserClosed);
		// 			removeHandlers();
		// 			resolve();
		// 		} else {
		// 			wasLastUrlProcessed = false;
		// 		}
		// 	});

		// 	action().catch(reject);

		// 	function removeHandlers() {
		// 		appUrlOpenHandler.remove();
		// 		browserFinishedHandler.remove();
		// 	}
		// });
	}

	#getUriWithPersistentQueries({ uri, returnTo } = {}) {
		const redirect_uri = new URL(uri);
		redirect_uri.search = new URLSearchParams(
			Object.entries({
				...this.#getPersistentQuery(),
				returnTo,
			}).filter(([, value]) => value !== undefined),
		).toString();

		return redirect_uri;
	}

	#getPersistentQuery() {
		const peristantQuery = Object.fromEntries(
			Object.entries(this.persistentQueries.getQuery() ?? {}).filter(([key, value]) => (value === this.defaultEnvironmentName.toLowerCase() && key === "environment" ? false : true)),
		);
		return peristantQuery;
	}
}
