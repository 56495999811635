import { EntityTypeNames } from "../EntityTypeNames.js";

export default class VenueProcessor {
	static doesSupportType(typeName) {
		return typeName === EntityTypeNames.Venue;
	}

	constructor({ entityStore }) {
		this.entityStore = entityStore;
	}

	process({ entity, existingEntity, entityProcessor }, args, summary) {
		const processedEntity = entityProcessor.processObject(entity, args, summary);

		const storedEntity = this.entityStore.store(
			{
				id: entity.id,
				typeName: EntityTypeNames.Venue,
				entity: {
					...processedEntity,
					images: entityProcessor.mergeArray(entity?.images ?? [], existingEntity?.images ?? [], 0, summary),
				},
				methods: {
					zone: (stored) => stored.zone,
					perks: (stored) => stored.perks,
				},
			},
			summary,
		);

		return storedEntity;
	}
}
