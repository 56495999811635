<template>
	<VenueDetailSection title="Social" class="venue-social">
		<a v-external-link :href="instagramLink" target="_blank" class="venue-instagram"><span class="icon"></span><span>Check it out on Instagram</span> </a>
	</VenueDetailSection>
</template>

<script setup>
import { computed } from "vue";

import VenueDetailSection from "./VenueDetailSection.vue";

const props = defineProps({
	venue: {
		type: Object,
		required: true,
	},
});

const instagramLink = computed(() => (props.venue.instagramHandle ? `https://www.instagram.com/${props.venue.instagramHandle}` : null));
</script>

<style scoped lang="scss">
@import "../../assets/styles/variables_new.scss";

.venue-social {
	:deep(.venue-instagram) {
		display: flex;
		align-items: center;
		font-size: $text-size-secondary;

		.icon {
			width: 26px;
			height: 26px;
			background-image: url("../../assets/icons/instagram.svg");
			margin-right: calc($spacing / 2);
		}
	}
}
</style>
