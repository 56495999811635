/* 
  An async function that rate limits the execution of an async function to a single concurrent execution.
  If a call to the rate limited function is made while it is already executing, it will be queued up to be executed when the current execution is complete.
  If the rate limited function is called multiple times while it is executing, only the last call will be queued up.
  The rate limited function will be called with the arguments of the last call to the rate limited function.
  The rate limited function will return a promise that resolves when the execution of the rate limited function is complete.

  NOTE:
    * This function needs to async
    * This function needs to be clean and simple

  @param {Function} fn - The function to rate limit.
  @returns {Function} - The rate limited function.
*/
export const rateLimitPromise = (fn) => {
	let isExecuting = false;
	let queuedCall = null;

	const execute = async (args) => {
		isExecuting = true;
		try {
			await fn(args);
		} finally {
			isExecuting = false;
			if (queuedCall) {
				const queuedCallArgs = queuedCall;
				queuedCall = null;
				console.log("Executing queued call");
				await execute(queuedCallArgs);
			}
		}
	};

	return async (args) => {
		if (isExecuting) {
			if (queuedCall) {
				console.log("Already executing, replacing queued call");
			} else {
				console.log("Already executing, queuing call");
			}
			queuedCall = args;
		} else {
			console.log("Not already executing, executing call");
			await execute(args);
		}
	};
};
