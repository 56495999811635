import { inject, isRef, nextTick, onActivated, onBeforeUnmount, onDeactivated, onMounted, onUpdated, reactive, ref, unref, watch } from "vue";
import { debounce } from "lodash-es";

import { useLogger } from "../logger/index.js";
import loggingMessages from "./useScrollMemory.logging-messages.js";

const RESET_POSITION_RETRY_TIMES_MAX = 50;

export const useScrollMemory = ({ name, containerRef, instantReset = false, isPersistent = null, isEnabled = null, focusOnScroll = false } = {}) => {
	const logger = useLogger({ name: "useScrollMemory" });
	const positionCache = inject("positionCache");
	return new ScrollMemory({ logger, positionCache, name, containerRef, isPersistent, isEnabled, instantReset, focusOnScroll });
};

class ScrollMemory {
	constructor({ logger, positionCache, name, containerRef, isPersistent, isEnabled = true, instantReset = false, focusOnScroll = false } = {}) {
		this.logger = logger;
		this.positionCache = positionCache;
		this.name = name;
		this.containerRef = containerRef;
		this.isPersistent = isPersistent ?? !!name;
		this.isEnabled = isEnabled;
		this.instantReset = instantReset;
		this.focusOnScroll = focusOnScroll;

		this.isMounted = false;
		this.isResetting = ref(true);
		this.nonPersistentPositionCache = null;
		this.storage = reactive({
			test: null,
			target: null,
		});

		this.debouncedStorePosition = debounce(this.storePosition.bind(this), 100, { leading: false, trailing: true });

		this.onScrollStorePosition = () => {
			if (this.isMounted) {
				this.debouncedStorePosition();
			}
		};

		this.onScrollSetFocus = debounce(
			() => {
				if (this.focusOnScroll) {
					this.storage.target.focus();
				}
			},
			1000,
			{ leading: true, trailing: false },
		);

		if (this.containerRef) {
			this.#setupContainer();
		}
	}

	#setupContainer() {
		onMounted(() => {
			watch(
				this.containerRef,
				() => {
					const containerElRef = this.#getContainerElement();
					if (containerElRef && !this.isMounted) {
						this.mounted(containerElRef);
					} else if (!containerElRef && this.isMounted) {
						this.beforeUnmount();
					}
				},
				{ immediate: true },
			);
			if (isRef(this.name)) {
				watch(this.name, () => {
					if (this.#getPosition()) {
						this.restorePosition();
					} else {
						this.scrollToTop();
					}
				});
			}
		});
		onBeforeUnmount(() => {
			this.beforeUnmount();
		});
		onActivated(() => {
			const containerElRef = this.#getContainerElement();
			if (containerElRef) {
				this.activated(containerElRef);
			}
		});
		onDeactivated(() => {
			this.deactivated();
		});
		onUpdated(() => {
			this.updated();
		});
	}

	storePosition() {
		if (this.storage.target && !this.isResetting.value && this.#getIsEnabled()) {
			const isScrolledToTopLeft = this.storage.target.scrollTop === 0 && this.storage.target.scrollLeft === 0;
			const isScrolled = this.storage.target.scrollTop > 0 || this.storage.target.scrollLeft > 0;
			// console.log("storePosition", isScrolledToTopLeft, isScrolled);
			if (this.#getPosition() && isScrolledToTopLeft) {
				// console.log("storePosition delete", getPosition(), isScrolledToTopLeft);
				this.deletePosition();
			} else if (isScrolled) {
				// console.log("storePosition store", isScrolled);
				const updatedPosition = { top: this.storage.target.scrollTop, left: this.storage.target.scrollLeft };
				if (this.isPersistent) {
					this.positionCache[this.#getName()] = updatedPosition;
				} else {
					this.nonPersistentPositionCache = { name: this.#getName(), data: updatedPosition };
				}
				this.logger.log(loggingMessages.scrollPositionStored, { name: this.#getName(), position: updatedPosition });
			}
		}
	}

	restorePosition(currentRetryTimes = 0) {
		if (currentRetryTimes === 0) {
			// debugger;
		}
		/* might be solved now?? */
		/* BUG: always start resetting, regardless of whether there is a position stored to kick off transition fade animations, but this causes a flicker! */
		if (this.isMounted && this.#getIsEnabled()) {
			this.#startResetting();
			const position = this.#getPosition();
			if (position) {
				if (currentRetryTimes < RESET_POSITION_RETRY_TIMES_MAX) {
					const updatePosition = () => {
						const targetElement = this.storage.target;
						if (targetElement && targetElement.isConnected) {
							targetElement.scroll(position);
							if (targetElement.scrollTop !== position.top || targetElement.scrollLeft !== position.left) {
								if (this.instantReset) {
									nextTick(() => {
										this.restorePosition(currentRetryTimes + 1);
									});
								} else {
									this.restorePosition(currentRetryTimes + 1);
								}
							} else {
								this.#finishResetting();
								this.logger.log(loggingMessages.successfullyResetScrollPosition, {
									name: this.#getName(),
									position: position,
									attempt: currentRetryTimes + 1,
								});
							}
						}
					};
					if (this.instantReset) {
						updatePosition();
					} else {
						nextTick(() => {
							updatePosition();
						});
					}
				} else {
					this.#finishResetting();
					const targetElement = this.storage.target;
					this.logger.log(loggingMessages.unableToResetScrollPosition, {
						name: this.#getName(),
						position: position,
						attempt: currentRetryTimes + 1,
						position2: { top: targetElement?.scrollTop, left: targetElement?.scrollLeft },
					});
					this.deletePosition();
				}
			} else {
				this.#finishResetting();
			}
		} else {
			this.#finishResetting();
		}
	}

	deletePosition() {
		if (this.isPersistent) {
			delete this.positionCache[this.#getName()];
		} else {
			this.nonPersistentPositionCache = null;
		}
		this.logger.log(loggingMessages.scrollPositionRemoved, { name: this.#getName() });
	}

	mounted(_target) {
		this.isMounted = true;
		this.isActivated = true;
		this.storage.target = _target;
		if (this.#getPosition()) {
			this.restorePosition();
		} else {
			this.#finishResetting();
		}
		this.#wireUpScrollListener(this.storage.target);
	}

	activated() {
		this.isActivated = true;
		if (this.#getPosition()) {
			this.restorePosition();
		} else {
			this.#finishResetting();
		}
		this.#wireUpScrollListener(this.storage.target);
	}

	deactivated() {
		this.isActivated = false;
		this.#unWireUpScrollListener(this.storage.target);
	}

	beforeUnmount() {
		this.isActivated = false;
		this.isMounted = false;
		this.#unWireUpScrollListener(this.storage.target);
	}

	updated() {
		if (this.isActivated) {
			this.debouncedStorePosition();
		}
	}

	scrollToTop() {
		if (this.storage.target) {
			this.storage.target.scrollTop = 0;
			this.storePosition();
		}
	}

	#wireUpScrollListener(target) {
		if (target) {
			this.#unWireUpScrollListener(target);
			target.addEventListener("scroll", this.onScrollStorePosition);
			target.addEventListener("scroll", this.onScrollSetFocus);
		}
	}

	#unWireUpScrollListener(target) {
		if (target) {
			target.removeEventListener("scroll", this.onScrollStorePosition);
			target.removeEventListener("scroll", this.onScrollSetFocus);
		}
	}

	#getContainerElement() {
		const container = this.containerRef.value;
		return container instanceof HTMLElement ? container : container?.$el;
	}

	#getName() {
		return this.name ? unref(this.name) : "non-persistent";
	}

	#getIsEnabled() {
		return this.isEnabled ? unref(this.isEnabled) : true;
	}

	#getPosition() {
		let position = null;
		if (this.isPersistent) {
			position = this.positionCache[this.#getName()];
		} else {
			if (this.nonPersistentPositionCache?.name === this.#getName()) {
				position = this.nonPersistentPositionCache.data;
			} else {
				this.deletePosition();
			}
		}
		return position;
	}

	#startResetting() {
		this.isResetting.value = true;
	}

	#finishResetting() {
		setTimeout(() => {
			this.isResetting.value = false;
		}, 0);
	}
}
