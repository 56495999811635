import { EntityTypeNames } from "../EntityTypeNames.js";
import { VenueCountPropertyNameLookup, UserListDatabaseNamesLookup, GlobalChangeSets } from "../../constants/index.js";

export default class {
	constructor({ changeManager, entityStore }) {
		this.changeManager = changeManager;
		this.entityStore = entityStore;
	}

	add({ venue, cityId, list }) {
		const listName = UserListDatabaseNamesLookup[list.name];
		const listItem = list.items.find((item) => item.venue.id === venue.id);
		if (!listItem) {
			throw new Error("List Item could not be found, venue is not in the list");
		}
		const data = { venueId: venue.id, cityId, listId: list.id, listName, listItemId: listItem.id };
		if (
			!this.changeManager.removeChangeFromQueue(
				{ typeName: "AddVenueToUserNamedList", data: { venueId: data.venueId, cityId: data.cityId, listId: data.listId, listName: data.listName } },
				{ activeOnly: true },
			)
		) {
			this.changeManager.addChangeToQueue({ typeName: this.name, data, args: { venue } }, { changeSet: GlobalChangeSets.UserLists });
		}
	}

	getChangeEffects(change) {
		return [
			{
				typeName: EntityTypeNames.List,
				id: change.data.listId,
				getEffects: () => {
					return [
						(list, newEntity) => {
							newEntity.items = (newEntity.items ?? list.items)?.cloneAndRemoveEntity({ id: change.data.listItemId });
						},
					];
				},
			},
			{
				typeName: EntityTypeNames.Venue,
				id: change.data.venueId,
				getEffects: (venue) => {
					const userListNameEnum = UserListDatabaseNamesLookup[change.data.listName];
					const countPropertyName = VenueCountPropertyNameLookup[userListNameEnum];
					const effects = [];
					if (countPropertyName) {
						effects.push({
							type: "set",
							key: countPropertyName,
							value: venue[countPropertyName] - 1,
						});
					}
					return effects;
				},
			},
		];
	}
}
