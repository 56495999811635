import { PageNames } from "./PageNames.js";

export const PageRouteNameLookup = {
	[PageNames.LOG_IN]: ({ cityName, returnTo } = {}) => ({
		name: PageNames.LOG_IN,
		params: {
			city: cityName,
		},
		query: {
			returnTo: returnTo ? encodeURIComponent(returnTo) : undefined,
		},
	}),
	[PageNames.CREATE_PROFILE]: ({ cityName, returnTo } = {}) => ({
		name: PageNames.CREATE_PROFILE,
		params: {
			city: cityName,
		},
		query: {
			returnTo: returnTo ? encodeURIComponent(returnTo) : undefined,
		},
	}),
	[PageNames.CITY]: ({ cityName } = {}) => ({
		name: PageNames.CITY,
		params: {
			city: cityName,
		},
	}),
	[PageNames.HOME_FEED]: ({ cityName } = {}) => ({
		name: PageNames.HOME_FEED,
		params: {
			city: cityName,
		},
	}),
	[PageNames.EXPLORE_LIST]: ({ cityName, list } = {}) => ({
		name: PageNames.EXPLORE_LIST,
		params: {
			city: cityName,
			listId: list.id,
		},
	}),
	[PageNames.EXPLORE_TAG]: ({ cityName, tag } = {}) => ({
		name: PageNames.EXPLORE_TAG,
		params: {
			city: cityName,
			tagId: tag.id,
		},
	}),
	[PageNames.EXPLORE_ZONE_TAG]: ({ cityName, tag, zone } = {}) => ({
		name: PageNames.EXPLORE_ZONE_TAG,
		params: {
			city: cityName,
			zoneId: zone.id,
			tagId: tag.id,
		},
	}),
	[PageNames.EXPLORE_AREA]: ({ cityName, area } = {}) => ({
		name: PageNames.EXPLORE_AREA,
		params: {
			city: cityName,
			areaId: area.id,
		},
	}),
	[PageNames.EXPLORE_ZONE]: ({ cityName, zone } = {}) => ({
		name: PageNames.EXPLORE_ZONE,
		params: {
			city: cityName,
			zoneId: zone.id,
		},
		query: {},
	}),
	[PageNames.EXPLORE_VENUE]: ({ cityName, venue } = {}) => ({
		name: PageNames.EXPLORE_VENUE,
		params: {
			city: cityName,
			venueId: venue.id,
		},
	}),
	[PageNames.ADMIN]: ({ cityName } = {}) => ({
		name: PageNames.ADMIN,
		params: {
			city: cityName,
		},
	}),
	[PageNames.PERKS]: ({ cityName, subscribeToProduct = null, returnTo } = {}) => ({
		name: PageNames.PERKS,
		params: {
			city: cityName,
		},
		query: {
			subscribeToProduct: subscribeToProduct ? encodeURIComponent(subscribeToProduct) : undefined,
			returnTo: returnTo ? encodeURIComponent(returnTo) : undefined,
		},
	}),
	[PageNames.OTHER_USER_PROFILE]: ({ cityName, user } = {}) => ({
		name: PageNames.OTHER_USER_PROFILE,
		params: {
			city: cityName,
			userId: user.id,
		},
	}),
};
