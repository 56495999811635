import { computed, shallowRef } from "vue";
import cuid from "cuid";

export class EntityStore {
	constructor({ logger, platform, changeManager }) {
		this.logger = logger.nested({ name: "EntityStore" });
		this.platform = platform;
		this.changeManager = changeManager;

		this.dataStore = {};
		this.changeWrappers = {};
	}

	store({ id, typeName, entity, methods = {}, computedFields = {} } = {}) {
		if (!id) {
			throw new Error(`You must supply an 'id' when storing an entity (${typeName}), you supplied: ${Object.keys(entity).map((key) => key)}`);
		}
		const storedEntity = this.createOrGetStoredEntity({ id, typeName });
		storedEntity.value = { ...storedEntity.value, ...entity };

		const storedEntityWithMethodsAndComputedFields = computed(() => ({
			...storedEntity.value,
			...Object.fromEntries(Object.entries(methods).map(([name, method]) => [name, () => method(storedEntity.value)])),
		}));

		const changeWrapper = this.updateAndGet({ id, typeName, entity: storedEntityWithMethodsAndComputedFields, computedFields });

		return changeWrapper;
	}

	createOrGetStoredEntity({ id, typeName } = {}) {
		if (!this.dataStore[typeName]) {
			this.dataStore[typeName] = {};
		}

		if (!this.dataStore[typeName][id]) {
			/* REF: This was changed from ref to shallowRef to try to help with performance. If this causes issues, we can change it back. */
			this.dataStore[typeName][id] = shallowRef({ __cuid: cuid() });
		}

		const storedEntity = this.dataStore[typeName][id];
		return storedEntity;
	}

	getStoredEntity({ id, typeName } = {}) {
		if (!this.dataStore[typeName]) {
			return null;
		}

		if (!this.dataStore[typeName][id]) {
			return null;
		}

		const storedEntity = this.dataStore[typeName][id];
		return storedEntity;
	}

	updateAndGet({ id, typeName, entity, computedFields } = {}) {
		if (!typeName) {
			throw new Error(`You must supply a 'typeName' not found for id ${id}`);
		}
		if (!this.changeWrappers[typeName]) {
			this.changeWrappers[typeName] = {};
		}

		if (!this.changeWrappers[typeName][id]) {
			if (!entity) {
				throw new Error(`You must supply an 'entity' not found for id ${id} and typeName ${typeName}`);
			}
			this.changeWrappers[typeName][id] = this.changeManager.process({ id, typeName, entity, computedFields });
		}

		const changeWrapper = this.changeWrappers[typeName][id];
		return changeWrapper;
	}

	get({ id, typeName } = {}) {
		if (!this.changeWrappers[typeName]) {
			this.changeWrappers[typeName] = {};
		}

		const changeWrapper = this.changeWrappers[typeName][id] ?? null;
		return changeWrapper;
	}

	getAllTypeNames() {
		return Object.keys(this.dataStore);
	}

	getTypeCount(typeName) {
		return Object.keys(this.dataStore[typeName]).length;
	}
}
