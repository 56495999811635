import { reactive } from "vue";

export class DebugContext {
	constructor() {
		this.data = reactive([]);
	}

	add({ name, type, value }) {
		const typeName = type.name ? type.name : type;
		const existingIndex = this.data.findIndex((d) => d.name === name);
		if (existingIndex > -1) {
			this.data.splice(existingIndex, 1);
		}
		this.data.push({ name, type: typeName.toLowerCase(), value });
	}

	getAll() {
		return this.data;
	}
}
