import { computed } from "vue";

export default class {
	constructor({ logger, modelBuilder, api, authentication }) {
		this.logger = logger.nested({ name: "Model" });
		this.modelBuilder = modelBuilder;
		this.api = api;
		this.authentication = authentication;
	}

	execute({ userId } = {}) {
		return this.modelBuilder.build({
			name: "GetFollowersAndFollowing",
			args: { userId },
			execute: async ({ args }) => {
				let followers = null,
					following = null;

				if (args.userId) {
					const user = await this.api.queries.getFollowersAndFollowing(
						{ userId: args.userId },
						{ useClientCache: true, accessToken: this.authentication.isAuthenticated.value ? this.authentication.getAccessToken : null },
					);
					followers = computed(() => user.searchUserProfile.followers);
					following = computed(() => user.searchUserProfile.following);
				}

				return { followers, following };
			},
		});
	}
}
