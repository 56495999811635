// export const getFileDetails = (filename) => {
// 	const match = /\/(?<basename>.+?)\.(?<extension>.+?)$/.exec(filename);
// 	return {
// 		basename: match?.groups["basename"],
// 		extension: match?.groups["extension"],
// 	};
// };

export const getFileDetails = (filename) => {
	const match = /\/(?<path>.*?\/){0,1}(?<basename>.+)\.(?<extension>.+?)$/.exec(filename);
	const fileDetails = {
		filename,
		name: match?.groups["basename"],
		basename: (match?.groups["path"] ?? "") + match?.groups["basename"],
		extension: match?.groups["extension"],
	};
	return fileDetails;
};
