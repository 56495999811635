import { useEventListener } from "@vueuse/core";

export const useScrollClickBlocker = (containerRef) => {
	let isPreventingDefault = false,
		timeoutId = null;

	useEventListener(
		containerRef,
		"scroll",
		() => {
			isPreventingDefault = true;
			if (timeoutId) {
				clearTimeout(timeoutId);
			}
			timeoutId = setTimeout(() => {
				isPreventingDefault = false;
			}, 200);
		},
		{},
	);
	useEventListener(
		containerRef,
		"click",
		(e) => {
			if (isPreventingDefault) {
				e.preventDefault();
			}
		},
		{ capture: true },
	);
};
