<template>
	<pre @click="copyValueToClipboard">{{ formattedValue }}</pre>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
	item: {
		type: Object,
		required: true,
	},
});

const formattedValue = computed(() => JSON.stringify(props.item.value, null, 2));

function copyValueToClipboard() {
	navigator.clipboard.writeText(formattedValue.value);
	window.alert("Copied to clipboard!");
}
</script>

<style scoped lang="scss">
@import "../../assets/styles/variables_new.scss";

pre {
	white-space: pre-wrap;
}
</style>
