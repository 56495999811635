import { ErrorNested } from "./ErrorNested.js";

export class SubscriptionManagerError extends ErrorNested {
	constructor(message, error, extensions) {
		super("SubscriptionManagerError", message, {
			exception: error,
			code: "SUBSCRIPTION_MANAGER_ERROR",
			...extensions,
		});
	}
}
