import { reactive, watch } from "vue";
import { useOneSignal } from "@onesignal/onesignal-vue3";
import mixpanel from "mixpanel-browser";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { FirebaseAnalytics } from "@capacitor-firebase/analytics";
import { AdvertisingId } from "@capacitor-community/advertising-id";

import { BookingMethods, TrackingEventNames } from "../constants/index.js";
import { getUserPremiumContext } from "../helpers/index.js";
import { useConfig } from "./config.js";
import loggingMessages from "./Tracking.logging-messages.js";
import { TrackingIdFetchError } from "../errors/TrackingIdFetchError.js";

let OneSignal = null;

const ADVERTISING_STATUS = { AUTHORIZED: "Authorized", DENIED: "Denied", NOT_DETERMINED: "Not Determined", RESTRICTED: "Restricted" };

const globalConfig = useConfig();
export class Tracking {
	constructor({ logger, deviceReady, platform, router, branch, debugContext } = {}) {
		this.logger = logger.nested({ name: "Tracking" });
		this.deviceReady = deviceReady;
		this.platform = platform;
		this.router = router;
		this.branch = branch;
		this.debugContext = debugContext;

		this.isInitialised = false;
		this.environmentName = null;
		this.config = null;
		this.mixpanel = null;
		this.user = null;
		this.currentPageName = null;
		this.oneSignalWeb = this.platform.isWeb ? useOneSignal() : null;
		this.advertisingId = null;

		this.services = {
			clarity: { enabled: false },
			firebaseAnalytics: { enabled: false },
			googleAnalytics: { enabled: false },
			oneSignal: { enabled: false },
			mixpanel: { enabled: false },
			branch: { enabled: false },
		};

		this.oneSignal = reactive({
			canRequestPermission: null,
			hasPermission: null,
			wasGranted: null,
		});

		this.referrerDetails = reactive({
			install: { utm_source: null, utm_medium: null, utm_campaign: null, clickDateTime: null, installBeginDateTime: null },
			open: { utm_source: localStorage.getItem("utm_source"), utm_medium: localStorage.getItem("utm_medium"), utm_campaign: localStorage.getItem("utm_campaign") },
		});
		this.router.beforeEach((to) => {
			this.#storeOpenReferrerDetails(to.query);
		});
		this.#storeOpenReferrerDetails(this.router.currentRoute.value.query);
	}

	async init(environmentName, config) {
		if (this.isInitialised) {
			throw new Error("Tracking already initialised, you must call reset before calling init again");
		}

		this.logger.log(loggingMessages.initialisingTrackingForEnvironment, { environmentName });
		this.isInitialised = true;
		this.environmentName = environmentName;
		this.config = config;

		await this.#initialiseReferrer();

		this.advertisingId = await this.#getAdvertisingId();
		this.logger.log(loggingMessages.advertisingIdAvailable, { data: { id: this.advertisingId.id, status: this.advertisingId.status } });

		if (config.clarity?.projectId) {
			this.logger.log(loggingMessages.initialisingClarity);
			window.setupClarity(config.clarity.projectId);
			this.services.clarity.enabled = true;
		}
		if (config.firebase?.apiKey) {
			await this.#initialiseFirebase(config);
		}
		// if (config.googleAnalytics?.tagId) {
		// 	this.logger.log(loggingMessages.initialisingGoogleAnalytics);
		// 	window.gtag("config", config.googleAnalytics.tagId);
		// 	this.services.googleAnalytics.enabled = true;
		// }
		if (config.oneSignal?.appId) {
			await this.initialiseOneSignal();
		}
		if (config.mixpanel?.projectToken) {
			await this.#initialiseMixpanel(config);
		}
	}

	async #getAdvertisingId() {
		if (this.platform.isWeb) {
			return { id: null, status: null };
		} else {
			try {
				const { status: initialStatus } = await AdvertisingId.getAdvertisingStatus();
				if (initialStatus === ADVERTISING_STATUS.NOT_DETERMINED) {
					const wasTrackingAccepted = await AdvertisingId.requestTracking();
					this.logger.log(loggingMessages.requestedAdvertisingIdPermissionResult, { data: { wasTrackingAccepted } });
				}
				const { id, status } = await AdvertisingId.getAdvertisingId();
				this.debugContext.add({ name: "advertisingID", type: Object, value: { id, status } });
				return { id, status };
			} catch (error) {
				throw new TrackingIdFetchError(error);
			}
		}
	}

	async #initialiseFirebase(config) {
		this.logger.log(loggingMessages.initialisingFirebaseAnalytics);
		if (this.platform.isWeb) {
			const app = initializeApp(config.firebase);
			getAnalytics(app);
		}
		await FirebaseAnalytics.setEnabled({
			enabled: true,
		});
		this.services.firebaseAnalytics.enabled = true;
	}

	async #initialiseMixpanel(config) {
		const projectToken = config.mixpanel?.projectToken;
		this.logger.log(loggingMessages.initialisingMixpanel, { token: projectToken });
		this.mixpanel = mixpanel.init(projectToken, { debug: false, track_pageview: false, persistence: "localStorage" }, this.environmentName);
		watch(
			this.referrerDetails,
			() => {
				/* TODO: Include campaign and install timestamp, etc. */
				this.mixpanel.register({
					Client_Name: globalConfig.clientName,
					Client_Version: globalConfig.clientVersion,
					Install_StorePageViewDateTime: this.referrerDetails.install.clickDateTime,
					Install_InstallBeginDateTime: this.referrerDetails.install.installBeginDateTime,
					Install_UTM_Source: this.referrerDetails.install.utm_source,
					Install_UTM_Medium: this.referrerDetails.install.utm_medium,
					Install_UTM_Campaign: this.referrerDetails.install.utm_campaign,
					Open_UTM_Source: this.referrerDetails.open.utm_source,
					Open_UTM_Medium: this.referrerDetails.open.utm_medium,
					Open_UTM_Campaign: this.referrerDetails.open.utm_campaign,
					UTM_Source: this.referrerDetails.open.utm_source ?? this.referrerDetails.install.utm_source,
					UTM_Medium: this.referrerDetails.open.utm_medium ?? this.referrerDetails.install.utm_medium,
					UTM_Campaign: this.referrerDetails.open.utm_campaign ?? this.referrerDetails.install.utm_campaign,
				});
			},
			{ immediate: true },
		);
		this.services.mixpanel.enabled = true;
	}

	#storeOpenReferrerDetails(query = {}) {
		let { utm_source, utm_medium, utm_campaign, referrer } = query;
		if (referrer && !utm_source && !utm_medium && !utm_campaign) {
			const { utm_source: referrer_utm_source, utm_medium: referrer_utm_medium, utm_campaign: referrer_utm_campaign } = Object.fromEntries(new URLSearchParams(referrer).entries());
			utm_source = referrer_utm_source;
			utm_medium = referrer_utm_medium;
			utm_campaign = referrer_utm_campaign;
		}

		if (utm_source) {
			localStorage.setItem("utm_source", utm_source);
			this.referrerDetails.open.utm_source = utm_source;
		}
		if (utm_medium) {
			localStorage.setItem("utm_medium", utm_medium);
			this.referrerDetails.open.utm_medium = utm_medium;
		}
		if (utm_campaign) {
			localStorage.setItem("utm_campaign", utm_campaign);
			this.referrerDetails.open.utm_campaign = utm_campaign;
		}
	}

	async #initialiseReferrer() {
		const googleReferrerApi = !this.platform.isServer ? window?.cordova?.plugins?.referrer : null;
		if (googleReferrerApi) {
			const googleReferrerResults = await googleReferrerApi.get();
			const clickDateTime = googleReferrerResults?.clickTimestamp ? new Date(googleReferrerResults?.clickTimestamp) : null;
			const installBeginDateTime = googleReferrerResults?.installBeginTimestamp ? new Date(googleReferrerResults?.installBeginTimestamp) : null;
			const { utm_source, utm_medium, utm_campaign } = Object.fromEntries(new URLSearchParams(googleReferrerResults?.referrer ?? "").entries());
			this.referrerDetails.install.utm_source = utm_source;
			this.referrerDetails.install.utm_medium = utm_medium;
			this.referrerDetails.install.utm_campaign = utm_campaign;
			this.referrerDetails.install.clickDateTime = clickDateTime;
			this.referrerDetails.install.installBeginDateTime = installBeginDateTime;
			this.logger.log(loggingMessages.googlePlayStoreInstallReferrerAvailable, { data: { utm_source, utm_medium, utm_campaign } });
		} else {
			this.logger.log(loggingMessages.googlePlayStoreInstallReferrerNotAvailable);
		}
	}

	/* REF: We import one signal dynamically to avoid loading it server-side as is not compatible (refers to window) */
	async #importOneSignalMobile() {
		const OneSignalImported = await import("onesignal-cordova-plugin");
		OneSignal = OneSignalImported.default;
		return OneSignal;
	}

	async addPushNotificationHandler(handler) {
		console.log("addPushNotificationHandler");
		await this.#importOneSignalMobile();
		console.log("OneSignal", OneSignal);
		OneSignal.Notifications.addEventListener("click", handler);
	}

	reset() {
		if (this.isInitialised) {
			this.logger.log(loggingMessages.resettingTrackingForEnvironment, { environmentName: this.environmentName });
			this.isInitialised = false;
			this.environmentName = null;
			this.config = null;
			this.user = null;
			this.currentPageName = null;
			this.mixpanel = null;
			Object.values(this.services).forEach((service) => (service.enabled = false));
		}
	}

	async identifyUser({ user } = {}) {
		if (!this.isInitialised) {
			throw new Error("Tracking not initialised, you must call init before calling identifyUser");
		}

		const { isPremium, subscription } = getUserPremiumContext(user);
		this.user = user;
		const { id: userId, firstName, lastName, username, profileImage } = user;

		if (this.config.clarity?.projectId) {
			this.logger.log(loggingMessages.identifyingUserToClarity, { userId });
			window.clarity("set", "userId", userId);
		}
		if (this.services.firebaseAnalytics.enabled) {
			this.logger.log(loggingMessages.identifyingUserToFirebaseAnalytics, { userId });
			await FirebaseAnalytics.setUserId({
				userId: userId,
			});
		}
		// if (this.config.googleAnalytics?.tagId) {
		// 	this.logger.log(loggingMessages.identifyingUserToGoogleAnalytics, { userId });
		// 	window.gtag("set", { user_id: userId });
		// }
		if (this.services.oneSignal.enabled) {
			const oneSignal = this.oneSignalWeb ?? OneSignal;
			this.logger.log(loggingMessages.identifyingUserToOneSignal, { userId });
			await oneSignal.login(userId);
			oneSignal.User.addEmail(user.email);
			const basicTags = {
				first_name: firstName,
				last_name: lastName,
			};
			const extraTags = {
				api_environment: this.environmentName,
				user_name: username,
				client_name: globalConfig.clientName,
				client_version: globalConfig.clientVersion,
				client_environment: globalConfig.origin,
			};
			oneSignal.User.addTags({
				...basicTags,
				...(this.config.oneSignal.supportsExtraTags ? extraTags : {}),
			});
		}
		if (this.config.mixpanel?.projectToken) {
			this.logger.log(loggingMessages.identifyingUserToMixpanel, { userId });
			this.mixpanel.identify(userId);
			if (profileImage?.url) {
				this.mixpanel.people.set("$avatar", profileImage.url);
			}
			this.mixpanel.people.set("$first_name", firstName);
			this.mixpanel.people.set("$last_name", lastName);
			this.mixpanel.people.set("$username", username);
			this.mixpanel.people.set("Premium_IsActive", isPremium);
			if (isPremium && subscription) {
				this.mixpanel.people.set("Premium_ProductSKU", subscription.product_SKU);
				this.mixpanel.people.set("Premium_PurchaseMethod", subscription.purchaseMethod);
				this.mixpanel.people.set("Premium_PurchaseDate", subscription.purchaseDate);
			}
		}

		/* TODO: We shouldn't be setting branch identity here, as feels we initialise branch elsewhere (unlike other services here), also it's getting a bit messy */
		await this.branch.setIdentity(userId);
	}

	async ensureLoggedOut() {
		if (this.services.oneSignal.enabled) {
			this.logger.log(loggingMessages.ensureLoggedOutFromOneSignal);
			const oneSignal = this.oneSignalWeb ?? OneSignal;
			await oneSignal.logout();
		}
	}

	trackPageView({ name, query, params } = {}) {
		// if (!this.isInitialised) {
		// 	throw new Error("Tracking not initialised, you must call init before calling trackPageView");
		// }

		if (this.services.mixpanel.enabled && name !== this.currentPageName) {
			this.logger.log(loggingMessages.trackingPageViewWithMixPanel, { pageName: name, query, params });
			this.mixpanel.track_pageview({ name, Client_Name: this.config.clientName, Client_Version: this.config.clientVersion });
		}
		if (this.services.firebaseAnalytics.enabled && name !== this.currentPageName) {
			this.logger.log(loggingMessages.trackingPageViewWithFirebaseAnalytics, { pageName: name });
			FirebaseAnalytics.setCurrentScreen({
				screenName: name,
			});
		}
		this.currentPageName = name;
	}

	async createAccount() {
		await this.#trackEvent(TrackingEventNames.SignUpAttempt);
	}

	async login() {
		await this.#trackEvent(TrackingEventNames.SignInAttempt);
	}

	async logout() {
		await this.#trackEvent(TrackingEventNames.SignOut);
		this.logger.log(loggingMessages.loggingOutFromMixpanel);
		this.mixpanel.reset();

		if (this.services.oneSignal.enabled) {
			this.logger.log(loggingMessages.loggingOutFromOneSignal);
			const oneSignal = this.oneSignalWeb ?? OneSignal;
			await oneSignal.logout();
		}

		if (this.services.branch.enabled) {
			await this.branch.logout();
		}
	}

	async cancelCreateUserProfile() {
		await this.#trackEvent(TrackingEventNames.CancelCreateUserProfile);
	}

	async successfulSignIn() {
		await this.#trackEvent(TrackingEventNames.SuccessfulSignIn);
	}

	async successfulAuthenticationCreatingAccount() {
		await this.#trackEvent(TrackingEventNames.SuccessfulAuthenticationCreatingAccount);
	}

	async failedSignIn() {
		await this.#trackEvent(TrackingEventNames.FailedSignIn);
	}

	async viewVenue(venue) {
		const perk = venue.perks()?.length > 0 ? venue.perks()[0] : null;
		await this.#trackEvent(TrackingEventNames.ViewVenue, { venueId: venue.id, venueName: venue.name, cityName: venue.zone().area().city().name, hasPerk: !!perk });
	}

	async venueBookPressed(venue, bookingMethod = BookingMethods.URL) {
		const perk = venue.perks()?.length > 0 ? venue.perks()[0] : null;
		await this.#trackEvent(TrackingEventNames.VenueBookPressed, {
			bookingMethod,
			venueId: venue.id,
			venueName: venue.name,
			bookingType: venue.bookingType,
			cityName: venue.zone().area().city().name,
			hasPerk: !!perk,
		});
	}

	async initialiseOneSignal() {
		const appId = this.config.oneSignal?.appId;
		if (this.services.oneSignal.enabled) {
			this.logger.log(loggingMessages.oneSignalAlreadyInitialisedSkipping);
		} else {
			this.logger.log(loggingMessages.initialisingOneSignal, { token: appId });
			try {
				if (this.oneSignalWeb) {
					await this.#oneSignalInitialiseWeb(appId);
				} else {
					await this.#oneSignalInitialiseAndroidAndIOS(appId);
					await this.oneSignalPromptForPushPermission();
				}
			} catch (error) {
				this.logger.log(loggingMessages.errorInitialisingOneSignal, { error });
			}
		}
	}

	async #oneSignalInitialiseWeb(appId) {
		const isPushSupported = this.oneSignalWeb.Notifications.isPushSupported();
		if (isPushSupported) {
			/* REF: this stops prompt showing on safari */
			try {
				await this.oneSignalWeb.init({ appId: appId, allowLocalhostAsSecureOrigin: true, autoRegister: false, autoResubscribe: false });
				this.services.oneSignal.enabled = true;
			} catch (error) {
				this.logger.log(loggingMessages.errorInitialisingOneSignal, { error });
			}
		} else {
			this.logger.log(loggingMessages.oneSignalPushNotificationsNotSupported);
		}
	}

	async #oneSignalInitialiseAndroidAndIOS(appId) {
		OneSignal.Debug.setLogLevel(6);
		try {
			OneSignal.setConsentRequired(false);
			OneSignal.initialize(appId);
		} catch (error) {
			this.logger.log(loggingMessages.errorInitialisingOneSignal, { error });
		}
	}

	async oneSignalPromptForPushPermission() {
		/* REF: only initialise OneSignal after small timeout due to current issue with plugin: https://github.com/OneSignal/OneSignal-Cordova-SDK/issues/925 */
		const canRequestPermission = await OneSignal.Notifications.canRequestPermission();
		this.oneSignal.canRequestPermission = canRequestPermission;

		await new Promise((res) => {
			setTimeout(async () => {
				try {
					const hasPermission = OneSignal.Notifications.hasPermission();
					this.oneSignal.hasPermission = hasPermission;
					this.logger.log(loggingMessages.oneSignalNotificationPermissionStatus, { hasPermission, canRequestPermission });
					/* REF: Disable prompt to allow for one signal in-app message to be shown instead */
					//* Since this shows a generic native prompt, we recommend instead using an In-App Message to prompt for notification permission (See step 7) to better communicate to your users what notifications they will get.
					// if (!hasPermission && canRequestPermission) {
					// 	this.logger.log(loggingMessages.requestingOneSignalNotificationPermission);
					// 	const wasGranted = await OneSignal.Notifications.requestPermission(true);
					// 	this.oneSignal.wasGranted = wasGranted;
					// 	this.logger.log(loggingMessages.oneSignalNotificationPermissionRequestResult, { wasGranted });
					// }
					this.services.oneSignal.enabled = true;
					res();
				} catch (error) {
					this.logger.log(loggingMessages.errorInitialisingOneSignal, { error });
					res();
				}
			}, 100);
		});
	}

	async #trackEvent(eventName, properties = {}) {
		if (this.isInitialised) {
			if (this.services.mixpanel.enabled) {
				this.logger.log(loggingMessages.trackingEventWithMixPanel, { eventName, properties });
				await new Promise((res) => {
					this.mixpanel.track(eventName, properties, res);
				});
			}
			if (this.services.firebaseAnalytics.enabled) {
				this.logger.log(loggingMessages.trackingEventWithFirebaseAnalytics, { eventName, properties });
				await FirebaseAnalytics.logEvent({
					name: eventName,
					params: properties,
				});
			}
			// } else {
			// 	throw new Error("Tracking not initialised, you must call init before calling trackEvent");
		}
	}
}
