<template>
	<div>
		<ul v-if="!isStoreEmpty" class="type-list">
			<li v-for="{ typeName, entityCount } in entityStore" :key="typeName">{{ typeName }} ({{ entityCount }})</li>
		</ul>
		<div v-else class="empty-store">No entities in store</div>
	</div>
</template>

<script setup>
import { ref, inject, computed, onMounted } from "vue";

const currentEnvironment = inject("currentEnvironment");

const isStoreEmpty = computed(() => (currentEnvironment.value?.api.entityProcessorService.entityStore.getAllTypeNames() ?? []).length === 0);

const entityStore = computed(() => {
	const summary = [];
	if (isMounted.value) {
		const allTypeNames = currentEnvironment.value?.api.entityProcessorService.entityStore.getAllTypeNames() ?? [];
		allTypeNames.forEach((typeName) => {
			summary.push({
				typeName,
				entityCount: currentEnvironment.value?.api.entityProcessorService.entityStore.getTypeCount(typeName),
			});
		});
	}
	return summary;
});

const isMounted = ref(false);
onMounted(() => {
	isMounted.value = true;
});
</script>

<style lang="scss" scoped>
@import "../../assets/styles/variables.scss";

.type-list {
	margin: $spacing;
}

.empty-store {
	margin: $spacing;
}
</style>
