<template>
	<TreeNode :is-expanded="props.isExpanded" :class="{ 'is-expanded': props.isExpanded }">
		<template #default>
			<MiniCard v-if="props.showMini" :text="props.area.name" :bottom-text="venueCount" :image-src="imageUrl" :image-preview="imagePreview" :router-link="routerLink" />
			<Card v-else :top-text="props.area.name" :top-sub-text="props.area.city().name" :bottom-text="venueCount" :image-src="imageUrl" :image-preview="imagePreview" :router-link="routerLink" />
		</template>
		<template #children>
			<ZoneCard v-for="zone in zones" :key="zone.id" :zone="zone" :show-mini="true" :image-size="34" :show-venue-count="true" />
		</template>
	</TreeNode>
</template>

<script setup>
import { computed } from "vue";

import { sorters, useRouteParams } from "../helpers/index.js";
import { PageNames, SortOrder } from "../constants/index.js";

import Card from "./Card.vue";
import MiniCard from "./MiniCard.vue";
import ZoneCard from "./ZoneCard.vue";
import TreeNode from "./TreeNode.vue";

const props = defineProps({
	area: {
		type: Object,
		required: true,
		validator: (area) => {
			return area.id && area.name && area.city()?.name && area.image?.url && area.zones;
		},
	},
	showMini: {
		type: Boolean,
		default: false,
	},
	showZones: {
		type: Boolean,
		default: false,
	},
	isExpanded: {
		type: Boolean,
		default: false,
	},
	shouldAnimateExpanding: {
		type: Boolean,
		default: false,
	},
});

const routeParams = useRouteParams();

const zones = computed(() => [...(props.area?.zones ?? [])].sort(sorters.sortZones[SortOrder.NAME]));

const imageUrl = computed(() => {
	return props.area.image.url;
});

const imagePreview = computed(() => {
	const preview = props.area.image.preview ?? null;
	return preview;
});

const venueCount = computed(() => {
	const numberOfVenues = props.area.zones.reduce((acc, zone) => acc + zone.venueCount, 0);
	return `${numberOfVenues} places`;
});

const routerLink = computed(() => {
	return props.isExpanded
		? {
				name: PageNames.EXPLORE_AREAS,
				params: {
					city: routeParams.cityName,
				},
		  }
		: {
				name: PageNames.EXPLORE_AREA,
				params: {
					city: routeParams.cityName,
					areaId: props.area.id,
				},
		  };
});
</script>

<style lang="scss" scoped>
@import "../assets/styles/variables_new.scss";

.is-expanded {
	:deep(> .mini-card .venue-info > .text) {
		font-weight: $text-bold-primary;
	}
}
</style>
