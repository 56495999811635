import colors from "ansi-colors";

export default {
	routerConfigurationLoaded: {
		level: "debug",
		message: ({ routeConfigs }) => `🚦 Vue router configuration loaded for the following routes:\n${routeConfigs}`,
	},
	routeChanged: {
		level: "info",
		message: ({ routeFrom, routeTo, meta, fromParams, fromQuery, toParams, toQuery }, { routeFrom: rawRouteFrom }) =>
			`🚦 Route changed from\n` + (rawRouteFrom.name ? `${routeFrom} - ${fromParams} - ${fromQuery}` : `${colors.blue("<INITIAL PAGE LOAD>")}`) + `\nto\n${routeTo}- ${toParams} - ${toQuery}: ${meta}`,
	},
	errorDynamicallyImportingRoute: {
		level: "warn",
		message: ({ path }) => `Error dynamically importing route ${path}`,
	},
	errorDynamicallyImportingRouteWithCacheBusting: {
		level: "warn",
		message: ({ path }) => `Error dynamically importing route, with cache busting ${path}`,
	},
	errorDuringRouteNavigation: {
		level: "error",
		message: ({ error, to, from }) => `Error during route navigation from ${from} to ${to}: ${error}`,
	},
};
