import { LOADING_STATE } from "../../../constants/index.js";

const ITEM_COUNT_PER_PAGE = 10;

export default class {
	constructor({ logger, modelBuilder, api, router, authentication }) {
		this.logger = logger.nested({ name: "Model" });
		this.modelBuilder = modelBuilder;
		this.api = api;
		this.router = router;
		this.authentication = authentication;
	}

	execute({ pageSize = ITEM_COUNT_PER_PAGE, cityName, isSignedIn } = {}) {
		return this.modelBuilder.build({
			name: "GetHomeFeed",
			args: {
				cityName,
				isSignedIn,
			},
			state: {
				first: pageSize,
				skip: 0,
				loading: LOADING_STATE.LOAD_INITIAL,
				useClientCache: true,
				replace: false,
				lastCityName: null,
				lastIsAuthenticated: false,
			},
			actions: {
				async refresh({ state, execute }) {
					state.skip = 0;
					state.loading = LOADING_STATE.LOAD_INITIAL;
					state.useClientCache = false;
					state.replace = true;
					await execute();
				},
				async loadMore({ state, execute, model }) {
					state.skip = model?.city.feedLists?.length ?? 0;
					state.loading = LOADING_STATE.LOAD_MORE;
					state.useClientCache = false;
					state.replace = false;
					await execute();
				},
			},
			watchArgs: ({ args, state }) => {
				if (state.lastCityName !== args.cityName) {
					state.skip = 0;
					state.loading = LOADING_STATE.LOAD_INITIAL;
					state.useClientCache = false;
					state.replace = true;
					state.lastCityName = args.cityName;
				}
				// if (state.lastIsAuthenticated !== args.isAuthenticated) {
				// 	state.loading = LOADING_STATE.AUTHENTICATION_CHANGED;
				// 	state.lastIsAuthenticated = args.isAuthenticated;
				// }
			},
			execute: async ({ args, state }) => {
				const cities = await this.api.queries.getCitiesAnonymous({}, { useClientCache: true, usePersistentCache: true });
				const foundCity = cities.cities.find(({ name }) => name.toLowerCase() === args.cityName);
				if (!foundCity) {
					throw new Error(`City '${args.cityName}' not found`);
				}

				const homeFeed = await this.api.queries.getHomeFeed(
					{ cityId: foundCity.id, skip: state.skip, first: state.first, replace: state.replace },
					{ useClientCache: state.useClientCache, usePersistentCache: true, accessToken: args.isSignedIn ? this.authentication.getAccessToken : undefined },
				);

				return homeFeed;
			},
		});
	}
}
