export default {
	componentMounted: {
		level: "verbose",
		message: ({ name }) => `Component mounted ${name} `,
	},
	waitedForDataForComponent: {
		level: "verbose",
		message: ({ name }) => `Waited for data for component ${name}`,
	},
};
