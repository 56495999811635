import { computed, getCurrentInstance, markRaw, reactive, ref, unref, watch } from "vue";
import useVuelidate from "@vuelidate/core";
import _ from "lodash";

import { EntityTypes } from "./model/index.js";
import { pausableWatch } from "@vueuse/core";

export class EntityManager {
	constructor({ logger, addChange }) {
		this.logger = logger.nested({ name: "EntityManager" });
		this.addChange = addChange;
	}

	/* WHY: entityTypeName is supplied for new entities, as they don't have __typename yet. */
	createEntityState(entity, fieldsConfig = {}, entityTypeName = null, { syncState = ref(false) } = {}) {
		const entityType = computed(() => {
			const entityTypeNameUnref = unref(entityTypeName) ?? unref(entity)?.__typename;

			const EntityType = EntityTypes.find(({ Class: { typeName } }) => typeName === entityTypeNameUnref)?.Class;
			if (!EntityType) {
				throw new Error(`Cannot create entity state. Unknown entity type: ${entityTypeNameUnref}, possible options are: ${EntityTypes.map(({ Class: { typeName } }) => typeName).join(", ")}`);
			}
			const instance = new EntityType({ addChange: this.addChange });
			return instance;
		});

		const fieldDefinitions = computed(() => {
			return unref(entityType)
				.fields.map((field) => ({ ...field, ...(unref(fieldsConfig)[field.name] ?? {}) }))
				.filter(({ isHidden }) => !isHidden);
		});

		const state = reactive({});
		const pausableEntityWatcher = pausableWatch(
			entity,
			() => {
				if (entity) {
					Object.entries(unref(entity)).forEach(([key, value]) => {
						state[key] = _.cloneDeep(value);
					});
				}
			},
			{ immediate: true, deep: true },
		);

		watch(
			[state, syncState],
			() => {
				if (unref(syncState)) {
					pausableEntityWatcher.stop();
					Object.entries(unref(state)).forEach(([key, value]) => {
						unref(entity)[key] = value;
					});
					pausableEntityWatcher.resume();
				}
			},
			{ immediate: false, deep: true },
		);

		const validationRules = computed(() =>
			fieldDefinitions.value.reduce((acc, { name, validation }) => {
				_.set(acc, name, { ...(validation ?? {}), $autoDirty: true });
				return acc;
			}, {}),
		);
		const validation = useVuelidate(validationRules, state, { currentVueInstance: getCurrentInstance()?.proxy });

		const fields = computed(() =>
			fieldDefinitions.value.map((fieldDefinition) => ({
				field: markRaw(fieldDefinition),
				validation: _.get(validation.value, fieldDefinition.name) ?? {},
				state: _.get(state, fieldDefinition.name),
				updateState: markRaw(({ value }) => {
					_.set(state, fieldDefinition.name, value);
				}),
			})),
		);

		const entityState = reactive({
			entityType,
			validation,
			state,
			fields,
			validateEntity: markRaw(() => {
				unref(validation).$touch();
				return !unref(validation).$invalid;
			}),
			saveEntity: markRaw(async () => {
				const response = { state, isValid: false, success: false, serverError: null };
				if (entityState.validateEntity()) {
					response.isValid = true;
					const { changeResult, error } = (await unref(entityType).onSave?.(state)) ?? { changeResult: { success: true } };
					if (changeResult.success) {
						response.success = true;
					} else {
						if (changeResult.userErrors?.length > 0) {
							response.serverError = changeResult.userErrors.map(({ message }) => message).join(", ");
						} else if (error) {
							response.serverError = "An error occurred. Please try again later.";
						}
					}
				}

				return response;
			}),
		});

		return entityState;
	}
}
