<template>
	<div>
		<Dialog ref="dialogRef" v-slot="{ closeDialog }" class="debug-context-dialog">
			<DialogSimpleLayout @close="closeDialog">
				<div v-if="hasOpened" class="debug-context">
					<h2>Debug Context</h2>
					<ul class="debug-context-items">
						<li v-for="item in debugContext.getAll()" :key="item.name">
							<label class="key">{{ item.name }}</label>
							<div class="value">
								<component :is="getDebugTypeRenderer(item.type)" v-if="getDebugTypeRenderer(item.type)" :item="item" />
								<span v-else>No renderer found for type: {{ item.type }}</span>
							</div>
						</li>
					</ul>
				</div>
			</DialogSimpleLayout>
		</Dialog>
	</div>
</template>

<script setup>
import { inject, ref } from "vue";

import Dialog from "./Dialog.vue";
import DialogSimpleLayout from "./DialogSimpleLayout.vue";
import DebugTypes from "./debug-types/index.js";

const debugContext = inject("debugContext");

const dialogRef = ref(null),
	hasOpened = ref(false);

defineExpose({
	open,
	close,
});

async function open() {
	const dialog = dialogRef.value;
	if (dialog && !dialog.isOpen) {
		hasOpened.value = true;
		return dialog.open();
	}
}

function close() {
	const dialog = dialogRef.value;
	dialog.close();
}

function getDebugTypeRenderer(typeToFind) {
	const debugType = DebugTypes.find(({ type }) => type === typeToFind);
	return debugType?.Class;
}
</script>

<style scoped lang="scss">
@import "../assets/styles/variables_new.scss";

.debug-context-dialog {
	width: min(95vw, 420px);
	max-height: 90dvh;

	:deep(.content) {
		margin: 0 calc($spacing / 2) 0 calc($spacing / 2);
	}

	.debug-context {
		display: flex;
		flex-direction: column;
		overflow: auto;

		> h2 {
			font-size: $text-size-secondary;
		}

		.debug-context-items {
			overflow: auto;
			display: grid;
			list-style-type: none;
			padding: 0;
			grid-template-columns: minmax(0, max-content) minmax(50%, 1fr);
			grid-auto-rows: max-content;
			gap: calc($spacing/2);
			font-size: $text-size-tertiary;

			> li {
				display: contents; /* Makes <li> not act as a container, so <label> and <pre> are placed directly in the grid */

				> .key,
				> .value {
					padding: calc($spacing/2);
					border: $border-size-primary solid $border-color-quinary;
					margin: 0;
				}

				> .key {
					font-weight: $text-bold-secondary;
					color: $text-color-onezone-1;
					word-wrap: break-word;
				}
				> .value {
					overflow-x: scroll;
					color: $text-color-secondary;
				}
			}
		}
	}
}
</style>
