import { computed, markRaw, reactive, ref, shallowReactive, watch } from "vue";
import { reactiveComputed } from "@vueuse/core";
import { isEqual } from "lodash-es";
import debounce from "p-debounce";
import cuid from "cuid";

import ChangeHandlers from "./change-handlers/index.js";
import ChangeEffectHandlers from "./change-effect-handlers/index.js";
import { ensureArray } from "../helpers/index.js";
import { GlobalChangeSets } from "../constants/GlobalChangeSets.js";
import loggingMessages from "./ChangeManager.logging-messages.js";

export class ChangeManager {
	constructor({ logger }) {
		this.logger = logger.nested({ name: "ChangeManager" });

		this.addChange = {};

		this.changeSets = reactive({}); /* needs to be reactive so that computed properties are unwrapped */
		this.createChangeSet(GlobalChangeSets.Instant, { delay: 0 });
		this.createChangeSet(GlobalChangeSets.UserLists, { delay: 4000 });
		this.createChangeSet(GlobalChangeSets.Followers, { delay: 4000 });

		this.oldChanges = [];
		this.changes = shallowReactive([]);
		this.changeEffects = {};

		this.processedEntities = {};
		this.saveAgain = false;
		/* TODO this should be stored against the change set */
		this.isSavingChanges = false;
	}

	createChangeSet(name, { delay = 0 } = {}) {
		if (this.changeSets[name]) {
			throw new Error(`Change set with name ${name} already exists`);
		}
		/* TODO the saveChanges needs to be scoped to the change set only (currently it processes all changes) */
		this.changeSets[name] = {
			name,
			options: {
				delay,
			},
			changes: computed(() => this.changes.filter((change) => change.changeSet === name)),
			saveChanges: debounce(this.saveChanges.bind(this), delay),
		};
	}

	init({ entityStore, model }) {
		this.entityStore = entityStore;
		this.model = model;

		this.changeHandlers = Object.fromEntries(
			ChangeHandlers.map(({ name, Class: ChangeHandler }) => {
				const changeHandler = new ChangeHandler({ changeManager: this, entityStore });
				changeHandler.name = name;
				return [name, changeHandler];
			}),
		);
		this.addChange = Object.fromEntries(Object.entries(this.changeHandlers).map(([name, handler]) => [name, handler.add.bind(handler)]));

		watch(
			this.changes,
			() => {
				try {
					const changesAdded = this.changes.filter((change) => !this.oldChanges.includes(change));
					changesAdded.forEach((change) => {
						this.logger.log(loggingMessages.addingChange, { typeName: change.typeName, data: change.data, changeCount: this.changes.length });
						const changeTypeHandler = this.#getChangeHandler(change.typeName);
						change.changeEffects = changeTypeHandler.getChangeEffects(change);
						change.changeEffects.forEach((changeEffect) => {
							this.#addChangeEffect(changeEffect);
						});
					});
					const changesRemoved = this.oldChanges.filter((change) => !this.changes.includes(change));
					changesRemoved.forEach((change, index) => {
						this.logger.log(loggingMessages.removingChange, { typeName: change.typeName, data: change.data, changeCount: this.changes.length + changesRemoved.length - index - 1 });
						change.changeEffects.forEach((changeEffect) => {
							this.#removeChangeEffect(changeEffect);
						});
					});
					this.oldChanges = this.changes.map((change) => change);
				} catch (error) {
					this.logger.log(loggingMessages.errorProcessingChanges, { error });
				}
			},
			{ deep: false },
		);
	}

	async saveChanges() {
		if (this.isSavingChanges || this.changes.length === 0) {
			// this.saveAgain = true;
			return false;
		} else {
			this.isSavingChanges = true;
			// this.saveAgain = false;
			/* snapshot changes as they current stand in case more are added mid-save */
			const changesSnapshot = [...this.changes];
			changesSnapshot.forEach((change) => {
				change.isSaving = true;
			});
			this.logger.log(loggingMessages.savingChanges, { count: changesSnapshot.length });
			/* TODO: What happens if network request fails? We need to display something to user and keep changes in queue */
			const applyChangeResults = await this.model.mutations.ApplyChanges(changesSnapshot);
			applyChangeResults.forEach(({ change, success, error, changeResult }) => {
				if (success) {
					this.removeChangeFromQueue(change);
				} else {
					const errors = ensureArray(error ?? []);
					const errorMessage = errors.map(({ message }) => message).join(", ");

					const userErrors = changeResult?.userErrors ?? [];

					if (errors.length > 0) {
						this.logger.log(loggingMessages.errorSavingChangeToServer, { change: { id: change.id, typeName: change.typeName, data: change.data }, errorMessage });
					} else if (userErrors.length > 0) {
						this.logger.log(loggingMessages.userErrorSavingChangeToServer, {
							change: { id: change.id, typeName: change.typeName, data: change.data },
							errorMessage: userErrors.map(({ message }) => message).join(", "),
						});
					}
					/* TODO: need to display something to user. Maybe we could mark each change type as critical or not, and display message to user and keep in queue if critical and remove if not? */
				}
			});
			this.isSavingChanges = false;
			// if (this.saveAgain) {
			// 	await this.saveChanges();
			// }
			return applyChangeResults;
		}
	}

	addChangeToQueue(change, { changeSet: changeSetName = null } = {}) {
		const changeSet = changeSetName ? this.changeSets[changeSetName] : null;
		if (!changeSet) {
			throw new Error(`Change set does not exist: ${changeSetName}`);
		}
		change.changeSet = changeSet;
		change.id = cuid();
		this.changes.push(change);

		return changeSet.saveChanges().then((applyChangeResults) => {
			const applyChangeResult = Array.isArray(applyChangeResults) ? applyChangeResults.find(({ change: { id } }) => change.id === id) : applyChangeResults;
			if (!applyChangeResult) {
				return { change, changeResult: null, success: null, error: null };
				// throw new Error(`Fatal error. Could not find applyChangeResult for change: ${change.id}. This should never happen!`);
			}
			return applyChangeResult;
		});
	}

	removeChangeFromQueue({ typeName, data }, { activeOnly = false, ignoreData = false } = {}) {
		const change = this.findChange({ typeName, data }, { activeOnly, ignoreData });
		if (change) {
			this.changes.splice(this.changes.indexOf(change), 1);
		}
		return change;
	}

	findChange({ typeName, data }, { activeOnly = false, ignoreData = false } = {}) {
		return this.changes.find((change) => {
			const changeData = Object.fromEntries(Object.keys(data).map((key) => [key, change.data[key]]));
			return (activeOnly ? !change.isSaving : true) && change.typeName === typeName && (ignoreData || isEqual(changeData, data));
		});
	}

	process({ id, typeName, entity, computedFields = {} } = {}) {
		if (!this.processedEntities[typeName]) {
			this.processedEntities[typeName] = {};
		}
		if (!this.processedEntities[typeName][id]) {
			this.#ensureChangeEffectStorage({ typeName, id });
			this.processedEntities[typeName][id] = reactiveComputed(() => {
				const changeEffectsForEntity = this.changeEffects[typeName][id].value;
				const processedEntity = {
					...entity.value,
					...(changeEffectsForEntity.length > 0 ? this.#processChangeEffectsForEntity({ id, typeName, entity: entity, changeEffectsForEntity }) : {}),
					...Object.fromEntries(Object.entries(computedFields).map(([name, field]) => [name, computed(() => field(processedEntity))])),
				};
				return markRaw(processedEntity);
			});
		}
		return this.processedEntities[typeName][id];
	}

	#processChangeEffectsForEntity({ id, typeName, entity, changeEffectsForEntity }) {
		const changeEffects = changeEffectsForEntity.map((changeEffect) => changeEffect.getEffects(entity.value) ?? []).flat();

		const processedEntity = changeEffects.reduce((acc, changeEffect) => {
			if (typeof changeEffect === "function") {
				changeEffect(entity.value, acc);
			} else {
				const changeEffectHandler = ChangeEffectHandlers.find(({ type }) => type === changeEffect.type);
				if (!changeEffectHandler) {
					throw new Error(`Change effect handler not found for type: ${changeEffect.type}`);
				}
				this.logger.log(loggingMessages.processingChangeEffect, { typeName, id });
				const shouldExecuteChangeEffect = changeEffect.if ? changeEffect.if(entity.value) : true;
				if (shouldExecuteChangeEffect) {
					try {
						changeEffectHandler.handler(acc, changeEffect, entity.value);
					} catch (error) {
						this.logger.log(loggingMessages.errorProcessingChangeEffect, { typeName, id, error });
					}
				}
			}
			return acc;
		}, {});

		return processedEntity;
	}

	#ensureChangeEffectStorage({ typeName, id }) {
		if (!this.changeEffects[typeName]) {
			this.changeEffects[typeName] = {};
		}
		if (!this.changeEffects[typeName][id]) {
			this.changeEffects[typeName][id] = ref([]);
		}
	}

	#addChangeEffect(changeEffect) {
		const { typeName, id } = changeEffect;
		changeEffect.effectId = cuid();
		this.logger.log(loggingMessages.addingChangeEffect, { typeName, id, effectId: changeEffect.effectId });
		this.#ensureChangeEffectStorage({ typeName, id });
		this.changeEffects[typeName][id].value.push(changeEffect);
	}

	#removeChangeEffect(changeEffect) {
		const { typeName, id } = changeEffect;
		this.logger.log(loggingMessages.removingChangeEffect, { typeName, id, effectId: changeEffect.effectId });
		this.#ensureChangeEffectStorage({ typeName, id });
		this.changeEffects[typeName][id].value = this.changeEffects[typeName][id].value.filter((effect) => effect.effectId !== changeEffect.effectId);
	}

	#getChangeHandler(changeType) {
		const changeHandler = this.changeHandlers[changeType];
		if (!changeHandler) {
			throw new Error(`Unknown change type: ${changeType}`);
		}
		return changeHandler;
	}
}
