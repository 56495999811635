<template>
	<div class="profile-page-container">
		<div v-if="isSignedIn || !isCurrentUser" class="profile-page-wrapper">
			<loading-message-with-error v-if="queries.isLoading || queries.error" class="top-loading-message" :error="queries.error" :retry="queries.retry">
				{{ loadingMessage }}
			</loading-message-with-error>
			<div v-else-if="!user">
				<error-message>User not found</error-message>
			</div>
			<div v-else class="profile-page">
				<UserProfileSummary :user="user" class="user-profile-summary" />
				<div class="content">
					<ActivatedRouterView v-slot="{ Component }">
						<keep-alive>
							<component :is="Component" :user="user" :user-lists="userLists" :current-user-lists="currentUserLists" />
						</keep-alive>
					</ActivatedRouterView>
				</div>
			</div>
		</div>
		<UserActionPagePreview v-else :feature="PremiumFeature.PROFILE" :user-action="SuggestedUserAction.SIGN_IN" :page-image-url="ProfilePagePreviewImage">
			<ProfilePagePreview />
		</UserActionPagePreview>
	</div>
</template>

<script setup>
import { inject, ref, onMounted, computed } from "vue";

import { combineQueries, useRouteParams } from "../helpers/index.js";
import { PremiumFeature, SuggestedUserAction } from "../constants/index.js";

import UserActionPagePreview from "../components/UserActionPagePreview.vue";
import ProfilePagePreview from "../components/ProfilePagePreview.vue";
import UserProfileSummary from "../components/UserProfileSummary.vue";

import ProfilePagePreviewImage from "../assets/home-feed-screenshot.png";

const currentEnvironment = inject("currentEnvironment");
const model = inject("model");
const routeParams = useRouteParams();

const isMounted = ref(false);

onMounted(() => {
	isMounted.value = true;
});

const activeUser = computed(() => currentEnvironment.value.activeUser.value);
const userId = computed(() => routeParams.userId ?? activeUser.value?.id);
const isCurrentUser = computed(() => userId.value === activeUser.value?.id);
const isSignedIn = computed(() => currentEnvironment.value.isSignedIn.value);
const queries = combineQueries({
	currentUser: model.queries.GetCurrentUser(),
	currentUserLists: model.queries.GetCurrentUserLists({ isSignedIn }),
	user: model.queries.GetUser({ userId }),
	userLists: model.queries.GetUserLists({ userId }),
});

const loadingMessage = computed(() => (isCurrentUser.value ? "Loading your profile..." : "Loading user profile..."));
const user = computed(() => (isCurrentUser.value ? queries.model?.currentUser : queries.model?.user));

const currentUserLists = computed(() => queries.model?.currentUserLists);
const userLists = computed(() => (isCurrentUser.value ? currentUserLists.value : queries.model?.userLists));
</script>

<style lang="scss" scoped>
@import "../assets/styles/variables_new.scss";

.profile-page-container {
	display: flex;
	flex-direction: column;
	height: 100%;
	overflow: auto;
	padding: 0 calc($spacing);
	box-sizing: border-box;

	.profile-page-wrapper {
		display: flex;
		flex-direction: column;
		height: 100%;
		@include centre-loading-message;

		.profile-page {
			display: flex;
			gap: calc($spacing / 2);
			flex-direction: column;
			height: 100%;

			.user-profile-summary {
				margin: 0 calc($spacing / 2);
			}
		}
	}

	// .header :deep(.logo) {
	// 	display: flex;
	// 	flex-direction: column;

	// 	& h1 {
	// 		font-size: $text-size-quaternary;
	// 		line-height: $text-size-quaternary;
	// 		text-align: center;
	// 		margin: 0;
	// 	}
	// 	& h2 {
	// 		color: $text-color-secondary;
	// 		font-size: $text-size-secondary;
	// 		line-height: $text-size-secondary;
	// 		text-align: center;
	// 		margin: calc($spacing / 3) 0;
	// 	}
	// }

	.content {
		overflow: auto;
		flex: 1;
	}
}

@media (min-width: $bp-medium) {
	.profile-page-container {
		padding: 0;
	}
}
</style>
