import fetch from "cross-fetch";
import { ApolloClient, InMemoryCache, HttpLink } from "@apollo/client/core/index.js";

import { useConfig } from "../domain/config.js";
import loggingMessages from "./DataService.logging-messages";
import { ApiRequestError } from "../errors/index.js";

export class DataService {
	constructor({ logger, platform, endpointUrl, ssrState, queryString, environmentName }) {
		this.logger = logger.nested({ name: "DataService" });
		this.platform = platform;
		this.endpointUrl = endpointUrl;
		this.ssrState = ssrState;
		this.queryString = queryString;
		this.environmentName = environmentName;
		this.config = useConfig();
		this.apolloClient = {};
	}

	async get({ name: operationName, query, variables = {}, options: { useSSRState = true, accessToken, shouldImpersonate = true } = {} } = {}) {
		let data = null;
		if (useSSRState) {
			const cachedData = this.#getFromSSRState(operationName, variables);
			if (cachedData) {
				this.logger.log(loggingMessages.usingSSRCachedDataForOperation, { operationName, variables, environmentName: this.environmentName });
				data = cachedData;
				this.#removeSSRState(operationName, variables); /* WHY: Remove SSR data for this operation after it's retrieved and allow client to call API or use client cache */
			}
		}

		if (!data) {
			this.logger.log(loggingMessages.usingApiDataForOperation, { operationName, variables, environmentName: this.environmentName });
			const apolloClient = await this.#getApolloClient({ accessToken, shouldImpersonate });
			try {
				const results = await apolloClient.query({ query, variables, fetchPolicy: "no-cache" });
				this.logger.log(loggingMessages.apiDataCallForOperationSuccessful, { operationName, variables, environmentName: this.environmentName });
				data = results.data;
			} catch (error) {
				const graphQLErrors = error?.networkError?.result?.errors ?? [];
				throw new ApiRequestError(error.message, new Error(JSON.stringify(graphQLErrors, null, 2)));
			}
		}

		if (this.platform.isServer) {
			this.#storeInSSRState(operationName, variables, data);
		}

		return data;
	}

	async update({ name: operationName, mutation, variables = {}, options: { useClientCache = true, accessToken } = {} } = {}) {
		let data = null,
			errors = [];

		if (!data) {
			this.logger.log(loggingMessages.usingApiDataForOperation, { operationName, variables, useClientCache, environmentName: this.environmentName });
			const apolloClient = await this.#getApolloClient({ accessToken });
			try {
				const results = await apolloClient.mutate({ mutation, variables, fetchPolicy: useClientCache ? "network-only" : "no-cache", errorPolicy: "all" });
				data = results.data;
				errors = results.errors ?? [];
			} catch (error) {
				const graphQLErrors = error?.networkError?.result?.errors ?? [];
				throw new ApiRequestError(error.message, new Error(JSON.stringify(graphQLErrors, null, 2)));
			}
		}
		if (this.platform.isServer) {
			this.#storeInSSRState(operationName, variables, data);
		}
		return { data, errors };
	}

	#generateCacheKey(operationName, variables) {
		return `${operationName}:${Object.entries(variables ?? {})
			.map(([key, value]) => `${key}=${value}`)
			.join(",")}`;
	}

	#storeInSSRState(operationName, variables, data) {
		const cacheKey = this.#generateCacheKey(operationName, variables);
		this.ssrState[cacheKey] = data;
	}

	#removeSSRState(operationName, variables) {
		const cacheKey = this.#generateCacheKey(operationName, variables);
		delete this.ssrState[cacheKey];
	}

	#getFromSSRState(operationName, variables) {
		const cacheKey = this.#generateCacheKey(operationName, variables);
		const cachedData = this.ssrState ? this.ssrState[cacheKey] : null;
		return cachedData;
	}

	async #getApolloClient({ accessToken = null, shouldImpersonate = true } = {}) {
		const finalAccessToken = typeof accessToken === "function" ? await accessToken() : accessToken;

		if (!this.apolloClient[finalAccessToken]) {
			this.apolloClient[finalAccessToken] = {};
		}

		if (!this.apolloClient[finalAccessToken][shouldImpersonate]) {
			const apolloClient = new ApolloClient({
				link: new HttpLink({
					uri: this.endpointUrl,
					headers: {
						"is-premium-free": "true",
						"apollographql-client-name": this.config.clientName,
						"apollographql-client-version": this.config.clientVersion,
						...(finalAccessToken
							? {
									Authorization: `Bearer ${finalAccessToken}`,
							  }
							: {}),
						...(this.queryString.impersonate && shouldImpersonate ? { "impersonate-user": this.queryString.impersonate } : {}),
						// "skip-cache-get": "true",
					},
					fetch,
				}),
				cache: new InMemoryCache({
					typePolicies: {
						List: {
							fields: {
								/* NOTE: as we don't really care about ListItem ids and don't supply them to API, we always merge incoming here. (when we add venues to lists in change effect, we don't generate list item id) */
								items: {
									merge: (existing, incoming) => incoming,
								},
							},
						},
					},
				}),
				defaultOptions: {
					query: {
						fetchPolicy: "cache-first",
						errorPolicy: "none",
					},
				},
				connectToDevTools: true,
			});
			this.logger.log(loggingMessages.gotApolloClient, { isAuthenticated: !!finalAccessToken });
			this.apolloClient[finalAccessToken][shouldImpersonate] = apolloClient;
		}

		return this.apolloClient[finalAccessToken][shouldImpersonate];
	}
}
