export const toArray = (obj, fields) => {
	return Object.entries(obj)
		.filter(([key]) => fields.includes(key))
		.map(([, value]) => encodeURIComponent(value))
		.join(",");
};

export const fromArray = (arrayString, fields) => {
	return arrayString
		? Object.fromEntries(
				arrayString
					.split(",")
					.map((value, index) => {
						const field = fields[index];
						const fieldName = Array.isArray(field) ? field[0] : field;
						const fieldParser = Array.isArray(field) ? field[1] : (v) => v;
						const decodedValue = decodeURIComponent(value);

						return [fieldName, fieldParser ? fieldParser(decodedValue) : decodedValue];
					})
					.filter(([, value]) => value),
		  )
		: null;
};
