<template>
	<div class="qr-code-container">
		<canvas ref="qrCodeRef" class="qr-code"></canvas>
	</div>
</template>

<script setup>
import { ref, watch } from "vue";

import QRCode from "qrcode";

const props = defineProps({
	data: {
		type: String,
		required: true,
	},
	width: {
		type: Number,
		default: 150,
	},
	height: {
		type: Number,
		default: 150,
	},
	margin: {
		type: Number,
		default: 0,
	},
});

const qrCodeRef = ref(null);

watch(
	[props, qrCodeRef],
	() => {
		const canvas = qrCodeRef.value;
		if (canvas) {
			const scale = window.devicePixelRatio || 1;

			QRCode.toCanvas(qrCodeRef.value, props.data, {
				...props,
				width: props.width * scale,
				height: props.height * scale,
			});
			qrCodeRef.value.style.height = `${props.height}px`;
			qrCodeRef.value.style.width = "auto";
		}
	},
	{ immediate: true },
);
</script>

<style scoped lang="scss">
@import "../assets/styles/variables_new.scss";

.qr-code-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
	overflow: hidden;

	.qr-code {
		padding: 0;
		margin: 0;
	}
}
</style>
