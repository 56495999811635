export default {
	usingClientCachedDataForOperation: {
		level: "verbose",
		message: ({ operationName, variables }) => `📦 Using client cached data for ${operationName} ${variables}`,
	},
	usingPersistentCachedDataForOperation: {
		level: "verbose",
		message: ({ operationName, variables }) => `💾📦 Using persistent cached data for ${operationName} ${variables}`,
	},
};
