import { getFileDetails } from "../../helpers/index.js";

const result = import.meta.glob("./*.js", { eager: true });
const entityProcessors = Object.entries(result).map(([filename, file]) => {
	const { extension } = getFileDetails(filename);
	const extensions = extension.split(".");
	const environmentName = extensions.length > 1 ? extensions?.[0] ?? null : null;

	return {
		environmentName,
		processor: file.default,
	};
});

export default entityProcessors;
