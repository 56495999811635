<template>
	<div class="buy-button-wrapper">
		<button type="button" class="buy-button push-button-tertiary" @click="purchaseButtonOnClick">
			<div v-if="props.premiumProductInterval === PremiumProductInterval.YEAR" class="most-popular">
				<img :src="DiamongGradient" class="logo" />
				<span class="message">Most Popular</span>
			</div>
			<div class="top-row">
				<div class="name">{{ capitalize(props.name) }}</div>
				<div class="price">{{ premiumProduct.formattedPrice }}</div>
			</div>
			<div class="message">{{ message }}</div>
		</button>
		<div v-if="isStripeEnvironmentNonProduction" class="stripe-environment-warning">
			(Stripe environment:
			<span class="environment-name">{{ stripeEnvironment }}</span>
			)
		</div>
	</div>
</template>

<script setup>
import { computed, inject, capitalize } from "vue";
import { useRoute, useRouter } from "vue-router";
import { debounce } from "lodash-es";

import { useQueryString } from "../functions/query-string/index.js";
import { PageNames, PremiumProductInterval, StripeStoreEnvironment } from "../constants/index.js";
import { getCurrentPageRelativeUrl, urlBuilder } from "../helpers/index.js";

import DiamongGradient from "../assets/icons/Diamond-Gradient.svg";

const MESSAGE_LOOKUP = {
	[PremiumProductInterval.MONTH]: computed(() => "Cancel anytime"),
	[PremiumProductInterval.YEAR]: computed(() => `${annualDiscount.value}% discount`),
};

const props = defineProps({
	name: {
		type: String,
		required: true,
	},
	premiumProducts: {
		type: Array,
		required: true,
	},
	premiumProductInterval: {
		type: String,
		required: true,
	},
});
const emit = defineEmits(["click"]);

const currentEnvironment = inject("currentEnvironment");

const router = useRouter();
const route = useRoute();
const queryString = useQueryString({ impersonate: null, stripeEnvironment: null }, { router, route });

const subscriptionManager = computed(() => currentEnvironment.value.subscriptionManager);
const isSignedIn = computed(() => currentEnvironment.value.isSignedIn.value);
const stripeEnvironment = computed(() => queryString.stripeEnvironment);
const isStripeEnvironmentNonProduction = computed(() => stripeEnvironment.value && stripeEnvironment.value !== StripeStoreEnvironment.PRODUCTION);
const premiumProduct = computed(() => props.premiumProducts.find(({ interval }) => interval === props.premiumProductInterval));
const message = computed(() => MESSAGE_LOOKUP[premiumProduct.value.interval].value);

const monthlyProduct = computed(() => props.premiumProducts.find((product) => product.interval === PremiumProductInterval.MONTH));
const annualProduct = computed(() => props.premiumProducts.find((product) => product.interval === PremiumProductInterval.YEAR));
const annualDiscount = computed(() => Math.round((1 - annualProduct.value?.price / (monthlyProduct.value?.price * 12)) * 100));

const purchaseButtonOnClick = debounce(
	() => {
		if (isSignedIn.value) {
			subscriptionManager.value.subscribeToPremium({ premiumProduct: premiumProduct.value });
		} else {
			const returnTo = getCurrentPageRelativeUrl();
			const premiumPageUrl = urlBuilder(PageNames.PERKS, { subscribeToProduct: premiumProduct.value.id, returnTo });
			router.push(premiumPageUrl);
		}
		emit("click");
	},
	2000,
	{ leading: true, trailing: false },
);
</script>

<style scoped lang="scss">
@import "../assets/styles/variables_new.scss";

.stripe-environment-warning {
	font-size: $text-size-tertiary;
	color: $text-color-warning;

	.environment-name {
		color: $text-color-warning-2;
		font-weight: $text-bold-primary;
	}
}

.buy-button-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	flex-shrink: 1;
	.buy-button {
		flex-shrink: 1;
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: calc($spacing / 3);
		font-weight: normal;
		padding: calc($spacing * 2);
		box-sizing: border-box;
		border-radius: $border-radius-primary;

		&:has(.most-popular) {
			border-width: $border-size-secondary;
			border-color: $border-color-secondary;
		}

		.most-popular {
			position: absolute;
			top: 0;
			right: calc($spacing / 4);
			transform: translateY(-50%);

			display: flex;
			flex-direction: column;
			align-items: center;

			.logo {
				width: 18px;
				aspect-ratio: 1;
				background-color: $background-color-primary;
			}
			> .message {
				text-transform: uppercase;
				font-size: calc($text-size-quinary / 1.3);
				font-weight: $text-bold-secondary;
			}
		}

		.top-row {
			display: flex;
			gap: calc($spacing / 3);

			.name {
				font-size: $text-size-quaternary;
			}
			.price {
				font-size: $text-size-quaternary;
				font-weight: $text-bold-primary;
			}
		}

		.message {
			font-size: $text-size-tertiary;
			color: $text-color-primary;
			text-decoration: underline;
			font-weight: $text-bold-secondary;
		}
	}
}

@media (max-width: $bp-small) {
	.buy-button-wrapper {
		.buy-button {
			padding: calc($spacing * 2) calc($spacing);
		}
	}
}
</style>
