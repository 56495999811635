<template>
	<div>
		<pre>{{ JSON.stringify(deviceDetails, null, 2) }}</pre>
		<div class="actions">
			<button class="push-button-tertiary" @click="copyDataDogSessionIdToClipboard">Copy DataDog SessionID to Clipboard</button>
			<a v-external-link :href="deviceDetails.dataDogLink" class="push-button-tertiary">Open DataDog Logs</a>
		</div>
	</div>
</template>

<script setup>
import { reactive, inject } from "vue";
import { useEventListener } from "@vueuse/core";

const logging = inject("logging");

const hasHoverMediaQuery = window.matchMedia("(hover: hover)");
const hasFinePointerMediaQuery = window.matchMedia("(pointer: fine)");

const deviceDetails = reactive({
	hasHover: hasHoverMediaQuery.matches,
	hasFinePointer: hasFinePointerMediaQuery.matches,
	dataDogSessionId: logging.sessionId,
	dataDogLink: logging.dataDogLink,
});

useEventListener(hasHoverMediaQuery, "change", (e) => {
	deviceDetails.hasHover = e.matches;
});
useEventListener(hasFinePointerMediaQuery, "change", (e) => {
	deviceDetails.hasFinePointer = e.matches;
});

function copyDataDogSessionIdToClipboard() {
	navigator.clipboard.writeText(deviceDetails.dataDogSessionId);
}
</script>

<style scoped lang="scss">
@import "../assets/styles/variables_new.scss";

pre {
	white-space: pre-wrap;
}

.actions {
	display: flex;
	gap: calc($spacing/2);
}
</style>
