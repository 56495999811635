import { watchEffect } from "vue";

import { ExternallyResolvablePromise } from "./ExternallyResolvablePromise.js";

export const awaitableWatchEffect = (effect, options) => {
	const firstExecutionPromise = new ExternallyResolvablePromise();
	watchEffect(async () => {
		try {
			await effect();
			firstExecutionPromise.resolve();
		} catch (error) {
			firstExecutionPromise.reject(error);
		}
	}, options);

	return firstExecutionPromise;
};
