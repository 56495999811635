b
<template>
	<VenueDetailSection title="Location" class="venue-location">
		<a target="_blank" :href="directionsUrl">{{ location }}</a>
		<a target="_blank" :href="directionsUrl" class="location-map"><img :src="mapImageUrl" /></a>
	</VenueDetailSection>
</template>

<script setup>
import { computed } from "vue";

import { getDirectionsUrl } from "../../helpers/index.js";

import VenueDetailSection from "./VenueDetailSection.vue";

const props = defineProps({
	venue: {
		type: Object,
		required: true,
	},
});

const location = computed(() => {
	return `${props.venue.addressLine1}, ${props.venue.addressLine2}, ${props.venue.addressCity}, ${props.venue.postcode}`;
});
const directionsUrl = computed(() => getDirectionsUrl(props.venue));
const mapImageUrl = computed(() => props.venue.mapImageUrl);
</script>

<style scoped lang="scss">
@import "../../assets/styles/variables_new.scss";

.venue-location {
	> :deep(.detail) {
		flex-direction: column;
	}

	:deep(.location-map) {
		display: flex;
		justify-content: center;
		width: 100%;
		overflow: hidden;

		> img {
			width: 800px;
			height: 100px;
			object-fit: contain;
		}
	}
}
</style>
