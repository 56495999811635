<template>
	<div class="header-container">
		<SafeArea />
		<header class="header">
			<div class="header-tray-left">
				<PremiumButton v-if="props.showButtons && shouldShowPremiumButton" />
			</div>
			<div class="logo-wrapper">
				<div v-if="showCurrentEnvironmentName" class="current-environment-name">{{ currentEnvironmentName }}</div>
				<custom-router-link :to="{ name: 'City/Home', params: { city: currentCityName } }" class="logo-link">
					<div class="beta-decal">{{ formattedCurrentCityName }}</div>
					<img src="../assets/onezone-logo.png" alt="logo" class="logo" @click="logoOnClick" />
				</custom-router-link>
				<SearchContextLabel v-if="isAdmin && impersonatingUserEmail" class="impersonating-user" :has-remove-button="true" :invert-colors="false" @remove="stopImpersonating">
					<template v-if="allowImpersonateUser">impersonating: {{ impersonatingUserEmail }}</template>
					<template v-else>Sign in as ADMIN to impersonate: {{ impersonatingUserEmail }}</template>
				</SearchContextLabel>
				<!-- <div v-if="isAdmin && impersonatingUserEmail" class="impersonating-user">
					<template v-if="allowImpersonateUser">impersonating: {{ impersonatingUserEmail }}</template>
					<template v-else>Sign in as ADMIN to impersonate: {{ impersonatingUserEmail }}</template>
				</div> -->
			</div>
			<div class="header-tray-right">
				<!-- <EditModeButton v-if="isAdmin" class="edit-mode-button" /> -->
				<CitySwitchingButton v-if="props.showButtons" class="city-switching-button" />
			</div>
		</header>
	</div>
</template>

<script setup>
import { computed, inject, onMounted, ref } from "vue";

import { getAllowImpersonate, useRouteParams } from "../helpers/index.js";
import { useConfig } from "../domain/index.js";

import CitySwitchingButton from "./city-switching/CitySwitchingButton.vue";
import PremiumButton from "./PremiumButton.vue";
import SearchContextLabel from "./SearchContextLabel.vue";
// import EditModeButton from "./EditModeButton.vue";

const props = defineProps({
	showButtons: {
		type: Boolean,
		default: true,
	},
});

const currentEnvironment = inject("currentEnvironment");
const queryString = inject("queryString");
const debugContextDialogRef = inject("debugContextDialogRef", null);

const config = useConfig();
const routeParams = useRouteParams();

const isMounted = ref(false);

const isAdmin = computed(() => (isMounted.value ? currentEnvironment?.value.isAdmin.value : false));
const currentEnvironmentName = computed(() => (isMounted.value ? currentEnvironment.value?.name : null));
const showCurrentEnvironmentName = computed(() => currentEnvironmentName.value !== "production");
const impersonatingUserEmail = computed(() => queryString?.impersonate);
const allowImpersonateUser = computed(() => getAllowImpersonate(currentEnvironment.value).canImpersonate);
const currentCityName = computed(() => routeParams.cityName ?? config.defaultCityName);
const formattedCurrentCityName = computed(() => currentCityName.value.toUpperCase());
const shouldShowPremiumButton = computed(() => routeParams.cityName.toLowerCase() === "london");

onMounted(async () => {
	isMounted.value = true;
});

let lastClickTime = null,
	consecutiveClickCount = 0,
	isDebugContextDialogEnabled = false;
function logoOnClick() {
	if (isDebugContextDialogEnabled) {
		debugContextDialogRef.value?.open();
	} else {
		const currentTime = new Date().getTime();
		if (currentTime - (lastClickTime ?? currentTime) < 1000) {
			consecutiveClickCount++;
			if (consecutiveClickCount === 10) {
				isDebugContextDialogEnabled = true;
				debugContextDialogRef.value?.open();
				consecutiveClickCount = 0;
			}
		} else {
			consecutiveClickCount = 1;
		}
		lastClickTime = currentTime;
	}
}

function stopImpersonating() {
	queryString.remove("impersonate");
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/variables_new.scss";

.header-container {
	background-color: $background-color-primary;

	.header {
		display: flex;
		align-items: center;
		margin: 0 calc($spacing * 1.5) calc($spacing * 1.5) calc($spacing * 1.5);

		> .header-tray-left,
		> .header-tray-right {
			flex: 1;
			display: flex;
			align-items: center;
			height: 100%;
			gap: calc($spacing / 2);
		}

		> .header-tray-left {
			order: 1;
		}
		> .logo-wrapper {
			order: 2;
			position: relative;

			.logo-link {
				display: flex;
				flex-direction: column;
				.beta-decal {
					order: 2;
					font-size: $text-size-beta-mobile;
					line-height: $text-size-beta-mobile;
					font-weight: $text-bold-primary;
					color: $text-color-tertiary;
					text-align: center;
				}
			}

			.current-environment-name {
				position: absolute;
				top: -9px;
				width: 100%;
				text-align: center;
				font-size: $text-size-tertiary;
				font-weight: $text-bold-secondary;
				color: $text-color-secondary;
			}
			.logo {
				width: 120px;
			}

			.impersonating-user {
				position: absolute;
				z-index: 100;
				// top: -13px;
				left: -70px;
				width: 250px;
				text-align: center;
				font-size: $text-size-tertiary;
				font-weight: $text-bold-tertiary;
				color: $text-color-onezone-1;
			}
		}

		> .header-tray-right {
			order: 3;
			justify-content: flex-end;
		}
	}
}

@media (min-width: $bp-medium) {
	.header-container {
		.header {
			justify-content: flex-start;
			gap: $spacing;
			margin: calc($spacing * 2) 0 calc($spacing * 2) 0;

			> .header-tray-left,
			> .header-tray-right {
				width: auto;
				flex-grow: 0;
				gap: $spacing;
			}

			> .logo-wrapper {
				order: 1;
				display: flex;
				gap: calc($spacing * 2);
				align-items: center;
				flex-grow: 1;

				.logo-link {
					.beta-decal {
						position: absolute;
						top: -10px;
						left: 4px;
					}
				}

				.current-environment-name {
					width: 146px;
					top: 24px;
					font-size: $text-size-primary;
					text-align: right;
				}

				.impersonating-user {
					position: static;
					width: auto;
				}

				> a {
					display: flex;
					.logo {
						width: 150px;
					}
				}
			}

			> .header-tray-left {
				order: 2;
			}

			> .header-tray-right {
				order: 3;

				.edit-mode-button {
					order: 2;
				}
				.city-switching-button {
					order: 1;
				}
			}
		}
	}
}
</style>
