import { computed } from "vue";

export default class {
	constructor({ logger, modelBuilder, api, authentication }) {
		this.logger = logger.nested({ name: "Model" });
		this.modelBuilder = modelBuilder;
		this.api = api;
		this.authentication = authentication;
	}

	execute({ userId }) {
		return this.modelBuilder.build({
			name: "GetUser",
			args: { userId },
			execute: async ({ args }) => {
				let user = null;
				if (args.userId) {
					const data = await this.api.queries.getUser(
						{ userId: args.userId },
						{ useClientCache: false, accessToken: this.authentication.isAuthenticated.value ? this.authentication.getAccessToken : null },
					);
					user = computed(() => data.searchUserProfile);
				}

				return { user };
			},
		});
	}
}
