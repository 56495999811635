<template>
	<div class="scroll-arrows">
		<NavigationArrow direction="left" :class="{ 'navigation-button': true, 'navigation-button-prev': true, 'disabled': !isPreviousArrowEnabled }" @click="onPreviousArrowClick" />
		<NavigationArrow direction="right" :class="{ 'navigation-button': true, 'navigation-button-next': true, 'disabled': !isNextArrowEnabled }" @click="onNextArrowClick" />
	</div>
</template>

<script setup>
import { computed, inject, onDeactivated, ref, unref } from "vue";
import { useElementHover, useElementSize, useScroll } from "@vueuse/core";

import NavigationArrow from "./NavigationArrow.vue";

const props = defineProps({
	containerRef: {
		type: Object,
		default: null,
	},
	scrollRef: {
		type: Object,
		default: null,
	},
	itemCount: {
		type: Number,
		required: true,
	},
	itemSize: {
		type: Number,
		required: true,
	},
});

const platform = inject("platform");

const scrollRef = computed(() => unref(props.scrollRef));
const containerRef = computed(() => unref(props.containerRef ? props.containerRef : props.scrollRef));

const scroll = useScroll(scrollRef, { behavior: "smooth" });
const isHovered = platform.isServer ? ref(false) : useElementHover(containerRef, { delayEnter: 200, delayLeave: 200 });
const containerSize = useElementSize(containerRef);

const numberOfVisibleItems = computed(() => (unref(containerSize.width) ? unref(containerSize.width) / props.itemSize : 0));
const minimumNumberOfVisibleItems = computed(() => Math.floor(unref(numberOfVisibleItems)));
const pageSize = computed(() => Math.floor(unref(numberOfVisibleItems) + 0.1));

const isPreviousArrowEnabled = computed(() => {
	const currentIndex = Math.ceil(getCurrentIndex());
	return currentIndex - 1 >= 0 && unref(isHovered);
});

const isNextArrowEnabled = computed(() => {
	const currentIndex = Math.floor(getCurrentIndex());
	return currentIndex + unref(minimumNumberOfVisibleItems) < props.itemCount - 1 && unref(isHovered);
});

const onPreviousArrowClick = () => {
	const currentIndex = Math.ceil(getCurrentIndex());
	if (currentIndex >= 0) {
		scroll.x.value = (currentIndex - unref(pageSize)) * props.itemSize;
	}
};

const onNextArrowClick = () => {
	const currentIndex = Math.floor(getCurrentIndex());
	if (currentIndex + unref(minimumNumberOfVisibleItems) < props.itemCount - 1) {
		scroll.x.value = Math.min(currentIndex + unref(pageSize), props.itemCount - unref(pageSize)) * props.itemSize;
	}
};

function getCurrentIndex() {
	return scroll.x.value / props.itemSize;
}

onDeactivated(() => {
	isHovered.value = false;
});
</script>

<style scoped lang="scss">
@import "../assets/styles/variables_new.scss";

.scroll-arrows {
	display: flex;
	justify-content: space-between;
	width: 100%;
	box-sizing: border-box;
	pointer-events: none;

	.navigation-button {
		pointer-events: all;

		&.disabled {
			opacity: 0;
		}
	}
}

@media (min-width: $bp-medium) {
	.scroll-arrows {
		.navigation-button {
			&.navigation-button-prev {
				left: calc($spacing / 2);
				right: auto;
			}
			&.navigation-button-next {
				right: calc($spacing / 2);
				left: auto;
			}
		}
	}
}
</style>
