export default {
	addingChange: {
		level: "verbose",
		message: ({ typeName, data, changeCount }) => `Adding Change ${typeName} (${changeCount} active changes) with data ${data}`,
	},
	removingChange: {
		level: "verbose",
		message: ({ typeName, data, changeCount }) => `Removing Change ${typeName} (${changeCount} active changes remaining) with data ${data}`,
	},
	addingChangeEffect: {
		level: "debug",
		message: ({ effectId, typeName, id }) => `Adding Change Effect (${effectId}) for ${typeName} ${id}`,
	},
	removingChangeEffect: {
		level: "debug",
		message: ({ effectId, typeName, id }) => `Removing Change Effect (${effectId}) for ${typeName} ${id}`,
	},
	processingChangeEffect: {
		level: "silly",
		message: ({ typeName, id }) => `Processing change effect for ${typeName} ${id}`,
	},
	errorProcessingChangeEffect: {
		level: "error",
		message: ({ typeName, id, error }) => `Error processing change effect for ${typeName} ${id}:\n${error}`,
	},
	savingChanges: {
		level: "verbose",
		message: ({ count }) => `Saving ${count} changes`,
	},
	userErrorSavingChangeToServer: {
		level: "warn",
		message: ({ change, errorMessage }) => `Server error caused by user when saving change: ${change}:\n${errorMessage}`,
	},
	errorSavingChangeToServer: {
		level: "error",
		message: ({ change, errorMessage }) => `Server error when saving change: ${change}:\n${errorMessage}`,
	},
	errorProcessingChanges: {
		level: "error",
		message: ({ error }) => `Error processing changes: ${error}`,
	},
	criticalErrorSavingChanges: {
		level: "error",
		message: ({ error }) => `Critical error saving changes: ${error}`,
	},
};
