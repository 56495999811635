<template>
	<VenueDetailSection title="Price" class="venue-price">
		<div class="price-graph"><span v-for="index in 5" :key="index" :class="{ highlight: index <= price }">£</span></div>
		<!-- <span class="price-summary">({{ venue.price }} out of 5)</span> -->
	</VenueDetailSection>
</template>

<script setup>
import { computed } from "vue";

import VenueDetailSection from "./VenueDetailSection.vue";

const props = defineProps({
	venue: {
		type: Object,
		required: true,
	},
});

const price = computed(() => props.venue.price);
</script>

<style scoped lang="scss">
@import "../../assets/styles/variables_new.scss";

.venue-price {
	:deep(.price-graph) {
		letter-spacing: 0.3rem;
		color: $text-color-tertiary !important;
		font-size: $text-size-secondary;

		> .highlight {
			color: $text-color-primary;
		}
	}

	// :deep(.price-summary) {
	// 	letter-spacing: normal;
	// 	color: $text-color-tertiary;
	// 	font-size: $text-size-tertiary;
	// 	font-style: italic;
	// }
}
</style>
