export const persistQueryAcrossNavigations = (router, queryNames) => {
	router.persistedQueryNames = router.persistedQueryNames || {};
	queryNames.forEach((queryName) => {
		router.persistedQueryNames[queryName] = true;
	});

	router.beforeEach((to, from, next) => {
		const query = getQuery({ to, from });
		if (query) {
			next({ ...to, query: { ...query, ...to.query } });
		} else {
			next();
		}
	});

	return {
		getQuery,
	};

	function getQuery({ to, from } = {}) {
		if (!from) {
			from = router.currentRoute.value;
		}

		let query = null;
		queryNames.forEach((queryName) => {
			if (from.query[queryName] && typeof to?.query[queryName] === "undefined") {
				if (!query) {
					query = {};
				}
				query[queryName] = from.query[queryName];
			}
		});
		return query;
	}
};
