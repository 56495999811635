<template>
	<ImgIxWithPlaceholder :src="props.image.url" :preview="props.image.preview" :sizes="sizes" :widths="widths" :aspect-ratio="0.72" class="image" />
</template>

<script setup>
import { computed } from "vue";
import { useWindowSize } from "@vueuse/core";

import ImgIxWithPlaceholder from "../imgix/ImgIxWithPlaceholder.vue";

const props = defineProps({
	image: {
		type: Object,
		required: true,
	},
});

const windowSize = useWindowSize();

const sizes = "(max-width: 767px) 100vw, 440px";
const widths = computed(() => [...new Array(3).fill(null).map((v, index) => windowSize.width.value * (index + 1)), ...new Array(3).fill(null).map((v, index) => 440 * (index + 1))]);
</script>

<style scoped lang="scss">
@import "../../assets/styles/variables_new.scss";

.image {
	width: 100%;
	// object-fit: scale-down !important;
}

@media (min-width: $bp-medium) {
	.image {
		/* 1px is to fix a bug where the image is not full height on safari. when resizing window, and text wraps on right, sometimes a pixel gap appears at bottom of image */
		height: calc(100% + 1px);
		// object-fit: cover;
	}
}
</style>
