<template>
	<div class="city-switching">
		<a href="javascript:;" :class="{ 'is-showing-dialog': isShowingDialog }" @click="citySwitchButtonPressed">
			<img draggable="false" src="../../assets/icons/globe.svg" alt="switch cities" class="switch-cities-icon" />
			<!-- <span class="switch-cities-name">{{ currentCityName }}</span> -->
			<span class="switch-cities-text">change city</span>
		</a>
	</div>
</template>

<script setup>
import { computed, inject } from "vue";
// import { useRoute } from "vue-router";

import { safeHapticsVibrate } from "../../helpers/index.js";

// const currentRoute = useRoute();
const citySwitchingDialog = inject("citySwitchingDialog");

// const currentCityName = computed(() => currentRoute.params.city);
const isShowingDialog = computed(() => citySwitchingDialog.value?.isOpen);

const citySwitchButtonPressed = async () => {
	citySwitchingDialog.value.toggle();
	await safeHapticsVibrate({ duration: 20 });
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/variables_new.scss";

.city-switching {
	a {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 1px;

		@media (pointer: fine) {
			&:hover {
				opacity: 50%;
			}
		}

		.switch-cities-icon {
			height: 30px;
		}

		// .switch-cities-name {
		// 	font-size: $text-size-quinary;
		// 	font-weight: 400;
		// 	text-transform: capitalize;
		// }

		.switch-cities-text {
			text-transform: capitalize;
			white-space: nowrap;
			font-size: $text-size-quinary;
			font-weight: 400;
		}
	}
}

@media (min-width: $bp-medium) {
	.city-switching {
		a {
			.switch-cities-icon {
				display: none;
			}

			// .switch-cities-name {
			// 	display: none;
			// }

			.switch-cities-text {
				// display: block;
				font-weight: $text-bold-tertiary;
				font-size: $text-size-header;
			}

			// &.is-showing-dialog .switch-cities-text {
			// 	font-weight: $text-bold-primary;
			// }
		}
	}
}
</style>
