<template>
	<div :class="{ 'form-layout-simple': true, 'with-labels': props.showLabels, 'is-single-column': props.isSingleColumn }">
		<template v-for="entityFieldState in fields" :key="entityFieldState.field.name">
			<FormFieldLabel v-if="shouldShowLabel(entityFieldState.field)" class="label">{{ entityFieldState.field.label }}</FormFieldLabel>
			<EntityField :entity-field-state="entityFieldState" />
		</template>
		<ValidationErrorMessage v-if="props.serverErrorMessage">{{ props.serverErrorMessage }}</ValidationErrorMessage>
		<slot name="save-button">
			<div class="save-button-container">
				<button class="push-button-tertiary save-button" @click="saveOnClick">Save</button>
			</div>
		</slot>
	</div>
</template>

<script setup>
import { computed } from "vue";

import EntityField from "./EntityField.vue";
import FormFieldLabel from "./FormFieldLabel.vue";
import ValidationErrorMessage from "./ValidationErrorMessage.vue";

const props = defineProps({
	entityState: {
		type: Object,
		required: true,
	},
	showLabels: {
		type: Boolean,
		default: true,
	},
	isSingleColumn: {
		type: Boolean,
		default: false,
	},
	serverErrorMessage: {
		type: String,
		default: null,
	},
});

const emit = defineEmits(["save"]);

const fields = computed(() => props.entityState?.fields ?? []);

function shouldShowLabel(field) {
	return props.showLabels && !field.componentProps?.isLabelHidden;
}

function saveOnClick() {
	emit("save");
}
</script>

<style scoped lang="scss">
@import "../../assets/styles/variables_new.scss";

.form-layout-simple {
	width: 100%;
	display: grid;
	grid-template-columns: auto;
	gap: calc($spacing * 1);
	align-items: center;
	justify-content: center;

	.save-button-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: calc($spacing);

		.save-button {
			width: 100px;
		}
	}

	&.with-labels {
		grid-template-columns: max-content 1fr;
		justify-content: flex-start;
		&.is-single-column {
			grid-template-columns: 1fr;
			justify-content: center;
			align-self: center;
			width: 80%;
			gap: calc($spacing / 2);

			.label {
				margin-left: calc($spacing / 3);
			}

			:deep(.save-button-container) {
				grid-column: auto;
			}
		}

		.label {
			display: flex;
			align-items: center;
		}

		:deep(.save-button-container) {
			align-items: flex-start;
			grid-column: 1 / span 2;
		}
	}
}
</style>
