<template>
	<div class="user-profile-summary">
		<div class="user-profile">
			<div class="profile-image-wrapper"><ProfileImage :profile-image="user.profileImage" class="profile-image" :size="profileImageSize" /></div>
			<div v-if="props.showFollowers" class="other-user">
				<router-link :to="{ name: isCurrentUser ? PageNames.PROFILE_FOLLOWERS : PageNames.OTHER_USER_FOLLOWERS }" class="followers">
					<span class="count">{{ followersCount }}</span>
					<span>Followers</span>
				</router-link>
				<router-link :to="{ name: isCurrentUser ? PageNames.PROFILE_FOLLOWING : PageNames.OTHER_USER_FOLLOWING }" class="following">
					<span class="count">{{ followingCount }}</span>
					<span>Following</span>
				</router-link>
			</div>
			<div class="this-user">
				<router-link v-if="isCurrentUser" :to="{ name: PageNames.PROFILE_SETTINGS }" class="push-button-primary">Settings</router-link>
				<FollowUserButton v-else :user="props.user" />
			</div>
		</div>
		<div class="user-details">
			<span class="fullname">{{ name }}</span>
			<span class="username">{{ username }}</span>
			<span v-if="isAdmin" class="email admin-data">{{ email }}</span>
		</div>
	</div>
</template>

<script setup>
import { computed, inject } from "vue";

import { PageNames } from "../constants/index.js";

import ProfileImage from "../components/ProfileImage.vue";
import FollowUserButton from "./FollowUserButton.vue";

const props = defineProps({
	user: {
		type: Object,
		required: true,
	},
	showFollowers: {
		type: Boolean,
		default: true,
	},
	highlightName: {
		type: Boolean,
		default: true,
	},
});

const currentEnvironment = inject("currentEnvironment");

const activeUser = computed(() => currentEnvironment.value.activeUser.value);
const userId = computed(() => props.user.id);
const isCurrentUser = computed(() => userId.value === activeUser.value?.id);
const isAdmin = computed(() => currentEnvironment.value?.isAdmin.value);

const profileImageSize = computed(() => 60);
const name = computed(() => `${props.user.firstName} ${props.user.lastName}`);
const username = computed(() => props.user.username);
const email = computed(() => props.user.email);
const followersCount = computed(() => props.user?.followers?.length);
const followingCount = computed(() => props.user?.following?.length);
</script>

<style lang="scss" scoped>
@import "../assets/styles/variables_new.scss";

.user-profile-summary {
	display: flex;
	flex-direction: column;
	// align-items: flex-start;
	gap: $spacing;

	.user-profile {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-grow: 1;
		overflow: auto;

		.profile-image-wrapper {
			flex-shrink: 0;
			min-width: 85px;

			.profile-image {
				width: 60px;
				height: 60px;
			}
		}

		.other-user {
			display: flex;
			gap: $spacing;
		}

		.followers,
		.following {
			display: flex;
			flex-direction: column;
			align-items: center;
			// gap: calc($spacing/8);
			font-size: $text-size-header-secondary;

			.count {
				font-size: $text-size-header;
				font-weight: $text-bold-primary;
			}
		}

		.this-user {
			// flex: 1;
			display: flex;
			justify-content: right;
			min-width: 85px;
		}
	}

	.user-details {
		overflow: auto;
		display: flex;
		flex-direction: column;
		gap: calc($spacing/4);
		justify-content: center;
		overflow: auto;

		.fullname {
			display: block;
			font-size: $text-size-header;
			font-weight: $text-bold-primary;
			@include text-ellipsis;
		}
		.username {
			display: block;
			font-size: $text-size-tertiary;
			color: $text-color-secondary;
			@include text-ellipsis;
		}
		.email {
			display: block;
			font-size: $text-size-tertiary;
			@include text-ellipsis;
		}
	}
}

@media (min-width: $bp-medium) {
	.user-profile-summary {
		align-self: flex-start;
		.user-profile {
			gap: calc($spacing * 2);
		}
	}
}
</style>
