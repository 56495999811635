import { computed, reactive } from "vue";

import { getFilterCount } from "../../../helpers/index.js";

export default class {
	constructor({ logger, modelBuilder, api, authentication }) {
		this.logger = logger.nested({ name: "Model" });
		this.modelBuilder = modelBuilder;
		this.api = api;
		this.authentication = authentication;
	}

	execute({ searchText, searchLocation, cityName, searchContext, filterState, size } = {}, { routeName } = {}) {
		return this.modelBuilder.build({
			routeName,
			name: "searchExplore",
			args: { isSignedIn: computed(() => this.authentication.isAuthenticated.value), searchText, searchLocation, cityName, searchContext, filterState, size },
			execute: async ({ args }) => {
				const cities = await this.api.queries.getCitiesAnonymous({}, { useClientCache: true, usePersistentCache: true });
				const city = cities.cities.find(({ name }) => name.toLowerCase() === args.cityName);
				if (!city) {
					throw new Error(`City '${args.cityName}' not found`);
				}

				const filters = {
					...args.filterState,
					listIds: args.filterState.listIds?.map(({ id }) => id),
					zoneIds: args.filterState.zoneIds?.map(({ id }) => id),
					prices: args.filterState.prices?.map((price) => parseInt(price)),
					location: args.searchLocation ? { latitude: args.searchLocation.lat, longitude: args.searchLocation.lng, distance: 30000 } : undefined,
				};

				const shouldExecuteSearch = computed(() => args.searchText?.length > 0 || args.searchLocation || getFilterCount(args.filterState) > 0);

				const data = shouldExecuteSearch.value
					? await this.api.queries.searchExplore(
							{ search: args.searchText, filters, searchContext: args.searchContext, cityId: city.id, first: args.size },
							{ useClientCache: false, accessToken: args.isSignedIn ? this.authentication.getAccessToken : undefined },
					  )
					: null;

				const detachedCitySearch = data?.city.search ?? null;
				const detachedSeach = data?.search ?? null;

				const tags = computed(() => detachedCitySearch?.tagResults.tags ?? []);
				const venues = computed(() => detachedCitySearch?.venueResults.venues ?? []);
				const lists = computed(() => detachedCitySearch?.listResults.lists ?? []);
				const zones = computed(() => detachedCitySearch?.zoneResults.zones ?? []);
				const places = computed(() => detachedCitySearch?.googleLocationResults.places ?? []);
				const users = computed(() => detachedSeach?.userResults.users ?? []);

				return { search: reactive({ wasSearchExecuted: shouldExecuteSearch, venues, lists, zones, tags, places, users }) };
			},
		});
	}
}
