<template>
	<div v-if="!isActiveUserPremium" class="premium-upgrade">
		<h3 class="message"><slot /></h3>
		<button class="redeem-button push-button-tertiary" @click="upgradeOnClick">Upgrade Now</button>
	</div>
</template>

<script setup>
import { computed, inject } from "vue";

import { getUserPremiumContext } from "../helpers/index.js";

const props = defineProps({
	feature: {
		type: String,
		required: true,
	},
	userAction: {
		type: String,
		required: true,
	},
});

const userActionDialogRef = inject("userActionDialogRef");
const currentEnvironment = inject("currentEnvironment");

const activeUser = computed(() => currentEnvironment.value?.activeUser.value);
const isActiveUserPremium = computed(() => (activeUser.value ? getUserPremiumContext(activeUser.value).isPremium : false));

const upgradeOnClick = () => {
	userActionDialogRef.value.open({ feature: props.feature, userAction: props.userAction });
};
</script>

<style scoped lang="scss">
@import "../assets/styles/variables_new.scss";

.premium-upgrade {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: calc($spacing / 2);

	.message {
		// width: 80vw;
		margin: 0 $spacing;
		text-align: center;
	}

	.redeem-button {
		width: 13rem;
	}
}

@media (min-width: $bp-medium) {
	.premium-upgrade {
		.message {
			// width: 100%;
		}
	}
}
</style>
