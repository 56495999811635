<template>
	<div class="info-section">
		<div class="current-city" @click="citySwitchButtonPressed">
			<div class="label">Current City</div>
			<div class="value">{{ capitalize(routeParams.cityName ?? "???") }}</div>
		</div>
		<div class="tag-line">Curating The World</div>
	</div>
</template>

<script setup>
import { capitalize, inject } from "vue";

import { safeHapticsVibrate, useRouteParams } from "../helpers/index.js";

const routeParams = useRouteParams();
const citySwitchingDialog = inject("citySwitchingDialog");

const citySwitchButtonPressed = async () => {
	citySwitchingDialog.value.toggle();
	await safeHapticsVibrate({ duration: 20 });
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/variables_new.scss";

.info-section {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	gap: calc($spacing/2);
	margin-bottom: calc($spacing * 2);
	font-size: $text-size-quaternary;

	> .current-city {
		display: flex;
		flex-direction: column;
		cursor: pointer;

		> .label {
			margin-right: $spacing;
		}
		> .value {
			font-weight: $text-bold-primary;
			margin-right: $spacing;
		}
	}

	> .tag-line {
		color: $text-color-tertiary;
		font-weight: $text-bold-primary;
		font-size: $text-size-tertiary;
		// white-space: nowrap;
	}
}
</style>
