<template>
	<div :class="{ 'error-message-container': true, 'full-screen': props.fullScreen }">
		<div class="error-message">
			<img class="warning-icon" src="../assets/icons/warning.svg" />
			<h2>
				<slot v-if="hasDefaultSlot" name="default" /><span v-else>{{ errorHeading }}</span>
			</h2>
			<h3>
				<slot name="secondary">Please reload your screen.</slot>
			</h3>

			<div class="action-tray">
				<button v-if="props.retry" class="push-button-tertiary" @click="props.retry">Retry</button>
				<button v-else class="push-button-tertiary" @click="reloadPageOnClick">Reload</button>
				<button class="push-button-tertiary" @click="gotoHomeFeed">Home</button>
			</div>
			<span class="further-instructions">(if the error persists, press the home button)</span>
			<div v-if="canShowDetailedError" class="toggle-detailed-error" @click="toggleDetailedError">({{ toggleDetailedErrorText }})</div>
			<div v-if="canShowDetailedError && isShowingDetailedError" class="detailed-message">
				<h3>Detailed Error:</h3>
				<pre>{{ errorText }}</pre>
			</div>
			<QRCode :data="dataDogLink" class="qr-code" :width="120" :height="120" />
		</div>
	</div>
</template>

<script setup>
import { computed, inject, ref, useSlots } from "vue";
import { useRouter } from "vue-router";

import { PageNames } from "../constants/index.js";
import { urlBuilder } from "../helpers/index.js";

import QRCode from "./QRCode.vue";
import { getErrorStack } from "../errors/errorHelpers.js";

const ERROR_CODE_HEADINGS = {
	DEFAULT: "Sorry there was an error!",
	// API_REQUEST_ERROR: "Sorry there seems to be a problem with your internet connection!",
};

const props = defineProps({
	error: {
		type: [String, Object],
		default: null,
	},
	retry: {
		type: Function,
		default: null,
	},
	fullScreen: {
		type: Boolean,
		default: false,
	},
});

const logging = inject("logging");
const persistentQueries = inject("persistentQueries");

const router = useRouter();
const slots = useSlots();
const hasDefaultSlot = computed(() => !!slots.default);

const errorCode = computed(() => props.error?.extensions?.code);
const errorHeading = computed(() => ERROR_CODE_HEADINGS[errorCode.value ?? "DEFAULT"]);
const errorText = computed(() => {
	if (typeof props.error === "string") {
		return props.error;
	} else if (props.error instanceof Error) {
		return props.error.stack ? getErrorStack(props.error) : props.error;
	} else {
		return "An error occurred. Unable to get message!";
	}
});
const dataDogLink = computed(() => logging.dataDogLink.value);

const canShowDetailedError = computed(() => !!props.error);
const isShowingDetailedError = ref(false);
const toggleDetailedError = () => {
	isShowingDetailedError.value = !isShowingDetailedError.value;
};
const toggleDetailedErrorText = computed(() => {
	return isShowingDetailedError.value ? "hide detailed error" : "show detailed error";
});

const reloadPageOnClick = () => {
	window.location.reload();
};

function gotoHomeFeed() {
	const BASE_URL = "https://www.example.com";
	const url = router.resolve(urlBuilder(PageNames.HOME_FEED));
	const finalUrl = new URL(url.href, BASE_URL);
	const persistedQuery = persistentQueries.getQuery() ?? {};
	Object.entries(persistedQuery).forEach(([key, value]) => {
		finalUrl.searchParams.set(key, value);
	});
	const urlString = finalUrl.toString().replace(BASE_URL, "");
	window.location.href = urlString;
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/variables_new.scss";

.error-message-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	&.full-screen {
		height: 100%;
	}

	.error-message {
		display: flex;
		flex-direction: column;
		gap: $spacing;
		align-items: center;
		width: 90%;
		color: $text-color-primary;

		.warning-icon {
			width: 32px;
		}

		h2 {
			font-size: $text-size-header;
			font-weight: $text-bold-secondary;
			margin: 0;
			text-align: center;
		}

		h3 {
			font-size: $text-size-header-secondary;
			font-weight: normal;
			margin: 0;
			text-align: center;
		}

		.retry-button {
			width: 60px;
			color: $text-color-primary;
		}

		.toggle-detailed-error {
			font-size: 0.6rem;
			opacity: 50%;
		}

		.further-instructions {
			font-size: $text-size-secondary;
			color: $text-color-secondary;

			& > a {
				font-size: $text-size-secondary;
				color: $text-color-secondary;
			}

			@media (pointer: fine) {
				& > a:hover {
					color: $text-color-primary;
				}
			}
		}

		.detailed-message {
			width: 95%;
			background-color: $background-color-error;
			border: 1px solid $border-color-error;
			border-radius: $border-radius-primary;

			h3 {
				margin: $spacing;
			}

			pre {
				max-height: 30vh;
				margin: $spacing;
				overflow: auto;
				white-space: pre-wrap;
			}
		}

		.action-tray {
			display: flex;
			flex-direction: row;
			gap: calc($spacing/ 2);
		}
	}
}

@media (min-width: $bp-medium) {
	.error-message-container {
		.error-message {
			max-width: 80vw;
		}
	}
}
</style>
