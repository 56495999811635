<template>
	<div>
		<dl>
			<dt>Current Environment</dt>
			<dd>{{ currentEnvironmentName }}</dd>
			<dt>API Version</dt>
			<dd>{{ currentEnvironmentApiVersion }}</dd>
			<dt>Client Version</dt>
			<dd>{{ config.clientVersion }}</dd>
			<dt>Client Version Code</dt>
			<dd>{{ config.clientVersionCode }}</dd>
			<dt><label for="changeEnvironment">Change Environment</label></dt>
			<dd>
				<select id="changeEnvironment" @change="changeEnvironment">
					<option v-for="name in environmentNames" :key="name" :value="name" :selected="name === currentEnvironmentName">{{ name }}</option>
				</select>
			</dd>
		</dl>
	</div>
</template>

<script setup>
import { ref, unref, inject, computed, onMounted } from "vue";

import { useQueryString } from "../../functions/query-string/index.js";

const environmentManager = inject("environmentManager");
const config = inject("config");

const environmentNames = computed(() => (isMounted.value ? Object.entries(environmentManager.allEnvironments).map(([environmentName]) => environmentName) : []));
const currentEnvironmentName = computed(() => (isMounted.value ? environmentManager.currentEnvironment.value?.name : null));
const currentEnvironmentApiVersion = computed(() => (isMounted.value ? unref(environmentManager.currentEnvironment.value?.apiVersion) : null));

const queryString = useQueryString({
	environment: null,
});

const changeEnvironment = async (event) => {
	const newEnvironmentName = event?.srcElement?.value;
	queryString.set("environment", newEnvironmentName);
};

const isMounted = ref(false);
onMounted(() => {
	isMounted.value = true;
});
</script>

<style lang="scss" scoped>
@import "../../assets/styles/variables_new.scss";

dl {
	display: flex;
	flex-flow: row wrap;
	gap: $spacing;
	margin: 0;
}
dt {
	flex-basis: calc(35% - ($spacing * 2));
	border: 1px solid $border-color-primary;
	background: $background-color-tertiary;
	color: $text-color-secondary;
	text-align: right;
	text-align: right;
	padding: $spacing $spacing $spacing 0;
	box-sizing: border-box;
}
dd {
	flex-basis: calc(65% - ($spacing * 2));
	flex-grow: 1;
	border: 1px solid $border-color-primary;
	margin-left: 0;
	padding: 0 $spacing;
	display: flex;
	flex-direction: column;
	justify-content: center;
	box-sizing: border-box;

	select {
		max-width: 200px;
	}
}
</style>
