import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

import loggingMessages from "./UnlockVenueBookingAvailability.logging-messages.js";

export default class {
	constructor({ logger, modelBuilder, api, authentication }) {
		this.logger = logger.nested({ name: "Model" });
		this.modelBuilder = modelBuilder;
		this.api = api;
		this.authentication = authentication;
	}

	async execute({ venueId, reservationToken }) {
		const response = await this.api.mutations.unlockVenueBookingAvailability(
			{ venueId, reservationToken },
			{ accessToken: this.authentication.isAuthenticated.value ? this.authentication.getAccessToken : undefined },
		);

		const errors = response.errors;
		if (errors?.length > 0) {
			this.logger.log(loggingMessages.criticalErrorUnlockingVenueBookingAvailability, { venueId, errors: errors.map(({ message }) => message) });
			return { success: false, error: "Error reserving time slot with venue" };
		} else {
			const unlockAvailability = response.data?.venue.book.unlockAvailability;
			if (!unlockAvailability.success) {
				this.logger.log(loggingMessages.errorUnlockingVenueBookingAvailability, { venueId, error: unlockAvailability.error });
				return { success: false, error: unlockAvailability.error };
			} else {
				return unlockAvailability;
			}
		}
	}
}
