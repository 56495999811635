<template>
	<Page v-if="isAllowedToViewVenue">
		<DialogFullScreenPageLayout class="venue-details-wrapper" :show-header-on-desktop="false">
			<template #header>
				<h2>{{ venue.name }}</h2>
				<h3>{{ venue.addressLine2 }}</h3>
			</template>
			<template #header-tray-right>
				<ShareButton :title="venue.name" :text="venue.shortDescription" dialog-title="Share Venue" :show-label="false" class="share-button" />
			</template>
			<template #default>
				<PerkDialog :perk="perk" />
				<div ref="venueDetailsContainerRef" :class="{ 'venue-details-container': true, 'arrived-at-bottom': scroll.arrivedState.bottom }">
					<VenueDetailImages :venue="venue" />
					<div class="venue-details">
						<div class="venue-header-desktop">
							<div class="venue-title">
								<h2>{{ venue.name }}</h2>
								<h3>{{ venue.addressLine2 }}</h3>
							</div>
						</div>
						<div class="action-tray">
							<section>
								<VenueInUserLists :venue="venue" :city-id="zone.area().city().id" :user-lists="userLists" orientation="vertical" :show-venue-count="false" />
								<PerkButton v-if="perk" :perk="perk" :venue="venue" class="perk-button-mobile" />
							</section>
						</div>
						<VenueCountSummary :venue="venue" />
						<div class="venue-content">
							<VenueDetailAbout :venue="venue" />
							<VenueDetailTags :venue="venue" />
							<VenueDetailPrice :venue="venue" />
							<VenueDetailOpeningHours :venue="venue" />
							<VenueDetailLocation :venue="venue" />
							<VenueDetailContact :venue="venue" />
							<VenueDetailSocial :venue="venue" />
							<span class="disclaimer">onezone may receive commission on restaurant and hotel reservations</span>
						</div>
						<VenueNavigation :venue="venue" class="venue-navigation-desktop" />
					</div>
				</div>
				<VenueNavigation :venue="venue" :class="{ 'venue-navigation-mobile': true, 'arrived-at-bottom': scroll.arrivedState.bottom }" />
			</template>
		</DialogFullScreenPageLayout>
	</Page>
	<PremiumUpgradeMessage v-else :feature="PremiumFeature.CITY" :user-action="suggestedUserActionToViewVenue">
		Only premium members are allowed to view venues in {{ cityName }}.<br /><br />
		Access more cities with onezone premium
	</PremiumUpgradeMessage>
</template>

<script setup>
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/zoom";
import { computed, inject, watch, ref } from "vue";
import { useScroll } from "@vueuse/core";

import { PremiumFeature } from "../../constants/index.js";
import { useScrollMemory } from "../../functions/scroll-memory/index.js";

import PremiumUpgradeMessage from "../PremiumUpgradeMessage.vue";
import VenueInUserLists from "../VenueInUserLists.vue";
import VenueNavigation from "./VenueNavigation.vue";
import PerkButton from "./PerkButton.vue";
import ShareButton from "../ShareButton.vue";
import PerkDialog from "./PerkDialog.vue";
import VenueCountSummary from "./VenueCountSummary.vue";
import Page from "../Page.vue";
import DialogFullScreenPageLayout from "../DialogFullScreenPageLayout.vue";
import VenueDetailLocation from "./VenueDetailLocation.vue";
import VenueDetailTags from "./VenueDetailTags.vue";
import VenueDetailOpeningHours from "./VenueDetailOpeningHours.vue";
import VenueDetailAbout from "./VenueDetailAbout.vue";
import VenueDetailPrice from "./VenueDetailPrice.vue";
import VenueDetailContact from "./VenueDetailContact.vue";
import VenueDetailSocial from "./VenueDetailSocial.vue";
import VenueDetailImages from "./VenueDetailImages.vue";

const props = defineProps({
	venue: {
		type: Object,
		required: true,
	},
	userLists: {
		type: Object,
		required: true,
	},
});

const tracking = inject("tracking");

const venueDetailsContainerRef = ref(null);
const scroll = useScroll(venueDetailsContainerRef, { passive: true });
useScrollMemory({ containerRef: venueDetailsContainerRef });

const venue = computed(() => props.venue);
const userLists = computed(() => props.userLists);
const isAllowedToViewVenue = computed(() => venue.value?.canView?.canView);
const suggestedUserActionToViewVenue = computed(() => venue.value?.canView?.action);
const perk = computed(() => (props.venue.perks()?.length > 0 ? props.venue.perks()[0] : null));
const zone = computed(() => props.venue.zone());
const cityName = computed(() => {
	return venue.value.zone().area().city().name;
});

watch(
	venue,
	async () => {
		if (venue.value) {
			await tracking?.viewVenue(venue.value, perk.value);
		}
	},
	{ immediate: true },
);
</script>

<style lang="scss" scoped>
@import "../../assets/styles/variables_new.scss";

$max-width-venue-carousel: 460px;
$max-height-venue-carousel-mobile: max(50dvh, 480px);
$max-height-venue-details-desktop: 700px;

.venue-details-wrapper {
	:deep(.content) {
		padding: 0;
		margin: 0;
	}

	.venue-header-desktop {
		display: none;
	}

	:deep(.venue-navigation-mobile) {
		// @include box-shadow($color: $text-color-primary, $opacity: 0.05, $x: 0px, $y: -2px, $blur: 8px);
		margin: 0;

		&.arrived-at-bottom {
			box-shadow: none;
		}
	}

	:deep(.venue-details-container) {
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		// height: 100%;
		overflow-y: auto;
		gap: calc($spacing / 2);
		overscroll-behavior: none;
		// @include fade-out-content;

		&.arrived-at-bottom {
			mask-image: none;
		}

		.venue-details {
			flex: 1;
			display: flex;
			flex-direction: column;
			gap: calc($spacing * 1);
			margin: 0 calc($spacing * 1) $spacing calc($spacing * 1);

			.action-tray {
				display: flex;
				justify-content: space-between;
				gap: $spacing;

				section {
					display: flex;
					gap: calc($spacing * 1.5);
				}
			}

			.venue-content {
				display: flex;
				gap: calc($spacing * 2);
				flex-direction: column;
				padding-right: calc($spacing / 2);
				box-sizing: border-box;
				min-height: 20px;

				> .disclaimer {
					width: 100%;
					font-size: $text-size-tertiary;
					color: $text-color-tertiary;
					text-align: center;
				}

				> * {
					&:not(.disclaimer) {
						&:not(:first-child) {
							// padding: calc($spacing * 1.5) 0;
							// border-top: $border-size-primary solid $border-color-primary;
						}
						&:first-child {
							// padding: calc($spacing * 0.5) 0 calc($spacing * 1.5) 0;
						}
					}
					&:last-child {
						// padding-bottom: calc($spacing);
					}
				}
			}
		}
	}

	:deep(.venue-navigation-desktop) {
		display: none;
	}
}

@media (min-width: $bp-medium) {
	.venue-details-wrapper {
		:deep(.share-button) {
			display: none;
		}

		:deep(.venue-details-container) {
			mask-image: none;
			padding: 0;
			flex-direction: row;
			width: 100%;
			max-height: $max-height-venue-details-desktop;
			overflow: hidden;
			justify-content: stretch;
			gap: $spacing;

			.venue-details {
				margin: $spacing $spacing $spacing 0;
				overflow: auto;

				.venue-header-desktop {
					display: flex;
					flex-wrap: wrap;
					gap: calc($spacing/2);
					justify-content: center;
					align-items: center;
					padding: 0 0 calc($spacing / 2) 0;
					margin-right: calc($spacing * 1.5);
					box-sizing: border-box;

					h2,
					h3 {
						margin: 0;
						vertical-align: bottom;
					}

					h2 {
						display: inline;
						font-size: $text-size-quaternary;
						line-height: calc($text-size-header-tertiary + 0.1rem);
						// white-space: nowrap;
					}

					h3 {
						display: inline;
						// line-height: calc($text-size-header-tertiary + 0.1rem);
						font-size: $text-size-header-secondary;
						font-weight: normal;
						white-space: nowrap;
						margin-left: calc($spacing / 2);
					}

					.venue-title {
						width: 100%;
						// display: flex;
						// flex-wrap: wrap;
						// justify-content: space-between;
						// gap: calc($spacing/2);
						// align-items: baseline;
					}
				}

				.venue-content {
					flex: 1;
					overflow: auto;
				}
			}
		}

		:deep(.venue-navigation-mobile) {
			display: none;
		}
		:deep(.venue-navigation-desktop) {
			display: flex;
			border: none;
			// border: $border-size-primary solid $border-color-primary;
			// border-radius: $border-radius-primary;
		}
	}
}
</style>
